import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { Container, Row, Col, Card, CardHeader, CardBody, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import SweetAlert from 'sweetalert2'

import Breadcrumb from '../../../layout/breadcrumb';

import { useGetAll, useDelete } from '../../../hooks/';

import Form from "../../form";
import { apiUrl, rqModel, pathname, columns, schema } from "./settings";

const MfMusic = () => {
    const history = useHistory();

    const commercialsQuery = useGetAll(apiUrl, rqModel);

    const [currentCommercials, setCommercial] = useState(null);
    const [viewType, setViewType] = useState(null);
    const [commercialId, setCommercialId] = useState(null);

    const deleteData = useDelete(apiUrl, rqModel, commercialId)

    const toggleTable = () => {
        setViewType(null);
        setCommercial(null);
    }

    const createView = () => {
        setViewType("form");
        setCommercial(null);
        window.history.pushState(null, null, `${pathname}?view=create`);
    }

    const editView = (commercial) => {
        setViewType("form");
        setCommercial(commercial);
        window.history.pushState(null, null, `${pathname}?view=edit`);
    }

    const createSuccess = (data) => {
        toast.success(`Spot ${data.name} ${data.name.last} criado!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const editSuccess = (data) => {
        toast.success(`Spot ${data.name} ${data.name.last} editado!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const deleteRequest = async (_id) => {
        await setCommercialId(_id);
        SweetAlert.fire({
            title: 'Atenção!',
            text: 'Confirma que pretende apagar a Música?',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then(async (result) => {
                if (result.value) {
                    await deleteData.mutate();
                    if (deleteData.isIdle) {
                        toast.success("Música apagada!", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                }
            })
    }

    return (
        <Fragment>
            <Breadcrumb parent="Marketing" title="Mf-Music" />
            <Container fluid={true}>
                {!commercialsQuery.isLoading ? (
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader>
                                    <span>{"Descrição"}</span>
                                </CardHeader>
                                <CardBody>
                                    <div className="todo">
                                        <div className="todo-list-wrapper">
                                            <div className="todo-list-container">
                                                <div className="mark-all-tasks">
                                                    <div className="mark-all-tasks-container">
                                                        {viewType !== "form" && <Button color="primary" onClick={createView}>{"Criar"}</Button>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {viewType === "form" ? (
                                            <Form data={currentCommercials} toggleTable={toggleTable} schema={schema} rqModel={rqModel} route={apiUrl} createSuccess={createSuccess} editSuccess={editSuccess} pathname={pathname} />
                                        ) : currentCommercials && viewType === "profile" ? (
                                            <Form data={currentCommercials} toggleTable={toggleTable} schema={schema} rqModel={rqModel} route={apiUrl} createSuccess={createSuccess} editSuccess={editSuccess} pathname={pathname} />
                                        ) : (
                                            <div className="table-responsive product-table">
                                                <DataTable
                                                    noHeader
                                                    columns={columns}
                                                    data={commercialsQuery.data && commercialsQuery.data.results.map((commercial) => ({
                                                        name: `${commercial.name}`,
                                                        url: `${commercial.url.split("/")[commercial.url.split("/").length - 1]}`,
                                                        ANFs: `${commercial.ANFs.length}`,
                                                        action: <div>
                                                            <Button color="primary" size="xs" className="ml-2" onClick={() => deleteRequest(commercial._id)}><i className="fa fa-trash"></i></Button>
                                                            {/* <Button color="primary" size="xs" className="ml-2" onClick={() => history.push(`${process.env.PUBLIC_URL}/mf-music/${commercial._id}`)}><i className="fa fa-eye"></i></Button> */}
                                                            <Button color="primary" size="xs" className="ml-2" onClick={() => editView(commercial)}><i className="fa fa-pencil"></i></Button>
                                                        </div>
                                                    }))}
                                                    highlightOnHover={true}
                                                    pointerOnHover={true}
                                                    pagination={true}
                                                    paginationPerPage={25}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    <Row style={{ minHeight: 500 }}>
                        <Col sm="12">
                            <div className="loader-box loader-abs">
                                <div className="loader-7" />
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
        </Fragment>
    )
}

export default MfMusic
