import React, { Fragment, useState } from 'react';
import { Col, Card, CardHeader, CardBody, Button, Media, Collapse } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import ScrollArea from 'react-scrollbar';

import three from "../../../../assets/images/user/3.png";

const RightBar = ({ data }) => {
    const history = useHistory();

    const [isIntro, setisIntro] = useState(true);
    const [isFollowers, setisFollowers] = useState(true);

    // const [isFollowings, setisFollowings] = useState(true);
    // const [isFriends, setisFriends] = useState(true);

    return data ? (
        <Fragment>
            <Col xl="12 xl-50 box-col-6">
                <Card>
                    <CardHeader>
                        <h5 className="mb-0">
                            <Button color="link pl-0" onClick={() => setisIntro(!isIntro)} data-toggle="collapse" data-target="#collapseicon7" aria-expanded={isIntro} aria-controls="collapseicon7">
                                {"Dados"}
                            </Button>
                        </h5>
                    </CardHeader>
                    <Collapse isOpen={isIntro}>
                        <CardBody className="filter-cards-view"><span className="f-w-600">{"Morada"}:</span>
                            <p>
                                {data.address.street}
                                <br />
                                {data.address.code1 + '-' + data.address.code2}
                                <br />
                                {data.address.city}
                            </p>

                            <span className="f-w-600">{"Contactos"}:</span>
                            <p>
                                {data.contact.fix_number}
                                <br />
                                {data.contact.mobile_number}
                            </p>

                            <div className="social-network theme-form"><span className="f-w-600">{"Redes Sociais"}</span>
                                <Button color="social-btn btn-fb mb-2 text-center" onClick={() => history.push(data.facebook)}><i className="fa fa-facebook m-r-5"></i>Facebook</Button>
                                <Button color="social-btn btn-fb mb-2 text-center" onClick={() => history.push(data.instagram)} style={{ backgroundColor: '#FF2873' }}><i className="fa fa-instagram m-r-5"></i>Instagram</Button>
                            </div>
                        </CardBody>
                    </Collapse>
                </Card>
            </Col>
            {/* <Col xl="12 xl-50 box-col-6">
                <Card>
                    <CardHeader>
                        <h5 className="mb-0">
                            <Button color="link pl-0" onClick={() => setisFriends(!isFriends)} data-toggle="collapse" data-target="#collapseicon15" aria-expanded={isFriends} aria-controls="collapseicon15">
                                {"Equipa"}
                            </Button>
                        </h5>
                    </CardHeader>
                    <Collapse isOpen={isFriends}>
                        <CardBody className="avatar-showcase filter-cards-view">
                            {data.employees && data.employees.map((user) => (
                                <div className="d-inline-block friend-pic"><Media body className="img-50 rounded-circle" src={user && user.profilePhoto ? user.profilePhoto : three} alt={user.username} /></div>
                            ))}
                            {data.coaches && data.coaches.map((user) => (
                                <div className="d-inline-block friend-pic"><Media body className="img-50 rounded-circle" src={user && user.profilePhoto ? user.profilePhoto : three} alt={user.username} /></div>
                            ))}
                            {data.representatives && data.representatives.map((user) => (
                                <div className="d-inline-block friend-pic"><Media body className="img-50 rounded-circle" src={user && user.profilePhoto ? user.profilePhoto : three} alt={user.username} /></div>
                            ))}
                        </CardBody>
                    </Collapse>
                </Card>
            </Col> */}
            <Col xl="12 xl-50 box-col-6">
                <Card>
                    <CardHeader>
                        <h5 className="mb-0">
                            <Button color="link pl-0" onClick={() => setisFollowers(!isFollowers)} data-toggle="collapse" data-target="#collapseicon8" aria-expanded={isFollowers} aria-controls="collapseicon8">
                                {"Equipa"}
                            </Button>
                        </h5>
                    </CardHeader>
                    <Collapse isOpen={isFollowers}>
                        <CardBody className="social-list filter-cards-view" style={{ minHeight: 403, maxHeight: 403 }}>
                            <ScrollArea
                                style={{ minHeight: 350, maxHeight: 350 }}
                                horizontal={false}
                                vertical={true}
                            >
                                {data.employees && data.employees.map((user) => (
                                    <Media>
                                        <img className="img-50 img-fluid m-r-20 rounded-circle" alt={user.username} src={user && user.profilePhoto ? user.profilePhoto : three} />
                                        <Media body><span className="d-block">{user.name.first} {user.name.last}</span>{user.phoneNumber}</Media>
                                    </Media>
                                ))}
                                {data.coaches && data.coaches.map((user) => (
                                    <Media>
                                        <img className="img-50 img-fluid m-r-20 rounded-circle" alt={user.username} src={user && user.profilePhoto ? user.profilePhoto : three} />
                                        <Media body><span className="d-block">{user.name.first} {user.name.last}</span>{user.phoneNumber}</Media>
                                    </Media>
                                ))}
                                {/* {data.representatives && data.representatives.map((user) => (
                                    <Media>
                                        <img className="img-50 img-fluid m-r-20 rounded-circle" alt={user.username} src={user && user.profilePhoto ? user.profilePhoto : three} />
                                        <Media body><span className="d-block">{user.name.first} {user.name.last}</span>{user.phoneNumber}</Media>
                                    </Media>
                                ))} */}
                            </ScrollArea>
                        </CardBody>
                    </Collapse>
                </Card>
            </Col>
            {/* <Col xl="12 xl-50 box-col-6">
                <Card>
                    <CardHeader>
                        <h5 className="mb-0">
                            <Button color="link pl-0" onClick={() => setisFollowings(!isFollowings)} data-toggle="collapse" data-target="#collapseicon11" aria-expanded={isFollowings} aria-controls="collapseicon11">
                                {Following}
                            </Button>
                        </h5>
                    </CardHeader>
                    <Collapse isOpen={isFollowings}>
                        <CardBody className="social-list filter-cards-view">
                            <Media>
                                <img className="img-50 img-fluid m-r-20 rounded-circle" alt="" src={three} />
                                <Media body><span className="d-block">{SarahLoren}</span><a href="#javascript">{AddFriend}</a></Media>
                            </Media>
                            <Media>
                                <img className="img-50 img-fluid m-r-20 rounded-circle" alt="" src={two} />
                                <Media body><span className="d-block">{BuckyBarnes}</span><a href="#javascript">{AddFriend}</a></Media>
                            </Media>
                            <Media>
                                <img className="img-50 img-fluid m-r-20 rounded-circle" alt="ten" src={ten} />
                                <Media body><span className="d-block">{ComerenDiaz}</span><a href="#javascript">{AddFriend}</a></Media>
                            </Media>
                            <Media>
                                <img className="img-50 img-fluid m-r-20 rounded-circle" alt="threeImg" src={three} />
                                <Media body><span className="d-block">{JasonBorne}</span><a href="#javascript">{AddFriend}</a></Media>
                            </Media>
                            <Media>
                                <img className="img-50 img-fluid m-r-20 rounded-circle" alt="elevenImg" src={eleven} />
                                <Media body><span className="d-block">{AndewJon}</span><a href="#javascript">{AddFriend}</a></Media>
                            </Media>
                        </CardBody>
                    </Collapse>
                </Card>
            </Col> */}
            {/* <Col xl="12 xl-50 box-col-6">
                <Card>
                    <CardHeader>
                        <h5 className="mb-0">
                            <Button color="link pl-0" onClick={() => setisPhotos(!isPhotos)} data-toggle="collapse" data-target="#collapseicon9" aria-expanded={isPhotos} aria-controls="collapseicon9">
                                {LatestPhotos}
                            </Button>
                        </h5>
                    </CardHeader>
                    <Collapse isOpen={isPhotos}>
                        <CardBody className="photos filter-cards-view px-0">
                            <ul className="text-center">
                                <li>
                                    <div className="latest-post">
                                        <img className="img-fluid" alt="post1" src={post1} />
                                    </div>
                                </li>
                                <li>
                                    <div className="latest-post">
                                        <img className="img-fluid" alt="post2" src={post2} />
                                    </div>
                                </li>
                                <li>
                                    <div className="latest-post">
                                        <img className="img-fluid" alt="post3" src={post3} />
                                    </div>
                                </li>
                                <li>
                                    <div className="latest-post">
                                        <img className="img-fluid" alt="post4" src={post4} />
                                    </div>
                                </li>
                                <li>
                                    <div className="latest-post">
                                        <img className="img-fluid" alt="post5" src={post5} />
                                    </div>
                                </li>
                                <li>
                                    <div className="latest-post">
                                        <img className="img-fluid" alt="post6" src={post6} />
                                    </div>
                                </li>
                                <li>
                                    <div className="latest-post">
                                        <img className="img-fluid" alt="post7" src={post7} />
                                    </div>
                                </li>
                                <li>
                                    <div className="latest-post">
                                        <img className="img-fluid" alt="post8" src={post8} />
                                    </div>
                                </li>
                                <li>
                                    <div className="latest-post">
                                        <img className="img-fluid" alt="post9" src={post9} />
                                    </div>
                                </li>
                            </ul>
                        </CardBody>
                    </Collapse>
                </Card>
            </Col> */}
            {/* <Col xl="12 xl-50 box-col-6">
                <Card><Media className="img-fluid" src={timeline4} alt="" /></Card>
            </Col> */}
        </Fragment>
    ) : null;
};

export default RightBar;