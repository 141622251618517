export const apiUrl = "/api/v1/orders/table_v2";
export const rqModel = "store_orders";

export const columns = [
    {
        name: "Número",
        selector: "number",
        sortable: false,
        center: true,
    },
    {
        name: "Farmácia",
        selector: "name",
        sortable: false,
        center: true,
        grow: 2
    },
    {
        name: "Data",
        selector: "date",
        sortable: false,
        center: true,
        grow: 2
    },
    {
        name: "Estado",
        selector: "state",
        sortable: false,
        center: true,
    },
    {
        name: "Total",
        selector: "total",
        sortable: false,
        center: true,
    },
    {
        name: "Opções",
        selector: "action",
        sortable: false,
        center: true,
    },
];