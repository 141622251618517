import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Card, Button, Media, CardBody, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { Truck, Gift, CreditCard, Clock } from 'react-feather';
import { toast } from 'react-toastify';
import axios from 'axios';

import { useGetOne } from '../../../hooks/';

import { setMyPharma } from '../../../redux/actionTypes';

const StoreProductProfile = (props) => {
    const history = useHistory();

    const dispatch = useDispatch();

    const user = useSelector(content => content.User.user);
    const myPharma = useSelector(content => content.User.myPharma);

    const { data, status } = useGetOne(`/api/v2/store_products/${props.match && props.match.params && props.match.params.id}`, props.match && props.match.params && props.match.params.id, 'store_products');

    if (status === "error") history.push(`${process.env.PUBLIC_URL}/pages/errors/error404`);

    const [quantity, setQuantity] = useState(1);

    const [addCartLoading, setAddCartLoading] = useState(false);
    const [addCartMessage, setAddCartMessage] = useState(false);

    if (data?.groupedItems?.length) {
        data.groupedPrice = data.groupedItems.reduce((accumulator, object) => {
            return accumulator + object.price;
          }, 0);
    }

    const minusQty = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1)
        }
    }

    const changeQty = (e) => {
        setQuantity(parseInt(e.target.value))
    }

    const plusQty = () => {
        if (quantity >= 1) {
            setQuantity(quantity + 1)
        }
    }

    const addcart = (product, qty) => {
        if (qty < product.minimumOrderQuantity) {
            toast.error(<><span>{"Inválido"}</span><br /><span>{'Este produto tem um mínimo de ' + product.minimumOrderQuantity + ' unidades'}</span></>, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setAddCartLoading(true);

            axios.post(`/api/v1/pharma/${myPharma.ANF}/add_to_cart`, { productId: product._id, quantity: qty })
                .then((result) => {
                    setAddCartLoading(false);

                    const newPharma = { ...myPharma };
                    newPharma.cart = result.data.cart;
                    dispatch(setMyPharma(newPharma));

                    setAddCartMessage({ message: 'Adicionado!', type: 'success' });
                    setTimeout(() => {
                        setAddCartMessage(null);
                    }, 2250);
                })
                .catch((error) => {
                    setAddCartMessage({ message: 'Produto Indisponível', type: 'error' });
                    setTimeout(() => {
                        setAddCartMessage(null);
                    }, 2250);
                });
        }
    }

    return (
        <Fragment>
            <Container fluid={true}>
                {data ? (
                    <Row className="product-page-main">
                        <Col xl="4">
                            <Row>
                                <Col xl="12">
                                    <div className="product-slider">
                                        <Media src={data.photo} alt={data.name} className="img-fluid" />
                                    </div>
                                </Col>
                                <Col xl="12 xl-cs-35">
                                    {data.lab && (
                                        <Card>
                                            <CardBody>
                                                <div className="filter-block">
                                                    <h4>{"Fornecedor"}</h4>
                                                    <ul>
                                                        <li>{data.lab.name} ({data.lab.code})</li>
                                                        {data.lab.minimumAmount && (
                                                            <li>Quantidade Mínima <br />{data.lab.minimumAmount} €</li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    )}
                                    <Card>
                                        <CardBody>
                                            <div className="collection-filter-block">
                                                <ul>
                                                    <li>
                                                        <div className="media"><Truck />
                                                            <div className="media-body">
                                                                <h5>{"Portes de Envio"}</h5>
                                                                <p>{(data.lab.shippingAmount) ? <>{data.lab.shippingAmount} €</> : <>Grátis</>}</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="media"><CreditCard />
                                                            <div className="media-body">
                                                                <h5>{"Forma de Pagamento"}</h5>
                                                                <p>{"Débito Directo"}</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl="8">
                            <Card>
                                <CardBody>
                                    <div className="product-page-details">
                                        <h3>{data.name}{data.stock <= 0 && (<span style={{color: '#f99'}}> [Sem Stock]</span>)}</h3>
                                    </div>
                                    <div className="product-price f-28">
                                        {data.groupedPrice ? data.groupedPrice.toFixed(2) : data.price.toFixed(2)} €
                                    </div>
                                    <div className=" f-14">
                                        Quantidade Mínima: {data.minimumOrderQuantity} Unidades
                                    </div>
                                    <hr />
                                    <div dangerouslySetInnerHTML={{ __html: data.description }} />
                                    <hr />
                                    {user && user.role === "FarmaciaRole" && (
                                        <div className="product-qnty mt-2">
                                            <b>{"Quantidade"}</b>
                                            <InputGroup className="bootstrap-touchspin mt-2">
                                                <InputGroupAddon addonType="prepend">
                                                    <Button color="primary btn-square" className="bootstrap-touchspin-down" onClick={minusQty}><i className="fa fa-minus"></i></Button>
                                                </InputGroupAddon>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText className="bootstrap-touchspin-prefix" style={{ display: "none" }}></InputGroupText>
                                                </InputGroupAddon>
                                                <Input className="touchspin text-center" type="text" name="quantity" value={quantity} onChange={(e) => changeQty(e)} style={{ display: "block" }} />
                                                <InputGroupAddon addonType="append">
                                                    <InputGroupText className="bootstrap-touchspin-postfix" style={{ display: "none" }}></InputGroupText>
                                                </InputGroupAddon>
                                                <InputGroupAddon addonType="append" className="ml-0">
                                                    <Button color="primary btn-square" className="bootstrap-touchspin-up" onClick={plusQty}><i className="fa fa-plus"></i></Button>
                                                </InputGroupAddon>
                                            </InputGroup>
                                            <hr />
                                            <div className="m-t-15">
                                                <Button color="primary" className="mr-2 mt-2" onClick={() => addcart(data, quantity)} disabled={addCartLoading || addCartMessage || (data.stock <= 0)}>
                                                    {addCartLoading ? (<i className="fa fa-spin fa-spinner" />) : addCartMessage ? addCartMessage.message : "Adicionar ao Carrinho"}
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    <Row style={{ minHeight: 500 }}>
                        <Col sm="12">
                            <div className="loader-box loader-abs">
                                <div className="loader-7" />
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
        </Fragment>
    );
}

export default StoreProductProfile;