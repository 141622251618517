import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Container, Row, Col, Form, Input, Label, Button, TabContent, TabPane } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from 'axios';

import { Password, Username, ForgotPassword, LoginWithJWT } from '../constant';

import { setUser } from "../redux/actionTypes";

const Logins = () => {
    const dispatch = useDispatch()
    const { register, handleSubmit, errors } = useForm();

    const [loading, setLoading] = useState(false);
    const [requestMessage, setRequestMessage] = useState(null);

    const login = (values) => {
        setLoading(true);

        const formOptions = {
            username: values.username,
            password: values.password
        }

        return axios.post('/api/v1/accounts/login', formOptions)
            .then(user => {
                setLoading(false);
                setRequestMessage("Login efetuado com sucesso");
                setTimeout(() => {
                    setRequestMessage(null);
                }, 3000);

                dispatch(setUser(user));
                window.location.href = `${process.env.PUBLIC_URL}/`;

                return user;
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
                setRequestMessage("Ocorreu um erro");
                setTimeout(() => {
                    setRequestMessage(null);
                }, 3000);
            });
    }

    return (
        <Container fluid={true} className="p-0">
            <Row>
                <Col xs="12">
                    <div className="login-card">
                        <div>
                            <div>
                                <a className="logo" href="https://www.maisfarmacia.org/" target="_blank" rel="noopener noreferrer" style={{ width: 200, marginLeft: 'auto', marginRight: 'auto' }}>
                                    <img className="img-fluid for-light" src={require("../assets/images/logo/logo.png")} alt="logotipo" />
                                    <img className="img-fluid for-dark" src={require("../assets/images/logo/logo.png")} alt="logotipo" />
                                </a>
                            </div>
                            <div className="login-main login-tab">
                                <TabContent activeTab={"jwt"} className="content-login">
                                    <TabPane className="fade show" tabId={"jwt"}>
                                        <Form className="theme-form needs-validation" noValidate="" onSubmit={handleSubmit(login)}>
                                            <h2>Entrar no Portal</h2>
                                            <p>{"Bem-vindo à plataforma maisFarmácia Business Intelligence."} <br /> {"Grupo maisfarmácia."}</p>
                                            <div className="">
                                                <Label className="col-form-label">{Username}</Label>
                                                <Input className="form-control" type="text" name="username" innerRef={register({ required: true })} />
                                                <span className="form-error">{errors.username && 'Este campo é obrigatório'}</span>
                                            </div>
                                            <div className="">
                                                <Label className="col-form-label">{Password}</Label>
                                                <Input className="form-control" type={"password"} name="password" innerRef={register({ required: true })} />
                                                <span className="form-error">{errors.password && 'Este campo é obrigatório'}</span>
                                            </div>
                                            <div className=" mb-0 mt-4">
                                                <Button color="primary" className="btn-block" type="submit" disabled={loading || requestMessage}>
                                                    {loading ? (<i className="fa fa-spin fa-spinner" />) : requestMessage ? (requestMessage) : (LoginWithJWT)}
                                                </Button>
                                            </div>
                                            <div className=" mb-0 mt-4">
                                                <NavLink to={`${process.env.PUBLIC_URL}/recuperar-password`}>{ForgotPassword}</NavLink>
                                            </div>

                                            {/* <div className="form-row mb-0 mt-4">
                                                <Button href="/" color="outline-light" className="btn-block" type="submit">
                                                    Sair do modo demonstração
                                                </Button>
                                            </div> */}
                                            
                                            <p className="mt-4 ml-0">maisfarmácia &copy; 2024 - {process.env.REACT_APP_VERSION_TAG}</p>

                                        </Form>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default withRouter(Logins);