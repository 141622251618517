export const apiUrl = "/api/v1/kpi_list";
export const rqModel = "kpis";
export const pathname = `${process.env.PUBLIC_URL}/administracao/premiacao`;

export const columns = [
    {
        name: "Nome",
        selector: "nome",
        sortable: true,
        center: false,
        width:'450px',
        wrap: true
    },
    {
        name: "Categoria",
        selector: "categoria",
        sortable: true,
        center: true,
    },
    {
        name: "Ranges",
        selector: "ranges",
        sortable: true,
        center: true,
    },
    {
        name: "Pontuação",
        selector: "scores",
        sortable: false,
        center: true,
    },
    {
        name: "Validade",
        selector: "dates",
        sortable: false,
        center: true,
    },
    {
        name: "Ciclo",
        selector: "kc_id",
        sortable: true,
        center: true,
    },
    {
        name: "Opções",
        selector: "actions",
        sortable: false,
        center: true,
    },
];

export const schema = [
    {
        colSize: "4",
        label: "Nome",
        name: "nome",
        id: "nome",
        type: "text",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "4",
        label: "Categoria",
        name: "categoria",
        id: "categoria",
        type: "select",
        required: true,
        requiredMessage: "Este campo é obrigatório",
        multi: false,
        optionLabel: 'label',
        optionValue: 'value',
        options: [
            {
                value: "otc-alinhamento",
                label: "OTC Alinhamento",
            },
            {
                value: "otc-desafio",
                label: "OTC Desafio Sellout",
            },
            {
                value: "generic-obj",
                label: "Cumprimento Objectivo",
            },
            {
                value: "a3",
                label: "Visibilidade Gestão de Categorias",
            },
            {
                value: "e",
                label: "Atualização Leitura Newsletter",
            },
            {
                value: "b2",
                label: "Comunicação Partilha Facebook",
            },
            {
                value: "a4",
                label: "Encomendas Push",
            },
            {
                value: "training",
                label: "Formações",
            },
            {
                value: "registerFacebook",
                label: "Registo Facebook",
            },
            {
                value: "tacitap",
                label: "Resposta Push Tácita Plus",
            },
            {
                value: "newsRead",
                label: "Leitura Newsletter",
            },
            {
                value: "webinar",
                label: "Webinar",
            },
            {
                value: "outros",
                label: "Outros",
            },
            {
                value: "inqueritoAnswerTacit",
                label: "Resposta Push Aceite por Defeito",
            },
            {
                value: "inqueritoAnswerOptional",
                label: "Resposta Push Opcional",
            },
            {
                value: "inqueritoAnswerMandatory",
                label: "Resposta Push Obrigatória",
            },
            {
                value: "visibilityGC",
                label: "Gestão de Categorias",
            },
        ]
    },
    {
        colSize: "2",
        label: "Válido de",
        name: "date_start",
        id: "date_start",
        type: "datepicker",
    },
    {
        colSize: "2",
        label: "Válido a",
        name: "date_end",
        id: "date_end",
        type: "datepicker",
    },
    {
        name: "hr",
        type: "component",
    },
    {
        colSize: "2",
        label: "Range 1",
        name: "colrange1",
        id: "colrange1",
        type: "text"
    },
    {
        colSize: "2",
        label: "Range 2",
        name: "colrange2",
        id: "colrange2",
        type: "text"
    },
    {
        colSize: "2",
        label: "Range 3",
        name: "colrange3",
        id: "colrange3",
        type: "text",
    },

    {
        name: "hr",
        type: "component",
    },

    {
        colSize: "2",
        label: "Pontos 1",
        name: "colrel1",
        id: "colrel1",
        type: "text"
    },
    {
        colSize: "2",
        label: "Pontos 2",
        name: "colrel2",
        id: "colrel2",
        type: "text"
    },
    {
        colSize: "2",
        label: "Range 3",
        name: "colrel3",
        id: "colrel3",
        type: "text",
    },
    {
        colSize: "2",
        label: "Pontos 4",
        name: "colrel4",
        id: "colrel4",
        type: "text",
    },

    {
        name: "hr",
        type: "component",
    },
    {
        colSize: "3",
        label: "Pontuação Máx",
        name: "max_score",
        id: "max_score",
        type: "text",
    },

    {
        colSize: "2",
        label: "Ciclo",
        name: "kc_id",
        id: "kc_id",
        type: "select",
        required: true,
        requiredMessage: "Este campo é obrigatório",
        multi: false,
        optionLabel: 'label',
        optionValue: 'value',
        options: [
            {
                value: "1",
                label: "Ciclo 1",
            },
            {
                value: "2",
                label: "Ciclo 2",
            },
            {
                value: "3",
                label: "Ciclo 3",
            },
            {
                value: "4",
                label: "Ciclo 4",
            },
            {
                value: "5",
                label: "Ciclo 5",
            },
            {
                value: "6",
                label: "Ciclo 6",
            },
            {
                value: "7",
                label: "Ciclo 7",
            },
        ]
    },

    {
        colSize: "2",
        label: "Manual",
        name: "is_manual",
        id: "is_manual",
        type: "checkbox",
    },
];
