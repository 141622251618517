import React, { Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Card, CardHeader, Media, Input, Form } from 'reactstrap';

import three from "../../../../assets/images/user/3.png";

import getFinalRole from '../../../../data/getFinalRole';

const TeamTab = ({ data, laboratories }) => {


    const [filtered, setFiltered] = useState(data.representatives);

    const setTempSearchKeyword = (tempSearchKeyword) => {
        console.log('setTempSearchKeyword');
        const moreFiltered = data.representatives.filter((user) => {
            console.log('user', user);
            return user.name.first.toLowerCase().includes(tempSearchKeyword.toLowerCase())
            || user.name.last.toLowerCase().includes(tempSearchKeyword.toLowerCase())
            || user.email.toLowerCase().includes(tempSearchKeyword.toLowerCase());
        })
        setFiltered(moreFiltered)
    };

    return (
        <Fragment>
             <Row style={{marginBottom: 20}}>
                    <Col md="12">
                        <Form onSubmit={(ev) => ev.preventDefault()}>
                            <Input
                                className="form-control"
                                type="text"
                                placeholder="Procurar por Laboratório ou DIM"
                           
                                onChange={(e) => {
                                    setTempSearchKeyword(e.target.value);
                                
                                }}
                           
                            />
                                {/* <Button color="primary" onClick={handleSearchKeyword}><i className="fa fa-search" /></Button> */}
                        </Form>
                    </Col>
                </Row>
            <Row>
                {data.employees && data.employees.map((user, i) => (
                    <Col sm="6" md="4" xl="4 box-col-6 xl-50" key={i}>
                        <Card className="custom-card">
                            <CardHeader>
                                <Media body className="img-fluid" src={require(`../../../../assets/images/team_banner_2_sm.png`)} alt="team-bg" />
                            </CardHeader>
                            <div className="card-profile">
                                <Media body className="rounded-circle" src={user.profilePhoto && user.profilePhoto !== "" ? user.profilePhoto : three} alt="" />
                            </div>
                            <div className="text-center profile-details">
                                <NavLink to={`${process.env.PUBLIC_URL}/utilizador/${user._id}`}><h4>{user.name.first} {user.name.last}</h4></NavLink>
                                <h6>{user.email}</h6>
                                <h6>{getFinalRole(user) ? getFinalRole(user)._finalRole : "Não Definido"}</h6>
                            </div>
                            {/* <CardFooter className="row">
                                <Col sm="4 col-4">
                                    <h6>{"Seguidores"}</h6>
                                    <h3 className="counter">0</h3>
                                </Col>
                                <Col sm="4 col-4">
                                    <h6>{"Segue"}</h6>
                                    <h3><span className="counter">0</span></h3>
                                </Col>
                                <Col sm="4 col-4">
                                    <h6>{"Publicações"}</h6>
                                    <h3><span className="counter">0</span></h3>
                                </Col>
                            </CardFooter> */}
                        </Card>
                    </Col>
                ))}
                {/* {data.coaches && data.coaches.map((user, i) => (
                    <Col md="6" xl="4 box-col-6 xl-50" key={i}>
                        <Card className="custom-card">
                            <CardHeader>
                                <Media body className="img-fluid" src={require(`../../../../assets/images/team_bg.jpg`)} alt="team-bg" />
                            </CardHeader>
                            <div className="card-profile">
                                <Media body className="rounded-circle" src={user.profilePhoto && user.profilePhoto !== "" ? user.profilePhoto : three} alt="" />
                            </div>
                       
                            <div className="text-center profile-details">
                                <NavLink to={`${process.env.PUBLIC_URL}/utilizador/${user._id}`}><h4>{user.name.first} {user.name.last}</h4></NavLink>
                                <h6>{user.email}</h6>
                                <h6>{user.mf_role === 'pcj' && "Pharmacy Coach Júnior"}</h6>
                                <span>{user.phoneNumber}</span>
                            </div>

                        </Card>
                    </Col>
                ))} */}
             

                { filtered.map((user, i) => (
                    <Col md="6" xl="4 box-col-6 xl-50" key={i}>
                        <Card className="custom-card">
                            <CardHeader>
                                <Media body className="img-fluid" src={require(`../../../../assets/images/team_banner_2_sm.png`)} alt="team-bg" />
                            </CardHeader>
                            <div className="card-profile">
                                <Media body className="rounded-circle" src={user.profilePhoto && user.profilePhoto !== "" ? user.profilePhoto : three} alt="" />
                            </div>
                            {/* <ul className="card-social">
                                <li><a href="#javascript"><i className="fa fa-facebook"></i></a></li>
                                <li><a href="#javascript"><i className="fa fa-google-plus"></i></a></li>
                                <li><a href="#javascript"><i className="fa fa-twitter"></i></a></li>
                                <li><a href="#javascript"><i className="fa fa-instagram"></i></a></li>
                                <li><a href="#javascript"><i className="fa fa-rss"></i></a></li>
                            </ul> */}
                            <div className="text-center profile-details">
                                <NavLink to={`${process.env.PUBLIC_URL}/utilizador/${user._id}`}><h4>{user.name.first} {user.name.last}</h4></NavLink>
                                <h6>{laboratories && laboratories.find((lab) => lab.fornecedor_id === user.fornecedor_id) ? laboratories.find((lab) => lab.fornecedor_id === user.fornecedor_id).name : ''}</h6>
                                <h6>{user.email}</h6>
                                <span>{user.phoneNumber}</span>
                                <span>{user.fornecedor_name}</span>
                            </div>
                            {/* <CardFooter className="row">
                                <Col sm="4 col-4">
                                    <h6>{"Seguidores"}</h6>
                                    <h3 className="counter">0</h3>
                                </Col>
                                <Col sm="4 col-4">
                                    <h6>{"Segue"}</h6>
                                    <h3><span className="counter">0</span></h3>
                                </Col>
                                <Col sm="4 col-4">
                                    <h6>{"Publicações"}</h6>
                                    <h3><span className="counter">0</span></h3>
                                </Col>
                            </CardFooter> */}
                        </Card>
                    </Col>
                ))}
            </Row>
        </Fragment>
    );
};

export default TeamTab;