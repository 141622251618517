import React, { useRef } from "react";

import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/js/third_party/embedly.min.js";

import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/third_party/embedly.min.css";

import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/css/plugins/colors.min.css';

import Froala from "react-froala-wysiwyg";

export const FroalaEditor = (props) => {
    const ref = useRef();

    const handleModelChange = (newModel) => {
        props.onChange(newModel);
    };

    return (
        <>
            <Froala
                ref={ref}
                // pastePlain={true}
                model={props.model && props.model}
                onModelChange={handleModelChange}
                tag="textarea"
                config={{
                    key: 'lSA3D-17B1B3G2B1H1E1rmuD1F-11rB-8fpvjtB-11xuA-8B2prfD-13C3B2E2F2E3B1C2C7E2A1==',
                    attribution: false,
                    height: 300,
                    placeholderText: props.placeholder ? props.placeholder : "Comece a escrever",
                    toolbarInline: false,
                    toolbarButtons: [
                        'paragraphFormat', '|', 'bold', 'italic', 'underline', 'strikeThrough', 'formatOL', 'formatUL', '|', 'fontFamily', 'fontSize', 'colors', 'clearFormatting',
                        '|', 'lineHeight', 'paragraphStyle', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'quote', '-',
                        'insertLink', 'insertImage', 'insertVideo', '|', 'insertTable', 'getPDF', 'print', '|', 'undo', 'redo'
                    ],

                    colorsBackground: 'default',
                    colorsButtons: 'default',
                    colorsText: 'default',

                    imageUploadParam: 'file_data',
                    imageUploadURL: '/api/v1/uploads',
                    imageUploadParams: { id: props.imageUploadParams },
                    imageUploadMethod: 'POST',
                    imageMaxSize: 5 * 1024 * 1024,
                    imageAllowedTypes: ['jpeg', 'jpg', 'png'],
                    // pluginsEnabled: ['color']

                    
                    // toolbarButtons: {
                    //     moreText: {
                    //         buttons: [
                    //             "bold",
                    //             "italic",
                    //             "underline",
                    //             "strikeThrough",
                    //             "subscript",
                    //             "superscript",
                    //             "fontFamily",
                    //             "fontSize",
                    //             "textColor",
                    //             "backgroundColor",
                    //             "inlineClass",
                    //             "inlineStyle",
                    //             "clearFormatting"
                    //         ]
                    //     },
                    //     moreParagraph: {
                    //         buttons: [
                    //             "alignLeft",
                    //             "alignCenter",
                    //             "formatOLSimple",
                    //             "alignRight",
                    //             "alignJustify",
                    //             "formatOL",
                    //             "formatUL",
                    //             "paragraphFormat",
                    //             "paragraphStyle",
                    //             "lineHeight",
                    //             "outdent",
                    //             "indent",
                    //             "quote"
                    //         ]
                    //     },
                    //     moreRich: {
                    //         buttons: [
                    //             "insertLink",
                    //             "insertImage",
                    //             "insertVideo",
                    //             "insertTable",
                    //             "emoticons",
                    //             "fontAwesome",
                    //             "specialCharacters",
                    //             "embedly",
                    //             "insertFile",
                    //             "insertHR"
                    //         ]
                    //     },
                    //     moreMisc: {
                    //         buttons: [
                    //             "undo",
                    //             "redo",
                    //             "fullscreen",
                    //             "print",
                    //             "getPDF",
                    //             "spellChecker",
                    //             "selectAll",
                    //             "html",
                    //             "help"
                    //         ],
                    //         align: "right",
                    //         buttonsVisible: 2
                    //     }
                    // },
                    // pluginsEnabled: [
                    //     "table",
                    //     "spell",
                    //     "quote",
                    //     "save",
                    //     "quickInsert",
                    //     "paragraphFormat",
                    //     "paragraphStyle",
                    //     "help",
                    //     "draggable",
                    //     "align",
                    //     "link",
                    //     "lists",
                    //     "file",
                    //     "image",
                    //     "emoticons",
                    //     "url",
                    //     "video",
                    //     "embedly",
                    //     "colors",
                    //     "entities",
                    //     "inlineClass",
                    //     "inlineStyle",
                    //     // 'codeBeautif '
                    //     // 'spellChecker',
                    //     "imageTUI"
                    // ]
                }}
            />
        </>
    );
};