import React, { Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Row, Col, Card, CardHeader, CardBody, Button, Form, FormGroup, Input, Table } from 'reactstrap';
import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt";
import { useQuery } from 'react-query';
import moment from 'moment';
import 'moment/locale/pt';

import ScrollArea from 'react-scrollbar';
import PaginationDT from '../../pagination';

import { rqModel } from "./settings";
import { getWithFilters } from "../../../api/";
import errorImg from '../../../assets/images/search-not-found.png';

registerLocale("pt", pt);

const Newsletters = () => {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(1);

    const [tempSearchKeyword, setTempSearchKeyword] = useState('');
    const [searchKeyword, setSearchKeyword] = useState(null);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const getNews = (page, limit, searchKeyword) => getWithFilters({ apiUrl: '/api/v1/newsletters/table', pagination: { page: page, limit: limit }, filters: { search: searchKeyword } });

    const {
        isLoading,
        data,
    } = useQuery([rqModel, page, searchKeyword], () => getNews(page, limit, searchKeyword), { keepPreviousData: true, staleTime: 300000 });

    const handleSearch = (keyword) => {
        if (tempSearchKeyword.length > 3) {
            setPage(1)
            setSearchKeyword(tempSearchKeyword)
        } else if (tempSearchKeyword.length <= 3) {
            setPage(1)
            setSearchKeyword('')
        }
    }

    return (
        <Fragment>
            <Container fluid={true}>
                <Row className="justify-content-center">
                    <Col xl="4 xl-60" className="feature-products mt-4">
                        <Form onSubmit={(ev) => ev.preventDefault()}>
                            <FormGroup className="m-0">
                                <Input
                                    className="form-control"
                                    type="text"
                                    placeholder="Procurar (mais de 3 caracteres)"
                                    defaultValue={searchKeyword}
                                    value={tempSearchKeyword}
                                    onChange={(e) => {
                                        setTempSearchKeyword(e.target.value);
                                        if (e.target.value.length === 0) handleSearch();
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') handleSearch();
                                    }}
                                />
                            </FormGroup>
                        </Form>
                    </Col>
                    <Col xl="5 xl-60" className="feature-products mt-4">
                        <Row>
                            <Col xl="4">
                                <DatePicker
                                    style={{ height: 50 }}
                                    className="form-control digits search-datepicker"
                                    dateFormat="dd/MM/yyyy"
                                    locale={'pt'}
                                    selected={startDate ? moment(startDate)._d : startDate}
                                    onChange={(date) => {
                                        setStartDate(date)
                                    }}
                                />
                            </Col>
                            <Col xl="4">
                                <DatePicker
                                    style={{ height: 50 }}
                                    className="form-control digits search-datepicker"
                                    dateFormat="dd/MM/yyyy"
                                    locale={'pt'}
                                    selected={endDate ? moment(endDate)._d : endDate}
                                    onChange={(date) => {
                                        setEndDate(date)
                                    }}
                                />
                            </Col>
                            <Col xl="4">
                                <Button color="primary" style={{ height: 50 }} onClick={handleSearch}>{"Procurar"}</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col xl="9 xl-60">
                        <hr />
                        
                        {!isLoading && data.results.length <= 0 ?
                            <div className="search-not-found text-center">
                                <div>
                                    <img className="img-fluid second-search" src={errorImg} alt="no-results" />
                                    <p>{"Não foram encontrados resultados"}</p>
                                </div>
                            </div>
                            :
                            <Row>
                                {!isLoading && data && (
                                                <PaginationDT
                                                    centered
                                                    totalPage={data.totalPages}
                                                    currentPage={data.page}
                                                    limit={data.limit}
                                                    onChangePage={setPage}
                                                />
                                            )}
                                {!isLoading ? data.results.map((data, i) =>
                                
                                    <Col xl="12">
                                        <h3 className="mb-4 mt-4">{data.title}</h3>
                                        <Row>
                                        
                                            <Col sm="6">
                                                <Card style={{ minHeight: 300 }}>
                                                    <CardHeader className="card-no-border">
                                                        <h5>{"Quadro maisFarmácia"}</h5>
                                                    </CardHeader>
                                                    <CardBody className="pt-0" style={{ minHeight: 325, maxHeight: 325 }}>
                                                        <div className="our-product">
                                                            <ScrollArea horizontal={false} vertical={true} style={{ minHeight: 325, maxHeight: 325 }}>
                                                                {data.quadroOperacional.length > 0 ? (
                                                                    
                                                                    <Row className="mb-6">
                                                                        {data.quadroOperacional.map((item) => (
                                                                            <>
                                                                                <Col sm="1">
                                                                                    <img className="img-fluid mb-3 d-flex mx-auto" style={{ height: 20, width:20 }} src={item.cover ? item.cover : require("../../../assets/images/search-not-found.png")} alt={item.title} />
                                                                                </Col>
                                                                                <Col sm="11">
                                                                                    <div className="media-body">
                                                                                        <NavLink to={`${process.env.PUBLIC_URL}/noticias/${item._id}`}><span>{item.title}</span></NavLink>
                                                                                    </div>
                                                                                </Col>
                                                                                <hr></hr>
                                                                            </>
                                                                        ))}
                                                                    </Row>
                                                                     
                                                                ) : (
                                                                    <Row className="justify-content-center align-items-center">
                                                                        <h4>Sem Notícias</h4>
                                                                    </Row>
                                                                )}
                                                            </ScrollArea>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col sm="6">
                                                <Card style={{ minHeight: 300 }}>
                                                    <CardHeader className="card-no-border">
                                                        <h5>{"Campanhas"}</h5>
                                                    </CardHeader>
                                                    <CardBody className="pt-0" style={{ minHeight: 325, maxHeight: 325 }}>
                                                        <div className="our-product">
                                                            <ScrollArea horizontal={false} vertical={true} style={{ minHeight: 325, maxHeight: 325 }}>
                                                                {data.campanhas.length > 0 ? (
                                                                    
                                                                    <Row className="mb-6">
                                                                        { data.campanhas.map((item) => (
                                                                            <>
                                                                                <Col sm="1">
                                                                                    <img className="img-fluid mb-3 d-flex mx-auto" style={{ height: 20, width:20 }} src={item.cover ? item.cover : require("../../../assets/images/search-not-found.png")} alt={item.title} />
                                                                                </Col>
                                                                                <Col sm="11">
                                                                                    <div className="media-body">
                                                                                        <NavLink to={`${process.env.PUBLIC_URL}/noticias/${item._id}`}><span>{item.title}</span></NavLink>
                                                                                    </div>
                                                                                </Col>
                                                                                <hr></hr>
                                                                            </>
                                                                        ))}
                                                                    </Row>
                                                                   
                                                                ) : (
                                                                    <Row className="justify-content-center align-items-center">
                                                                        <h4>Sem Notícias</h4>
                                                                    </Row>
                                                                )}
                                                            </ScrollArea>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col sm="6">
                                                <Card style={{ minHeight: 300 }}>
                                                    <CardHeader className="card-no-border">
                                                        <h5>{"Negociações"}</h5>
                                                    </CardHeader>
                                                    <CardBody className="pt-0" style={{ minHeight: 325, maxHeight: 325 }}>
                                                        <div className="our-product">
                                                            <ScrollArea horizontal={false} vertical={true} style={{ minHeight: 325, maxHeight: 325 }}>
                                                                {data.negociacoes.length > 0 ? (
                                                                
                                                                    <Row className="mb-6">
                                                                        {data.negociacoes.map((item) => (
                                                                            <>
                                                                                <Col sm="1">
                                                                                    <img className="img-fluid mb-3 d-flex mx-auto" style={{ height: 20, width:20 }} src={item.cover ? item.cover : require("../../../assets/images/search-not-found.png")} alt={item.title} />
                                                                                </Col>
                                                                                <Col sm="11">
                                                                                    <div className="media-body">
                                                                                        <NavLink to={`${process.env.PUBLIC_URL}/noticias/${item._id}`}><span>{item.title}</span></NavLink>
                                                                                    </div>
                                                                                </Col>
                                                                                <hr></hr>
                                                                            </>
                                                                        ))}
                                                                    </Row>
                                                                   
                                                                ) : (
                                                                    <Row className="justify-content-center align-items-center">
                                                                        <h4>Sem Notícias</h4>
                                                                    </Row>
                                                                )}
                                                            </ScrollArea>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col sm="6">
                                                <Card style={{ minHeight: 300 }}>
                                                    <CardHeader className="card-no-border">
                                                        <h5>{"Formação e Serviços"}</h5>
                                                    </CardHeader>
                                                    <CardBody className="pt-0" style={{ minHeight: 325, maxHeight: 325 }}>
                                                        <div className="our-product">
                                                            <ScrollArea horizontal={false} vertical={true} style={{ minHeight: 325, maxHeight: 325 }}>
                                                                {data.formacoes.length > 0 ? (
                                                                    <Row className="mb-6">
                                                                        {data.formacoes.map((item) => (
                                                                            <>
                                                                                <Col sm="1">
                                                                                    <img className="img-fluid mb-3 d-flex mx-auto" style={{ height: 20, width:20 }} src={item.cover ? item.cover : require("../../../assets/images/search-not-found.png")} alt={item.title} />
                                                                                </Col>
                                                                                <Col sm="11">
                                                                                    <div className="media-body">
                                                                                        <NavLink to={`${process.env.PUBLIC_URL}/noticias/${item._id}`}><span>{item.title}</span></NavLink>
                                                                                    </div>
                                                                                </Col>
                                                                                <hr></hr>
                                                                            </>
                                                                        ))}
                                                                    </Row>
                                                                
                                                                ) : (
                                                                    <Row className="justify-content-center align-items-center">
                                                                        <h4>Sem Notícias</h4>
                                                                    </Row>
                                                                )}
                                                            </ScrollArea>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col sm="6">
                                                <Card style={{ minHeight: 300 }}>
                                                    <CardHeader className="card-no-border">
                                                        <h5>{"Outras Informações"}</h5>
                                                    </CardHeader>
                                                    <CardBody className="pt-0" style={{ minHeight: 325, maxHeight: 325 }}>
                                                        <div className="our-product">
                                                            <ScrollArea horizontal={false} vertical={true} style={{ minHeight: 325, maxHeight: 325 }}>
                                                                {data.outras.length > 0 ? (
                                                                    <Row className="mb-6">
                                                                        {data.outras.map((item) => (
                                                                            <>
                                                                                <Col sm="1">
                                                                                    <img className="img-fluid mb-3 d-flex mx-auto" style={{ height: 20, width:20 }} src={item.cover ? item.cover : require("../../../assets/images/search-not-found.png")} alt={item.title} />
                                                                                </Col>
                                                                                <Col sm="11">
                                                                                    <div className="media-body">
                                                                                        <NavLink to={`${process.env.PUBLIC_URL}/noticias/${item._id}`}><span>{item.title}</span></NavLink>
                                                                                    </div>
                                                                                </Col>
                                                                                <hr></hr>
                                                                            </>
                                                                        ))}
                                                                    </Row>
                                                                   
                                                                ) : (
                                                                    <Row className="justify-content-center align-items-center">
                                                                        <h4>Sem Notícias</h4>
                                                                    </Row>
                                                                )}
                                                            </ScrollArea>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            
                                            <Col sm="12">
                                                <Card>
                                                    <CardHeader className="card-no-border">
                                                        <h5>{"POWER-UPS"}</h5>
                                                    </CardHeader>
                                                    <CardBody className="p-0">
                                                        <Row className="mb-4 pb-4 chart-main align-items-center">
                                                            <Col xl="3" md="6" sm="6" className="box-col-6">
                                                                <a href="https://www.dropbox.com/sh/4axter2kgkjej06/AAAEj5wFLHI-IP_jv409SS3ua?dl=0" rel="noreferrer noopener" target="_blank">
                                                                    <div className="media border-none">
                                                                        <img className="img-fluid m-r-15" style={{ height: 150, marginLeft: 'auto', marginRight: 'auto' }} src={require("../../../assets/images/power_1.png")} alt={""} />
                                                                    </div>
                                                                </a>
                                                            </Col>
                                                            <Col xl="3" md="6" sm="6" className="box-col-6">
                                                                <a href="https://www.dropbox.com/sh/cd87e1jat7cvrwr/AAD5Pq9u_QAN4DBDNwQU-C2Ea?dl=0" rel="noreferrer noopener" target="_blank">
                                                                    <div className="media border-none">
                                                                        <img className="img-fluid m-r-15" style={{ height: 150, marginLeft: 'auto', marginRight: 'auto' }} src={require("../../../assets/images/power_2.png")} alt={""} />
                                                                    </div>
                                                                </a>
                                                            </Col>
                                                            <Col xl="3" md="6" sm="6" className="box-col-6">
                                                                <a href="https://www.dropbox.com/sh/6wpiuka3kp9d56f/AADJ0ZttU1etN4kP0DvmpWaoa?dl=0" rel="noreferrer noopener" target="_blank">
                                                                    <div className="media border-none">
                                                                        <img className="img-fluid m-r-15" style={{ height: 150, marginLeft: 'auto', marginRight: 'auto' }} src={require("../../../assets/images/power_3.png")} alt={""} />
                                                                    </div>
                                                                </a>
                                                            </Col>
                                                            <Col xl="3" md="6" sm="6" className="box-col-6">
                                                                <a href="https://www.dropbox.com/sh/q5rsrmmcyq9uhej/AACJKbonydPnvXWV96m77sCRa?dl=0" rel="noreferrer noopener" target="_blank">
                                                                    <div className="media border-none">
                                                                        <img className="img-fluid m-r-15" style={{ height: 150, marginLeft: 'auto', marginRight: 'auto' }} src={require("../../../assets/images/power_4.png")} alt={""} />
                                                                    </div>
                                                                </a>
                                                            </Col>
                                                            <Col xl="3" md="6" sm="6" className="box-col-6">
                                                                <a href="https://docs.google.com/forms/d/e/1FAIpQLScheAx8sTX36_ydQILlrXNyMVqCjtykkVUnZwMxoBKFREK8Sg/viewform" rel="noreferrer noopener" target="_blank">
                                                                    <div className="media border-none">
                                                                        <img className="img-fluid m-r-15" style={{ height: 150, marginLeft: 'auto', marginRight: 'auto' }} src={require("../../../assets/images/power_5.png")} alt={""} />
                                                                    </div>
                                                                </a>
                                                            </Col>
                                                        </Row>
                                                        {/* <Row className="mt-4">
                                                            <Col xl="12">
                                                                <div className="alert alert-primary" role="alert">
                                                                    Inquérito "A minha Farmácia é..." -
                                                                    <a href="https://form.jotform.com/200702694523350"> Responder</a>
                                                                </div>
                                                            </Col>
                                                        </Row> */}
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                        
                                    </Col>
                                    
                                ) : isLoading && (
                                    <div className="loader-box loader-abs">
                                        <div className="loader-7" />
                                    </div>
                                )}
                            </Row>
                        }
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default Newsletters