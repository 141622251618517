import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Card, CardHeader, Media, Button, Modal, ModalHeader, ModalBody } from 'reactstrap'
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import axios from 'axios';

import getFinalRole from "../../data/getFinalRole";

import { Email } from '../../constant'

import { useGetOne } from '../../hooks/'

const UserProfile = (props) => {
    const [modal, setModal] = useState(false);
    const [modalInfo, setModalInfo] = useState(null);

    const pharmacies = useSelector(content => content.Pharmacies.results);
    const loaded = useSelector(content => content.Pharmacies.loaded);

    const history = useHistory();
    const userQuery = useGetOne(`/api/v1/accounts/${props.params && props.params.id ? props.params.id : props && props.match && props.match.params.id ? props.match.params.id : null}`, props.params && props.params.id ? props.params.id : props && props.match && props.match.params.id ? props.match.params.id : null, 'users');

    if (userQuery.status === "error") history.push(`${process.env.PUBLIC_URL}/pages/errors/error404`);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const toggleModal = () => {
        setModal(!modal);
        setModalInfo(null);
    }

    const activateQlik = () => {
        SweetAlert.fire({
            title: 'Atenção!',
            text: 'Confirma que pretende renovar a tabela Qlik?',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then((result) => {
                if (result.value) {
                    axios.post(`/api/v1/laboratories/${props.match.params.id}/refresh-sql`)
                        .then((result) => {
                            toast.success("Tabela Renovada", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        })
                        .catch((error) => {
                            toast.error("Cancelado", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        });
                }
            })
    }

    const validateQlik = () => {
        axios.get(`/api/v1/accounts/${props.match.params.id}/validateQlik`)
            .then((results) => {
                setModal(true);
                setModalInfo(results.data);
            })
            .catch((error) => {
                toast.error("Ocorreu um erro", {
                    position: toast.POSITION.TOP_RIGHT
                });
                console.log(error);
            });
    }

    return (
        <Fragment>
            <Container fluid={true}>
                {userQuery.data && loaded ? (
                    <div className="user-profile">
                        <Row>
                            <Col sm="12" className="mt-4">
                                <Card className="card hovercard text-center">
                                    <CardHeader className="cardheader" style={{ backgroundImage: `url(${pharmacies.find((ph) => ph.ANF && ph.ANF === userQuery.data.anf_code && userQuery.data.anf_code) ? pharmacies.find((ph) => ph.ANF === userQuery.data.anf_code).extraData.banner : "../../../assets/images/social-app/social-image.png"})` }}></CardHeader>
                                    <Button color="primary" size="xs" className="ml-2" onClick={() => activateQlik()} style={{ position: 'absolute', right: 20, top: 20 }}>
                                        Ativar Qlik
                                    </Button>
                                    <Button color="primary" size="xs" className="ml-2" onClick={() => validateQlik()} style={{ position: 'absolute', right: 100, top: 20 }}>
                                        Validar Qlik
                                    </Button>
                                    <div className="user-image">
                                        <div className="avatar"><Media body src={userQuery.data.profilePhoto ? userQuery.data.profilePhoto : require('../../assets/images/user/7.jpg')} data-intro="This is Profile image" /></div>
                                    </div>
                                    <div className="info">
                                        <Row>
                                            <Col sm="6" lg="4" className="order-sm-1 order-xl-0">
                                                <Row >
                                                    <Col md="6">
                                                        <div className="ttl-info text-left">
                                                            <h6><i className="fa fa-envelope mr-2"></i> {Email}</h6><span>{userQuery.data.email}</span>
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="ttl-info text-left ttl-sm-mb-0">
                                                            <h6><i className="fa fa-user"></i>   {"Username"}</h6><span>{userQuery.data.username}</span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm="12" lg="4" className="order-sm-0 order-xl-1">
                                                <div className="user-designation">
                                                    <div className="title"><a target="_blank" href="#javascript">{`${userQuery.data.name.first} ${userQuery.data.name.last}`}</a></div>
                                                    <div className="desc mt-2">{getFinalRole(userQuery.data)._mainRole}</div>
                                                    <div className="desc mt-2">{getFinalRole(userQuery.data)._finalRole}</div>
                                                </div>
                                            </Col>
                                            <Col sm="6" lg="4" className="order-sm-2 order-xl-2">
                                                <Row>
                                                    <Col md="6">
                                                        <div className="ttl-info text-left ttl-xs-mt">
                                                            <h6><i className="fa fa-telegram"></i>   {"Telegram"}</h6><span>{userQuery.data.telegram_username}</span>
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="ttl-info text-left ttl-sm-mb-0">
                                                            {userQuery.data.role === "FarmaciaRole" ? (
                                                                <>
                                                                    <h6><i className="fa fa-location-arrow"></i>   {"ANF"}</h6><span>{userQuery.data.anf_code}</span>
                                                                </>
                                                            ) : userQuery.data.role === "AdminRole" ? (
                                                                <></>
                                                            ) : userQuery.data.role === "FornecedorRole" && (
                                                                <>
                                                                    <h6><i className="fa fa-location-arrow"></i>   {"ID"}</h6><span>{userQuery.data.fornecedor_id}</span>
                                                                </>
                                                            )}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <hr />
                                        {/* <div className="social-media step4" data-intro="This is your Social details">
                                            <ul className="list-inline">
                                                <li className="list-inline-item"><a href="#javascript"><i className="fa fa-facebook"></i></a></li>
                                                <li className="list-inline-item"><a href="#javascript"><i className="fa fa-google-plus"></i></a></li>
                                                <li className="list-inline-item"><a href="#javascript"><i className="fa fa-twitter"></i></a></li>
                                                <li className="list-inline-item"><a href="#javascript"><i className="fa fa-instagram"></i></a></li>
                                                <li className="list-inline-item"><a href="#javascript"><i className="fa fa-rss"></i></a></li>
                                            </ul>
                                        </div> */}
                                        {/* <div className="follow">
                                            <Row>
                                                <Col col="6" className="text-md-right border-right">
                                                    <div className="follow-num counter">0</div><span>{"Seguidores"}</span>
                                                </Col>
                                                <Col col="6" className="text-md-left">
                                                    <div className="follow-num counter">0</div><span>{"Segue"}</span>
                                                </Col>
                                            </Row>
                                        </div> */}
                                    </div>
                                </Card>
                            </Col>
                            {/* <Col sm="12" className="mt-4">
                                <Card>
                                    <div className="profile-img-style">
                                        <Row>
                                            <Col sm="8">
                                                <div className="media"><Media className="img-thumbnail rounded-circle mr-3" src={require("../../assets/images/user/7.jpg")} alt="Generic placeholder image" />
                                                    <div className="media-body align-self-center">
                                                        <h5 className="mt-0 user-name">{JOHANDIO}</h5>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col sm="4" className="align-self-center">
                                                <div className="float-sm-right"><small>{"10 Hours ago"}</small></div>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <p>{UserProfileDesc1}</p>
                                        <div className="img-container">
                                            <div id="aniimated-thumbnials">
                                                <a href="#javascript">
                                                    <Media className="img-fluid rounded" src={require("../../assets/images/other-images/profile-style-img3.png")} alt="gallery" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="like-comment mt-4">
                                            <ul className="list-inline">
                                                <li className="list-inline-item border-right pr-3">
                                                    <label className="m-0"><a href="#javascript"><i className="fa fa-heart"></i></a>  {Like}</label><span className="ml-2 counter">{"2659"}</span>
                                                </li>
                                                <li className="list-inline-item ml-2">
                                                    <label className="m-0"><a href="#javascript"><i className="fa fa-comment"></i></a>  {Comment}</label><span className="ml-2 counter">{"569"}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                            <Col sm="12">
                                <Card>
                                    <div className="profile-img-style">
                                        <Row>
                                            <Col sm="8">
                                                <div className="media"><Media className="img-thumbnail rounded-circle mr-3" src={require("../../assets/images/user/7.jpg")} alt="Generic placeholder image" />
                                                    <div className="media-body align-self-center">
                                                        <h5 className="mt-0 user-name">{JOHANDIO}</h5>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col sm="4" className="align-self-center">
                                                <div className="float-sm-right"><small>{"10 Hours ago"}</small></div>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <p>{UserProfileDesc1}</p>
                                        <Row className="mt-4 pictures">
                                            <a className="col-sm-6" href="#javascript">
                                                <Media body className="img-fluid rounded" src={require("../../assets/images/other-images/profile-style-img.png")} alt="gallery" />
                                            </a>
                                            <a className="col-sm-6" href="#javascript">
                                                <Media body className="img-fluid rounded" src={require("../../assets/images/other-images/profile-style-img.png")} alt="gallery" />
                                            </a>
                                        </Row>
                                        <div className="like-comment mt-4">
                                            <ul className="list-inline">
                                                <li className="list-inline-item border-right pr-3">
                                                    <label className="m-0"><a href="#javascript"><i className="fa fa-heart"></i></a>  {Like}</label><span className="ml-2 counter">{"2659"}</span>
                                                </li>
                                                <li className="list-inline-item ml-2">
                                                    <label className="m-0"><a href="#javascript"><i className="fa fa-comment"></i></a>  {Comment}</label><span className="ml-2 counter">{"569"}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Card>
                            </Col>

                            <Col sm="12">
                                <Card>
                                    <div className="profile-img-style">
                                        <Row>
                                            <Col sm="8">
                                                <div className="media"><Media className="img-thumbnail rounded-circle mr-3" src={require("../../assets/images/user/7.jpg")} alt="Generic placeholder image" />
                                                    <div className="media-body align-self-center">
                                                        <h5 className="mt-0 user-name">{JOHANDIO}</h5>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col sm="4" className="align-self-center">
                                                <div className="float-sm-right"><small>{"10 Hours ago"}</small></div>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <p>{UserProfileDesc2}</p>
                                        <div className="like-comment mt-4">
                                            <ul className="list-inline">
                                                <li className="list-inline-item border-right pr-3">
                                                    <label className="m-0"><a href="#javascript"><i className="fa fa-heart"></i></a>  {Like}</label><span className="ml-2 counter">{"2659"}</span>
                                                </li>
                                                <li className="list-inline-item ml-2">
                                                    <label className="m-0"><a href="#javascript"><i className="fa fa-comment"></i></a>  {Comment}</label><span className="ml-2 counter">{"569"}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Card>
                            </Col>

                            <Col sm="12">
                                <Card>
                                    <div className="profile-img-style">
                                        <Row>
                                            <Col sm="8">
                                                <div className="media"><Media className="img-thumbnail rounded-circle mr-3" src={require("../../assets/images/user/7.jpg")} alt="Generic placeholder image" />
                                                    <div className="media-body align-self-center">
                                                        <h5 className="mt-0 user-name">{JOHANDIO}</h5>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col sm="4" className="align-self-center">
                                                <div className="float-sm-right"><small>{"10 Hours ago"}</small></div>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row>
                                            <Col lg="12" xl="4">
                                                <div id="aniimated-thumbnials-3"><a href="#javascript"><Media body className="img-fluid rounded" src={require("../../assets/images/blog/img.png")} alt="gallery" /></a></div>
                                                <div className="like-comment mt-4 like-comment-lg-mb">
                                                    <ul className="list-inline">
                                                        <li className="list-inline-item border-right pr-3">
                                                            <label className="m-0"><a href="#javascript"><i className="fa fa-heart"></i></a>  {Like}</label><span className="ml-2 counter">{"2659"}</span>
                                                        </li>
                                                        <li className="list-inline-item ml-2">
                                                            <label className="m-0"><a href="#javascript"><i className="fa fa-comment"></i></a>  {Comment}</label><span className="ml-2 counter">{"569"}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </Col>
                                            <Col xl="6">
                                                <p>{UserProfileDesc3}</p>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col> */}
                        </Row>
                        <Modal isOpen={modal} toggle={toggleModal}>
                            <ModalHeader toggle={toggleModal}>
                                {"Dados Qlik"}
                            </ModalHeader>
                            <ModalBody>
                                {modalInfo && (<>
                                    <span>Farmácias Configuradas: {modalInfo.byAnfCode.results}</span>
                                    <br />
                                    <span>Laboratórios Configurados: {modalInfo.byLabCode.results}</span>
                                    <br />
                                    {modalInfo.checklabs.count === modalInfo.byLabCode.results && (
                                        <span>Laboratórios Validados: {modalInfo.checklabs.count}</span>
                                    )}
                                </>
                                )}
                            </ModalBody>
                        </Modal>
                    </div>
                ) : (
                    <Row style={{ minHeight: 500 }}>
                        <Col sm="12">
                            <div className="loader-box loader-abs">
                                <div className="loader-7" />
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
        </Fragment>
    );
}

export default UserProfile;