import React from 'react';
import { Row } from 'reactstrap';
import { ArrowLeft } from 'react-feather';
import { Link } from 'react-router-dom';

const ProfileTitle = (props) => {
    return (
        <div className="page-title">
            <Row style={{ paddingRight: 15, paddingLeft: 15 }}>
                {props.route ? (
                    <Link to={props.route}><ArrowLeft /></Link>
                ) : props.goBack && (
                    <Link onClick={props.goBack}><ArrowLeft /></Link>
                )}
                <h3 className="mb-1 ml-2">{props.title}</h3>
            </Row>
        </div>
    );
}

export default ProfileTitle;