export const apiUrl = "/api/v1/renting";
export const rqModel = "renting";

export const columns = [
    {
        name: "Data",
        selector: "createdOn",
        sortable: true,
        center: true,
    },
    {
        name: "Farmácia",
        selector: "pharma",
        sortable: true,
        center: true,
    },
    {
        name: "Periodo",
        selector: "period",
        sortable: true,
        center: true,
    },
    {
        name: "Equipamento",
        selector: "product",
        sortable: false,
        center: true,
    },
    {
        name: "Opções",
        selector: "action",
        sortable: false,
        center: true,
    },
];

export const schema = [
    {
        colSize: "6",
        label: "Nome",
        name: "name",
        id: "name",
        type: "text",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "2",
        label: "Compra em Bulk",
        name: "bulk",
        id: "bulk",
        type: "number",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "2",
        label: "Fee Atraso Diária",
        name: "latePerDayFee",
        id: "latePerDayFee",
        type: "number",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "2",
        label: "Fee Atraso Única",
        name: "latePerOrderFee",
        id: "latePerOrderFee",
        type: "number",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "12",
        label: "Produto",
        name: "productId",
        id: "productId",
        type: "select",
        required: true,
        requiredMessage: "Este campo é obrigatório",
        multi: false,
        optionLabel: 'name',
        optionValue: '_id',
        options: null,
        backendPopulated: true,
        api: {
            route: 'api/v2/store_products?fetch=renting'
        }
    },
    {
        colSize: "6",
        label: "Disponibilidade (De)",
        name: "availabilityRange.start",
        id: "availabilityRange.start",
        type: "datepicker",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "6",
        label: "Disponibilidade (A)",
        name: "availabilityRange.end",
        id: "availabilityRange.end",
        type: "datepicker",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
];
