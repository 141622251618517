import React, { useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Card, Button, Form, FormGroup, Input, Media, Modal, ModalHeader, ModalBody, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import axios from 'axios';

import Breadcrumb from '../../../layout/breadcrumb'

import PaginationDT from '../../pagination';

import { useGetAll } from '../../../hooks';
import { getWithFilters } from "../../../api/";
import { apiUrl, rqModel } from "./settings";

import errorImg from '../../../assets/images/search-not-found.png';
import notFound from '../../../assets/images/not-found.webp';

import { setMyPharma } from '../../../redux/actionTypes';

const Product = (props) => {
    const history = useHistory();

    const dispatch = useDispatch();
    const myPharma = useSelector(content => content.User.myPharma);

    const laboratories = useGetAll(`/api/v1/store_laboratories?fetch=all`, 'store_laboratories');
    const categories = useGetAll(`/api/v2/store_products/categories`, 'categories');

    if(laboratories.data) laboratories.data.results = laboratories.data.results.sort((a, b) => {
        let fa = a.name.toLowerCase(),
            fb = b.name.toLowerCase();
    
        if (fa < fb) {
            return -1;
        }
        if (fa > fb) {
            return 1;
        }
        return 0;
    });

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(12);
    const [laboratory, setLaboratory] = useState(null);
    const [category, setCategory] = useState(null);

    const [tempSearchKeyword, setTempSearchKeyword] = useState('');
    const [searchKeyword, setSearchKeyword] = useState('');

    const getProducts = (page, limit, searchKeyword, laboratory) => getWithFilters({ apiUrl: apiUrl, pagination: { page: page, limit: limit }, filters: { search: searchKeyword, laboratory: laboratory, category } });

    const {
        isLoading,
        data,
        error
    } = useQuery([rqModel, page, limit, searchKeyword, laboratory, category], () => getProducts(page, limit, searchKeyword, laboratory, category), { keepPreviousData: true, staleTime: 300000 });


    if (error && error.response) {
        history.push(`${process.env.PUBLIC_URL}/pages/errors/error${error.response.status}`);
    } else if (error) {
        history.push(`${process.env.PUBLIC_URL}/pages/errors/error500`);
    }

    const [open, setOpen] = useState(false);
    const [singleProduct, setSingleProduct] = useState(null);
    const [quantity, setQuantity] = useState(1);

    const [addCartLoading, setAddCartLoading] = useState(false);
    const [addCartMessage, setAddCartMessage] = useState(false);


    if(!isLoading && data) {
        data.results.forEach(el => {
            if(el.groupedItems?.length) {
                el.groupedPrice = el.groupedItems.reduce((accumulator, object) => {
                    return accumulator + object.price;
                  }, 0);
            }
            if(el.photo) {
                el.photo = el.photo.replaceAll('http://maisfarmacia.dokky.cottonhat.net', 'https://be.maisfarmacia.org');
            }
        })
    }

    const onOpenModal = (product) => {
        setQuantity(product.minimumOrderQuantity);
        setOpen(true);
        setSingleProduct(product);
    };

    const onCloseModal = () => {
        setOpen(false);
        setSingleProduct(null);
        setQuantity(1);
    };

    const minusQty = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1)
        }
    }

    const changeQty = (e) => {
        setQuantity(parseInt(e.target.value))
    }

    const plusQty = () => {
        if (quantity >= 1) {
            setQuantity(quantity + 1)
        }
    }

    const addcart = (product, qty) => {
        if (qty < product.minimumOrderQuantity) {
            toast.error(<><span>{"Inválido"}</span><br /><span>{'Este produto tem um mínimo de ' + product.minimumOrderQuantity + ' unidades'}</span></>, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setAddCartLoading(true);

            axios.post(`/api/v1/pharma/${myPharma.ANF}/add_to_cart`, { productId: product._id, quantity: qty })
                .then((result) => {
                    setAddCartLoading(false);

                    const newPharma = { ...myPharma };
                    newPharma.cart = result.data.cart;
                    dispatch(setMyPharma(newPharma));

                    setAddCartMessage({ message: 'Adicionado!', type: 'success' });
                    setTimeout(() => {
                        setAddCartMessage(null);
                    }, 2250);
                })
                .catch((error) => {
                    setAddCartMessage({ message: 'Produto Indisponível', type: 'error' });
                    setTimeout(() => {
                        setAddCartMessage(null);
                    }, 2250);
                });
        }
    }

    const handleSearchKeyword = (keyword) => {
        if (tempSearchKeyword.length > 3) {
            setPage(1)
            setSearchKeyword(tempSearchKeyword)
        } else if (tempSearchKeyword.length <= 3) {
            setPage(1)
            setSearchKeyword('')
        }
    }

    const onClickDetailPage = (product) => {
        const id = product.id;
        history.push(`${process.env.PUBLIC_URL}/produto-de-loja/${id}`)
    }

    return (
        <Fragment>
            <Breadcrumb parent="Loja maisFarmácia" title="Produtos" />
            <Container fluid={true} className="product-wrapper">
                <div className="product-grid">
                    <div className="feature-products">
                        <Row style={{marginBottom: 20}}>
                            <Col md="6">
                                <Form onSubmit={(ev) => ev.preventDefault()}>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        placeholder="Procurar (mais de 3 caracteres)"
                                        defaultValue={searchKeyword}
                                        value={tempSearchKeyword}
                                        onChange={(e) => {
                                            setTempSearchKeyword(e.target.value);
                                            if (e.target.value.length === 0) handleSearchKeyword();
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') handleSearchKeyword();
                                        }}
                                    />
                                        {/* <Button color="primary" onClick={handleSearchKeyword}><i className="fa fa-search" /></Button> */}
                                </Form>
                            </Col>
                            
                            { categories.data ? (
                            <Col md="3" className="text-right">
                                <Select
                                    placeholder="Selecionar Categoria"
                                    className="basic-single"
                                    // id="lab_select"
                                    classNamePrefix="select"
                                    defaultValue={null}
                                    isMulti={false}
                                    isDisabled={false}
                                    isClearable
                                    isSearchable
                                    name={"lab_select"}
                                    options={categories.data.map(el => el)}
                                    onChange={(value) => {
                                        setPage(1);
                                        if(value) setCategory(value)
                                        else setCategory(null);
                                    }}
                                    getOptionLabel={(opt) => opt}
                                    getOptionValue={(opt) => opt}
                                />
                            </Col>
        ) : ''}
                        </Row>
                
                    </div>
                    <div className="product-wrapper-grid">
                        {!isLoading && data.results.length <= 0 && !error ?
                            <div className="search-not-found text-center">
                                <div>
                                    <img className="img-fluid second-search" src={errorImg} alt="no-results" />
                                    <p>{"Não foram encontrados resultados"}</p>
                                </div>
                            </div>
                            :
                            <Row className="gridRow">
                                {!isLoading ? data.results.map((item, i) =>
                                    <div className={'col-xl-3 col-sm-6 xl-4 col-grid-box'} key={i}>
                                        <Card style={{ minHeight: 500 }}>
                                            <div className="product-box">
                                                <div className="product-img">
                                                    {item.starred && (
                                                        <span className="ribbon ribbon-secondary">
                                                            <i className="fa fa-star" style={{ color: "#EEBB00" }} /> {item.score} {item.score > 1 ? "pts" : "pt"}
                                                        </span>
                                                    )}

                                                    <img className="img-fluid" src={item.photo ? item.photo : notFound} alt={item.name} />
                                                    <div className="product-hover">
                                                        <ul>
                                                            {/* <li>
                                                                <Button color="default" onClick={() => addcart(item, quantity)} disabled={addCartLoading || addCartMessage}>
                                                                    {addCartLoading ? <i className="fa fa-spin fa-spinner" /> : addCartMessage && addCartMessage.type === 'success' ? <i className="fa fa-check-circle-o"></i> : addCartMessage && addCartMessage.type === 'error' ? <i className="ion ion-close"></i> : <i className="icon-shopping-cart"></i>}
                                                                </Button>
                                                            </li> */}
                                                            <li>
                                                                <Button color="default" data-toggle="modal"
                                                                    onClick={() => onOpenModal(item)}>
                                                                    <i className="icon-eye"></i>
                                                                </Button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="product-details">
                                                    {/* <div className="rating">
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                    </div> */}
                                                    <h4 onClick={() => onClickDetailPage(item)} className="font-primary" style={{ cursor: 'pointer' }}>{item.name}{item.stock <= 0 && (<span style={{color: '#f99'}}> [Sem Stock]</span>)}</h4>
                                                    {/* <p>{item.description}</p> */}
                                                    <div className="product-price">
                                                        {item.groupedPrice ? `${item.groupedPrice.toFixed(2)} €`: item.price === 0 ? 'Sob Consulta' : `${item.price.toFixed(2)} €`}
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                ) : isLoading && (
                                    <div className="loader-box loader-abs">
                                        <div className="loader-7" />
                                    </div>
                                )}

                                {singleProduct && (
                                    <Modal className="modal-lg modal-dialog-centered product-modal" isOpen={open}>
                                        <ModalBody>
                                            <ModalHeader toggle={onCloseModal}>
                                                <div className="product-box row">
                                                    <Col lg="6" className="product-img">
                                                        <Media className="img-fluid" src={singleProduct.photo} alt={singleProduct.name} />
                                                    </Col>
                                                    <Col lg="6" className="product-details text-left" style={{ paddingLeft: 10}}>
                                                        <span>{singleProduct.name}{singleProduct.stock <= 0 && (<span style={{color: '#f99'}}> [Sem Stock]</span>)}</span>
                                                        <div className="product-price">
                                                            {singleProduct.groupedPrice ? `${singleProduct.groupedPrice.toFixed(2)} €`: singleProduct.price === 0 ? 'Sob Consulta' : `${singleProduct.price.toFixed(2)} €`}
                                                        </div>
                                                        <span style={{ fontSize: 13 }}>Este artigo atribui {singleProduct.score} {singleProduct.score === 1 ? "ponto" : "pontos"}</span>
                                                        <div className="product-view">
                                                            <h6 className="f-w-600">{"Detalhes"}</h6>
                                                            <div dangerouslySetInnerHTML={{ __html: singleProduct.description }} />
                                                        </div>
                                                        <div className="product-qnty mt-2">
                                                            <h6 className="f-w-600">{"Quantidade"}</h6>
                                                            <fieldset>
                                                                <InputGroup className="bootstrap-touchspin">
                                                                    <InputGroupAddon addonType="prepend">
                                                                        <Button color="primary btn-square" className="bootstrap-touchspin-down" onClick={minusQty}><i className="fa fa-minus"></i></Button>
                                                                    </InputGroupAddon>
                                                                    <InputGroupAddon addonType="prepend">
                                                                        <InputGroupText className="bootstrap-touchspin-prefix" style={{ display: "none" }}></InputGroupText>
                                                                    </InputGroupAddon>
                                                                    <Input className="touchspin text-center" type="text" name="quantity" value={quantity} onChange={(e) => changeQty(e)} style={{ display: "block" }} />
                                                                    <InputGroupAddon addonType="append">
                                                                        <InputGroupText className="bootstrap-touchspin-postfix" style={{ display: "none" }}></InputGroupText>
                                                                    </InputGroupAddon>
                                                                    <InputGroupAddon addonType="append" className="ml-0">
                                                                        <Button color="primary btn-square" className="bootstrap-touchspin-up" onClick={plusQty}><i className="fa fa-plus"></i></Button>
                                                                    </InputGroupAddon>
                                                                </InputGroup>
                                                            </fieldset>
                                                            <div className="addcart-btn">
                                                                <Button color="primary" className="mr-2 mt-2" onClick={() => addcart(singleProduct, quantity)} disabled={addCartLoading || addCartMessage || (singleProduct.stock <= 0)}>
                                                                    {addCartLoading ? (<i className="fa fa-spin fa-spinner" />) : addCartMessage ? addCartMessage.message : "Adicionar ao Carrinho"}
                                                                </Button>
                                                                <Button onClick={() => onClickDetailPage(singleProduct)} color="primary" className="mr-2 mt-2">
                                                                    {"Ver Detalhes"}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </div>
                                            </ModalHeader>
                                        </ModalBody>
                                    </Modal>
                                )}
                            </Row>
                        }
                    </div>
                </div>
                {!isLoading && data.results.length > 0 && !error && (
                    <div className="mb-4">
                        <PaginationDT
                            centered
                            totalPage={data.totalPages}
                            currentPage={data.page}
                            limit={data.limit}
                            onChangePage={(e) => {
                                setPage(e);
                                window.scrollTo(0, 0);
                            }}
                        />
                    </div>
                )}
            </Container>
        </Fragment>
    );
}

export default Product;