export const apiUrl = "/api/v2/inqueritos?state=closed&type=desafio";
export const rqModel = "desafio-flash";
export const pathname = `${process.env.PUBLIC_URL}/push/desafio-flash`;

export const columns = [
  {
    name: "Criado em",
    selector: "createdOn",
    sortable: true,
    center: true,
  },
  {
    name: "Nome",
    selector: "name",
    sortable: true,
    center: true,
    grow: 3,
  },
  {
    name: "Estado",
    selector: "state",
    sortable: true,
    center: true,
  },
  {
    name: "Respostas",
    selector: "responses",
    sortable: true,
    center: true,
  },
  {
    name: "Recebidas",
    selector: "received",
    sortable: true,
    center: true,
  },
  {
    name: "Opções",
    selector: "action",
    sortable: false,
    center: true,
    grow: 2,
  },
];

export const schema = [
  {
    colSize: "6",
    label: "Nome da Encomenda",
    name: "name",
    id: "name",
    type: "text",
    required: true,
    requiredMessage: "Este campo é obrigatório",
  },
  {
    colSize: "6",
    label: "Tipo de Inquérito",
    name: "inq_type",
    id: "inq_type",
    type: "select",
    required: true,
    requiredMessage: "Este campo é obrigatório",
    multi: false,
    optionLabel: "label",
    optionValue: "value",
    options: [
      {
        value: "facultativo",
        label: "Facultativo (Opcional)",
      },
      {
        value: "obrigatório",
        label: "Obrigatória (Aceite por Defeito)",
      },
      {
        value: "tacita",
        label: "Tácita (Aceite por Defeito)",
      },
    ],
  },
  {
    colSize: "4",
    label: "Data de Fecho",
    name: "end_date",
    id: "end_date",
    type: "datepicker",
    required: true,
    requiredMessage: "Este campo é obrigatório",
  },
  {
    type: "component",
    colSize: "4",
    name: "col",
    id: "col",
  },
  {
    name: "hr",
    id: "hr",
    type: "component",
    className: "mb-4",
  },
  {
    title: "Data Prevista Entrega",
    name: "sectionTitle",
    id: "sectionTitle",
    type: "sectionTitle",
  },
  {
    colSize: "3",
    label: "De",
    name: "delivery.start",
    id: "delivery.start",
    type: "datepicker",
    required: true,
    requiredMessage: "Este campo é obrigatório",
  },
  {
    colSize: "3",
    label: "A",
    name: "delivery.end",
    id: "delivery.end",
    type: "datepicker",
    required: true,
    requiredMessage: "Este campo é obrigatório",
  },
  {
    name: "hr",
    id: "hr",
    type: "component",
    className: "mb-4",
  },
  {
    colSize: "6",
    label: "Distribuidor (A Farmácia Pode Escolher Distribuidor)",
    name: "preferred_distribution",
    id: "preferred_distribution",
    type: "select",
    required: true,
    requiredMessage: "Este campo é obrigatório",
    multi: true,
    optionLabel: "label",
    optionValue: "value",
    options: [
      {
        value: "lab",
        label: "Directo - Laboratório",
      },
      {
        value: "plural",
        label: "Plural",
      },
      {
        value: "alliance",
        label: "Alliance",
      },
      {
        value: "udifar",
        label: "Udifar",
      },
      {
        value: "empifarma",
        label: "Empifarma",
      },
    ],
  },
  {
    colSize: "6",
    label: "Fornecedor Associado",
    name: "fornecedor",
    id: "fornecedor",
    type: "select",
    required: true,
    requiredMessage: "Este campo é obrigatório",
    multi: false,
    options: null,
    optionLabel: "name",
    optionValue: "fornecedor_id",
    api: {
      route: "/api/v1/laboratories",
      redux: "Laboratories",
    },
  },
  {
    colSize: "6",
    label: "Investimento Fixo",
    name: "investmentFixed",
    id: "investmentFixed",
    type: "number",
    required: true,
    requiredMessage: "Este campo é obrigatório",
  },
  {
    colSize: "6",
    label: "Motivo Distribuição",
    name: "distributionReason",
    id: "distributionReason",
    type: "select",
    required: true,
    requiredMessage: "Este campo é obrigatório",
    multi: false,
    optionLabel: "label",
    optionValue: "value",
    // options: [
    //     {
    //         value: "pack_default",
    //         label: "Pack",
    //     },
    //     {
    //         value: "desc_cliente",
    //         label: "Desconto Cliente",
    //     },
    //     {
    //         value: "montra_facult",
    //         label: "Montra Facultativa",
    //     },
    //     {
    //         value: "pack_promo",
    //         label: "Pack Promocional",
    //     },
    //     {
    //         value: "desc_extra",
    //         label: "Desconto Adicional",
    //     },
    // ],
    options: [
      {
        value: "quinzenamais",
        label: "Quinzena mais",
      },
      {
        value: "equipastandard",
        label: "Equipa Standard",
      },
      {
        value: "equipapremium",
        label: "Equipa Premium",
      },
      {
        value: "mupistandard",
        label: "Mupi Standard",
      },
      {
        value: "mupipremium",
        label: "Mupi Premium",
      },
      {
        value: "formacaoonlinestandard",
        label: "Formação Online Standard",
      },
      {
        value: "formacaoonlinepremium",
        label: "Formação Online Premium",
      },
      {
        value: "formacaopresencial",
        label: "Formação Presencial",
      },
      {
        value: "rastreiostandard",
        label: "Rastreio Standard",
      },
      {
        value: "rastreiopremium",
        label: "Rastreio Premium",
      },
      {
        value: "lancamento",
        label: "Lançamento",
      },
      {
        value: "promocional",
        label: "Promocional",
      },
      {
        value: "outro",
        label: "Outro",
      },
    ],
  },
  {
    colSize: "6",
    label: "Créditos na Loja",
    name: "convertsToCredits",
    id: "convertsToCredits",
    type: "number",
    required: true,
    requiredMessage: "Este campo é obrigatório",
  },
  {
    colSize: "6",
    label: "Data Copagamento",
    name: "copaymentDate",
    id: "copaymentDate",
    type: "datepicker",
    required: true,
    requiredMessage: "Este campo é obrigatório",
  },
  {
    name: "hr",
    id: "hr",
    type: "component",
    className: "mb-4",
  },
  {
    title: "Data de Promoção",
    name: "sectionTitle",
    id: "sectionTitle",
    type: "sectionTitle",
  },
  {
    colSize: "3",
    label: "De",
    name: "promo.start",
    id: "promo.start",
    type: "datepicker",
    required: true,
    requiredMessage: "Este campo é obrigatório",
  },
  {
    colSize: "3",
    label: "A",
    name: "promo.end",
    id: "promo.end",
    type: "datepicker",
    required: true,
    requiredMessage: "Este campo é obrigatório",
  },
  {
    name: "hr",
    id: "hr",
    type: "component",
    className: "mb-4 mt-4",
  },
  {
    colSize: "12",
    label: "Ficheiro xls de Segmentação",
    name: "file",
    id: "file",
    type: "file",
    content: "file",
    multi: false,
    validate: "push",
  },
  {
    colSize: "12",
    label: "Imagem",
    name: "profilePhoto",
    id: "profilePhoto",
    type: "file",
    content: "image",
    multi: false,
    // imageEditInstantEdit: true,
    // imageResizeTargetWidth: 1200,
  },
];
