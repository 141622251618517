import axios from 'axios';
import { useQuery, useQueryClient } from 'react-query';

export const fetchOne = (route) => axios.get(`${route}`).then((res) => res.data);

export default function useGetOne(route, modelId, rqModel, opts) {
    const queryClient = useQueryClient();

    return useQuery([route, modelId, rqModel, opts], () => fetchOne(route), {
        onSuccess: () => {
            queryClient.cancelQueries(rqModel)
        },
        initialData: () => {
            if (queryClient.getQueryData(rqModel) && queryClient.getQueryData(rqModel).results) {
                return queryClient.getQueryData(rqModel).results.find(d => d.id === modelId)
            } else if (queryClient.getQueryData(rqModel)) {
                if (queryClient.getQueryData(rqModel).find(d => d.id === modelId) && rqModel !== "to_do") {
                    return queryClient.getQueryData(rqModel).find(d => d.id === modelId)
                } else {
                    return null
                }
            }
        },
        enabled: opts && opts.enabled ? opts.enabled : true,
        initialStale: true,
        staleTime: 300000,
    });
}