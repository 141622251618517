import axios from 'axios';
import { useQuery, useQueryClient } from 'react-query';

export default function useGetAll(route, rqModel) {
    const queryClient = useQueryClient();

    return useQuery(rqModel, () => axios.get(route).then((res) => res.data),
        {
            onSuccess: () => {
                queryClient.cancelQueries(rqModel)
            },
            staleTime: 300000,
        });
}