import './index.scss';
import ReactDOM from 'react-dom';
import React, { Fragment, useState, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Provider } from 'react-redux';
import axios from 'axios';

import * as serviceWorker from './serviceWorker';

import App from './components/app';

import { routes } from './route';

import store from './store';

import ConfigDB from './data/customizer/config';

// Login Page
import Signin from './auth/signin';
import ForgotPwd from './auth/forgotpassword';
import ChangePwd from './auth/changepassword';

// Authentication
import UnlockUser from "./pages/authentication/unlockUser";
import Forgetpwd from "./pages/authentication/forgetpwd";
import Resetpwd from "./pages/authentication/resetpwd";

// Error page
import Error400 from "./pages/errors/error400";
import Error401 from "./pages/errors/error401";
import Error403 from "./pages/errors/error403";
import Error404 from "./pages/errors/error404";
import Error500 from "./pages/errors/error500";
import Error503 from "./pages/errors/error503";

// Maintenance
import Maintenance from "./pages/maintenance";

// Dashboards
import Admin from "./components/dashboard/admin";
import Default from "./components/dashboard/default";
import LabDashboard from "./components/dashboard/lab";

// Product
import AdminProductProfile from "./components/products/admin/profile";
import ProductProfile from "./components/products/profile";

// Push Orders
import AdminPushOrders from "./components/push/adminOrders";
import PushOrders from "./components/push/orders";

// Push Orders Profile
import AdminOrderProfile from "./components/push/profile/admin/";
import OrderProfile from "./components/push/profile/";

// Push Orders
import AdminFlash from "./components/push-flash/adminOrders";
import PushFlash from "./components/push-flash/orders";

import PushDirect from "./components/pushDirect/orders";

// Push Orders Profile
import AdminFlashProfile from "./components/push-flash/profile/admin/";
import OrderFlashProfile from "./components/push-flash/profile/";

// Store Products
import StoreProductsAdmin from "./components/store/products/admin/";
import StoreProductsPharma from "./components/store/products/";

// Store Orders
import OrdersAdmin from "./components/store/orders/admin/";
import OrdersPharma from "./components/store/orders/";

// Visibilidade
import VisibilidadeAdmin from "./components/visibilidade/admin/";
import VisibilidadePharma from "./components/visibilidade/";

// Visibilidade
import FacebookPharma from "./components/marketing/facebook/";
import FacebookAdmin from "./components/marketing/facebook/admin";

// Newsletters
import NewslettersPharma from "./components/centro/newsletters/";
import NewslettersAdmin from "./components/centro/newsletters/admin";

// Renting
import RentingPharma from "./components/store/renting/";
import RentingAdmin from "./components/store/renting/admin/";

// PharmacyProfile
import AdminPharmacyProfile from "./components/pharmacies/admin/profile/";
import PharmacyProfile from "./components/pharmacies/profile/";

import { setUser, setPharmacies, setMyPharma, setLaboratories } from '../src/redux/actionTypes';

const queryClient = new QueryClient()

const Root = () => {
    const [anim, setAnim] = useState("");

    const animation = localStorage.getItem("animation") || ConfigDB.data.router_animation || 'fade';

    const abortController = new AbortController();

    const [role, setRole] = useState(false);
    const [myANF, setMyANF] = useState(false);

    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (localStorage.getItem('layout_version') === 'dark-only') {
            document.body.className = "dark-only"
        }

        setAnim(animation);
        setLoading(true);

        axios
            .get(`/api/auth`)
            .then((result) => {
                store.dispatch(setUser(result.data));

                if (result.data.user.anf_code) setMyANF(result.data.user.anf_code);

                setRole(result.data.user.role);
                setAuthenticated(true);
                setLoading(false);

                // var bifrostCors = new BifrostCors("https://qap-proxy.maisfarmacia.org", false);
                // bifrostCors.setLocalStorage({ key: "loggedUser", value: result.data.user.username }) 
                // localStorage.setItem("loggedUser", result.data.user.username) 
                
                //*  -- GlOBAL STATE --
                axios
                    .get(`/api/v1/pharma`)
                    .then((pharmacies) => {
                        store.dispatch(setPharmacies(pharmacies.data));
                        if (result.data.user.role === "FarmaciaRole" && result.data.user.anf_code) {
                            axios
                                .get(`/api/v1/pharma/${result.data.user.anf_code}`)
                                .then((pharma) => {
                                    store.dispatch(setMyPharma(pharma.data));
                                })
                                .catch((error) => {
                                    console.error(error);
                                    store.dispatch(setMyPharma(pharmacies.data.results.find((pharma) => pharma.ANF === result.data.user.anf_code)));
                                });
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        store.dispatch(setPharmacies([]));
                    });
                axios
                    .get(`/api/v1/laboratories`)
                    .then((laboratories) => {
                        store.dispatch(setLaboratories(laboratories.data));
                    })
                    .catch((error) => {
                        console.error(error);
                        store.dispatch(setLaboratories([]));
                    });
                //* -- GlOBAL STATE --
            })
            .catch((error) => {
                let resetUser = {
                    loaded: true,
                    authenticated: false,
                    kpiInfo: [],
                    user: null,
                }

                setAuthenticated(false);
                setLoading(false);

                console.error(error);
                store.dispatch(setUser(resetUser));
            });

        return function cleanup() {
            abortController.abort();
        }
    }, []); // eslint-disable-line 

    return (
        <Fragment>
            <QueryClientProvider client={queryClient}>
                <Provider store={store}>
                    <BrowserRouter basename={`/`}>
                        <Switch>
                            <Route path={`${process.env.PUBLIC_URL}/login`} component={() => <Signin />} />
                            <Route path={`${process.env.PUBLIC_URL}/recuperar-password`} component={ForgotPwd} />
                            <Route path={`${process.env.PUBLIC_URL}/alterar-password`} component={ChangePwd} />


                            <Route path={`${process.env.PUBLIC_URL}/pages/auth/unlockUser`} component={UnlockUser} />

                            <Route path={`${process.env.PUBLIC_URL}/pages/auth/forgetPwd`} component={Forgetpwd} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/auth/resetPwd`} component={Resetpwd} />

                            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error400`} component={Error400} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error401`} component={Error401} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error403`} component={Error403} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error404`} component={Error404} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error500`} component={Error500} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error503`} component={Error503} />

                            <Route path={`${process.env.PUBLIC_URL}/pages/maintenance`} component={Maintenance} />

                            {loading ? (
                                <Route>
                                    <div className="loader-wrapper loderhide"><div className="loader-index"><span></span></div><svg><defs></defs><filter id="goo"><fegaussianblur in="SourceGraphic" stdDeviation="11" result="blur"></fegaussianblur><fecolormatrix in="blur" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo"></fecolormatrix></filter></svg></div>
                                </Route>
                            ) : !loading && authenticated ? (
                                <App>
                                    {/* <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                                        return (<Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />)
                                    }} /> */}
                                    <TransitionGroup>
                                    

                                        <Route path={`${process.env.PUBLIC_URL}/push/encomendas`} component={role === "AdminRole" ? AdminPushOrders : PushOrders} />
                                        <Route path={`${process.env.PUBLIC_URL}/push/encomenda/:id`} component={role === "AdminRole" ? AdminOrderProfile : OrderProfile} />

                                        <Route path={`${process.env.PUBLIC_URL}/push/desafio-flash`} component={role === "AdminRole" ? AdminFlash : PushFlash} />
                                        <Route path={`${process.env.PUBLIC_URL}/push/desafio-flash/:id`} component={role === "AdminRole" ? AdminFlashProfile : OrderFlashProfile} />

                                        <Route path={`${process.env.PUBLIC_URL}/push/direct`} component={role === "AdminRole" ? AdminFlash : PushDirect} />


                                        <Route path={`${process.env.PUBLIC_URL}/visibilidade`} component={role === "AdminRole" ? VisibilidadeAdmin : VisibilidadePharma} />

                                        <Route path={`${process.env.PUBLIC_URL}/loja/produtos-de-loja`} component={StoreProductsAdmin} />
                                        <Route path={`${process.env.PUBLIC_URL}/loja/produtos`} component={StoreProductsPharma} />

                                        <Route path={`${process.env.PUBLIC_URL}/loja/renting`} component={role === "AdminRole" ? RentingAdmin : RentingPharma} />
                                        <Route path={`${process.env.PUBLIC_URL}/loja/encomendas`} component={role === "AdminRole" ? OrdersAdmin : OrdersPharma} />
                                        <Route path={`${process.env.PUBLIC_URL}/loja/equipamentos-de-renting`} component={RentingPharma} />

                                        <Route path={`${process.env.PUBLIC_URL}/marketing/facebook`} component={role === "AdminRole" ? FacebookAdmin : FacebookPharma} />

                                        <Route path={`${process.env.PUBLIC_URL}/centro/newsletters`} component={role === "AdminRole" ? NewslettersAdmin : NewslettersPharma} />

                                        <Route path={`${process.env.PUBLIC_URL}/produto/:id`} component={role === "AdminRole" ? AdminProductProfile : ProductProfile} />

                                        {role === "AdminRole" ? (
                                            <Route path={`${process.env.PUBLIC_URL}/farmacia/:id`} component={AdminPharmacyProfile} />
                                        ) : myANF && (
                                            <Route path={`${process.env.PUBLIC_URL}/farmacia`} component={() => <PharmacyProfile myANF={myANF} />} />
                                        )}

                                        {routes.map(({ path, Component }) => (
                                            <Route key={path} path={`${process.env.PUBLIC_URL}${path}`} component={Component} />
                                        ))}
                                        
                                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={() => role === "AdminRole" ? <Admin /> : role === "FornecedorRole" ? <LabDashboard/> : <Default />} />

                                    </TransitionGroup>
                                </App>
                            ) : !loading && !authenticated && (
                                <Redirect to={`${process.env.PUBLIC_URL}/login`} />
                            )}

                        </Switch>
                    </BrowserRouter>
                </Provider>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </Fragment >
    )
}

ReactDOM.render(<Root />,
    document.getElementById('root')
);

serviceWorker.unregister();
