import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
// import { ShoppingBag, Layers, ShoppingCart, DollarSign } from 'react-feather';
import { Line } from 'react-chartjs-2';
import CountUp from 'react-countup';

import ProfileTitle from '../../layout/profileTitle';

import { useGetOne } from '../../hooks/';

import { lineChartData, lineChartOptions } from './settings';

const ProductProfile = (props) => {
    const history = useHistory();

    const { data, status } = useGetOne(`/api/v2/codes/${props.match && props.match.params && props.match.params.id}`, props.match && props.match.params && props.match.params.id, 'products');

    const sellouts_all = useGetOne(`/api/v1/sellouts_all/${props.match && props.match.params && props.match.params.id}?pharma=null&deep=true&homologous=true`, props.match && props.match.params && props.match.params.id, 'product_sellout');

    const sellins = useGetOne(`/api/v1/sellins/${props.match && props.match.params && props.match.params.id}?pharma=null&deep=true&homologous=true`, props.match && props.match.params && props.match.params.id, 'product_sellins');

    if (status === "error") history.push(`${process.env.PUBLIC_URL}/pages/errors/error404`);

    const datasetKeyProvider = () => {
        return Math.random();
    }

    return (
        <Fragment>
            <Container fluid={true}>
                {data ? (
                    <>
                        <ProfileTitle title={data.NOME} route={`${process.env.PUBLIC_URL}/administracao/produtos`} />
                        <Row>
                            <Col sm="6">
                                <Row>
                                    <Col sm={data.CATEGORIA_MF ? "6" : "12"} xl={data.CATEGORIA_MF ? "6" : "12"}>
                                        <Card className="ribbon-wrapper" style={{ minHeight: 222 }}>
                                            <CardBody style={{ padding: 25 }}>
                                                <div className="ribbon ribbon-primary">{"Laboratório"}</div>
                                                <h4
                                                    className="text-center"
                                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 60 }}
                                                >
                                                    {data.LABORATORIO}
                                                </h4>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    {data.CATEGORIA_MF && (
                                        <Col sm="6" xl="6">
                                            <Card className="ribbon-wrapper" style={{ minHeight: 222 }}>
                                                <CardBody style={{ padding: 25 }}>
                                                    <div className="ribbon ribbon-primary">{"Categoria"}</div>
                                                    <h4
                                                        className="text-center"
                                                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 60 }}
                                                    >
                                                        {data.CATEGORIA_MF}
                                                    </h4>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    )}
                                </Row>
                                <Row>
                                    <Col sm="4" xl="4">
                                        <Card className="ribbon-wrapper" style={{ minHeight: 222 }}>
                                            <CardBody style={{ padding: 25 }}>
                                                <div className="ribbon ribbon-success">{"Segmento"}</div>
                                                <h4
                                                    className="text-center"
                                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 60 }}
                                                >
                                                    {data.SEGMENTO_MF}
                                                </h4>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col sm="4" xl="4">
                                        <Card className="ribbon-wrapper" style={{ minHeight: 222 }}>
                                            <CardBody style={{ padding: 25 }}>
                                                <div className="ribbon ribbon-secondary">{"Sub Segmento"}</div>
                                                <h4
                                                    className="text-center"
                                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 60 }}
                                                >
                                                    {data.SUBSEGMENTO_MF}
                                                </h4>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col sm="4" xl="4">
                                        <Card className="ribbon-wrapper" style={{ minHeight: 222 }}>
                                            <CardBody style={{ padding: 25 }}>
                                                <div className="ribbon ribbon-info">{"Logística"}</div>
                                                <h4
                                                    className="text-center"
                                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 60 }}
                                                >
                                                    {data.MODELO_LOGISTICO}
                                                </h4>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm="6">
                                <Row>
                                    <Col xl="12" className="xl-100 box-col-12">
                                        <Card className="widget-joins">
                                            <Row>
                                                <Col sm="6" className="pr-0">
                                                    <div className="media border-after-xs">
                                                        <div className="align-self-center mr-3">IVA<br /><span className="text-success">{(data.IVA * 100)}%</span></div>
                                                        <div className="media-body details pl-3"><span className="mb-1">{"PVP"}</span>
                                                            <h4 className="mb-0 counter digits"><CountUp suffix={" €"} decimals={2} end={data.PVP ? data.PVP.toFixed(2) : 0} /></h4>
                                                        </div>
                                                        {/* <div className="media-body align-self-center"><ShoppingBag className="font-primary float-left ml-2" /></div> */}
                                                    </div>
                                                </Col>
                                                <Col sm="6" className="pr-0 pl-0">
                                                    <div className="media border-after-xs">
                                                        {/* <div className="align-self-center mr-3">{"68%"}</div> */}
                                                        <div className="media-body pl-3 pt-0"><span className="mb-1">{"PVP MF"}</span>
                                                            <h4 className="mb-0 counter digits"><CountUp suffix={" €"} decimals={2} end={data.PVPMF ? data.PVPMF.toFixed(2) : 0} /></h4>
                                                        </div>
                                                        {/* <div className="media-body align-self-center"><ShoppingCart className="font-primary float-left ml-2" /></div> */}
                                                    </div>
                                                </Col>
                                                <Col sm="6" className="pl-0">
                                                    <div className="media">
                                                        <div className="align-self-center mr-3 digits">PVA NET <br /><span className="text-success">{data.PVA_NET.toFixed(2)} €</span></div>
                                                        <div className="media-body details pl-3"><span className="mb-1">{"PVA"}</span>
                                                            <h4 className="mb-0 counter digits"><CountUp suffix={" €"} decimals={2} end={data.PVA ? data.PVA.toFixed(2) : 0} /></h4>
                                                        </div>
                                                        {/* <div className="media-body align-self-center"><Layers className="font-primary float-left ml-3" /></div> */}
                                                    </div>
                                                </Col>
                                                <Col sm="6" className="pl-0">
                                                    <div className="media">
                                                        {/* <div className="align-self-center mr-3">{"68%"}</div> */}
                                                        <div className="media-body pl-3 pt-0"><span className="mb-1">{"Rentabilidade"}</span>
                                                            <h4 className="mb-0 counter digits"><CountUp suffix={" €"} decimals={2} end={data.RENT_ABS ? data.RENT_ABS.toFixed(2) : 0} /></h4>
                                                        </div>
                                                        {/* <div className="media-body align-self-center"><DollarSign className="font-primary float-left ml-2" /></div> */}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col xl="6" md="12">
                                <Card>
                                    <CardHeader>
                                        <h5>{"Sellouts"}</h5>
                                    </CardHeader>
                                    <CardBody className="chart-block">
                                        <Line data={lineChartData} options={lineChartOptions} datasetKeyProvider={datasetKeyProvider} width={778} height={400} />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl="6" md="12">
                                <Card>
                                    <CardHeader>
                                        <h5>{"Sellins"}</h5>
                                    </CardHeader>
                                    <CardBody className="chart-block">
                                        <Line data={lineChartData} options={lineChartOptions} datasetKeyProvider={datasetKeyProvider} width={778} height={400} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row> */}
                    </>
                ) : (
                    <Row style={{ minHeight: 500 }}>
                        <Col sm="12">
                            <div className="loader-box loader-abs">
                                <div className="loader-7" />
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
        </Fragment>
    );
}

export default ProductProfile;