
export const schema = [
  {
    colSize: "6",
    label: "Laboratório",
    name: "fornecedor_id",
    id: "fornecedor_id",
    type: "select",
    required: true,
    requiredMessage: "Este campo é obrigatório",
    multi: false,
    options: null,
    optionLabel: "name",
    optionValue: "fornecedor_id",
    api: {
        route: "/api/v1/laboratories",
        redux: "Laboratories",
    },
},
{
    colSize: "6",
    label: "Laboratórios Afectos",
    name: "qlikRefLabCode",
    id: "qlikRefLabCode",
    type: "select",
    required: true,
    requiredMessage: "Este campo é obrigatório",
    multi: true,
    options: null,
    optionLabel: "name",
    optionValue: "fornecedor_id",
    api: {
        route: "/api/v1/laboratories",
        redux: "Laboratories",
    },
},
  {
        colSize: "12",
        label: "Perfil",
        name: "profile",
        id: "profile",
        type: "select",
        multi: false,
        optionLabel: "label",
        optionValue: "value",
        options: [
            {
                value: "standard",
                label: "Standard",
            },
            {
                value: "representative",
                label: "Representador",
            },
        ],
    },
];
