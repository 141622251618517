export const apiUrl = "/api/v1/pharma";
export const rqModel = "pharmacies";
export const pathname = `${process.env.PUBLIC_URL}/administracao/farmacias`;

export const columns = [
    {
        name: "Nome",
        selector: "name",
        sortable: true,
        center: true,
    },
    {
        name: "GLN",
        selector: "GLN",
        sortable: true,
        center: true,
    },
    {
        name: "ANF",
        selector: "ANF",
        sortable: true,
        center: true,
    },
    {
        name: "Email",
        selector: "email",
        sortable: true,
        center: true,
    }
];

export const schema = [
    {
        title: "Informação Principal",
        name: "sectionTitle",
        id: "sectionTitle",
        type: "sectionTitle",
    },
    {
        colSize: "4",
        label: "Nome",
        name: "name",
        id: "name",
        type: "text",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "4",
        label: "Código GLN",
        name: "GLN",
        id: "GLN",
        type: "number",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "4",
        label: "ANF",
        name: "ANF",
        id: "ANF",
        type: "number",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "4",
        label: "Fidelização",
        name: "loyalty.loyalty_type",
        id: "loyalty.loyalty_type",
        type: "select",
        // required: true,
        // requiredMessage: "Este campo é obrigatório",
        multi: false,
        optionLabel: "label",
        optionValue: "value",
        options: [
            {
                value: "null",
                label: "Outro",
            },
            {
                value: "sauda",
                label: "Cartāo Saúda",
            },
        ],
    },
    {
        colSize: "4",
        label: "Serviços Prestados",
        name: "services",
        id: "services",
        type: "select",
        required: true,
        requiredMessage: "Este campo é obrigatório",
        multi: true,
        optionLabel: "label",
        optionValue: "value",
        options: [
            {
                value: "dietaea",
                label: "Dieta EasySlim",
            },
            {
                value: "nutric",
                label: "Nutrição Clínica",
            },
            {
                value: "podol",
                label: "Podologia",
            },
            {
                value: "osteo",
                label: "Osteopatia",
            },
            {
                value: "enferm",
                label: "Enfermagem",
            },
            {
                value: "consulfarm",
                label: "Consulta Farmacêutica",
            },
            {
                value: "consulhomeo",
                label: "Consulta de Homeopatia",
            },
            {
                value: "nutriviv",
                label: "Nutrição ViV",
            },
            {
                value: "fisio",
                label: "Fisioterapia",
            },
            {
                value: "dispensa",
                label: "Dispensa de Medicação Individualizada",
            },
            {
                value: "checksaude",
                label: "Check Saúde",
            },
            {
                value: "admin_vac",
                label: "Administração de Vacinas e Injectáveis",
            },
            {
                value: "cessacao_tab",
                label: "Consulta de Cessação Tabágica",
            },
            {
                value: "manipul",
                label: "Preparação de Manipulados",
            },
            {
                value: "travely",
                label: "Aconselhamento do Viajante",
            },
            {
                value: "sveti",
                label: "SVETI",
            },
        ],
    },
    {
        type: "component",
        colSize: "4",
        name: "col",
        id: "col",
    },
    {
        name: "hr",
        id: "hr",
        type: "component",
        className: "mb-4",
    },
    {
        title: "Morada",
        name: "sectionTitle",
        id: "sectionTitle",
        type: "sectionTitle",
    },
    {
        colSize: "4",
        label: "Endereço",
        name: "address.street",
        id: "address.street",
        type: "text",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "4",
        label: "Localidade",
        name: "address.city",
        id: "address.city",
        type: "text",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "2",
        label: "Código Postal (****-)",
        name: "address.code1",
        id: "address.code1",
        type: "text",
        required: true,
        pattern: /^\d{4,4}$/,
        requiredMessage: "Código postal inválido (max 4 números)",
    },
    {
        colSize: "2",
        label: "(-***)",
        name: "address.code2",
        id: "address.code2",
        type: "text",
        required: true,
        pattern: /^\d{3,3}$/,
        requiredMessage: "(max 3 números)",
    },
    {
        name: "hr",
        id: "hr",
        type: "component",
        className: "mb-4",
    },
    {
        title: "Contactos",
        name: "sectionTitle",
        id: "sectionTitle",
        type: "sectionTitle",
    },
    {
        colSize: "6",
        label: "Email",
        name: "email",
        id: "email",
        type: "email",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "6",
        label: "Nome",
        name: "contact.name",
        id: "contact.name",
        type: "text",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "6",
        label: "Telefone",
        name: "contact.fix_number",
        id: "contact.fix_number",
        type: "text",
        // required: true,
        // requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "6",
        label: "Telemóvel",
        name: "contact.mobile_number",
        id: "contact.mobile_number",
        type: "text",
        // required: true,
        // requiredMessage: "Este campo é obrigatório",
    },
    {
        name: "hr",
        id: "hr",
        type: "component",
        className: "mb-4",
    },
    {
        title: "Fornecedores",
        name: "sectionTitle",
        id: "sectionTitle",
        type: "sectionTitle",
    },
    {
        colSize: "3",
        label: "Código Plural",
        name: "COD_PLURAL",
        id: "COD_PLURAL",
        type: "text",
        // required: true,
        // requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "3",
        label: "Código Empifarma",
        name: "COD_EPIFARMA",
        id: "COD_EPIFARMA",
        type: "text",
        // required: true,
        // requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "3",
        label: "Código Milénio",
        name: "COD_MILENIO",
        id: "COD_MILENIO",
        type: "text",
        // required: true,
        // requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "3",
        name: "col",
        id: "col",
        type: "col",
    },
    {
        name: "hr",
        id: "hr",
        type: "component",
        className: "mb-4",
    },
    {
        title: "Comunicação",
        name: "sectionTitle",
        id: "sectionTitle",
        type: "sectionTitle",
    },
    {
        colSize: "6",
        label: "Encomendas Push",
        name: "comms.encpush.accounts",
        id: "comms.encpush.accounts",
        type: "select",
        // required: true,
        // requiredMessage: "Este campo é obrigatório",
        multi: true,
        options: null,
        optionLabel: "name",
        optionValue: "_id",
        backendPopulated: true,
        api: {
            route: "/api/v2/inqueritos",
        },
    },
    {
        colSize: "6",
        label: "Emails Extra",
        name: "comms.encpush.emails",
        id: "comms.encpush.emails",
        type: "creatable",
        validateType: "email",
        options: [],
        values: [],
    },
    {
        name: "hr",
        id: "hr",
        type: "component",
        className: "mb-4",
    },
    {
        colSize: "6",
        label: "Encomendas loja maisfarmácia",
        name: "comms.orders.accounts",
        id: "comms.orders.accounts",
        type: "select",
        // required: true,
        // requiredMessage: "Este campo é obrigatório",
        multi: true,
        options: null,
        optionLabel: "name",
        optionValue: "_id",
        backendPopulated: true,
        api: {
            route: "/api/v1/orders",
        },
    },
    {
        colSize: "6",
        label: "Emails Extra",
        name: "comms.orders.emails",
        id: "comms.orders.emails",
        type: "creatable",
        validateType: "email",
        options: [],
        values: [],
    },
    {
        name: "hr",
        id: "hr",
        type: "component",
        className: "mb-4",
    },
    // {
    //     title: "Configuração do horário da Farmácia",
    //     name: "sectionTitle",
    //     id: "sectionTitle",
    //     type: "sectionTitle",
    // },
    // {
    //     colSize: "2",
    //     label: "Segunda (Abertura)",
    //     name: "radioSettings.schedule.mon.on",
    //     id: "radioSettings.schedule.mon.on",
    //     type: "number",
    //     min: 0,
    //     maxLength: 2,
    //     requiredMessage: "Apenas dois dígitos (00)",
    // },
    // {
    //     colSize: "2",
    //     label: "(Fecho)",
    //     name: "radioSettings.schedule.mon.off",
    //     id: "radioSettings.schedule.mon.off",
    //     type: "number",
    //     min: 0,
    //     maxLength: 2,
    //     requiredMessage: "Apenas dois dígitos (00)",
    // },
    // {
    //     name: "hr",
    //     id: "hr",
    //     type: "component",
    //     noBorder: true,
    // },
    // {
    //     colSize: "2",
    //     label: "Terça (Abertura)",
    //     name: "radioSettings.schedule.tue.on",
    //     id: "radioSettings.schedule.tue.on",
    //     type: "number",
    //     min: 0,
    //     maxLength: 2,
    //     requiredMessage: "Apenas dois dígitos (00)",
    // },
    // {
    //     colSize: "2",
    //     label: "(Fecho)",
    //     name: "radioSettings.schedule.tue.off",
    //     id: "radioSettings.schedule.tue.off",
    //     type: "number",
    //     min: 0,
    //     maxLength: 2,
    //     requiredMessage: "Apenas dois dígitos (00)",
    // },
    // {
    //     name: "hr",
    //     id: "hr",
    //     type: "component",
    //     noBorder: true,
    // },
    // {
    //     colSize: "2",
    //     label: "Quarta (Abertura)",
    //     name: "radioSettings.schedule.wed.on",
    //     id: "radioSettings.schedule.wed.on",
    //     type: "number",
    //     min: 0,
    //     maxLength: 2,
    //     requiredMessage: "Apenas dois dígitos (00)",
    // },
    // {
    //     colSize: "2",
    //     label: "(Fecho)",
    //     name: "radioSettings.schedule.wed.off",
    //     id: "radioSettings.schedule.wed.off",
    //     type: "number",
    //     min: 0,
    //     maxLength: 2,
    //     requiredMessage: "Apenas dois dígitos (00)",
    // },
    // {
    //     name: "hr",
    //     id: "hr",
    //     type: "component",
    //     noBorder: true,
    // },
    // {
    //     colSize: "2",
    //     label: "Quinta (Abertura)",
    //     name: "radioSettings.schedule.thu.on",
    //     id: "radioSettings.schedule.thu.on",
    //     type: "number",
    //     min: 0,
    //     maxLength: 2,
    //     requiredMessage: "Apenas dois dígitos (00)",
    // },
    // {
    //     colSize: "2",
    //     label: "(Fecho)",
    //     name: "radioSettings.schedule.thu.off",
    //     id: "radioSettings.schedule.thu.off",
    //     type: "number",
    //     min: 0,
    //     maxLength: 2,
    //     requiredMessage: "Apenas dois dígitos (00)",
    // },
    // {
    //     name: "hr",
    //     id: "hr",
    //     type: "component",
    //     noBorder: true,
    // },
    // {
    //     colSize: "2",
    //     label: "Sexta (Abertura)",
    //     name: "radioSettings.schedule.fri.on",
    //     id: "radioSettings.schedule.fri.on",
    //     type: "number",
    //     min: 0,
    //     maxLength: 2,
    //     requiredMessage: "Apenas dois dígitos (00)",
    // },
    // {
    //     colSize: "2",
    //     label: "(Fecho)",
    //     name: "radioSettings.schedule.fri.off",
    //     id: "radioSettings.schedule.fri.off",
    //     type: "number",
    //     min: 0,
    //     maxLength: 2,
    //     requiredMessage: "Apenas dois dígitos (00)",
    // },
    // {
    //     name: "hr",
    //     id: "hr",
    //     type: "component",
    //     noBorder: true,
    // },
    // {
    //     colSize: "2",
    //     label: "Sábado (Abertura)",
    //     name: "radioSettings.schedule.sat.on",
    //     id: "radioSettings.schedule.sat.on",
    //     type: "number",
    //     min: 0,
    //     maxLength: 2,
    //     requiredMessage: "Apenas dois dígitos (00)",
    // },
    // {
    //     colSize: "2",
    //     label: "(Fecho)",
    //     name: "radioSettings.schedule.sat.off",
    //     id: "radioSettings.schedule.sat.off",
    //     type: "number",
    //     min: 0,
    //     maxLength: 2,
    //     requiredMessage: "Apenas dois dígitos (00)",
    // },
    // {
    //     name: "hr",
    //     id: "hr",
    //     type: "component",
    //     noBorder: true,
    // },
    // {
    //     colSize: "2",
    //     label: "Domingo (Abertura)",
    //     name: "radioSettings.schedule.sun.on",
    //     id: "radioSettings.schedule.sun.on",
    //     type: "number",
    //     min: 0,
    //     maxLength: 2,
    //     requiredMessage: "Apenas dois dígitos (00)",
    // },
    // {
    //     colSize: "2",
    //     label: "(Fecho)",
    //     name: "radioSettings.schedule.sun.off",
    //     id: "radioSettings.schedule.sun.off",
    //     type: "number",
    //     min: 0,
    //     maxLength: 2,
    //     requiredMessage: "Apenas dois dígitos (00)",
    // },
    {
        name: "hr",
        id: "hr",
        type: "component",
        className: "mb-4",
    },
    {
        title: "Área MF",
        name: "sectionTitle",
        id: "sectionTitle",
        type: "sectionTitle",
    },
    {
        colSize: "4",
        label: "Adicionado Em",
        name: "addedOn",
        id: "addedOn",
        type: "datepicker",
    },
    {
        colSize: "4",
        label: "Removido Em",
        name: "removedOn",
        id: "removedOn",
        type: "datepicker",
        disabled: true,
    },
    // {
    //     colSize: "4",
    //     label: "Foi Removido ?",
    //     name: "starred",
    //     id: "starred",
    //     type: "checkbox",
    //     disables: "removedOn",
    // },
    {
        colSize: "6",
        label: "Pharmacy Coacher",
        name: "coaches",
        id: "coaches",
        type: "select",
        multi: true,
        options: null,
        optionLabel: "username",
        optionValue: "_id",
        backendPopulated: true,
        api: {
            route: `/api/v1/accounts?role=AdminRole&mf_role=pc,pcj,pcs,pcl`,
        },
    },
    {
        type: "component",
        colSize: "6",
        name: "col",
        id: "col",
    },
    {
        colSize: "6",
        label: "Área Documental",
        name: "extraData.area_doc",
        id: "extraData.area_doc",
        type: "text",
    },
    {
        colSize: "4",
        label: "Crédito em Loja",
        name: "storeCredit",
        id: "storeCredit",
        type: "number",
        step: '0.01'
    },
    {
        name: "hr",
        id: "hr",
        type: "component",
        className: "mb-4",
    },
    {
        title: "Social",
        name: "sectionTitle",
        id: "sectionTitle",
        type: "sectionTitle",
    },
    {
        colSize: "6",
        label: "Facebook",
        name: "facebook",
        id: "facebook",
        type: "text",
    },
    {
        colSize: "6",
        label: "Instagram",
        name: "instagram",
        id: "instagram",
        type: "text",
    },
    // {
    //     colSize: "12",
    //     label: "Banner",
    //     name: "profilePhoto",
    //     id: "profilePhoto",
    //     type: "file",
    //     content: "image",
    //     multi: false,
    //     imageEditInstantEdit: true,
    //     imageResizeTargetWidth: 1200,
    // },
];
