import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Card, CardHeader, CardBody, Button } from 'reactstrap';
import DataTable from 'react-data-table-component';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import moment from 'moment';
import 'moment/locale/pt';

import DataTableExtensions from 'react-data-table-component-extensions';
import "react-data-table-component-extensions/dist/index.css";

import { useGetAll } from '../../../hooks';

const SalesByPharma = ({ apiUrl, rqModel, columns }) => {
    const pharmacies = useSelector(content => content.Pharmacies.results);
    const loaded = useSelector(content => content.Pharmacies.loaded);
    const salesByPharma = useGetAll(apiUrl, rqModel);

    // const [ordersAgg, setOrdersAgg] = useState(null);

    const [totalSales, setTotalSales] = useState(0);
    const [totalCredit, setTotalCredit] = useState(0);
    const [totalAvlbl, setTotalAvlbl] = useState(0);

    useEffect(() => {
        // if (!ordersAgg && loaded && !salesByPharma.isLoading && salesByPharma.data) {
        //     let totalSales = 0, totalCredit = 0, totalAvlbl = 0;
        //     let currentPharma = [...pharmacies]

        //     pharmacies.forEach((el, index) => {
        //         const existingPharma = salesByPharma.data.find((elo) => {
        //             return (elo._id === el.ANF)
        //         });
        //         if (existingPharma) {
        //             currentPharma = currentPharma.filter((el) => {
        //                 return (existingPharma._id !== el.ANF)
        //             })
        //         }
        //     });

        //     salesByPharma.data.push(currentPharma.map(el => {
        //         return { nOrders: 0, total: 0, totalCredit: 0, _id: el.ANF }
        //     }));

        //     salesByPharma.data.forEach((el) => {
        //         totalSales += el.total;
        //         totalCredit += el.totalCredit;
        //     });

        //     salesByPharma.data.forEach((el) => {
        //         totalAvlbl += el.storeCredit;
        //     });

        //     setTotalSales(totalSales);
        //     setTotalCredit(totalCredit);
        //     setTotalAvlbl(totalAvlbl);
        // }
    }, [salesByPharma.isLoading, salesByPharma.data]) // eslint-disable-line

    const getTotalSalesStore = () => {
        let totalSales = 0;
        salesByPharma.data.forEach((el) => {
            totalSales += el.total;
        });

        return `${numberWithCommas(Math.floor(totalSales))}.${Math.floor((totalSales % 1) * 100)}`
    }

    const getTotalCreditStore = () => {
        let totalCredit = 0;
        salesByPharma.data.forEach((el) => {
            totalCredit += el.totalCredit;
        });

        return `${numberWithCommas(Math.floor(totalCredit))}.${Math.floor((totalCredit % 1) * 100)}`
    }

    const getTotalCreditToUse = () => {
        let totalAvlbl = 0;
        pharmacies.forEach((el) => {
            totalAvlbl += el.storeCredit;
        });

        return `${numberWithCommas(Math.floor(totalAvlbl))}.${Math.floor((totalAvlbl % 1) * 100)}`
    }

    const downloadStats = () => {
        /* Call XLSX */
        let wb = XLSX.utils.book_new();

        var myBookData = [];
        myBookData.push([
            "ANF",
            "Farmácia",
            "Encomendas",
            "Total",
            "Credito Usado",
            "Credito Disponivel"
        ])

        salesByPharma.data.forEach((myModel) => {
            let currentPharma = pharmacies.find((el) => el.ANF === myModel._id);
            myBookData.push([
                myModel._id,
                currentPharma.name,
                myModel.nOrders,
                myModel.total.toFixed(2),
                myModel.totalCredit ? myModel.totalCredit.toFixed(2) : 0,
                currentPharma.storeCredit ? currentPharma.storeCredit.toFixed(2) : 0
            ]);
        });

        var ws = sheet_from_array_of_arrays(myBookData);
        var defaultName = 'Listagem Loja MF'
        /* add worksheet to workbook */
        wb.SheetNames.push(defaultName);
        wb.Sheets[defaultName] = ws;
        var wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });

        function s2ab(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        }
        saveAs(new Blob([s2ab(wbout)], { type: "" }), 'Loja_maisfarmacia_estatistica_' + moment().format('YYYYMMDD') + ".xlsx")
    }

    return (
        <Fragment>
            {!salesByPharma.isLoading && loaded ? (
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <h5>Vendas e crédito usado</h5>
                                <span>{"Total de vendas na Loja maisFarmácia"}</span>
                                <div className="todo">
                                    <div className="todo-list-wrapper">
                                        <div className="todo-list-container">
                                            <div className="mark-all-tasks">
                                                <div className="mark-all-tasks-container">
                                                    <Button color="primary" onClick={downloadStats}>{"Download"}</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <Row className="mt-4">
                                    <Col sm="3">
                                        <div className="ecommerce-widgets media">
                                            <div className="media-body">
                                                <p className="f-w-500 font-roboto">{"TOTAIS DE VENDAS"}</p>
                                                <h5 className="f-w-500 mb-0">{getTotalSalesStore()} €</h5>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="ml-4" sm="3">
                                        <div className="ecommerce-widgets media">
                                            <div className="media-body">
                                                <p className="f-w-500 font-roboto">{"CRÉDITO USADO"}</p>
                                                <h5 className="f-w-500 mb-0">{getTotalCreditStore()} €</h5>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="ml-4" sm="3">
                                        <div className="ecommerce-widgets media">
                                            <div className="media-body">
                                                <p className="f-w-500 font-roboto">{"CRÉDITO DISPONÍVEL"}</p>
                                                <h5 className="f-w-500 mb-0">{getTotalCreditToUse()} €</h5>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>

                                <div className="table-responsive product-table">
                                    {/* <div className="feature-products" style={{ position: 'absolute', right: 50 }}>
                                        <span className="f-w-600 m-r-5">{"Data"}</span>
                                        <div className="select2-drpdwn-product select-options d-inline-block" onChange={(e) => console.log(e.target.value)}>
                                            <select className="form-control btn-square" name="select">
                                                <option value="2021">{"2021"}</option>
                                                <option value="2020">{"2020"}</option>
                                            </select>
                                        </div>
                                    </div> */}
                                    <DataTableExtensions
                                        columns={columns}
                                        filterPlaceholder={"Procurar..."}
                                        filterHidden={false}
                                        export={false}
                                        print={false}
                                        data={salesByPharma.data.map((data) => ({
                                            ANF: `${pharmacies.find((pharma) => pharma.ANF === data._id).name}`,
                                            orders: `${data.nOrders}`,
                                            total: `${data.total.toFixed(2)} €`,
                                            totalCredit: `${data.totalCredit.toFixed(2)} €`,
                                            storeCredit: `${pharmacies.find((pharma) => pharma.ANF === data._id).storeCredit.toFixed(2)} €`,
                                        }))}
                                    >
                                        <DataTable
                                            defaultSortField={'orders'}
                                            defaultSortAsc={false}
                                            noHeader
                                            columns={columns}
                                            data={salesByPharma.data.map((data) => ({
                                                ANF: `${pharmacies.find((pharma) => pharma.ANF === data._id).name}`,
                                                orders: `${data.nOrders}`,
                                                total: `${data.total.toFixed(2)} €`,
                                                totalCredit: `${data.totalCredit.toFixed(2)} €`,
                                                storeCredit: `${pharmacies.find((pharma) => pharma.ANF === data._id).storeCredit.toFixed(2)} €`,
                                            }))}
                                            highlightOnHover={true}
                                            pointerOnHover={true}
                                            pagination={true}
                                            paginationPerPage={10}
                                        />
                                    </DataTableExtensions>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            ) : (
                <div className="loader-box loader-abs">
                    <div className="loader-7" />
                </div>
            )}
        </Fragment>
    )
}

export default SalesByPharma

const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const datenum = (v, date1904) => {
    if (date1904) v += 1462;
    var epoch = Date.parse(v);
    return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
}
const sheet_from_array_of_arrays = (data, opts) => {
    var ws = {};
    var range = { s: { c: 10000000, r: 10000000 }, e: { c: 0, r: 0 } };
    for (var R = 0; R !== data.length; ++R) {
        for (var C = 0; C !== data[R].length; ++C) {
            if (range.s.r > R) range.s.r = R;
            if (range.s.c > C) range.s.c = C;
            if (range.e.r < R) range.e.r = R;
            if (range.e.c < C) range.e.c = C;
            var cell = { v: data[R][C] };
            if (cell.v == null) continue;
            var cell_ref = XLSX.utils.encode_cell({ c: C, r: R });

            if (typeof cell.v === 'number') cell.t = 'n';
            else if (typeof cell.v === 'boolean') cell.t = 'b';
            else if (cell.v instanceof Date) {
                cell.t = 'n'; cell.z = XLSX.SSF._table[14];
                cell.v = datenum(cell.v);
            }
            else cell.t = 's';

            ws[cell_ref] = cell;
        }
    }
    if (range.s.c < 10000000) ws['!ref'] = XLSX.utils.encode_range(range);
    return ws;
}