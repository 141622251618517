import React, { Fragment, useState } from 'react';
import axios from 'axios';
import { NavLink, useHistory } from 'react-router-dom';
import { Container, Row, Col, Card, CardHeader, CardBody, Button, Form, FormGroup, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import SweetAlert from 'sweetalert2';
import DataTable from 'react-data-table-component';

import Breadcrumb from '../../../layout/breadcrumb';
import NoResults from '../../ui-kits/noResults';
import CreditModal from './creditModal';

import PaginationDT from '../../pagination';

import CustomForm from "../../form";
import Upload from "./upload";
import { getWithFilters } from "../../../api/";
import { apiUrl, rqModel, pathname, columns, schema } from "./settings";

const Pharmacies = () => {
    const history = useHistory();
    const searchParams = new URLSearchParams(history.location.search);

    const currentPage = parseInt(searchParams.getAll('page')[0]) || 1

    const [page, setPage] = useState(currentPage);
    const [limit, setLimit] = useState(10);
    const [keyValue, setKeyValue] = useState('name');
    const [sortValue, setSortValue] = useState('asc');

    const [tempSearchKeyword, setTempSearchKeyword] = useState('');
    const [searchKeyword, setSearchKeyword] = useState('');

    const [currentPharmacy, setPharmacy] = useState(null);
    
    const [viewType, setViewType] = useState(null);

     //* MODAL
     const [isOpen, setOpen] = useState(false);
     const [currentCredits, setCurrentCredits] = useState(null);

    const role = useSelector(content => content.User.user.role);

    if (role === 'AdminRole' && columns.length === 4) {
        columns.push({
            name: "Opções",
            selector: "action",
            sortable: false,
            center: true,
        })
    };

    if (!searchParams.getAll('page')) window.history.replaceState(null, null, `${pathname}?page=1`);
 
    const getPharmacies = (page, limit, searchKeyword, keyValue, sortValue) => getWithFilters({ apiUrl: '/api/v1/pharma/table', pagination: { page: page, limit: limit }, filters: { search: searchKeyword, sortBy: keyValue, sort: sortValue } });
    const {
        isLoading,
        data,
        error,
    } = useQuery([rqModel, page, limit, searchKeyword, keyValue, sortValue], () => getPharmacies(page, limit, searchKeyword, keyValue, sortValue), { keepPreviousData: true, staleTime: 300000 });

    if (error && error.response) {
        history.push(`${process.env.PUBLIC_URL}/pages/errors/error${error.response.status}`);
    } else if (error) {
        history.push(`${process.env.PUBLIC_URL}/pages/errors/error500`);
    }

    const handleSearchKeyword = () => {
        if (tempSearchKeyword.length > 3) {
            setPage(1);
            setSearchKeyword(tempSearchKeyword)
        } else if (tempSearchKeyword.length <= 3) {
            setPage(1);
            setSearchKeyword('')
        }
    }

    const createView = () => {
        setViewType("form");
        setPharmacy(null);
        window.history.pushState(null, null, `${pathname}?view=create`);
    }

    const editView = async (pharmacy) =>  {
        setViewType("form");
        setPharmacy(pharmacy);
        window.history.pushState(null, null, `${pathname}?view=edit&phid=${pharmacy._id}`);
    }

    const deleteRequest = async (pharmacyId) => {
        // await setProductId(_id);
        SweetAlert.fire({
            title: 'Atenção!',
            text: 'Confirma que pretende marcar como removida do grupo? As contas associadas serão apagadas e não será possível reverter esta operação.',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then(async (result) => {
                await axios.delete(`/api/v1/pharma/${pharmacyId}`).then((res) => {
                    
                });
            })
    }

    const toggleTable = () => {
        setViewType(null);
        setPharmacy(null);
    }

    const toggleModal = async (pharmacy) => {
        setOpen(!isOpen);
        await axios.get(`/api/v1/pharma/${pharmacy.ANF}/credit`).then((res) => {
            setCurrentCredits(res);
            setPharmacy(pharmacy);
        });
    }

    const upload = () => {
        setViewType("upload");
        setPharmacy(null);
    }

    const createSuccess = (data) => {
        toast.success(`Farmácia ${data.name} criada!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const editSuccess = (data) => {
        toast.success(`Farmácia ${data.name} editada!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    return (
        <Fragment>
            <Breadcrumb parent="Administração" title="Farmácias" />
            <Container fluid={true}>
                {!isLoading ? (
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader>
                                    <span>{"Gestão geral de farmácias do grupo."}</span>
                                </CardHeader>
                                <CardBody>
                                    {viewType !== "form" && viewType !== "upload" && (
                                        <div className="feature-products">
                                            <Row>
                                                <Col md="6">
                                                    <Form onSubmit={(ev) => ev.preventDefault()}>
                                                        <FormGroup className="m-0">
                                                            <Input
                                                                className="form-control"
                                                                type="text"
                                                                placeholder="Procurar (mais de 3 caracteres)"
                                                                value={tempSearchKeyword}
                                                                onChange={(e) => {
                                                                    setTempSearchKeyword(e.target.value);
                                                                    if (e.target.value.length === 0) handleSearchKeyword();
                                                                }}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter') handleSearchKeyword();
                                                                }}
                                                            />
                                                            <Button color="primary" onClick={handleSearchKeyword}><i className="fa fa-search" /></Button>
                                                        </FormGroup>
                                                    </Form>
                                                </Col>
                                            </Row>
                                        </div>
                                    )}
                                    {(role === 'AdminRole') && (
                                    <div className="todo">
                                        <div className="todo-list-wrapper">
                                            <div className="todo-list-container">
                                                <div className="mark-all-tasks">
                                                    <div className="mark-all-tasks-container">
                                                        {viewType !== "form" && viewType !== "upload" && (
                                                            <>
                                                                <Button color="primary" onClick={() => upload()}>{"Upload de Montra e Expositor"}</Button>
                                                                <Button color="primary" className="ml-2" onClick={createView}>{"Criar"}</Button>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    )}
                                        {viewType === "form" ? (
                                            <CustomForm data={currentPharmacy} toggleTable={toggleTable} schema={schema} rqModel={"pharmacies"} route={apiUrl} createSuccess={createSuccess} editSuccess={editSuccess} pathname={pathname} />
                                        ) : viewType === "upload" ? (
                                            <Upload toggleTable={toggleTable} />
                                        ) : currentPharmacy && viewType === "profile" ? (
                                            <CustomForm data={currentPharmacy} toggleTable={toggleTable} schema={schema} rqModel={"pharmacies"} route={apiUrl} createSuccess={createSuccess} editSuccess={editSuccess} pathname={pathname} />
                                        ) : (
                                            <div className="table-responsive product-table">
                                                <DataTable
                                                    defaultSortAsc={true}
                                                    defaultSortField={'name'}
                                                    noHeader
                                                    columns={columns}
                                                    data={data && data.results.map((pharmacy) => ({
                                                        name: <NavLink to={`${process.env.PUBLIC_URL}/farmacia/${pharmacy.ANF}`}>{pharmacy.name}</NavLink>,
                                                        GLN: `${pharmacy.GLN}`,
                                                        ANF: `${pharmacy.ANF}`,
                                                        email: `${pharmacy.email}`,
                                                        score: `${pharmacy.score}`,
                                                        action: <div>
                                                            <Button color="primary" size="xs" className="ml-2" onClick={() => editView(pharmacy)}><i className="fa fa-pencil"></i></Button>
                                                            <Button color="primary" size="xs" className="ml-2" onClick={() => toggleModal(pharmacy)}><i className="fa fa-money"></i></Button>
                                                            { !pharmacy.removed && <Button color="danger" size="xs" className="ml-2" onClick={() => deleteRequest(pharmacy.ANF)}><i className="fa fa-trash"></i></Button> }
                                                        </div>
                                                    }))}
                                                    highlightOnHover={true}
                                                    pointerOnHover={true}
                                                    pagination={true}
                                                    paginationPerPage={25}
                                                    noDataComponent={<NoResults />}
                                                    paginationComponent={() => {
                                                        return (
                                                            <PaginationDT totalPage={data.totalPages} currentPage={data.page} limit={data.limit} onChangePage = {(page) => {
                                                                setPage(page)
                                                                window.history.replaceState(null, null, `${pathname}?page=${page}`);
                                                            }}
                                                        />
                                                        )
                                                    }}
                                                    sortServer={true}
                                                    onSort={(column, value) => {
                                                        setKeyValue(column.selector)
                                                        setSortValue(value)
                                                        setPage(1)
                                                    }}
                                                />
                                            </div>
                                        )}
                                    
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    <Row style={{ minHeight: 500 }}>
                        <Col sm="12">
                            <div className="loader-box loader-abs">
                                <div className="loader-7" />
                            </div>
                        </Col>
                    </Row>
                )}
                { currentCredits && currentPharmacy && (
                    <CreditModal
                        isOpen={isOpen}
                        toggle={() => {
                            setOpen(!isOpen);
                            setCurrentCredits(null);
                        }}
                        credits={currentCredits}
                        currentPharmacy={currentPharmacy}
                    />
                )}
            </Container>
        </Fragment>
    )
}

export default Pharmacies
