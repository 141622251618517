export const apiUrl = "/api/v2/codes/table";
export const rqModel = "all_products";

export const columns = [
    {
        name: "CNP",
        selector: "CNP",
        sortable: true,
        center: true,
    },
    {
        name: "Nome",
        selector: "NOME",
        sortable: true,
        center: true,
    },
    {
        name: "Laboratório",
        selector: "LABORATORIO",
        sortable: true,
        center: true,
    },
    {
        name: "Categoria",
        selector: "CATEGORIA_MF",
        sortable: true,
        center: true,
    },
    {
        name: "Segmento",
        selector: "SEGMENTO_MF",
        sortable: true,
        center: true,
    },
];