export const apiUrlScore = "/api/v1/kpis";
export const rqModelScore = "kpis";


export const schemaScore = [
    {
        colSize: "4",
        label: "Nome",
        name: "nome",
        id: "nome",
        type: "text",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "4",
        label: "KPI",
        name: "categoria",
        id: "categoria",
        type: "select",
        multi: false,
        optionLabel: 'nomeFormed',
        optionValue: 'id',
        options: []
    },
    {
        colSize: "3",
        label: "Categoria",
        name: "categoria_cat",
        id: "categoria_cat",
        type: "select",
        required: true,
        requiredMessage: "Este campo é obrigatório",
        multi: false,
        optionLabel: "label",
        optionValue: "value",
        options: [
          {
            value: "Desafio de sell-out",
            label: "Desafio de sell-out",
          },
          {
            value: "Visibilidade Gestão de Categorias",
            label: "Visibilidade Gestão de Categorias",
          },
          {
            value: "MUPI digital maisfarmácia",
            label: "MUPI digital maisfarmácia",
          },
          {
            value: "Rastreios",
            label: "Rastreios",
          },
          {
            value: "Implementação de Serviços Farmacêuticos",
            label: "Implementação de Serviços Farmacêuticos",
          },
          {
            value: "Visita Pharmacy Coachers",
            label: "Visita Pharmacy Coachers",
          },
          {
            value: "Eventos",
            label: "Eventos",
          },
          {
            value: "Formações",
            label: "Formações",
          },
          {
            value: "Outros",
            label: "Outros",
          },
        ],
      },
    {
        colSize: "1",
        label: "Data",
        name: "date",
        id: "date",
        useString: true,
        type: "datepicker",
    },
    {
        name: "hr",
        type: "component",
    },
    {
        colSize: "3",
        label: "Pontuação",
        name: "scoreBonus",
        id: "scoreBonus",
        type: "number",
    },
    {
        colSize: "2",
        label: "Factor",
        name: "scoreFactor",
        id: "scoreFactor",
        type: "number",
        defaultValue: 100
    },

    {
        colSize: "4",
        label: "Farmácias",
        name: "id",
        id: "id",
        type: "select",
        required: false,
        multi: true,
        options: null,
        optionLabel: 'name',
        optionValue: 'ANF',
        api: {
            route: "/api/v1/pharma",
            redux: 'Pharmacies',
        },
    },
    {
        colSize: "3",
        label: "Laboratório",
        name: "lab",
        id: "lab",
        type: "select",
        required: false,
        options: null,
        optionLabel: "name",
        optionValue: "fornecedor_id",
        api: {
            route: "/api/v1/laboratories",
            redux: "Laboratories",
        },
    },
    {
        colSize: "12",
        label: "Ficheiro xls",
        name: "file_manual_score",
        id: "file",
        type: "file",
        content: "file",
        multi: false,
        validate: "manual_score",
    },

];
