import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { Container, Row, Col, Card, CardHeader, CardBody, Button, Input } from 'reactstrap';
import { useQuery } from 'react-query';
import DataTable from 'react-data-table-component';
import { saveAs } from 'file-saver';
import moment from 'moment';
import 'moment/locale/pt';

import Breadcrumb from '../../../layout/breadcrumb';
import NoResults from '../../ui-kits/noResults';

import PaginationDT from '../../pagination';

import OrderModal from './orderModal';

import { getWithFilters } from "../../../api/";
import { apiUrl, rqModel, columns } from "./settings";

import * as XLSX from 'xlsx'

// eslint-disable-next-line no-extend-native
String.prototype.lpad = function(padString, length) {
    var str = this;
    while (str.length < length)
        str = padString + str;
    return str;
}

const StoreOrders = () => {
    const history = useHistory();

    const user = useSelector((content) => content.User.user);
    const pharmacies = useSelector(content => content.Pharmacies.results);
    const loaded = useSelector(content => content.Pharmacies.loaded);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [keyValue, setKeyValue] = useState(null);
    const [sortValue, setSortValue] = useState(null);

    const [tempSearchKeyword, setTempSearchKeyword] = useState('');
    const [searchKeyword, setSearchKeyword] = useState('');

    //* MODAL
    const [isOpen, setOpen] = useState(false)
    const [currentOrder, setCurrentOrder] = useState(null);

    const getPharmacies = (page, limit, searchKeyword, keyValue, sortValue) => getWithFilters({ apiUrl: apiUrl, pagination: { page: page, limit: limit }, filters: { search: searchKeyword, [keyValue]: sortValue } });
    const {
        isLoading,
        data,
        error,
        refetch,
    } = useQuery([rqModel, page, limit, searchKeyword, keyValue, sortValue], () => getPharmacies(page, limit, searchKeyword, keyValue, sortValue), { keepPreviousData: true, staleTime: 300000 });

    if (error && error.response) {
        history.push(`${process.env.PUBLIC_URL}/pages/errors/error${error.response.status}`);
    } else if (error) {
        history.push(`${process.env.PUBLIC_URL}/pages/errors/error500`);
    }

    const handleSearchKeyword = () => {
        setPage(1)
        setSearchKeyword(tempSearchKeyword)
    }

    const toggleModal = (order) => {
        setOpen(!isOpen);
        setCurrentOrder(order);
    }

    const downloadOrder = (order) => {
        const theRealBuyer = pharmacies.find((ph) => ph.ANF === order.buyer);
        if (theRealBuyer) {
            order.pharmName = theRealBuyer.name
            order.pharmANF = theRealBuyer.ANF
            order.pharmAddress = theRealBuyer.address
        } else {
            order.pharmName = 'Desconhecido'
            order.pharmANF = null
            order.pharmAddress = ''
        }

        /* Call XLSX */
        let wb = XLSX.utils.book_new();

        let myBookData = [];
        myBookData.push(["Pedido", "ANF", "Farmácia", "NIF", "Morada", "Data", "Fornecedor", "Nome", "Quantidade", "Preço", "Total", "Crédito"]);

        let myOrder = order;
        let currentSumValue = 0;
        let currentSumQuantity = 0;

        order.items.forEach((myItem, index) => {
            let myArr = [myOrder.orderNumber, myOrder.pharmANF, myOrder.pharmName, "NIF", `${myOrder.pharmAddress.street} ${myOrder.pharmAddress.city} ${myOrder.pharmAddress.code1}-${myOrder.pharmAddress.code2}`, moment(myOrder.createdOn).lang("pt").format('LLL'), myItem.seller.name];
            myArr.push(myItem.name);
            myArr.push(myItem.quantity);
            myArr.push(myItem.price.toString().replace('.', ',', 1));
            myArr.push((myItem.price * myItem.quantity).toString().replace('.', ',', 1));

            if (index === 0) {
                myArr.push(myOrder.usedCredit);
            } else {
                myArr.push(0)
            }

            currentSumQuantity += myItem.quantity
            currentSumValue += (myItem.price * myItem.quantity)
            myBookData.push(myArr)
        });

        let ws = sheet_from_array_of_arrays(myBookData);
        let defaultName = 'Encomenda'
        wb.SheetNames.push(defaultName);
        wb.Sheets[defaultName] = ws;
        let wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });

        function s2ab(s) {
            let buf = new ArrayBuffer(s.length);
            let view = new Uint8Array(buf);
            for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        }

        saveAs(new Blob([s2ab(wbout)], { type: "" }), `Encomenda_#${myOrder.orderNumber}_${moment().format('YYYYMMDD')}.xlsx`);
    }

    return (
        <Fragment>
            <Breadcrumb parent="Loja" title="Encomendas" />
            <Container fluid={true}>
                {!isLoading && loaded ? (
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader></CardHeader>
                                <CardBody>
                                    <div className="feature-products">
                                        <Row style={{marginBottom: 20}}>
                                            <Col md="6">
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Procurar (por nº de encomenda)"
                                                    value={tempSearchKeyword}
                                                    onChange={(e) => {
                                                        setTempSearchKeyword(e.target.value);
                                                        if (e.target.value.length === 0) handleSearchKeyword();
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') handleSearchKeyword();
                                                    }}
                                                />
                                                    
                                               
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="todo">
                                        <div className="table-responsive product-table">
                                            <DataTable
                                                noHeader
                                                columns={columns}
                                                data={data && data.results.map((order) => ({
                                                    number: <a style={{ color: '#7366FF' }} onClick={() => toggleModal(order)}>{order.orderNumber}</a>, //eslint-disable-line
                                                    name: <NavLink to={`${process.env.PUBLIC_URL}/farmacia/${order.buyer}`}>{pharmacies.find((ph) => ph.ANF === order.buyer).name}</NavLink>,
                                                    date: `${moment(order.createdOn).format("LLL")}`,
                                                    state: order.internalStatus === 'inSupplier' ? "Fornecedor" : order.status === 'cancelled' ? "Anulado" : "Pendente",
                                                    total: `${getTotals(order.items).toFixed(2)} €`,
                                                    action: <div>
                                                        <Button color="primary" size="xs" className="ml-2" onClick={() => downloadOrder(order)}><i className="fa fa-download"></i></Button>
                                                    </div>
                                                }))}
                                                highlightOnHover={true}
                                                pointerOnHover={true}
                                                pagination={true}
                                                paginationPerPage={10}
                                                noDataComponent={<NoResults />}
                                                paginationComponent={() => {
                                                    return (
                                                        <PaginationDT totalPage={data.totalPages} currentPage={data.page} limit={data.limit} onChangePage={setPage} />
                                                    )
                                                }}
                                                sortServer={true}
                                                onSort={(column, value) => {
                                                    setKeyValue(column.selector)
                                                    setSortValue(value)
                                                    setPage(1)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    <Row style={{ minHeight: 500 }}>
                        <Col sm="12">
                            <div className="loader-box loader-abs">
                                <div className="loader-7" />
                            </div>
                        </Col>
                    </Row>
                )}
                {currentOrder && user && (
                    <OrderModal
                        isOpen={isOpen}
                        toggle={() => {
                            setOpen(!isOpen);
                            setCurrentOrder(null);
                        }}
                        order={currentOrder}
                        setCurrentOrder={setCurrentOrder}
                        role={user.role}
                        refetch={refetch}
                    />
                )}
            </Container>
        </Fragment>
    )
}

export default StoreOrders

const getTotals = (items) => {
    let thisSum = 0;
    items.forEach((item) => thisSum += (item.price * item.quantity));
    return thisSum
}

const sheet_from_array_of_arrays = (data, opts) => {
    const datenum = (v, date1904) => {
        if (date1904) v += 1462;
        var epoch = Date.parse(v);
        return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
    }

    let ws = {};
    let range = { s: { c: 10000000, r: 10000000 }, e: { c: 0, r: 0 } };
    for (let R = 0; R !== data.length; ++R) {
        for (let C = 0; C !== data[R].length; ++C) {
            if (range.s.r > R) range.s.r = R;
            if (range.s.c > C) range.s.c = C;
            if (range.e.r < R) range.e.r = R;
            if (range.e.c < C) range.e.c = C;
            let cell = { v: data[R][C] };
            if (cell.v == null) continue;
            let cell_ref = XLSX.utils.encode_cell({ c: C, r: R });

            if (typeof cell.v === 'number') cell.t = 'n';
            else if (typeof cell.v === 'boolean') cell.t = 'b';
            else if (cell.v instanceof Date) {
                cell.t = 'n'; cell.z = XLSX.SSF._table[14];
                cell.v = datenum(cell.v);
            }
            else cell.t = 's';

            ws[cell_ref] = cell;
        }
    }
    if (range.s.c < 10000000) ws['!ref'] = XLSX.utils.encode_range(range);
    return ws;
}
