import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Card, CardHeader, Media } from 'reactstrap';

import ProfileTitle from '../../../layout/profileTitle';

import { useGetOne } from '../../../hooks/';

const SupplierProfile = (props) => {
    const history = useHistory();
    const supplierQuery = useGetOne(`/api/v1/laboratories/${props.params && props.params.id ? props.params.id : props && props.match && props.match.params.id ? props.match.params.id : null}`, props.params && props.params.id ? props.params.id : props && props.match && props.match.params.id ? props.match.params.id : null, 'suppliers');
    if (supplierQuery.error && supplierQuery.error.response) {
        history.push(`${process.env.PUBLIC_URL}/pages/errors/error${supplierQuery.error.response.status}`);
    } else if (supplierQuery.error) {
        history.push(`${process.env.PUBLIC_URL}/pages/errors/error500`);
    }

    return (
        <Fragment>
            <Container fluid={true}>
                {supplierQuery.data ? (
                    <>
                        <ProfileTitle title={supplierQuery.data.name} route={`${process.env.PUBLIC_URL}/administração/fornecedores`} />
                        <div className="user-profile">
                            <Row>
                                <Col sm="12" className="mt-4">
                                    <Card className="card hovercard text-center">
                                        <CardHeader className="cardheader"></CardHeader>
                                        <div className="user-image">
                                            <div className="avatar"><Media body src={supplierQuery.data.profilePhoto ? supplierQuery.data.profilePhoto : require('../../../assets/images/user/7.jpg')} data-intro="This is Profile image" /></div>
                                        </div>
                                        <div className="info">
                                            <Row>
                                                <Col sm="6" lg="4" className="order-sm-1 order-xl-0">
                                                    <Row >
                                                        <Col md="6">
                                                            <div className="ttl-info text-left">
                                                                <h6><i className="fa fa-envelope mr-2"></i> {"Código"}</h6><span>{supplierQuery.data.fornecedor_id}</span>
                                                            </div>
                                                        </Col>
                                                        <Col md="6">
                                                            <div className="ttl-info text-left ttl-sm-mb-0">
                                                                <h6><i className="fa fa-user"></i>   {"Estado"}</h6><span>{supplierQuery.data.partner_state}</span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col sm="12" lg="4" className="order-sm-0 order-xl-1">
                                                    <div className="user-designation">
                                                        <div className="title"><a target="_blank" href="#javascript">{`${supplierQuery.data.name}`}</a></div>
                                                    </div>
                                                </Col>
                                                <Col sm="6" lg="4" className="order-sm-2 order-xl-2">
                                                    <Row>
                                                        <Col md="6">
                                                            <div className="ttl-info text-left ttl-xs-mt">
                                                                <h6><i className="fa fa-telegram"></i>   {"Perfil"}</h6><span>{supplierQuery.data.profile}</span>
                                                            </div>
                                                        </Col>
                                                       
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <hr />
                                            
                                            
                                        </div>
                                    </Card>
                                </Col>
                                 <Col sm="12" className="mt-4">
                                
                            </Col>
                           
                   
                            </Row>
                        </div>
                    </>
                ) : (
                    <Row style={{ minHeight: 500 }}>
                        <Col sm="12">
                            <div className="loader-box loader-abs">
                                <div className="loader-7" />
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
        </Fragment>
    );
}

export default SupplierProfile;