import React, { Fragment, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Media, Form, FormGroup, Input, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import SweetAlert from 'sweetalert2'
import moment from 'moment';
import 'moment/locale/pt';

import Breadcrumb from '../../../layout/breadcrumb'

import PaginationDT from '../../pagination';

import { useDelete } from '../../../hooks/';

import CustomForm from "../../form";
import { getWithFilters } from "../../../api/";
import { apiUrl, rqModel, pathname, schema } from "./settings";

import errorImg from '../../../assets/images/search-not-found.png';
import { NavLink } from 'react-router-dom';

import { useSelector } from 'react-redux'

const News = () => {

    const user = useSelector(content => content.User.user);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(3);

    const [tempSearchKeyword, setTempSearchKeyword] = useState('');
    const [searchKeyword, setSearchKeyword] = useState(null);

    const getNews = (page, limit, searchKeyword) => getWithFilters({ apiUrl: apiUrl, pagination: { page: page, limit: limit }, filters: { search: searchKeyword } });

    const [currentNews, setNews] = useState(null);
    const [viewType, setViewType] = useState(null);
    const [newsId, setNewsId] = useState(null);

    const {
        isLoading,
        data,
    } = useQuery([rqModel, page, searchKeyword], () => getNews(page, limit, searchKeyword), { keepPreviousData: true, staleTime: 300000 });
    const deleteData = useDelete(apiUrl, rqModel, newsId, page);

    const handleSearchKeyword = () => {
        if (tempSearchKeyword.length > 3) {
            setPage(1)
            setSearchKeyword(tempSearchKeyword)
        } else if (tempSearchKeyword.length === 0) {
            setPage(1)
            setSearchKeyword('')
        }
    }

    const toggleTable = () => {
        setViewType(null);
        setNews(null);
    }

    const createView = () => {
        setViewType("form");
        setNews(null);
        window.history.pushState(null, null, `${pathname}?view=create`);
    }

    const editView = (user) => {
        setViewType("form");
        setNews(user);
        window.history.pushState(null, null, `${pathname}?view=edit`);
    }

    const createSuccess = (data) => {
        toast.success(`Notícia ${data.title} criada!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const editSuccess = (data) => {
        toast.success(`Notícia ${data.title} editada!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const deleteRequest = async (_id) => {
        await setNewsId(_id);
        SweetAlert.fire({
            title: 'Atenção!',
            text: 'Confirma que pretende apagar esta Notícia?',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then(async (result) => {
                if (result.value) {
                    await deleteData.mutate();
                    if (deleteData.isIdle) {
                        toast.success("Notícia apagada!", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                }
            })
    }

    return (
        <Fragment>
            <Breadcrumb parent="Geral" title="Notícias" />
            <Container fluid={true}>
                {viewType !== "form" &&
                    <Row className="justify-content-center">
                        <Col xl={(user.role === "AdminRole") ? 9: 10}
                            className="feature-products">
                
                            <Form onSubmit={(ev) => ev.preventDefault()}>
                                <FormGroup className="m-0">
                                    <Input
                                        className="form-control"
                                        type="text"
                                        placeholder="Procurar"
                                        value={tempSearchKeyword}
                                        onChange={(e) => {
                                            setTempSearchKeyword(e.target.value);
                                            handleSearchKeyword();
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') handleSearchKeyword();
                                        }}
                                    />
                                </FormGroup>
                            </Form>
                        </Col>
                        {(user.role === "AdminRole") ?
                            <Col xl="1" className="feature-products">
                                <Button color="primary" onClick={createView} style={{ height: 50, width: '100%' }}>{"Criar"}</Button>
                            </Col>
                        : '' }
                    </Row>
                }
                {viewType === "form" ? (
                    <Card>
                        <CardBody>
                            <CustomForm data={currentNews} toggleTable={toggleTable} schema={schema} rqModel={rqModel} route={apiUrl} createSuccess={createSuccess} editSuccess={editSuccess} pathname={pathname} />
                        </CardBody>
                    </Card>
                ) : currentNews && viewType === "profile" ? (
                    <Card>
                        <CardBody>
                            <CustomForm data={currentNews} toggleTable={toggleTable} schema={schema} rqModel={rqModel} route={apiUrl} createSuccess={createSuccess} editSuccess={editSuccess} pathname={pathname} />
                        </CardBody>
                    </Card>

                ) : (
                    <Row className="justify-content-center">
                        <Col xl="10 xl-60">
                            { !isLoading && data.results.length <= 0 ?
                                <div className="search-not-found text-center">
                                    <div>
                                        <img className="img-fluid second-search" src={errorImg} alt="no-results" />
                                        <p>{"Não foram encontrados resultados"}</p>
                                    </div>
                                </div>
                                :
                                <Row>
                                    { !isLoading && data && data.results.map((data, i) =>
                                        <Col xl="12" key={i}>
                                            <Card>
                                                <Row className="blog-box blog-list pt-4" style={{ alignItems: 'flex-start' }}>
                                                    <Col md="1">
                                                        {/* <Media className="img-fluid sm-100-w pt-0" src={data.cover} alt="" /> */}
                                                    </Col>
                                                    <Col md="10">
                                                        <div className="blog-details">
                                                            <div className="blog-date digits"><span>{moment(data.date).format("D")}</span> {moment(data.date).format("MMMM YYYY")}</div>
                                                            <h6><NavLink to={`${process.env.PUBLIC_URL}/noticias/${data._id}`}>{data.title}</NavLink></h6>
                                                            <div className="blog-bottom-content">
                                                                <ul className="blog-social">
                                                                    <span className="badge badge-info">{capitalize(data.type)}</span>
                                                                </ul>
                                                                <hr />
                                                                <p className="mt-0">{data.teaser}</p>
                                                                <div className="fr-view"  dangerouslySetInnerHTML={{ __html: data.body }} />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                {(user.role === "AdminRole") ?
                                                <div className="corner-buttons">
                                                    <Button color="primary" size="xs" className="ml-2" onClick={() => editView(data)}><i className="fa fa-pencil"></i></Button>
                                                    <Button color="primary" size="xs" className="ml-2" onClick={() => deleteRequest(data._id)}><i className="fa fa-trash"></i></Button>
                                                </div>
                                                : '' }
                                            </Card>
                                        </Col>
                                    )}
                                </Row>
                            }
                            {!isLoading && data.results.length > 0 && (
                                <PaginationDT
                                    centered
                                    totalPage={data.totalPages}
                                    currentPage={data.page}
                                    limit={data.limit}
                                    onChangePage={(page) => {
                                        window.scrollTo(0,0)
                                        setPage(page)
                                    }}
                                />
                            )}
                        </Col>
                    </Row>
                )}
            </Container>
        </Fragment>
    );
};

export default News;

// const getNewsAxios = (options) => {
//     let filterString = "?"

//     if (options.filters) {
//         let thisLength = Object.keys(options.filters).length;
//         let i = 0;
//         for (let k in options.filters) {
//             if (options.filters[k] !== null) filterString += "".concat(k, "=").concat(options.filters[k]);
//             if (i < thisLength - 1 || options.pagination) filterString += "&"; //! TEMP
//             i++;
//         }
//     }
//     if (options.pagination) filterString += "&limit=".concat(options.pagination.limit, "&page=").concat(options.pagination.page, "&");

//     return axios.get(`${apiUrl}${filterString}`).then((res) => res.data)
// }

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}