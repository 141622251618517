import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Table } from 'reactstrap';

const ProductsTable = ({ isOpen, toggle, products, segment }) => {
    return (
        <Modal isOpen={isOpen} toggle={() => toggle(!isOpen)} size="lg" style={{ maxWidth: 1185 }}>
            <ModalHeader toggle={() => toggle(!isOpen)}>
                {segment}
            </ModalHeader>
            <ModalBody>
                <Table>
                    <thead>
                        <tr>
                            <th className="text-center">Nome</th>
                            <th className="text-center">CNP</th>
                            <th className="text-center">Preço</th>
                            <th className="text-center">Quantidade</th>
                            <th className="text-center">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {products.map((product, index) => {
                            let myProcessDiscount = (100 - parseInt(product.discount)) / 100;
                            return (
                                <tr key={index}>
                                    <th className="text-center">{product.name}</th>
                                    <td className="text-center">{product.cnp}</td>
                                    <td className="text-center">{product.pvf} €</td>
                                    <td className="text-center">{product.quantity2}</td>
                                    <td className="text-center">{(product.pvf * product.quantity2 * myProcessDiscount).toFixed(2)} €</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={() => toggle(!isOpen)}>
                    {"Fechar"}
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default ProductsTable;