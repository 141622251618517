import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, Badge } from 'reactstrap';
import { Play, Pause, SkipForward, Circle } from 'react-feather';
import axios from 'axios';

import errorImg from '../../assets/images/search-not-found.png';

const Radio = ({ notificationsQuery, data, ANF, modalOpen, toggleModal }) => {
    const [currentPlaylist, setPlaylist] = useState(null);

    useEffect(() => {
        if (data && data.radio && data.radio.playlist && !currentPlaylist) {
            setPlaylist(data.radio.playlist);
        }
    }, [data, currentPlaylist])

    const playPlaylist = (playlist) => {
        axios.put(`/radio/send_command`, { ANF: ANF, command: data.radio.state === 'paused' ? "resume" : "play", song: 0, playlist: playlist.name })
            .then((result) => {
                //! MISSING Change currentSong and Category in title
                console.log(result);

                setPlaylist(playlist.name);
                data.radio.state = 'playing';
                notificationsQuery.refetch();
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const nextMusic = () => {
        axios.put(`/radio/send_command`, { ANF: ANF, command: "next", song: 0, playlist: currentPlaylist ? currentPlaylist : data.playlists[0].name })
            .then((result) => {
                //! MISSING Change currentSong and Category in title
                console.log(result);

                notificationsQuery.refetch();
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const play = () => {
        axios.put(`/radio/send_command`, { ANF: ANF, command: data.radio.state === 'paused' ? "resume" : "play", song: 0, playlist: currentPlaylist ? currentPlaylist : data.playlists[0].name })
            .then((result) => {
                //! MISSING Change currentSong and Category in title
                console.log(result);

                setPlaylist(currentPlaylist ? currentPlaylist : data.playlists[0].name);
                data.radio.state = 'playing';
                notificationsQuery.refetch();
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const stop = () => {
        axios.put(`/radio/send_command`, { ANF: ANF, command: "stop", song: 0, playlist: currentPlaylist })
            .then((result) => {
                //! MISSING Change currentSong and Category in title
                console.log(result);

                data.radio.state = 'stopped';
                notificationsQuery.refetch();
            })
            .catch((error) => {
                console.error(error);
            });
    }

    return (
        <Modal isOpen={modalOpen} toggle={toggleModal} wrapClassName="modal-right" backdrop="static">
            <ModalHeader toggle={toggleModal}>
                <h5><Circle size={"20"} color={data.radio.state === "stopped" || data.radio.state === "paused" ? "#dc3545" : "#51bb25"} /><span style={{ marginLeft: 15 }}>MF Music</span></h5>
                <div style={{ marginTop: 20 }}>
                    {data.radio.songTitle && (
                        <img src={data.radio.coverPhoto ? data.radio.coverPhoto : errorImg} width="75" alt="cover" />
                    )}
                    {data.radio.artist && (
                        <div>
                            <span className="song">{data.radio.artist}</span>
                            {/* <span className="song">{data.radio.artist} - {data.radio.songTitle}</span> */}
                        </div>
                    )}
                    <Row>
                        <Col md={"4"} sm={"12"} className="d-flex align-items-center justify-content-center">
                            {data.radio.songTitle && (
                                <img src={data.radio.coverPhoto ? data.radio.coverPhoto : errorImg} width="60" alt="cover" />
                            )}
                        </Col>
                        <Col md={"8"} sm={"12"} className="align-items-sm-center justify-content-sm-center">
                            <Row>
                                {data.radio.artist && (
                                    <span className="song">{data.radio.artist} - {data.radio.songTitle}</span>
                                )}
                            </Row>
                            <Row>
                                {(() => {
                                    switch (data.radio.state) {
                                        case "stopped":
                                            return (
                                                <Play className="radio-btn" onClick={() => play()} />
                                            )
                                        case "playing":
                                            return (
                                                <Pause className="radio-btn" onClick={() => stop()} />
                                            )
                                        case "paused":
                                            return (
                                                <Play className="radio-btn" onClick={() => play()} />
                                            )
                                        default: return null;
                                    }
                                })()}
                                <SkipForward className="radio-btn" onClick={() => nextMusic()} />
                            </Row>
                        </Col>
                    </Row>
                </div>
            </ModalHeader>
            <ModalBody>
                <Row className="pl-2 pr-2">
                    {data.playlists.map((playlist, index) => (
                        <Badge key={index} className="playlist-bdg" color={playlist.name === currentPlaylist ? "primary" : "warning"} onClick={() => playPlaylist(playlist)}>{playlist.name}</Badge>
                    ))}
                </Row>
            </ModalBody>
        </Modal>
    )
}

export default Radio