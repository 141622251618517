import { actionTypes } from "./";
import getFinalRole from "../../data/getFinalRole";

const INIT_STATE = {
    loaded: false,
    authenticated: false,
    kpiInfo: [],
    user: null,
    _finalRole: null,
    _mainRole: null,
    myPharma: null,

    notifications: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case actionTypes.SET_USER:
            let roles = {
                _finalRole: null,
                _mainRole: null
            };
            if (action.payload.user) roles = getFinalRole(action.payload.user);
            return {
                ...state,
                ...action.payload,
                _finalRole: roles._finalRole ? roles._finalRole : state._finalRole,
                _mainRole: roles._mainRole ? roles._mainRole : state._mainRole,
                kpiInfo: action.payload.kpiInfo ? action.payload.kpiInfo : state.kpiInfo,
                user: action.payload.user ? action.payload.user : state.user,
                loaded: action.payload.loaded ? action.payload.loaded : true,
                authenticated: action.payload.authenticated ? action.payload.authenticated : true,
            };
        case actionTypes.SET_MY_PHARMA:
            return {
                ...state,
                myPharma: action.payload
            };
        case actionTypes.SET_NEW_NOTIFICATION:
            return {
                ...state,
                notifications: [action.payload, ...state.notifications]
            };
        case actionTypes.MARK_NOTIFICATION_AS_READ:
            return {
                ...state,
                notifications: state.notifications.filter((notif) => notif._id !== action.payload._id)
            };
        case actionTypes.REFRESH_TODOS:
            return {
                ...state,
                todo: [...action.payload.todo]
            };
        default:
            return { ...state };
    }
};
