export const apiUrl = "/api/v1/store_laboratories";
export const rqModel = "store_laboratories";
export const pathname = `${process.env.PUBLIC_URL}/loja/fornecedores`;

export const columns = [
  {
    name: "Nome",
    selector: "name",
    sortable: true,
    center: true,
  },
  {
    name: "Fornecedor ID",
    selector: "code",
    sortable: true,
    center: true,
  },
  {
    name: "Opções",
    selector: "action",
    sortable: false,
    center: true,
  },
];

export const schema = [
  {
    colSize: "8",
    label: "Nome",
    name: "name",
    id: "name",
    type: "text",
    required: true,
    requiredMessage: "Este campo é obrigatório",
  },
  {
    colSize: "4",
    label: "Código",
    name: "code",
    id: "code",
    type: "text",
    required: true,
    requiredMessage: "Este campo é obrigatório",
  },
  {
    colSize: "3",
    label: "Mínimo da Encomenda",
    name: "minimumAmount",
    id: "minimumAmount",
    type: "number",
    step: '0.01',
    required: true,
    requiredMessage: "Este campo é obrigatório",
  },
  {
    colSize: "2",
    label: "Mínimo Quantidades",
    name: "minimumQuantity",
    id: "minimumQuantity",
    type: "number",
    required: true,
    requiredMessage: "Este campo é obrigatório",
  },
  {
    colSize: "3",
    label: "Portes Envio",
    name: "shippingAmount",
    id: "shippingAmount",
    type: "number",
    step: '0.01',
    required: true,
    requiredMessage: "Este campo é obrigatório",
  },
  {
    colSize: "3",
    label: "Aplica portes",
    name: "alwaysAppliesShippingFee",
    id: "alwaysAppliesShippingFee",
    type: "checkbox",
    requiredMessage: "Este campo é obrigatório",
  },
  {
    colSize: "3",
    label: "Tipo Fornecedor",
    name: "for_type",
    id: "for_type",
    type: "select",
    required: true,
    requiredMessage: "Este campo é obrigatório",
    multi: false,
    optionLabel: "label",
    optionValue: "value",
    options: [
      {
        value: "regular",
        label: "Normal",
      },
      {
        value: "renting",
        label: "Renting",
      },
    ],
  },
  {
    colSize: "9",
    label: "Emails",
    name: "emails",
    id: "emails",
    type: "creatable",
    validateType: "email",
    options: [],
    values: [],
  },
];
