import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';

export const deleteModel = (route, modelId) => {
    return axios.delete(`${route}/${modelId}`).then((res) => res.data)
}

export default function useDelete(route, rqModel, modelId, page) {
    const queryClient = useQueryClient();

    return useMutation([route, rqModel, modelId, page], () => deleteModel(route, modelId), {
        onSuccess: () => {
            if (page) {
                if (queryClient.getQueryData([rqModel, page])) {
                    queryClient.setQueryData([rqModel, page], old => {
                        if (old.results) {
                            const newModels = old.results.filter(d => d._id !== modelId)

                            old.results = newModels;
                            return old;
                        } else {
                            return old.filter(d => d._id !== modelId)
                        }
                    });
                }
            } else {
                if (queryClient.getQueryData(rqModel)) {
                    queryClient.setQueryData(rqModel, old => {
                        if (old.results) {
                            const newModels = old.results.filter(d => d._id !== modelId);

                            old.results = newModels;
                            return old;
                        } else {
                            return old.filter(d => d._id !== modelId);
                        }
                    });
                }
            }
        },
        onSettled: (data, variables, context) => {
            queryClient.invalidateQueries(rqModel);
        },
        initialStale: true
    });
}