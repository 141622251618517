export const apiUrl = "/api/v2/news";
export const rqModel = "news";
export const pathname = `${process.env.PUBLIC_URL}/centro/noticias`;

export const schema = [
  {
    colSize: "6",
    label: "Título",
    name: "title",
    id: "title",
    type: "text",
    required: true,
    requiredMessage: "Este campo é obrigatório",
  },
  {
    colSize: "2",
    label: "Tipo",
    name: "type",
    id: "type",
    type: "select",
    required: true,
    requiredMessage: "Este campo é obrigatório",
    multi: false,
    optionLabel: "label",
    optionValue: "value",
    options: [
      {
        value: "dicas",
        label: "Dicas",
      },
      {
        value: "quadroOperacional",
        label: "Quadro Operacional",
      },
      {
        value: "campanhas",
        label: "Campanhas",
      },
      {
        value: "negociacoes",
        label: "Negociações",
      },
      {
        value: "formacoes",
        label: "Formações",
      },
      {
        value: "outras",
        label: "Outras",
      },
    ],
  },
  {
    colSize: "1",
    label: "Data",
    name: "date",
    id: "date",
    type: "datepicker",
    required: true,
    useString: true,
    requiredMessage: "Este campo é obrigatório",
  },
  {
    colSize: "3",
    label: "Encomenda Push",
    name: "pushId",
    id: "pushId",
    type: "select",
    multi: false,
    options: null,
    optionLabel: "name",
    optionValue: "_id",
    api: {
      route: "/api/v1/inqueritos?state=temporary&quicklist=true",
    },
  },
  {
    colSize: "12",
    label: "Descritivo",
    name: "teaser",
    id: "teaser",
    type: "text",
    required: false,
    requiredMessage: "Este campo é obrigatório",
  },
  {
    colSize: "12",
    label: "Escolher imagem de capa",
    name: "cover",
    id: "cover",
    type: "file",
    content: "image",
    multi: false,
    route: "/api/v1/news",
    // imageEditInstantEdit: true,
    // imageResizeTargetWidth: 1200,
  },
  // {
  //   colSize: "3",
  //   label: "Score Factor (0-100)",
  //   name: "scoreFactor",
  //   id: "scoreFactor",
  //   type: "number",
  //   required: true,
  //   requiredMessage: "Este campo é obrigatório",
  // },
  // {
  //   colSize: "3",
  //   label: "Mailchimp Key",
  //   name: "mailchimpkey",
  //   id: "mailchimpkey",
  //   type: "text",
  //   required: true,
  //   requiredMessage: "Este campo é obrigatório",
  // },
  
  {
    name: "hr",
    id: "hr",
    type: "component",
    className: "mb-4",
  },
  {
    colSize: "3",
    label: "Exibe Fornecedor",
    name: "lab",
    id: "lab",
    type: "checkbox",
    className: "ml-20",
  },
  {
    colSize: "3",
    label: "Draft",
    name: "draft",
    id: "draft",
    type: "checkbox",
  },
  // {
  //   colSize: "3",
  //   label: "Banner (App)",
  //   name: "isBanner",
  //   id: "isBanner",
  //   type: "checkbox",
  // },
  {
    name: "hr",
    id: "hr",
    type: "component",
    className: "mb-4",
  },
  {
    colSize: "12",
    label: "Corpo da Notícia",
    name: "body",
    id: "body",
    type: "froala",
    required: true,
    requiredMessage: "Este campo é obrigatório",
    imageUploadParams: "news_editor",
  },
];
