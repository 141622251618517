
export const schema = [
  
{
  colSize: "6",
  label: "Posição",
  name: "internal_role",
  id: "FarmaciaRole",
  type: "select",
  required: true,
  requiredMessage: "Este campo é obrigatório",
  multi: false,
  optionLabel: "label",
  optionValue: "value",
  options: [
      {
          value: "dt",
          label: "Director Técnico",
      },
      {
          value: "fm",
          label: "Farmacêutico",
      },
      {
          value: "fmas",
          label: "Farmacêutico Adjunto Substituto",
      },
      {
          value: "tf",
          label: "Técnico de Farmácia",
      },
      {
          value: "tfa",
          label: "Ajudante Técnico de Farmácia",
      },
      {
          value: "aux",
          label: "Auxiliar",
      },
      {
          value: "ges",
          label: "Gestor",
      },
  ],
},
{
  colSize: "6",
  label: "Farmácia",
  name: "anf_code",
  id: "anf_code",
  type: "select",
  required: true,
  requiredMessage: "Este campo é obrigatório",
  multi: false,
  options: null,
  optionLabel: "name",
  optionValue: "ANF",
  api: {
      route: "/api/v1/pharma",
      redux: "Pharmacies",
  },
},
{
  colSize: "6",
  label: "Farmácia Afectas",
  name: "qlikRefANF",
  id: "qlikRefANF",
  type: "select",
  required: true,
  requiredMessage: "Este campo é obrigatório",
  multi: true,
  options: null,
  optionLabel: "name",
  optionValue: "ANF",
  api: {
      route: "/api/v1/pharma",
      redux: "Pharmacies",
  },
},
]