export const apiUrlPharma = "/api/v1/orders/aggregate_by_pharma";
export const rqModelPharma = "aggregate_by_pharma";

export const apiUrlSupplier = "/api/v1/orders/aggregate_by_seller";
export const rqModelSupplier = "aggregate_by_seller";

export const columnsByPharma = [
    {
        name: "ANF",
        selector: "ANF",
        sortable: true,
        center: true,
    },
    {
        name: "Encomendas",
        selector: "orders",
        sortable: true,
        center: true,
    },
    {
        name: "Valor Total",
        selector: "total",
        sortable: true,
        center: true,
    },
    {
        name: "Crédito Usado",
        selector: "totalCredit",
        sortable: true,
        center: true,
    },
    {
        name: "Crédito Disponível",
        selector: "storeCredit",
        sortable: false,
        center: true,
    }
];

export const columnsBySupplier = [
    {
        name: "Fornecedor",
        selector: "supplier",
        sortable: true,
        center: true,
    },
    {
        name: "Encomendas",
        selector: "orders",
        sortable: true,
        center: true,
    },
    {
        name: "Total",
        selector: "total",
        sortable: true,
        center: true,
    }
];