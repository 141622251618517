import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Container, Row, Col, Card, CardHeader, CardBody, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import SweetAlert from 'sweetalert2'

import Breadcrumb from '../../../layout/breadcrumb';
import NoResults from '../../ui-kits/noResults';

import { useGetAll, useDelete } from '../../../hooks/';


import CustomForm from "../../form";
import { apiUrl, rqModel, columns, schema, pathname } from "./settings";
import { apiUrlScore, rqModelScore, schemaScore} from './score'

const Kpis = () => {
    const history = useHistory();

    const kpisQuery = useGetAll(apiUrl, rqModel);
    const laboratories = useGetAll(`/api/v1/kpi_list?manual=true`, 'store_laboratories');

    if (laboratories && laboratories.data && laboratories.data.results) {
        laboratories.data.results.sort((a, b) => {
            return b.kc_id - a.kc_id;
          })
        laboratories.data.results.forEach((kpi) => {
            kpi.nomeFormed = `${kpi.nome} - Ciclo ${kpi.kc_id} - ${moment(kpi.date_start).format('YYYY/MM/DD')} a ${moment(kpi.date_end).format('YYYY/MM/DD')}`
        })
        schemaScore.find(el => el.name === 'categoria').options = laboratories.data.results;
    }
        
    const [currentKpi, setKpi] = useState(null);
    const [viewType, setViewType] = useState(null);
    const [kpiId, setKpiId] = useState(null);

    if(kpisQuery && kpisQuery.data && kpisQuery.data.results) {
        kpisQuery.data.results.forEach((kpi) => {
            const foundCat = schema.find(el => el.name === 'categoria').options.find(el => el.value === kpi.categoria);
            if (foundCat) {
                kpi.categoriaFormated = foundCat.label;
            } else {
                kpi.categoriaFormated = `<i>${kpi.categoria}</i>`;
            }
            kpi.rangesFormated = '-';
            if(kpi.colrange1) {
                kpi.rangesFormated += `${kpi.colrange1} < `
            }
            if(kpi.colrange2) {
                kpi.rangesFormated += `${kpi.colrange2} < `
            }
            if(kpi.colrange3){
                kpi.rangesFormated += kpi.colrange3;
            }
        });
    }

    const deleteData = useDelete(apiUrl, rqModel, kpiId)

    if (kpisQuery.error && kpisQuery.error.response) {
        history.push(`${process.env.PUBLIC_URL}/pages/errors/error${kpisQuery.error.response.status}`);
    } else if (kpisQuery.error) {
        history.push(`${process.env.PUBLIC_URL}/pages/errors/error500`);
    }

    const toggleTable = () => {
        setViewType(null);
        setKpi(null);
    }

    const createView = () => {
        setViewType("form");
        setKpi(null);
    }

    const editView = (kpi) => {
        setViewType("form");
        setKpi(kpi);
    }

    const cloneView = (kpi) => {
        setViewType("form");
const clonedKpi = { ...kpi };
delete clonedKpi.id;
delete clonedKpi._id;

        setKpi(clonedKpi);
    }
    const pontuarModal = () => {
        setViewType("score");
    }

    const createSuccess = (data) => {
        toast.success(`KPI criado!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const editSuccess = (data) => {
        toast.success(`KPI editado!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const deleteRequest = async (_id) => {
        await setKpiId(_id);
        SweetAlert.fire({
            title: 'Atenção!',
            text: 'Confirma que pretende apagar este Kpi?',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
        .then(async (result) => {
            if (result.value) {
                await deleteData.mutate();
                if (deleteData.isIdle) {
                    toast.success("User apagado!", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            }
        })
    }

    return (
        <Fragment>
            <Breadcrumb parent="Administração" title="Premiação" />
            <Container fluid={true}>
                {!kpisQuery.isLoading ? (
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader>
                                    <span>{"Gestão de KPIs"}</span>
                                </CardHeader>
                                <CardBody>
                                    <div className="todo">
                                        <div className="todo-list-wrapper">
                                            <div className="todo-list-container">
                                                <div className="mark-all-tasks">
                                                    <div className="mark-all-tasks-container">
                                                        {viewType !== "form" && (
                                                            <>
                                                                <Button color="primary" onClick={() => pontuarModal()}>{"Pontuar"}</Button>
                                                                <Button color="primary" className="ml-2" onClick={createView}>{"Criar"}</Button>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {viewType === "form" ? (
                                            <CustomForm data={currentKpi} toggleTable={toggleTable} schema={schema} route={apiUrl} rqModel={"kpiList"} createSuccess={createSuccess} editSuccess={editSuccess} pathname={pathname} />
                                        ) : currentKpi && viewType === "profile" ? (
                                            <CustomForm data={currentKpi} toggleTable={toggleTable} schema={schema} />
                                        ) : viewType === "score" ? (
                                            <CustomForm route={apiUrlScore} rqModel={rqModelScore} schema={schemaScore} toggleTable={toggleTable}/>
                                        ) : kpisQuery && kpisQuery.data && kpisQuery.data.results ? (
                                            <div className="table-responsive product-table">
                                                <DataTable
                                                    noHeader
                                                    columns={columns}
                                                    defaultSortField="kc_id"
                                                    defaultSortAsc={false}
                                                    data={kpisQuery.data.results.map((kpi) => ({
                                                        nome: <>{kpi.is_manual ? <span className="badge badge-secondary">Manual</span> : ""} {kpi.nome} </>,
                                                        categoria: <div dangerouslySetInnerHTML={{ __html: kpi.categoriaFormated }}></div>,
                                                        ranges: <div dangerouslySetInnerHTML={{ __html: kpi.rangesFormated }}></div>,
                                                        scores: `${kpi.colrel1 ? kpi.colrel1 : kpi.colrel2 ? kpi.colrel2 : kpi.colrel3 ? kpi.colrel3 : `-`}`,
                                                        dates: <> {moment(kpi.date_start).format('DD/MM/YYYY')} <br /> {moment(kpi.date_end).format('DD/MM/YYYY')}</>,
                                                        kc_id: kpi.kc_id,
                                                        actions: <div>
                                                            <Button color="primary" size="xs" className="ml-2" onClick={() => cloneView(kpi)}><i className="fa fa-clone"></i></Button>
                                                            <Button color="primary" size="xs" className="ml-2" onClick={() => editView(kpi)}><i className="fa fa-pencil"></i></Button>
                                                            <Button color="primary" size="xs" className="ml-2" onClick={() => deleteRequest(kpi._id)}><i className="fa fa-trash"></i></Button>
                                                        </div>
                                                    }))}
                                                    highlightOnHover={true}
                                                    pointerOnHover={true}
                                                    paginationPerPage={25}
                                                    pagination={true}
                                                    noDataComponent={<NoResults />}
                                                />
                                            </div>
                                        ) : (
                                            <NoResults />
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    <Row style={{ minHeight: 500 }}>
                        <Col sm="12">
                            <div className="loader-box loader-abs">
                                <div className="loader-7" />
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
        </Fragment>
    )
}

export default Kpis
