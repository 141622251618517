import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Media, Form, FormGroup, Input, InputGroup, InputGroupAddon, Button, Nav, NavItem, NavLink, TabPane, TabContent } from 'reactstrap';
import { useSelector } from 'react-redux';
import ScrollArea from 'react-scrollbar';
import { Picker } from 'emoji-mart';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/pt';

import getFinalRole from '../../data/getFinalRole';

// import { default as InfiniteScrollComponent } from 'react-infinite-scroll-component';

import { Following, Follower } from '../../constant';

import errorImg from '../../assets/images/search-not-found.png';
import man from '../../assets/images/dashboard/profile.jpg';

import { useGetAll } from '../../hooks/';

// import { Check } from 'react-feather';

const Chat = () => {
    const history = useHistory();

    const users = useGetAll('/api/v1/accounts?role=AdminRole&mf_role=pc,pcj,pcs,pcl', 'users_pc');
    const chat_rooms = useGetAll('/api/v1/chat_room/', 'chat_rooms');

    const user = useSelector((content) => content.User.user);
    const socket = useSelector((content) => content.Socket.socket);

    const pharmacies = useSelector(content => content.Pharmacies.results);
    const loaded = useSelector(content => content.Pharmacies.loaded);

    const [searchKeyword, setSearchKeyword] = useState('');
    const [messageInput, setMessageInput] = useState('');
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [menuToggle, setMenuToggle] = useState(false);
    const [activeTab, setActiveTab] = useState('3');

    const [selectedUser, setSelectedUser] = useState(null);
    const [activeChat, setActiveChat] = useState(null);

    const [activeChatRoom, setActiveChatRoom] = useState(null);
    const [activeConversation, setActiveConversation] = useState([]);

    const [chat_rooms_init, set_chat_rooms_init] = useState(false);
    const [initSocketListener, setInitSocketListener] = useState(false);
    // const [chat_rooms_init, set_chat_rooms_init] = useState(false);

    // const [page, setPage] = useState(1);

    const subscribeChat = (chatRoomId, chatUserId) => {
        socket.emit("subscribe", chatRoomId, chatUserId);
    }

    const initiateChat = (chatUserId, userInit) => {
        let temp_user = chatUserId
        if (userInit && userInit.initiated) {
            temp_user = chatUserId.filter((leUser) => leUser._id !== user._id)[0];
            setSelectedUser(temp_user);
        } else {
            setSelectedUser(users.data.find((elUser) => elUser._id === chatUserId));
        }

        if (user && user.initiated) {
            fetchChat(user.initiated, temp_user);
        } else {
            axios.post(`/api/v1/chat_room/initiate`, { userIds: [temp_user._id ? temp_user._id : temp_user] })
                .then((result) => {
                    if (result.data.success) {
                        chat_rooms.refetch();
                        setActiveChat(result.data.chatRoom.chatRoomId);
                        subscribeChat(result.data.chatRoom.chatRoomId, temp_user._id ? temp_user._id : temp_user);
                        fetchChat(result.data.chatRoom.chatRoomId, temp_user._id ? temp_user._id : temp_user);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    const fetchChat = (chatRoomId, chatUserId) => {
        axios.get(`/api/v1/chat_room/${chatRoomId}`)
            .then((result) => {

                const container = document.querySelector(".chat-history");
                setTimeout(() => {
                    container.scrollBy({ top: container.clientHeight + 300, behavior: 'smooth' });
                }, 250);

                if (result.data) {

                    setActiveChatRoom(result.data);
                    setActiveConversation(result.data.conversation);

                    window.history.pushState(null, null, `${process.env.PUBLIC_URL}/chat?chat=${chatUserId}`);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    useEffect(() => {
        if (chat_rooms && chat_rooms.data && users && users.data && !chat_rooms_init) {
            // chat_rooms.data.rooms.forEach((room) => {
            //     users.data.forEach((user) => {
            //         if (room.chatInitiator === user._id) {
            //             user.initiated = room._id;
            //         }
            //     });
            // });

            // chat_rooms.data.rooms.forEach((room) => {
            //     subscribeChat(room._id, room.chatInitiator);
            // });

            set_chat_rooms_init(true);
        }

        // newMessageInit
        if (socket && !initSocketListener) {
            socket.on("new message", data => {
                if (history.location.pathname === `${process.env.PUBLIC_URL}/chat`) {
                    const container = document.querySelector(".chat-history");
                    setTimeout(() => {
                        container.scrollBy({ top: 200, behavior: 'smooth' });
                    }, 300);

                    setActiveConversation(prevChatRoom => [...prevChatRoom, data.message]);
                }
            });
            setInitSocketListener(true);
        }

        if (socket && users && users.data && history.location && history.location.search && history.location.search && !selectedUser && user && loaded) {
            const chat = history.location.search.split("?chat=");
            if (chat) {
                initiateChat(chat[1], null)
            }
        }

        // if (socket && users && users.data && history.location && history.location.state && history.location.state.selectedUser) {
        //     initiateChat(history.location.state.selectedUser, null);
        // }
    }, [socket, history.location, users.data, chat_rooms.data, user, loaded]); // eslint-disable-line

    const handleMessagePress = (e) => {
        if (e.key === "Enter" || e === "send") {
            if (messageInput.length > 0) {
                axios.post(`/api/v1/chat_room/${activeChat}`, { messageText: messageInput })
                    .then((result) => {
                        setMessageInput('');
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        }
    }

    const handleSearchKeyword = (keyword) => {
        setSearchKeyword(keyword);
    }

    const handleMessageChange = (message) => {
        setMessageInput(message);
    }

    const toggleEmojiPicker = () => {
        setShowEmojiPicker(!showEmojiPicker);
    }

    const addEmoji = (emoji) => {
        const text = `${messageInput}${emoji.native}`;
        setShowEmojiPicker(false);
        setMessageInput(text);
    }

    const chatMenuToggle = () => {
        setMenuToggle(!menuToggle);
    }

    // const cancelChat = () => {
    //     setSelectedUser(null);
    //     setActiveChat(null);
    //     setActiveChatRoom(null);
    //     setActiveConversation([]);
    // }

    return (
        (socket && chat_rooms_init && !users.loading && !chat_rooms.loading && user && loaded) ?
            <Container fluid={true}>
                <Row>
                    <Col sm="12" className="call-chat-sidebar mt-4">
                        <Card className="my-chatbox2">
                            <CardBody className="chat-body">
                                <div className="chat-box">
                                    <div className="chat-left-aside people-list">
                                        <h5>Conversas</h5>
                                        <ul className="list mb-4 mt-4">
                                            <ScrollArea
                                                style={{ maxHeight: 275 }}
                                                speed={0.5}
                                                horizontal={false}
                                                vertical={true}
                                            >
                                                {chat_rooms.data.rooms.sort((a, b) => b.userIds[0].isAvailableChat - a.userIds[0].isAvailableChat).map((room, i) => (
                                                    <li className={`clearfix chat-user ${selectedUser && selectedUser._id === room.userIds[0]._id ? 'chat-user-active' : ''}`} key={i} onClick={() => initiateChat(room.userIds, { initiated: room._id })}>
                                                        <img src={room.userIds[0].profilePhoto && room.userIds[0].profilePhoto !== "" ? room.userIds[0].profilePhoto : man} className="rounded-circle chat-user-image" style={{ marginTop: 3.5 }} alt="profile-pic" />
                                                        <div className={`status-circle ${room.userIds[0].isAvailableChat ? 'online' : 'offline'}`}></div>
                                                        <div className="about">
                                                            <div className="name">
                                                                {room.userIds.filter((leUser) => leUser._id !== user._id).map((user, i) => (
                                                                    `${user.name.first} ${user.name.last}`
                                                                ))}
                                                            </div>
                                                            <div className="status">
                                                                {room.userIds[0].anf_code && user.role === "AdminRole" && pharmacies.find((ph) => ph.ANF === room.userIds[0].anf_code) ? pharmacies.find((ph) => ph.ANF === room.userIds[0].anf_code).name : room.userIds[0].role ? user.role === "AdminRole" && getFinalRole(room.userIds[0])._mainRole : null}
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ScrollArea>
                                        </ul>
                                        <div className="people-list">
                                            <h5>Contactos</h5>
                                            <div className="search">
                                                <Form className="theme-form">
                                                    <FormGroup className="form-group">
                                                        <Input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Procurar"
                                                            defaultValue={searchKeyword}
                                                            value={searchKeyword}
                                                            onChange={(e) => handleSearchKeyword(e.target.value)}
                                                        />
                                                        <i className="fa fa-search"></i>
                                                    </FormGroup>
                                                </Form>
                                            </div>
                                            {user.role === "AdminRole" ? (
                                                !users.loading && users.data && users.data.length > 0 ?
                                                    <ul className="list">
                                                        <ScrollArea
                                                            className="user-scrollList"
                                                            speed={0.5}
                                                            horizontal={false}
                                                            vertical={true}
                                                        >
                                                            {users.data.filter(x => {
                                                                const fullName = `${x.name.first} ${x.name.last}`;
                                                                if (searchKeyword.length >= 4 && fullName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchKeyword.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) && x._id !== user._id) {
                                                                    return user;
                                                                } else if (searchKeyword.length >= 4 && fullName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchKeyword.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) && x._id !== user._id) {
                                                                    return user;
                                                                } else if (searchKeyword.length <= 4 && x._id !== user._id) {
                                                                    return user;
                                                                } else {
                                                                    return null;
                                                                }
                                                            }).sort((a, b) => b.isAvailableChat - a.isAvailableChat).map((leUser, i) => {
                                                                return (
                                                                    <li className={`clearfix chat-user ${selectedUser && selectedUser._id === leUser._id ? 'chat-user-active' : ''}`} key={i} onClick={() => initiateChat(leUser._id, leUser)} >
                                                                        <img src={leUser.profilePhoto && leUser.profilePhoto !== "" ? leUser.profilePhoto : man} className="rounded-circle chat-user-image" style={{ marginTop: 3.5 }} alt="profile-pic" />
                                                                        <div className={`status-circle ${leUser.isAvailableChat ? 'online' : 'offline'}`}></div>
                                                                        <div className="about">
                                                                            <div className="name">{leUser.name.first} {leUser.name.last}</div>
                                                                            <div className="status">
                                                                                {leUser.anf_code && user.role === "AdminRole" && pharmacies.find((pharma) => pharma.ANF === leUser.anf_code) ? pharmacies.find((pharma) => pharma.ANF === leUser.anf_code).name : leUser.role ? getFinalRole(leUser)._mainRole : null}
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ScrollArea>
                                                    </ul>
                                                    : users.loading ?
                                                        <div className="loader-box loader-abs">
                                                            <div className="loader-7" />
                                                        </div>
                                                        :
                                                        <Media className="img-fluid m-auto" src={errorImg} alt="no-results" />

                                            ) : (
                                                !users.loading && users.data && users.data.length > 0 ?
                                                    <ul className="list" >
                                                        <ScrollArea
                                                            className="user-scrollList"
                                                            speed={0.5}
                                                            horizontal={false}
                                                            vertical={true}
                                                        >
                                                            {users.data.filter(x => {
                                                                const fullName = `${x.name.first} ${x.name.last}`;
                                                                if (searchKeyword.length >= 4 && fullName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchKeyword.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) && x._id !== user._id) {
                                                                    return user;
                                                                } else if (searchKeyword.length >= 4 && fullName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchKeyword.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) && x._id !== user._id) {
                                                                    return user;
                                                                } else if (searchKeyword.length <= 4 && x._id !== user._id) {
                                                                    return user;
                                                                } else {
                                                                    return null;
                                                                }
                                                            }).sort((a, b) => b.isAvailableChat - a.isAvailableChat).map((leUser, i) => {
                                                                return (
                                                                    <li className={`clearfix chat-user ${selectedUser && selectedUser._id === leUser._id ? 'chat-user-active' : ''}`} key={i} onClick={(e) => initiateChat(leUser._id, user)} >
                                                                        <img src={leUser.profilePhoto && leUser.profilePhoto !== "" ? leUser.profilePhoto : man} className="rounded-circle chat-user-image" style={{ marginTop: 3.5 }} alt="profile-pic" />
                                                                        <div className={`status-circle ${leUser.isAvailableChat ? 'online' : 'offline'}`}></div>
                                                                        <div className="about">
                                                                            <div className="name">{leUser.name.first} {leUser.name.last}</div>
                                                                            <div className="status">
                                                                                {leUser.role ? getFinalRole(leUser)._finalRole : null}
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ScrollArea>
                                                    </ul>
                                                    : users.loading ?
                                                        <div className="loader-box loader-abs">
                                                            <div className="loader-7" />
                                                        </div>
                                                        :
                                                        <Media className="img-fluid m-auto" src={errorImg} alt="no-results" />

                                            )}
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col className="call-chat-body mt-4">
                        <Card className="my-chatbox2">
                            <CardBody className="p-0">
                                <Row className="chat-box">
                                    <Col className="pr-0 chat-right-aside">
                                        <div className="chat">
                                            {selectedUser && (
                                                <div className="chat-header clearfix">
                                                    <Media src={selectedUser.profilePhoto && user.profilePhoto !== "" ? selectedUser.profilePhoto : man} className="rounded-circle" alt="profile-pic" />
                                                    <div className="about">
                                                        <div className="name">
                                                            {selectedUser.name.first} {selectedUser.name.last}
                                                        </div>
                                                        <div className="status digits" >
                                                            {selectedUser.isAvailableChat ? "Online" : "Offline"}
                                                        </div>
                                                    </div>
                                                    <ul className="list-inline float-left float-sm-right chat-menu-icons">
                                                        {/* <li className="list-inline-item" onClick={() => cancelChat()}>Cancelar (testing)</li> */}
                                                        {/* <li className="list-inline-item"><a href={`/video?room=${user.roomId ? user.roomId : 'teste'}`}><i className="icon-video-camera"></i></a></li> */}
                                                        <li className="list-inline-item toogle-bar" onClick={() => chatMenuToggle()}><a href="#javascript"><i className="icon-menu"></i></a></li>
                                                    </ul>
                                                </div>
                                            )}

                                            <div className="chat-history chat-msg-box custom-scrollbar">
                                                <ul>
                                                    {activeChatRoom && activeConversation && activeConversation.length > 0 ? (
                                                        // <InfiniteScrollComponent
                                                        //     dataLength={activeChatRoom && activeConversation && activeConversation.length}
                                                        //     next={() => fetchChat(activeChat, page + 1)}
                                                        //     hasMore={true}
                                                        //     loader={'A carregar mais resultados'}
                                                        //     inverse
                                                        // >
                                                        activeConversation.map((item, index) => {
                                                            return index + 1 === activeConversation.length ? (
                                                                <li key={index} className="clearfix">
                                                                    <div className={`message my-message pt-3 ${item.postedByUser._id !== user._id ? '' : 'float-right'}`}>
                                                                        <div className={`message-data ${item.postedByUser._id !== user._id ? '' : 'text-right'}`}>
                                                                            <span className="message-data-time">{moment(item.createdAt).format('DD/MM/YY - HH:MM')}</span>
                                                                            <Media src={item.postedByUser.profilePhoto && item.postedByUser.profilePhoto !== "" ? item.postedByUser.profilePhoto : man} className={`rounded-circle ${item.postedByUser._id !== user._id ? 'float-left' : 'float-right'} chat-user-img img-30`} alt="404" />
                                                                        </div>
                                                                        {item.message.messageText}
                                                                        {/* {item.readByRecipients && item.readByRecipients[0] && (
                                                                            <Check color={"#7366FF"} style={{ position: 'absolute', bottom: 0, right: 5 }} />
                                                                        )} */}
                                                                    </div>
                                                                </li>
                                                            ) : (
                                                                <li key={index} className="clearfix">
                                                                    <div className={`message my-message pt-3 ${item.postedByUser._id !== user._id ? '' : 'float-right'}`}>
                                                                        <div className={`message-data ${item.postedByUser._id !== user._id ? '' : 'text-right'}`}>
                                                                            <span className="message-data-time">{moment(item.createdAt).format('DD/MM/YY - HH:MM')}</span>
                                                                            <Media src={item.postedByUser.profilePhoto && item.postedByUser.profilePhoto !== "" ? item.postedByUser.profilePhoto : man} className={`rounded-circle ${item.postedByUser._id !== user._id ? 'float-left' : 'float-right'} chat-user-img img-30`} alt="404" />
                                                                        </div>
                                                                        {item.message.messageText}
                                                                    </div>
                                                                </li>
                                                            );
                                                        })
                                                        // </InfiniteScrollComponent>
                                                    ) : (
                                                        <div style={{ backgroundColor: '#e5ddd5', height: "100%", width: "100%" }}>
                                                            {/* <Media className="img-fluid" src={start_conversion} alt="start conversion" /> */}
                                                        </div>
                                                    )}
                                                </ul>
                                            </div>
                                            {selectedUser && (
                                                <div className="chat-message clearfix">
                                                    <Row>
                                                        <div className="mb-2">
                                                            {showEmojiPicker ? (
                                                                <Picker set="apple" emojiSize={30} onSelect={addEmoji} />
                                                            ) : null}
                                                        </div>
                                                        <Col xl="12" className="d-flex">
                                                            <div className="smiley-box bg-primary">
                                                                <div className="picker" onClick={() => toggleEmojiPicker()}>
                                                                    <Media src={require('../../assets/images/smiley.png')} alt="picker" />
                                                                </div>
                                                            </div>
                                                            <InputGroup className="text-box">
                                                                <Input
                                                                    type="text"
                                                                    className="form-control input-txt-bx"
                                                                    placeholder="Escrever mensagem..."
                                                                    value={messageInput}
                                                                    onKeyPress={(e) => handleMessagePress(e)}
                                                                    onChange={(e) => handleMessageChange(e.target.value)}
                                                                />
                                                                <InputGroupAddon addonType="append">
                                                                    <Button color="primary" onClick={() => handleMessagePress('send')} >{"Enviar"}</Button>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )}
                                        </div>
                                    </Col>
                                    <Col className={`pl-0 chat-menu ${menuToggle ? 'show' : ''}`}>
                                        <Nav tabs className="nav  border-tab nav-primary">
                                            {/* <NavItem id="myTab" role="tablist">
                                                    <NavLink tag="a" href="#javascript" className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
                                                        {CALL}
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem id="myTab" role="tablist">
                                                    <NavLink tag="a" href="#javascript" className={activeTab === '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
                                                        {STATUS}
                                                    </NavLink>
                                                </NavItem> */}
                                            {selectedUser && (
                                                <NavItem id="myTab" role="tablist">
                                                    <NavLink tag="a" className={activeTab === '3' ? 'active' : ''} onClick={() => setActiveTab('3')}>
                                                        {"Perfil"}
                                                    </NavLink>
                                                </NavItem>
                                            )}
                                        </Nav>
                                        <TabContent activeTab={activeTab}>
                                            {/* <TabPane tabId="1">
                                                    <div className="people-list">
                                                        <ul className="list digits custom-scrollbar">
                                                            <li className="clearfix"><Media className="rounded-circle chat-user-image" style={{marginTop: 3.5}} src={four} alt="" />
                                                                <div className="about">
                                                                    <div className="name">{EricaHughes}</div>
                                                                    <div className="status"><i className="fa fa-share font-success"></i>  {"5 May, 4:40 PM"}</div>
                                                                </div>
                                                            </li>
                                                            <li className="clearfix"><Media className="rounded-circle user-image mt-0" src={one} alt="" />
                                                                <div className="about">
                                                                    <div className="name">{VincentPorter}
                                                                        <div className="status">
                                                                            <i className="fa fa-reply font-danger"></i>  {"5 May, 5:30 PM"}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            {allMembers && allMembers.chats && allMembers.chats.filter(x => x.id !== currentUser.id).map((member, i) =>
                                                                <li className="clearfix" key={i} onClick={(e) => initiateChat(member.id)}>
                                                                    <Media src={member && member.thumb ? member.thumb : man} className="rounded-circle chat-user-image" style={{marginTop: 3.5}} alt="" />
                                                                    <div className="about">
                                                                        <div className="name">{member.name}</div>
                                                                        <div className="status">
                                                                            {member.reply}
                                                                            {member.status}
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId="2">
                                                    <div className="people-list">
                                                        <div className="search">
                                                            <Form className="theme-form">
                                                                <FormGroup>
                                                                    <Input className="form-control" type="text" placeholder="Write Status..." /><i className="fa fa-pencil"></i>
                                                                </FormGroup>
                                                            </Form>
                                                        </div>
                                                    </div>
                                                    <div className="status">
                                                        <p className="font-dark">{Active}</p>
                                                        <hr />
                                                        <p>
                                                            {ChataApp_p1}
                                                            <i className="icofont icofont-emo-heart-eyes font-danger f-20"></i>
                                                            <i className="icofont icofont-emo-heart-eyes font-danger f-20 m-l-5"></i>
                                                        </p>
                                                        <hr />
                                                        <p>{ChataApp_p2}<i className="icofont icofont-emo-rolling-eyes font-success f-20"></i></p>
                                                    </div>
                                                </TabPane> */}
                                            <TabPane tabId="3">
                                                {selectedUser && (
                                                    <div className="user-profile">
                                                        <div className="image">
                                                            <div className="avatar text-center">
                                                                <Media body alt="profile-pic" src={selectedUser.profilePhoto && selectedUser.profilePhoto !== "" ? selectedUser.profilePhoto : man} />
                                                            </div>
                                                        </div>
                                                        <div className="user-content text-center">
                                                            <h5 className="text-uppercase">{selectedUser.name.first} {selectedUser.name.last}</h5>
                                                            <div className="social-media">
                                                                {/* <ul className="list-inline">
                                                                    <li className="list-inline-item"><a href="#javascript"><i className="fa fa-facebook"></i></a></li>
                                                                    <li className="list-inline-item"><a href="#javascript"><i className="fa fa-google-plus"></i></a></li>
                                                                    <li className="list-inline-item"><a href="#javascript"><i className="fa fa-twitter"></i></a></li>
                                                                    <li className="list-inline-item"><a href="#javascript"><i className="fa fa-instagram"></i></a></li>
                                                                    <li className="list-inline-item"><a href="#javascript"><i className="fa fa-rss"></i></a></li>
                                                                </ul> */}
                                                            </div>
                                                            <hr />
                                                            <div className="follow text-center">
                                                                <Row>
                                                                    <Col className="border-right"><span>{Following}</span>
                                                                        <div className="follow-num">{"0"}</div>
                                                                    </Col>
                                                                    <Col><span>{Follower}</span>
                                                                        <div className="follow-num">{"0"}</div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                            <hr />
                                                            <div className="text-center digits" style={{ paddingBottom: 35 }}>
                                                                <p className="mb-0">{selectedUser.email}</p>
                                                                <p className="mb-0">{selectedUser.phoneNumber}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </TabPane>
                                        </TabContent>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            :
            <div className="loader-box loader-abs">
                <div className="loader-7" />
            </div>
    );
}

export default Chat;