import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Form, Label, Input, Table, Button } from 'reactstrap'
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import SweetAlert from 'sweetalert2'
import Select from 'react-select';
import axios from 'axios';

const getMyTotal = (response, segment) => {
    if (response) {
        let myTotal = 0;

        response[0].products.forEach((product) => {
            let myProcessDiscount = (100 - parseInt(segment.products.find((prod) => prod.cnp === product.cnp)).discount) / 100;
            myTotal += (segment.products.find((prod) => prod.cnp === product.cnp)).pvf * product.quantity * myProcessDiscount;
        });

        return myTotal;
    } else {
        let myTotal = 0;

        segment.products.forEach((product) => {
            let myProcessDiscount = (100 - parseInt(product.discount)) / 100;
            myTotal += (product.pvf * product.quantity * myProcessDiscount)
        })

        return myTotal;
    }
}

const rejectionOptions = [
    {
        value: "excesso",
        label: "Excesso de Stock na farmácia",
    },
    {
        value: "rotacao",
        label: "Sem rotação na farmácia",
    },
    {
        value: "seg-high",
        label: "Segmento com quantidades muito altas",
    },
    {
        value: "another",
        label: "Outra aposta na farmácia",
    }
]

const Segment = ({ currentOrder, currentSegment, setCurrentSegment, toggleTable }) => {
    const pharmacies = useSelector(content => content.Pharmacies.results);
    const loaded = useSelector(content => content.Pharmacies.loaded);

    const { register, handleSubmit, setValue, control } = useForm();
    const [reject, setReject] = useState(false);

    const onSubmit = data => {
        let submitValues = {
            ...data,
            distribution: "",
            rejected: true,
        }
        SweetAlert.fire({
            title: 'Atenção!',
            text: 'Confirma que pretende rejeitar a Encomenda Push',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                axios.put(`/api/v2/inqueritos/${currentOrder._id}`, submitValues)
                    .then((response) => {
                        toggleTable();
                    })
                    .catch((error) => {
                        toast.error(<><span>{"Ocorreu um erro"}</span><br /><span>{"Tente mais tarde ou contacte um responsável"}</span></>, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    });
            }
        });
    };

    let button = document.getElementById("next-button");
    if (button) button.style.display = 'block';

    return (
        <Fragment>
            <Row className="mt-4">
                <Col sm="12">
                    <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
                        <Table className="mb-4 text-center">
                            <thead>
                                <tr>
                                    <th scope="col">{"Seg"}</th>
                                    <th scope="col">{"Valor Min"}</th>
                                    <th scope="col">{"Unid. Min"}</th>
                                    <th scope="col">{"Valor"}</th>
                                    <th scope="col">{"Selecionar"}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentOrder.segments.map((seg, index) => {
                                    return currentOrder.response.length === 1 ? (
                                        <tr key={index}>
                                            <th scope="row">{seg.name}</th>
                                            <td>{seg.minimumOrder.toFixed(2)} €</td>
                                            <td>{seg.minimumQuantity > 0 ? seg.minimumQuantity + "un." : "-"}</td>
                                            <td>{getMyTotal(currentOrder.response, seg).toFixed(2)} €</td>
                                            <td>
                                                <Controller
                                                    control={control}
                                                    name={"currentSegment"}
                                                    defaultValue=""
                                                    render={({ onChange, onBlur, value }) => {
                                                        return (
                                                            <div className="radio radio-primary">
                                                                <Input id={seg._id} type="radio" name={seg._id} value={seg._id} onChange={() => setCurrentSegment(seg)} defaultChecked={index === 0} />
                                                                <Label for={seg._id}><span className="digits"></span></Label>
                                                            </div>
                                                        )
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    ) : (
                                        <tr key={index}>
                                            <th scope="row">{seg.name}</th>
                                            <td>{seg.minimumOrder.toFixed(2)} €</td>
                                            <td>{seg.minimumQuantity > 0 ? seg.minimumQuantity + "un." : "-"}</td>
                                            <td>{getMyTotal(undefined, seg).toFixed(2)} €</td>
                                            <td>
                                                <Controller
                                                    control={control}
                                                    name={"inquerito_observacoes-md-extra"}
                                                    defaultValue=""
                                                    render={({ onChange, onBlur, value }) => {
                                                        return (
                                                            <div className="radio radio-primary">
                                                                <Input id={seg._id} type="radio" name={seg._id} value={currentSegment._id} onChange={() => setCurrentSegment(seg)} defaultChecked={index === 0} />
                                                                <Label for={seg._id}><span className="digits"></span></Label>
                                                            </div>
                                                        )
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        {loaded && currentOrder.forceANF && (
                            <div className="form-row">
                                <Col md="12 mb-3">
                                    <Label className="col-form-label pt-0">{"Farmácia"}</Label>
                                    <Select
                                        ref={register('pharmaChosenANF')}
                                        placeholder="Selecionar ..."
                                        className="basic-single"
                                        id={"pharmaChosenANF"}
                                        classNamePrefix="select"
                                        defaultValue={null}
                                        isClearable
                                        isSearchable
                                        name={"pharmaChosenANF"}
                                        options={pharmacies}
                                        getOptionLabel={(opt) => opt['name']}
                                        getOptionValue={(opt) => opt['ANF']}
                                        onChange={(values, input) => {
                                            // * SET BODY VALUES
                                            const segment = { ...currentSegment }
                                            segment.pharmaChosenANF = values.ANF;
                                            setCurrentSegment(segment);
                                            setValue("pharmaChosenANF", values.ANF)
                                        }}
                                    />
                                </Col>
                            </div>
                        )}
                        <h4>Rejeição</h4>
                        <div className="form-row">
                            <Col md="6 mb-3">
                                <Label className="col-form-label pt-0">{"Observações (selecione para rejeitar)"}</Label>
                                <Select
                                    ref={register('obs')}
                                    placeholder="Selecionar ..."
                                    className="basic-single"
                                    id={"obs"}
                                    classNamePrefix="select"
                                    defaultValue={null}
                                    isClearable
                                    isSearchable
                                    name={"obs"}
                                    options={rejectionOptions}
                                    onChange={(values, input) => {
                                        // * SET BODY VALUES
                                        if (values.value) setReject(true)
                                        setValue("obs", values.value)
                                    }}
                                />
                            </Col>
                            <Col md="12 mb-3">
                                <Label>{"Observações"}</Label>
                                <Controller
                                    control={control}
                                    name={"obs_extra"}
                                    defaultValue=""
                                    render={({ onChange, onBlur, value }) => {
                                        return (
                                            <Input
                                                type="textarea"
                                                className="form-control"
                                                rows="3"
                                                onChange={(ev) => setValue('obs_extra', ev.target.value)}
                                            />
                                        )
                                    }}
                                />
                            </Col>
                            {reject && (
                                <Col md="12 mb-3">
                                    <Button color="danger" type="submit">Rejeitar Segmento</Button>
                                </Col>
                            )}
                        </div>
                    </Form>
                </Col>
            </Row>
        </Fragment>
    );
};

export default Segment;