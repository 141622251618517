
export const schema = [
    {
        colSize: "6",
        label: "Posição",
        name: "mf_role",
        id: "AdminRole",
        type: "select",
        required: true,
        requiredMessage: "Este campo é obrigatório",
        multi: false,
        optionLabel: "label",
        optionValue: "value",
        options: [
            {
                value: "adv",
                label: "Administrativo",
            },
            {
                value: "coo",
                label: "Coordenação",
            },
            {
                value: "neg",
                label: "Negociação",
            },
            {
                value: "opr",
                label: "Operações",
            },
            {
                value: "mkt",
                label: "Marketing",
            },
            {
                value: "adm",
                label: "Administração",
            },
            {
                value: "pc",
                label: "Pharmacy Coacher",
            },
            {
                value: "pcs",
                label: "Pharmacy Coach Senior",
            },
            {
                value: "pcj",
                label: "Pharmacy Coach Junior",
            },
            {
                value: "pcl",
                label: "Pharmacy Coach Líder",
            },
        ],
    },
];
