import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Card, CardHeader, CardBody, Table, Button, Progress } from 'reactstrap';
import { ArrowUp, ArrowDown, CheckCircle, X } from 'react-feather';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import SweetAlert from 'sweetalert2'

import DataTableExtensions from 'react-data-table-component-extensions';
import "react-data-table-component-extensions/dist/index.css";

// import { Doughnut } from 'react-chartjs-2';
// import CountUp from 'react-countup';
import moment from 'moment';
import 'moment/locale/pt';

import ProfileTitle from '../../../../layout/profileTitle';
import NoResults from '../../../ui-kits/noResults';

import ProductsTable from './productsTable';
import AnswersTable from './answersTable';
import Wizard from '../../wizard/';

import { useGetOne, usePatch } from '../../../../hooks';
import { getState, getButton, doughnutOption, getValorProposto, columns, getTotalPercent, getPatchOrderType } from './utils';
import configDB from '../../../../data/customizer/config';
import axios from 'axios';

const primary = localStorage.getItem('default_color') || configDB.data.color.primary_color;
const secondary = localStorage.getItem('secondary_color') || configDB.data.color.secondary_color;

const AdminOrderProfile = (props) => {
    const history = useHistory();
    window.scrollTo(0, 0);
    const { data, isLoading, status } = useGetOne(`/api/v2/inqueritos/${props.match && props.match.params && props.match.params.id}`, props.match && props.match.params && props.match.params.id, 'orders');

    if (status === "error") history.push(`${process.env.PUBLIC_URL}/pages/errors/error404`);

    const pharmacies = useSelector(content => content.Pharmacies.results);
    const loaded = useSelector(content => content.Pharmacies.loaded);

    const [adjustedData, setAdjustedData] = useState(false);
    const [obsAnaliseState, setObsAnalise] = useState(null);
    const [totals, setTotals] = useState(null);

    // const [totalAnswers, setTotalAnswers] = useState(null);
    const [currentOrder, setOrder] = useState(null);

    const [totalFinalBulk, setTotalFinalBulk] = useState(0);
    const [totalBulkInit, setTotalBulkInit] = useState(0);

    const [submitValues, setSubmitValues] = useState({});
    const patchData = usePatch('/api/v2/inqueritos', 'orders', submitValues);

    //* Products Table
    const [isOpen, setOpen] = useState(false)
    const [currentSegmentName, setCurrentSegmentName] = useState("");
    const [currentProducts, setCurrentProducts] = useState([]);

    //* Answers Table
    const [isOpenAnswers, setOpenAnswers] = useState(false)
    const [currentName, setCurrentName] = useState("");
    const [currentAnswersProducts, setCurrentAnswersProducts] = useState([]);

    const generateReport = () => {
        /* Call XLSX */
        let wb = XLSX.utils.book_new();

        // create cover sheet
        let myIndex;
        let prodSize = 0;
        for (let i = 0; i < data.segments.length; i++) {
            if (data.segments[i].products.length > prodSize) {
                prodSize = data.segments[i].products.length;
                myIndex = i;
            }
        }

        let biggerSegment = data.segments[myIndex];
        let myBookData = [];

        myBookData.push([
            "ANF_PH",
            "CAMPANHA",
            "SEGMENTO",
            "INICIO",
            // "DATA_NL",
            "DATA_FECHO",
            "TIPO_PUSH",
            "MOTIVO_DISTRIBUICAO",
            "DATA_DISTRIBUICAO",
            "DATA_FIM_DISTRIBUICAO",
            "QTD_OBJ_FIXO",
            "QTD_OBJ_VARIAVEL",
            "COPAGAMENTO",
            "DATA_COPAGAMENTO",
            "DESCONTO_ADICIONAL",
            "DESCONTO_FLASH",
        ]);
        var distrib_alliance_book = [];
        var distrib_plural_book = [];
        var distrib_udifar_book = [];
        var distrib_empifarma_book = [];

        let segments = data.segments;

        data.response.forEach((myResponse, index) => {
            const chosenSegmentObj = segments.find((seg) => seg._id === myResponse.chosenSegment);
            myBookData.push([
                myResponse.pharm_id,
                myResponse.name,
                chosenSegmentObj.name,
                data.createdOn,
                data.end_date,
                data.inq_type,
                data.distributionReason,
                data.delivery.start,
                data.delivery.end,
                data.investmentFixed,
                data.investmentVariable,
                data.copayment,
                data.copaymentDate,
                data.aditionalDiscount,
                data.flashDiscount,
            ]);
        });

        let headerRow = [];

        headerRow.push('CNP', 'Quantidade', 'Tipo Documento', 'Observações', 'Ph', 'Valor', 'Mês', 'Visib', 'Produto', 'Data de Envio', 'Tipo Push');

        let myPMatrix = [];

        distrib_alliance_book.push(headerRow)
        distrib_plural_book.push(headerRow)
        distrib_udifar_book.push(headerRow)
        distrib_empifarma_book.push(headerRow)

        data.response.forEach((myResponseHere, index2) => {
            biggerSegment.products.forEach((myProduct, index1) => {
                let myPArray = [];
                myResponseHere.myProductsPreMutate.forEach((myProductHere, index) => {
                    if (myProduct.cnp === myProductHere.cnp) {
                        myPArray.push(myProductHere.cnp);
                        myPArray.push(myProductHere.quantity2);
                        myPArray.push('FAPG');
                        myPArray.push(data.name);
                        myPArray.push(myResponseHere.name);
                        myPArray.push(myProductHere.pvf * myProductHere.quantity2);
                        myPArray.push('-');
                        myPArray.push('p_gmf');
                        myPArray.push(myProductHere.name);
                        myPArray.push(moment(data.end_date).format('DD/MM/YYYY'));
                        myPArray.push('-');
                    }
                });

                if (myResponseHere.distribution === 'alliance') distrib_alliance_book.push(myPArray);
                if (myResponseHere.distribution === 'plural') distrib_plural_book.push(myPArray);
                if (myResponseHere.distribution === 'udifar') distrib_udifar_book.push(myPArray);
                if (myResponseHere.distribution === 'empifarma') distrib_empifarma_book.push(myPArray);
            });
        });

        let myBookDatapPhamra = [];
        myBookDatapPhamra.push([
            "ANF",
            "Farmácia",
            "Segmento",
            "Estado",
            "Distribuidor",
            "Total Inicial",
            "Total Alterado",
            "Variação %",
            "Observações",
            "Total Alterado PVF"
        ]);

        let mySumArray = ['-', '-', '-', '-', 0, 0, 0]
        let notrejected = 0;

        data.response.forEach((lepharma, index2) => {
            // let mySegment;
            // data.segments.forEach((mySegments, index) => {
            //     if (mySegments.pharm.includes(lepharma.pharm_id)) {
            //         mySegment = mySegments;
            //     }
            // });
            const mySegment = data.segments.find((seg) => seg._id === lepharma.chosenSegment);

            if (mySegment) {

                let myPArray = [];
                if (lepharma.rejected) {
                    myPArray.push(lepharma.pharm_id);
                    myPArray.push(lepharma.name);
                    myPArray.push(mySegment.name);
                    myPArray.push('Rejeitado');
                    myPArray.push('')
                    myPArray.push(0);
                    myPArray.push(0);
                    myPArray.push(''); // no percentage, so space it
                    myPArray.push(lepharma.obs);
                    myBookDatapPhamra.push(myPArray);
                    myPArray.push(0);
                } else {
                    notrejected += 1;
                    myPArray.push(lepharma.pharm_id);
                    myPArray.push(lepharma.name);
                    myPArray.push(mySegment.name);
                    myPArray.push('Aceite');

                    // Total inicial
                    var myInitTotal = 0;
                    // const foundSeg = data.segments.find((seg) => seg.name === lepharma.segment);
                    // console.log(foundSeg, lepharma.segment)
                    // if (foundSeg) {
                        mySegment.products.forEach((myProduct, index) => {
                            var myProcessDiscount = (100 - parseFloat(myProduct.discount)) / 100;
                            myInitTotal += (myProduct.pvf * myProduct.quantity * myProcessDiscount)
                        });
                    // }

                    let dist = '';
                    if (lepharma) {
                        dist = lepharma.distribution;
                        if (!dist) dist = 'null'
                    }

                    myPArray.push(dist);
                    myPArray.push(myInitTotal);
                    // Total Final
                    let myTotal = 0;
                    let myTotal_pvf = 0;
                    lepharma.myProductsPreMutate.forEach((myProduct, index) => {
                        var myProcessDiscount = (100 - parseFloat(myProduct.discount)) / 100;
                        myTotal += (myProduct.pvf * myProduct.quantity2 * myProcessDiscount);
                        myTotal_pvf += (myProduct.pvf * myProduct.quantity2)
                    });

                    myPArray.push(myTotal);
                    // Variacao
                    myPArray.push(((((myTotal - myInitTotal) / myInitTotal) + 1) * 100).toFixed(2) + " %");
                    myBookDatapPhamra.push(myPArray);

                    mySumArray[4] += myInitTotal;
                    mySumArray[5] += myTotal;

                    if (lepharma.obs === null || lepharma.obs === 'null') {
                        myPArray.push('');
                    } else {
                        myPArray.push(lepharma.obs);
                    }
                    myPArray.push(myTotal_pvf)
                }
            } else {
                console.log(lepharma)
            }

        });

        mySumArray[6] = `${((mySumArray[5] / mySumArray[4]) * 100).toFixed(2)} %`;
        mySumArray[7] = '';
        myBookDatapPhamra.push(mySumArray)

        let defaultName = 'Total';
        /* add worksheet to workbook */
        wb.SheetNames.push(defaultName);
        let ws = sheet_from_array_of_arrays(myBookData);
        wb.Sheets[defaultName] = ws;

        wb.SheetNames.push('Total Farmacia');
        ws = sheet_from_array_of_arrays(myBookDatapPhamra);
        wb.Sheets['Total Farmacia'] = ws;

        // -- add idstibuition alliance --
        var allianceName = 'Alliance';
        wb.SheetNames.push(allianceName);
        ws = sheet_from_array_of_arrays(distrib_alliance_book);
        wb.Sheets[allianceName] = ws;

        // -- add plural alliance --
        var pluralName = 'Plural';
        wb.SheetNames.push(pluralName);
        ws = sheet_from_array_of_arrays(distrib_plural_book);
        wb.Sheets[pluralName] = ws;

        // -- add plural alliance --
        var udifarName = 'Udifar';
        wb.SheetNames.push(udifarName);
        ws = sheet_from_array_of_arrays(distrib_udifar_book);
        wb.Sheets[udifarName] = ws;

        // -- add empipharma --
        var empipharmaName = 'Empifarma';
        wb.SheetNames.push(empipharmaName);
        ws = sheet_from_array_of_arrays(distrib_empifarma_book);
        wb.Sheets[empipharmaName] = ws;

        // -------- For each pharmacy --------------------
        data.response.forEach((myResponseHere, index) => {
            if (!myResponseHere.name) {
                console.log('Failed to generate xls file for pharma : ', myResponseHere)
            }
            if (!myResponseHere.rejected && myResponseHere.name !== undefined) {
                var myBookData = [];
                var myResponseData = myResponseHere;

                myBookData.push([
                    "CNP",
                    "Nome",
                    "PVF (€)",
                    "Desconto (%)",
                    "Quantidade",
                    "Nota Crédito",
                    "Rappel",
                    "Total"
                ]);

                myResponseData.myProductsPreMutate.forEach((myProduct) => {
                    myBookData.push([
                        myProduct.cnp,
                        myProduct.name,
                        myProduct.pvf,
                        myProduct.discount / 100,
                        myProduct.quantity2,
                        myResponseHere.creditnote,
                        myResponseHere.rappel,
                        (myProduct.pvf * myProduct.quantity2 * (100 - myProduct.discount) / 100)
                    ])
                });

                // Produce a total
                var totalSum = 0;
                var totalUnits = 0;
                myResponseData.myProductsPreMutate.forEach((myProduct) => {
                    totalSum += (myProduct.pvf * myProduct.quantity2 * (100 - myProduct.discount) / 100);
                    totalUnits += myProduct.quantity2;
                });

                myBookData.push([
                    'Total',
                    '',
                    '',
                    '',
                    totalUnits,
                    '',
                    '',
                    totalSum
                ]);

                let defaultName = myResponseHere.name;
                /* add worksheet to workbook */
                wb.SheetNames.push(defaultName);
                ws = sheet_from_array_of_arrays(myBookData);
                wb.Sheets[defaultName] = ws;
            }
        });

        var wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });
        function s2ab(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        }

        saveAs(new Blob([s2ab(wbout)], { type: "" }), 'inquerito_' + data.name + ".xlsx");
    }

    useEffect(() => {
        if (!isLoading && loaded && !adjustedData) {
            let prodSize = 0;
            let lowerSegmentId = null;
            let lowerSegmentValue = 0;

            data.segments.forEach((seg, index) => {
                if (seg.products.length > prodSize) {
                    prodSize = seg.products.length;
                }
            });

            data.segments.forEach((seg, index) => {
                let nowTotal = 0;
                seg.products.forEach(prod => {
                    nowTotal += prod.pvf * prod.quantity * (100 - parseFloat(prod.discount)) / 100
                });
                if (index === 0) lowerSegmentValue = nowTotal;
                if (nowTotal <= lowerSegmentValue) {
                    lowerSegmentValue = nowTotal;
                    lowerSegmentId = seg._id
                }
            });

            let totalAccept = 0;
            let obsAnalise = {
                excesso: 0,
                rotacao: 0,
                "seg-high": 0,
                another: 0
            }

            data.response.forEach((lePharmInRespose, index) => {
                if (lePharmInRespose.rejected) {
                    obsAnalise[lePharmInRespose.obs] += 1;
                } else {
                    totalAccept++;
                }

                data.segments.forEach((seg, index) => {
                    let foundFlag = false;
                    seg.pharm.forEach((lePharm) => { // For each pharma in the segment we check if it is in the response
                        if (lePharm === lePharmInRespose.pharm_id) {
                            foundFlag = true;
                            if (lePharmInRespose.rejected) {
                                lePharmInRespose.segment = data.segments[0].name
                                lePharmInRespose.creditnote = "";
                                lePharmInRespose.rappel = "";
                                lePharmInRespose.chosenSegment = lowerSegmentId;
                            } else {
                                const foundSegment = data.segments.find((segInData) => segInData._id === lePharmInRespose.chosenSegment);
                                if (!foundSegment) {
                                    lePharmInRespose.segment = data.segments.find((segInData) => segInData._id === lowerSegmentId) ? data.segments.find((segInData) => seg._id === lowerSegmentId).name : "Não Encontrado";
                                    lePharmInRespose.creditnote = "";
                                    lePharmInRespose.rappel = "";
                                    lePharmInRespose.chosenSegment = lowerSegmentId;
                                } else {
                                    const foundSegment2 = data.segments.find((seg) => seg._id === lePharmInRespose.chosenSegment);

                                    lePharmInRespose.segment = foundSegment2 ? foundSegment2.name : "Não Encontrado";
                                    lePharmInRespose.creditnote = foundSegment2 ? foundSegment2.creditnote : "Não Encontrado";
                                    lePharmInRespose.rappel = foundSegment2 ? foundSegment2.rappel : "Não Encontrado";
                                }
                            }
                            // Find the name of this pharmacy in the temporary stored pharmacy list
                            pharmacies.forEach((pharma) => {
                                if (pharma.ANF === lePharmInRespose.pharm_id) {
                                    lePharmInRespose.name = pharma.name;
                                }
                            });
                        }
                    });
                    if (!foundFlag) console.log('Not Found in Segment', lePharmInRespose)
                });


                let myProductsPreMutate = [];
                
                const mySegmentProducts =
                        data.segments.find((seg) => seg._id === lePharmInRespose.chosenSegment) ? data.segments.find((seg) => seg._id === lePharmInRespose.chosenSegment).products : null;
                
                lePharmInRespose.products.forEach(myResponseProducts => {
                    let myProd = null;
                    if (mySegmentProducts) {
                        myProd = { ...mySegmentProducts.find((product) => product.cnp === myResponseProducts.cnp)};
                    }
                    if (myProd) {
                        myProd['quantity2'] = myResponseProducts.quantity;
                        myProductsPreMutate.push(myProd);
                    } else {
                        console.log('Aviso, Produtos inválidos na resposta da farmacia : ', lePharmInRespose.name)
                        console.log(myResponseProducts)
                    }
                });
                lePharmInRespose.myProductsPreMutate = myProductsPreMutate;
            });

            setObsAnalise(obsAnalise);
            // setTotalAnswers(obsAnalise['excesso'] + obsAnalise['rotacao'] + obsAnalise['seg-high'] + obsAnalise['another']);

            let myTotals = {
                inqTotal: 0,
                percentageTotal: 0,
                percentageAccept: (totalAccept / data.response.length) * 100
            }

            data.segments.forEach(mySegment => {
                myTotals.inqTotal += mySegment.pharm.length
            });

            myTotals.percentageTotal = (data.response.length / myTotals.inqTotal) * 100;

            setTotals(myTotals);

            let myTotal = 0;
            let myTotalInit = 0;

            data.response.forEach(response => {
                const foundSegment = data.segments.find((seg) => seg.name === response.segment);
                if (foundSegment) {
                    response.myProductsPreMutate.forEach((prod) => {
                        let myProcessDiscount = (100 - parseFloat(prod.discount)) / 100;
                        myTotal += (prod.pvf * prod.quantity2 * myProcessDiscount)
                    });

                    foundSegment.products.forEach((prod) => {
                        let myProcessDiscount = (100 - parseFloat(prod.discount)) / 100;
                        myTotalInit += (prod.pvf * prod.quantity * myProcessDiscount)
                    });
                }
            });

            setTotalBulkInit(myTotalInit)
            setTotalFinalBulk(myTotal)

            setAdjustedData(true);
        }
    }, [isLoading, loaded, adjustedData]) // eslint-disable-line

    const patchOrder = async (_id, state) => {
        const type = getPatchOrderType(state)
        const values = { _id: _id, state: type.newState };

        await setSubmitValues(values);
        await patchData.mutate();

        if (patchData.status !== "loading" && patchData.status === "idle") {
            toast.success(<><span>{type.text1}</span><br /><span>{type.text2}</span></>, {
                position: toast.POSITION.TOP_RIGHT
            });;
        } else {
            toast.error("Ocorreu um erro!", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const seeProducts = (products, name) => {
        setCurrentProducts(products)
        setCurrentSegmentName(name)
        setOpen(true);
    };

    const seeAnswers = (products, name, segmentName) => {
        console.log(products)
        const foundSegmentProducts = data.segments.find((seg) => seg.name === segmentName).products;

        setCurrentProducts(foundSegmentProducts);
        setCurrentAnswersProducts(products);
        setCurrentName(name);
        setOpenAnswers(true);
    };

    const deleteAnswer = (pharmId) => {
        SweetAlert.fire({
            title: 'Tem a certeza ?',
            text: 'A resposta desta farmácia vai ser eliminada. Deseja prosseguir ?',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then(async (result) => {
                if (result.value) {
                    axios.post(`/api/v2/inqueritos/${props.match.params.id}/reset_reject/${pharmId}`)
                        .then((result) => {
                            
                            const newData = data.response.filter((pharma) => pharma._id !== pharmId);
                            data.response = newData;

                            toast.success("Resposta apagada!", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        })
                        .catch((error) => {
                            toast.error("Ocorreu um erro!", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        })
                }
            })   
    }

    const toggleProfile = () => {
        setOrder(null);
    }

    const toggleOrder = () => {
        data.forceANF = true;
        setOrder(data);
    }

    return !currentOrder ? (
        <Fragment>
            <Container fluid={true} className="pt-2">
                {!isLoading && loaded && adjustedData ? (
                    <>
                        <ProfileTitle title={data.name} route={`${process.env.PUBLIC_URL}/push/encomendas`} />
                        <div className="todo">
                            <div className="todo-list-wrapper">
                                <div className="todo-list-container">
                                    <div className="mark-all-tasks">
                                        <div className="mark-all-tasks-container">
                                            <Button color="primary" onClick={generateReport}>{"Gerar Relatório"}</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Row>
                            {data && data.banner && (
                                <Col xl="12 xl-100" className="chart_data_left box-col-12 mb-4">
                                    <iframe title="chart" style={{ margin: 'auto', position: 'relative', display: 'block' }} src={data.banner} width="960" height="450" scrolling="no" frameBorder="0" allowtransparency={"true"} allow="autoplay" allowFullScreen={true} ></iframe>
                                </Col>
                            )}
                            {/* <Col sm="6" xl="4" lg="4">
                                <Card className="o-hidden">
                                    <CardBody className={`${totals.percentageTotal.toFixed(1) > 50 ? "bg-primary" : "bg-secondary"} b-r-4 card-body`}>
                                        <div className="media static-top-widget">
                                            <div className="align-self-center text-center">
                                                {totals.percentageTotal.toFixed(1) > 50 ? (
                                                    <ArrowUp />
                                                ) : (
                                                    <ArrowDown />
                                                )}
                                            </div>
                                            <div className="media-body"><span className="m-0">{"Taxa de Respostas"}</span>
                                                <h4 className="mb-0 counter">
                                                    <CountUp end={parseInt(totals.percentageTotal.toFixed(1))} suffix=" %" />
                                                </h4>
                                                {totals.percentageTotal.toFixed(1) > 50 ? (
                                                    <ArrowUp className="icon-bg" />
                                                ) : (
                                                    <ArrowDown className="icon-bg" />
                                                )}
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm="6" xl="4" lg="4">
                                <Card className="o-hidden">
                                    <CardBody className={`${totals.percentageAccept.toFixed(1) > 50 ? "bg-primary" : "bg-secondary"} b-r-4 card-body`}>
                                        <div className="media static-top-widget">
                                            <div className="align-self-center text-center">
                                                {totals.percentageAccept.toFixed(1) > 50 ? (
                                                    <ArrowUp />
                                                ) : (
                                                    <ArrowDown />
                                                )}
                                            </div>
                                            <div className="media-body"><span className="m-0">{"Aceitação"}</span>
                                                <h4 className="mb-0 counter">
                                                    <CountUp end={parseInt(totals.percentageAccept.toFixed(1))} suffix=" %" />
                                                </h4>
                                                {totals.percentageAccept.toFixed(1) > 50 ? (
                                                    <ArrowUp className="icon-bg" />
                                                ) : (
                                                    <ArrowDown className="icon-bg" />
                                                )}
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm="6" xl="4" lg="4">
                                <Card className="o-hidden">
                                    <CardBody className={`${56 > 50 ? "bg-primary" : "bg-secondary"} b-r-4 card-body`}>
                                        <div className="media static-top-widget">
                                            <div className="align-self-center text-center">
                                                {56 > 50 ? (
                                                    <ArrowUp />
                                                ) : (
                                                    <ArrowDown />
                                                )}
                                            </div>
                                            <div className="media-body"><span className="m-0">{"Respostas Tácitas"}</span>
                                                <h4 className="mb-0 counter">
                                                    <CountUp end={parseInt(56)} suffix=" %" />
                                                </h4>
                                                <ArrowDown className="icon-bg" />
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col> */}
                            <Col xl="4 xl-50" className="box-col-12">
                                <Card style={{ minHeight: 500 }}>
                                    <CardHeader>
                                        <h5>{getState(data.state) && "Estado"}</h5>
                                        <p>{getState(data.state)}</p>
                                        <h5>{moment(data.end_date).format('LL') && "Data de Fecho"}</h5>
                                        <p>{moment(data.end_date).format('LL')}</p>
                                        {getButton(data.state) && (
                                            <div className="d-flex mt-4">
                                                <button className="btn btn-sm btn-uppercase btn-primary flex-fill tx-spacing-1" onClick={() => patchOrder(data._id, data.state)}>{getButton(data.state)}</button>
                                            </div>
                                        )}
                                    </CardHeader>
                                    {/* <CardBody className="new-update pt-0">
                                        <h5 className="mb-4 mt-4">Respostas</h5>
                                        <div className="activity-timeline">
                                            {data.response.slice(0, 5).map((resposta, index) => (
                                                <div className="media mt-2" key={index}>
                                                    <div className={`${!resposta.rejected ? "activity-dot-success" : "activity-dot-secondary"}`}></div>
                                                    <div className="media-body">
                                                        <span>{resposta.name}</span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </CardBody> */}
                                </Card>
                            </Col>
                            {/* <Col xl="4 xl-50" className="box-col-12">
                                <Card style={{ minHeight: 500 }}>
                                    <CardHeader className="reduce-padding">
                                        <h6>{"Rejeição"}</h6>
                                    </CardHeader>
                                    <CardBody className="new-update pt-4">
                                        <Doughnut
                                            data={{
                                                labels: ['Excesso de Stock', 'Sem Rotação', 'Quantidades Altas', 'Outra Aposta'],
                                                datasets: [{
                                                    data: [obsAnaliseState['excesso'], obsAnaliseState['rotacao'], obsAnaliseState['seg-high'], obsAnaliseState['another']],
                                                    backgroundColor: [primary, secondary, "#51bb25"]
                                                }]
                                            }}
                                            options={doughnutOption}
                                            width={400}
                                            height={300}
                                        />
                                    </CardBody>
                                </Card>
                            </Col> */}
                            {/* <Col xl="4 xl-50" className="box-col-12">
                                <Card style={{ minHeight: 500 }}>
                                    <CardHeader className="reduce-padding">
                                        <h6>{"Resultados"}</h6>
                                    </CardHeader>
                                    <CardBody className="new-update pt-4">
                                        <Row>
                                            {totalFinalBulk < totalBulkInit ? (
                                                <>
                                                    <Col xl="12" className="mb-4">
                                                        <div className="media align-items-center">
                                                            <div className="media-body right-chart-content">
                                                                <h4>{totalFinalBulk.toFixed(2)} €</h4>
                                                                <span>{"Total Final"}</span>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xl="12" className="mb-4">
                                                        <div className="media align-items-center">
                                                            <div className="media-body right-chart-content">
                                                                <h4>{(totalBulkInit - totalFinalBulk).toFixed(2)} €</h4>
                                                                <span>{"Total Perda"}</span>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </>
                                            ) : (
                                                <>
                                                    <Col xl="12" className="mb-4">
                                                        <div className="media align-items-center">
                                                            <div className="media-body right-chart-content">
                                                                <h4>{totalFinalBulk.toFixed(2)} €</h4>
                                                                <span>{"Total Final"}</span>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xl="12" className="mb-4">
                                                        <div className="media align-items-center">
                                                            <div className="media-body right-chart-content">
                                                                <h4>{(totalBulkInit - totalFinalBulk).toFixed(2)} €</h4>
                                                                <span>{"Total Extra"}</span>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </>
                                            )}
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col> */}
                            <Col xl="8">
                                <Card>
                                    <CardBody>
                                        <div className="best-seller-table responsive-tbl">
                                            <div className="item">
                                                <div className="table product-list">
                                                    <Table borderless>
                                                        <thead>
                                                            <tr>
                                                                <th className="f-22">{"Segmentos"}</th>
                                                                {data.state !== "temporary" && (
                                                                    <th>{"%"}</th>
                                                                )}
                                                                <th className="text-center"></th>
                                                                <th className="text-center">{"Unidades Mínimas"}</th>
                                                                <th className="text-center"></th>
                                                                <th className="text-center">{"Opções"}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data.segments.map((segment, index) => (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <div className="d-inline-block align-middle">
                                                                            <div className="d-inline-block">
                                                                                <span>{segment.name}</span>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    {data.state !== "temporary" && (
                                                                        <td className="text-center">
                                                                            <Progress value="85" color="primary" className="sm-progress-bar" />
                                                                        </td>
                                                                    )}
                                                                    <td className="text-center"></td>
                                                                    {segment.minimumQuantity > 0 ? (
                                                                        <td className="text-center">{segment.minimumQuantity} un.</td>
                                                                    ) : (
                                                                        <td className="text-center">Sem Mínimo</td>
                                                                    )}
                                                                    <td className="text-center"><span className="label"></span></td>
                                                                    <td className="text-center"><Button color="primary" size="xs" className="ml-2" onClick={() => seeProducts(segment.products, segment.name)}>Ver Produtos</Button></td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl="12" className="mt-4">
                                <Card>
                                    <CardHeader>
                                        <h5>{"Respostas"}</h5>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="todo">
                                            <div className="todo-list-wrapper">
                                                <div className="todo-list-container">
                                                    <div className="mark-all-tasks">
                                                        <div className="mark-all-tasks-container">
                                                            <Button color="primary" onClick={() => toggleOrder(data)}>{"Responder pela Farmácia"}</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-responsive product-table">
                                                <DataTableExtensions
                                                    columns={columns}
                                                    filterPlaceholder={"Procurar..."}
                                                    filterHidden={false}
                                                    export={false}
                                                    print={false}
                                                    data={data.response.reverse().map((response) => ({
                                                        icon: getTotalPercent(response, data.segments).totalPercent < 100 && !response.rejected ? <ArrowDown className="txt-secondary" /> : response.rejected ? <X className="txt-danger" /> : <CheckCircle className="txt-success" />,
                                                        name: `${response.name}`,
                                                        segment: `${response.segment}`,
                                                        distribution: `${response.distribution ? response.distribution : "-"}`,
                                                        segments: `${!response.rejected ? `${getTotalPercent(response, data.segments).totalPercent} %` : "-"}`,
                                                        myTotal: `${((getTotalPercent(response, data.segments).myTotal * 100) / 100).toFixed(2)} €`,
                                                        products_issues: `${response.products_issues.length === 0 && !response.rejected ? "Aceite" : response.products_issues.length !== 0 ? "Atenção" : response.rejected && "Rejeitado"}`,
                                                        action: <div>
                                                            <Button color="primary" size="xs" className="ml-2" onClick={() => seeAnswers(response.products, response.name, response.segment)}><i className="fa fa-eye"></i></Button>
                                                            <Button color="primary" size="xs" className="ml-2" onClick={() => deleteAnswer(response.pharm_id)}><i className="fa fa-trash"></i></Button>
                                                        </div>
                                                    }))}
                                                >
                                                    <DataTable
                                                        noHeader
                                                        defaultSortField="name"
                                                        defaultSortAsc={true}
                                                        columns={columns}
                                                        data={data.response.reverse().map((response) => ({
                                                            icon: getTotalPercent(response, data.segments).totalPercent < 100 && !response.rejected ? <ArrowDown className="txt-secondary" /> : response.rejected ? <X className="txt-danger" /> : <CheckCircle className="txt-success" />,
                                                            name: `${response.name}`,
                                                            segment: `${response.segment}`,
                                                            distribution: `${response.distribution ? response.distribution : "-"}`,
                                                            segments: `${!response.rejected ? `${getTotalPercent(response, data.segments).totalPercent} %` : "-"}`,
                                                            myTotal: `${((getTotalPercent(response, data.segments).myTotal * 100) / 100).toFixed(2)} €`,
                                                            products_issues: `${response.products_issues.length === 0 && !response.rejected ? "Aceite" : response.products_issues.length !== 0 ? "Atenção" : response.rejected && "Rejeitado"}`,
                                                            action: <div>
                                                                <Button color="primary" size="xs" className="ml-2" onClick={() => seeAnswers(response.products, response.name)}><i className="fa fa-eye"></i></Button>
                                                                <Button color="primary" size="xs" className="ml-2" onClick={() => deleteAnswer(response.pharm_id)}><i className="fa fa-trash"></i></Button> 
                                                            </div>
                                                        }))}
                                                        highlightOnHover={true}
                                                        pointerOnHover={true}
                                                        paginationPerPage={10}
                                                        pagination={true}
                                                        noDataComponent={<NoResults />}
                                                    />
                                                </DataTableExtensions>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <ProductsTable
                            isOpen={isOpen}
                            toggle={setOpen}
                            products={currentProducts}
                            segment={currentSegmentName}
                        />
                        <AnswersTable
                            isOpen={isOpenAnswers}
                            toggle={setOpenAnswers}
                            leProducts={currentProducts}
                            products={currentAnswersProducts}
                            segment={currentName}
                        />
                    </>
                ) : (
                    <Row className="mt-4" style={{ minHeight: 500 }}>
                        <Col sm="12">
                            <div className="loader-box loader-abs">
                                <div className="loader-7" />
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
        </Fragment>
    ) : (
        <Wizard
            currentOrder={currentOrder}
            toggleTable={toggleProfile}
        />
    );
}

export default AdminOrderProfile;

const datenum = (v, date1904) => {
    if (date1904) v += 1462;
    let epoch = Date.parse(v);
    return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
}
const sheet_from_array_of_arrays = (data, opts) => {
    let ws = {};
    let range = { s: { c: 10000000, r: 10000000 }, e: { c: 0, r: 0 } };
    for (let R = 0; R !== data.length; ++R) {
        for (let C = 0; C !== data[R].length; ++C) {
            if (range.s.r > R) range.s.r = R;
            if (range.s.c > C) range.s.c = C;
            if (range.e.r < R) range.e.r = R;
            if (range.e.c < C) range.e.c = C;
            let cell = { v: data[R][C] };
            if (cell.v == null) continue;
            let cell_ref = XLSX.utils.encode_cell({ c: C, r: R });

            if (typeof cell.v === 'number') cell.t = 'n';
            else if (typeof cell.v === 'boolean') cell.t = 'b';
            else if (cell.v instanceof Date) {
                cell.t = 'n'; cell.z = XLSX.SSF._table[14];
                cell.v = datenum(cell.v);
            }
            else cell.t = 's';

            ws[cell_ref] = cell;
        }
    }
    if (range.s.c < 10000000) ws['!ref'] = XLSX.utils.encode_range(range);
    return ws;
}