import React, { Fragment } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Row, Col, Form, Card, CardHeader, CardBody, Label, Input, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { ShoppingBag } from 'react-feather';
import Select from 'react-select';
import moment from 'moment';
import 'moment/locale/pt';
import SweetAlert from 'sweetalert2'
import axios from 'axios';

const Summary = ({ currentOrder, currentSegment, optionalChosenANF, toggleTable }) => {
    const { register, handleSubmit, control, setValue, getValues } = useForm();

    const selectedSegment = currentOrder.segments.find((segment) => segment._id === currentSegment);
    const onSubmit = data => {
        let submitValues = {
            // ...data,
            products: selectedSegment.products,
            segmentId: selectedSegment._id,
            segmentChosenId: selectedSegment._id,
            distribution: getValues('distribution'),
            obs_extra: getValues('obs_extra'),
        }

        if (currentOrder.forceANF) {
            submitValues.forceANF = currentOrder.forceANF;
            submitValues.pharmaChosenANF = optionalChosenANF;
        }

        if (currentOrder.forceANF) {
            SweetAlert.fire({
                title: 'Atenção!',
                text: `Confirma que pretende responder a esta encomenda ${currentOrder.inq_type === 'direct' ? 'Direct' : 'Push'}?`,
                type: 'warning',
                confirmButtonText: 'Sim',
                showCancelButton: true,
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.value) {
                    axios.put(`/api/v2/inqueritos/${currentOrder._id}`, submitValues)
                        .then((response) => {
                            toggleTable();
                            toast.success(<><span>{"Push Respondida"}</span><br /><span>{"O responsável pela comunicação da farmácia irá receber um email de confirmação."}</span></>, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        })
                        .catch((error) => {
                            toast.error(<><span>{"Ocorreu um erro"}</span><br /><span>{"Tente mais tarde ou contacte um responsável"}</span></>, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        });
                }
            });
        } else {
            SweetAlert.fire({
                title: 'Atenção!',
                text: `Confirma que pretende responder a esta encomenda ${currentOrder.inq_type === 'direct' ? 'Direct' : 'Push'}?`,
                type: 'warning',
                confirmButtonText: 'Sim',
                showCancelButton: true,
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.value) {
                    console.log('submitValues', submitValues)
                    axios.put(`/api/v2/inqueritos/${currentOrder._id}`, submitValues)
                        .then((response) => {
                            toggleTable();
                            toast.success(<><span>{"Push Respondida com Sucesso"}</span><br /><span>{"Irá receber um email com a confirmação."}</span></>, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        })
                        .catch((error) => {
                            toast.error(<><span>{"Ocorreu um erro"}</span><br /><span>{"Tente mais tarde ou contacte um responsável"}</span></>, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        });
                }
            });
        }
    };

    const options = [];

    if (options.length === 0) {
        currentOrder.preferred_distribution.forEach((leOption, index) => {
            if (leOption === 'lab') {
                options.push({ value: leOption, label: "Directo - Laboratório" })
            }
            if (leOption === 'alliance') {
                options.push({ value: leOption, label: "Alliance" })
            }
            if (leOption === 'plural') {
                options.push({ value: leOption, label: "Plural" })
            }
            if (leOption === 'udifar') {
                options.push({ value: leOption, label: "Udifar" })
            }
            if (leOption === 'empifarma') {
                options.push({ value: leOption, label: "Empifarma" })
            }
        });
    }

    let mytotalAmount = null;
    if (!mytotalAmount) {
        selectedSegment.products.forEach((product) => {
            let myProcessDiscount = (100 - parseFloat(product.discount)) / 100;

            if (myProcessDiscount) {
                if (product.quantity) {
                    mytotalAmount += (product.quantity * parseFloat(product.pvf) * myProcessDiscount);
                } 
                // else {
                //     mytotalAmount += (1 * parseFloat(product.pvf) * myProcessDiscount);
                // }
            } else {
                if (product.quantity) {
                    mytotalAmount += (product.quantity * parseFloat(product.pvf));
                }
                // else {
                //     mytotalAmount += (1 * parseFloat(product.pvf));
                // }
            }
        });
    }

    return (
        <Fragment>
            <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
                <Row className="mt-4">
                    <Col sm="8">
                        <Card>
                            <CardHeader>
                                <h5>{"Resumo da Encomenda"}</h5>
                            </CardHeader>
                            <CardBody>
                                <p>{currentOrder.name}</p>
                                <p>{selectedSegment.name}</p>
                                <p className="text-muted">Data prevista de entrega</p>
                                <h3>{moment(currentOrder.delivery.end).format('DD/MM/YYYY')}</h3>
                                <p>{"Voltando aos passos anteriores é possível escolher novas quantidades ou segmento diferente."}</p>
                            </CardBody>
                        </Card>
                    </Col>
                    { currentOrder.inq_type !== 'direct' && (
                    <Col sm="4">
                        <Row>
                            <Col sm="12">
                                <Card className="o-hidden">
                                    <CardBody className="bg-primary b-r-4 card-body">
                                        <div className="media static-top-widget">
                                            <div className="align-self-center text-center"><ShoppingBag /></div>
                                            <div className="media-body"><span className="m-0">{"Valor Total"}</span>
                                                <h4 className="mb-0 counter">{mytotalAmount && mytotalAmount.toFixed(2)} €</h4><ShoppingBag className="icon-bg" />
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm="12">
                                <Card className="o-hidden">
                                    <CardBody className="bg-primary b-r-4 card-body">
                                        <div className="media static-top-widget">
                                            <div className="align-self-center text-center"><ShoppingBag /></div>
                                            <div className="media-body"><span className="m-0">{"Rentabilidade"}</span>
                                                <h4 className="mb-0 counter">0 €</h4><ShoppingBag className="icon-bg" />
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    )}
                </Row>
                <Row className="mt-4 mb-4">
                    <Col sm="12">
                        <div className="form-row">
                            <Col md="6 mb-3">
                                <Label className="col-form-label pt-0">{"Distribuidor"}</Label>

                                <Controller
                                    control={control}
                                    defaultValue={options[0].value}
                                    name="distribution"
                                    render={({ onChange, value, name, ref }) => (
                                        <Select
                                            inputRef={ref}
                                            className="basic-single"
                                            options={options}
                                            value={options.find(c => c.value === value)}
                                            onChange={val => onChange(val.value)}
                                        />
                                    )}
                                />

                            </Col>
                            <Col md="12 mb-3">
                                <Label>{"Observações"}</Label>
                                <Controller
                                    control={control}
                                    name={"obs_extra"}
                                    defaultValue=""
                                    render={({ onChange, onBlur, value }) => {
                                        return (
                                            <Input
                                                type="textarea"
                                                className="form-control"
                                                rows="3"
                                                onChange={(ev) => setValue('obs_extra', ev.target.value)}
                                            />
                                        )
                                    }}
                                />
                            </Col>
                        </div>
                    </Col>
                </Row>
                <Button color="primary" className="pull-right" onClick={onSubmit}>{"Submeter"}</Button>
            </Form>
        </Fragment>
    );
};

export default Summary;