export const apiUrl = "/api/v1/revista";
export const rqModel = "revistas";
export const pathname = `${process.env.PUBLIC_URL}/centro/revista`;

export const schema = [
  {
    colSize: "5",
    label: "Título",
    name: "name",
    id: "name",
    type: "text",
    required: true,
    requiredMessage: "Este campo é obrigatório",
  },
  {
    colSize: "5",
    label: "Link",
    name: "linkUrl",
    id: "linkUrl",
    type: "text",
    required: true,
    requiredMessage: "Este campo é obrigatório",
  },
  {
    colSize: "2",
    label: "Data",
    name: "orderDate",
    id: "orderDate",
    type: "datepicker",
    required: true,
    requiredMessage: "Este campo é obrigatório",
  },
  {
    colSize: "12",
    label: "Imagem",
    name: "photo",
    id: "photo",
    type: "file",
    content: "image",
    multi: false,
    route: "/api/v1/revista",
    imageEditInstantEdit: true,
    imageResizeTargetWidth: 900,
    // cropAspectRatio: "1:4",
    // cropAspectRatioOptions: [
    //   {
    //     label: "Square",
    //     value: "1:1",
    //   },
    // ],
  },
];
