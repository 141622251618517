import React, { Fragment, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Media, Form, FormGroup, Input, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import SweetAlert from 'sweetalert2'
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/pt';

import Breadcrumb from '../../../layout/breadcrumb'

import PaginationDT from '../../pagination';

import { useDelete } from '../../../hooks/';

import CustomForm from "../../form";
import { getWithFilters } from "../../../api/";
import { apiUrl, rqModel, pathname, schema } from "./settings";

import errorImg from '../../../assets/images/search-not-found.png';
import { NavLink } from 'react-router-dom';

import { useSelector } from 'react-redux'

const Parcerias = () => {

    const user = useSelector(content => content.User.user);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(3);

    const [tempSearchKeyword, setTempSearchKeyword] = useState('');
    const [searchKeyword, setSearchKeyword] = useState(null);

    const getParcerias = (page, limit, searchKeyword) => getWithFilters({ apiUrl: apiUrl, pagination: { page: page, limit: limit }, filters: { search: searchKeyword } });

    const [currentNews, setNews] = useState(null);
    const [viewType, setViewType] = useState(null);
    const [newsId, setNewsId] = useState(null);

    const {
        isLoading,
        data,
    } = useQuery([rqModel, page, searchKeyword], () => getParcerias(page, limit, searchKeyword), { keepPreviousData: true, staleTime: 300000 });
    const deleteData = useDelete(apiUrl, rqModel, newsId, page);

    const handleSearchKeyword = () => {
        if (tempSearchKeyword.length > 3) {
            setPage(1)
            setSearchKeyword(tempSearchKeyword)
        } else if (tempSearchKeyword.length === 0) {
            setPage(1)
            setSearchKeyword('')
        }
    }

    const toggleTable = () => {
        setViewType(null);
        setNews(null);
    }

    const createView = () => {
        setViewType("form");
        setNews(null);
        window.history.pushState(null, null, `${pathname}?view=create`);
    }

    const editView = (user) => {
        setViewType("form");
        setNews(user);
        window.history.pushState(null, null, `${pathname}?view=edit`);
    }

    const createSuccess = (data) => {
        toast.success(`Notícia ${data.title} criada!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const editSuccess = (data) => {
        toast.success(`Notícia ${data.title} editada!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const deleteRequest = async (_id) => {
        await setNewsId(_id);
        SweetAlert.fire({
            title: 'Atenção!',
            text: 'Confirma que pretende apagar esta Parceria?',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then(async (result) => {
                if (result.value) {
                    await deleteData.mutate();
                    if (deleteData.isIdle) {
                        toast.success("Parceria apagada!", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                }
            })
    }

    return (
        <Fragment>
            <Breadcrumb parent="Geral" title="Parcerias Externas" />
            <Container fluid={true} className="product-wrapper">
                
                { viewType !== "form" &&
                    <Row className="justify-content-center">
                        <Col xl={(user.role === "AdminRole") ? 8: 9}
                            className="feature-products">
                
                            <Form onSubmit={(ev) => ev.preventDefault()}>
                                <FormGroup className="m-0">
                                    <Input
                                        className="form-control"
                                        type="text"
                                        placeholder="Procurar"
                                        value={tempSearchKeyword}
                                        onChange={(e) => {
                                            setTempSearchKeyword(e.target.value);
                                            handleSearchKeyword();
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') handleSearchKeyword();
                                        }}
                                    />
                                </FormGroup>
                            </Form>
                        </Col>
                        {(user.role === "AdminRole") ?
                        <Col xl="1" className="feature-products">
                            <Button color="primary" onClick={createView} style={{ height: 50 }}>{"Criar"}</Button>
                        </Col>
                        : '' }
                    </Row>
                }
                { viewType === "form" ? (
                    <Card>
                        <CardBody>
                            <CustomForm data={currentNews} toggleTable={toggleTable} schema={schema} rqModel={rqModel} route={apiUrl} createSuccess={createSuccess} editSuccess={editSuccess} pathname={pathname} />
                        </CardBody>
                    </Card>
                ) :
                <div className="product-wrapper-grid">
                    { !isLoading && data.results.length <= 0 ?
                        <Row className="justify-content-center">
                            <Col xl="9 xl-60">
                                <div className="search-not-found text-center">
                                    <div>
                                        <img className="img-fluid second-search" src={errorImg} alt="no-results" />
                                        <p>{"Não foram encontrados resultados"}</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        :
                        <Row className="gridRow">
                            { !isLoading ? data.results.map((data, i) =>
                                <div className={'col-xl-3 col-sm-6 xl-4 col-grid-box'} key={i}>
                                    <Row className="gridRow">
                                        <Card style={{width: '100%', margin: '20px 20px' }}>
                                            <div className="product-box">
                                                <div className="product-img" style={{ backgroundSize: 'cover', backgroundImage: `url("${data.image}")`, height: 320 }}>
                                                </div>
                                                <div className="product-details">
                                                    <h4 onClick={() => window.open(data.linkUrl, '_blank')} className="font-primary" style={{ cursor: 'pointer' }}>{data.name} <i className="fa fa-download"></i></h4>
                                                    { (user.role === "AdminRole") &&
                                                        <h4 onClick={() => deleteRequest(data._id)} className="font-primary" style={{ cursor: 'pointer' }}><i className="fa fa-trash"></i></h4>
                                                    }
                                                </div>
                                            </div>
                                        </Card>
                                    </Row>
                                    </div>  
                                ) : isLoading && (
                                    <div className="loader-box loader-abs">
                                        <div className="loader-7" />
                                    </div>
                                )
                            }
                        </Row>
                        }
                   </div>
                    }
                        { !isLoading && data.results.length > 0 && (
                            <PaginationDT
                                centered
                                totalPage={data.totalPages}
                                currentPage={data.page}
                                limit={data.limit}
                                onChangePage={setPage}
                            />
                        )}
                    
            </Container>
        </Fragment>
    );
};

export default Parcerias;
