import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane, Table, Button } from 'reactstrap'
import { RefreshCcw, Tool, List, CheckCircle, X } from 'react-feather';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/pt';

import Wizard from './wizard/';

import Breadcrumb from '../../layout/breadcrumb'

import errorImg from '../../assets/images/search-not-found.png';

const getMyTotal = (response, segment) => {
    if (response) {
        let myTotal = 0;

        response[0].products.forEach((product) => {
            let myProcessDiscount = (100 - parseInt(segment.products.find((prod) => prod.cnp === product.cnp)).discount) / 100;
            myTotal += (segment.products.find((prod) => prod.cnp === product.cnp)).pvf * product.quantity * myProcessDiscount;
        });

        return myTotal;
    } else {
        let myTotal = 0;

        segment.products.forEach((product) => {
            let myProcessDiscount = (100 - parseInt(product.discount)) / 100;
            myTotal += (product.pvf * product.quantity * myProcessDiscount)
        })

        return myTotal;
    }
}

const Orders = () => {
    const history = useHistory();

    const [activeTab, setActiveTab] = useState("closed");
    const [currentOrder, setOrder] = useState(null);

    const getOrders = (activeTab) => axios.get('/api/v2/inqueritos?type=desafio&state=' + activeTab).then((res) => res.data)
    const {
        data,
        isLoading,
        isFetching,
    } = useQuery(['orders', activeTab, 'desafio'], () => getOrders(activeTab), { keepPreviousData: true, staleTime: 300000 });

    const userRole = useSelector(content => content.User.user.role);
    const myANF = useSelector(content => content.User && content.User.myPharma && content.User.myPharma.ANF ? content.User.myPharma.ANF : null);
    const laboratories = useSelector(content => content.Laboratories.results);
    const loaded = useSelector(content => content.Laboratories.loaded);

    useEffect(() => {
        if (history.location.pathname.split("/")[3] && history.location.pathname.split("/")[3].length > 15 && data && data.results) {
            const urlOrder = data.results.find((order) => order._id === history.location.pathname.split("/")[3]);
            setOrder(urlOrder);
        }
    }, [history, data])

    const toggleTable = () => {
        setActiveTab("ongoing");
        setOrder(null);
    }

    return !currentOrder ? (
        <Fragment>
            <Breadcrumb parent="Push" title="Desafio Flash" />
            <Container fluid={true}>
                <Row>
                    <Col md="12" className="project-list">
                        <Card>
                            <Row>
                                <Col sm="8">
                                    <Nav tabs className="border-tab">
                                        <NavItem><NavLink style={{ cursor: 'pointer' }} className={activeTab === "closed" ? "active" : ''} onClick={() => setActiveTab("closed")}><RefreshCcw />{"A Decorrer"}</NavLink></NavItem>
                                        <NavItem><NavLink style={{ cursor: 'pointer' }} className={activeTab === "sent" ? "active" : ''} onClick={() => setActiveTab("sent")}><List />{"Histórico"}</NavLink></NavItem>
                                    </Nav>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <TabContent activeTab={"main"}>
                                    <TabPane tabId="main">
                                        {!isLoading && !isFetching && loaded && data.results.length > 0 ? (
                                            <Row>
                                                {data.results.map((item, i) => {
                                                    return (
                                                        <Col sm="6" className="mt-4" key={i}>
                                                            <div className="project-box">
                                                                {item.response.length >= 1 && (
                                                                    !item.response[0].rejected ? (
                                                                        item.response[0].obs === 'Auto Accepted' ? (
                                                                            <span className={`badge badge-success`}>{"Aceite Tácitamente"}</span>
                                                                        ) : (
                                                                            <span className={`badge badge-success`}>{"Aceite"}</span>
                                                                        )
                                                                    ) : (
                                                                        <span className={`badge badge-warning`}>{"Rejeitado"}</span>
                                                                    )
                                                                )}
                                                                <h6>{item.name}</h6>
                                                                <div className="media">
                                                                    <div className="media-body">
                                                                        <p className="text-primary">{item.fornecedor && laboratories.find((lab) => item.fornecedor === lab.fornecedor_id) ? laboratories.find((lab) => item.fornecedor === lab.fornecedor_id).name : "Sem Fornecedor Associado"}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="table-responsive mb-4">
                                                                    <Table size="xs">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col">{"Seg"}</th>
                                                                                <th scope="col"></th>
                                                                                <th scope="col">{"Obj Mix"}</th>
                                                                                <th scope="col">{"Desc. Flash"}</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {item.segments.map((seg, index) => {
                                                                                return item.response.length === 1 ? (
                                                                                    <tr key={index}>
                                                                                        <th scope="row">{seg.name}</th>
                                                                                        <td></td>
                                                                                        <td>{item.objMixByPharma.find((lePharmaMix) => lePharmaMix.pharma === myANF) ? item.objMixByPharma.find((lePharmaMix) => lePharmaMix.pharma === myANF).amount : 0} %</td>
                                                                                        <td>{seg.products.find((leProd) => leProd.flashDiscount > 0) ? <CheckCircle className="txt-success" /> : <X className="txt-danger" />}</td>
                                                                                    </tr>
                                                                                ) : (
                                                                                    <tr key={index}>
                                                                                        <th scope="row">{seg.name}</th>
                                                                                        <td></td>
                                                                                        <td>{item.objMixByPharma.find((lePharmaMix) => lePharmaMix.pharma === myANF) ? item.objMixByPharma.find((lePharmaMix) => lePharmaMix.pharma === myANF).amount : 0} %</td>
                                                                                        <td>{seg.products.find((leProd) => leProd.flashDiscount > 0) ? <CheckCircle className="txt-success" /> : <X className="txt-danger" />}</td>
                                                                                    </tr>
                                                                                )
                                                                            })}
                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                                {/* <Row className="details mb-4">
                                                                    <Col xs="6"><span>{"Termina a"} </span></Col>
                                                                    <Col xs="6" className={'text-primary'}>{moment(item.end_date).calendar()}</Col>
                                                                    {item.delivery && (
                                                                        <>
                                                                            <Col xs="6"> <span>{"Esperado de"}</span></Col>
                                                                            <Col xs="6" className={'text-primary'}>{moment(item.delivery.start).format('DD/MM/YYYY') + " a " + moment(item.delivery.end).format('DD/MM/YYYY')}</Col>
                                                                        </>
                                                                    )}
                                                                </Row> */}
                                                                <p>Promoção decorre de {moment(item.promo.start).format('DD/MM/YYYY')} a {moment(item.promo.end).format('DD/MM/YYYY')}</p>
                                                                <p>{item.description}</p>
                                                                <div className="project-status mt-4">
                                                                    <div className="media mb-0">
                                                                        {item.response.length === 0 && item.state === 'ongoing' ? (
                                                                            userRole && userRole !== 'FornecedorRole' ? (
                                                                                <>
                                                                                    <Button color="primary" onClick={() => setOrder(item)}>Responder</Button>
                                                                                    <Button className="ml-4" color="info" onClick={() => history.push(`${process.env.PUBLIC_URL}/push/encomenda/${item._id}`)}>Ver Detalhes</Button>
                                                                                </>
                                                                            ) : (
                                                                                <Button color="primary" onClick={() => history.push(`${process.env.PUBLIC_URL}/push/encomenda/${item._id}`)}>Ver Detalhes</Button>
                                                                            )
                                                                        ) : (
                                                                            <Button color="primary" onClick={() => history.push(`${process.env.PUBLIC_URL}/push/encomenda/${item._id}`)}>Ver Detalhes</Button>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )
                                                }
                                                )}
                                            </Row>
                                        ) : !isLoading && !isFetching && data.results.length <= 0 ? (
                                            <div className="search-not-found text-center">
                                                <div>
                                                    <img className="img-fluid second-search" src={errorImg} alt="no-results-img" />
                                                    <p>{"Não foram encontrados resultados"}</p>
                                                </div>
                                            </div>
                                        ) : (
                                            <Row style={{ minHeight: 450 }}>
                                                <div className="loader-box loader-abs">
                                                    <div className="loader-7" />
                                                </div>
                                            </Row>
                                        )}
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    ) : (
        <Wizard currentOrder={currentOrder} toggleTable={toggleTable} history={history} />
    );
}

export default Orders;