import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Card, TabContent, TabPane, Nav, NavItem, NavLink, } from 'reactstrap';
import { useSelector } from 'react-redux'
import Activity from './tabs/activity';
import TeamTab from './tabs/team';
import ToDoTab from '../admin/tabs/todo';
import OthersTab from './tabs/others';

import { useGetOne } from '../../../hooks';

const PharmacyProfile = (props) => {
    const history = useHistory();

    const laboratories = useSelector(content => content.Laboratories.results);
    const { data, status } = useGetOne(`/api/v1/pharma/${props.myANF && props.myANF}?dashboard=true`, props.myANF && props.myANF, 'pharmacies');

    if (status === "error") history.push(`${process.env.PUBLIC_URL}/pages/errors/error404`);

    const [activeTab, setActiveTab] = useState('1');

    return (
        <Fragment>
            <Container fluid={true}>
                {data ? (
                    <Fragment>
                        <Container fluid={true}>
                            <div className="user-profile social-app-profile">
                                <Row>
                                    <Col sm="12" className="mt-4">
                                        <Card className="hovercard text-center">
                                            <div className="cardheader socialheader" style={{ backgroundImage: `url(${data.profilePhoto && data.profilePhoto ? data.profilePhoto : '../../../assets/images/social-app/social-image.png'})` }} />
                                            {/* <div className="user-image">
                                                <div className="avatar">
                                                    <Media body alt="user" src={require("../../../assets/images/user/1.jpg")} />
                                                </div>
                                                <div className="icon-wrapper"><i className="icofont icofont-pencil-alt-5"></i></div>
                                                <ul className="share-icons">
                                                    <li><a className="social-icon bg-primary" href="#javascripts"><i className="fa fa-smile-o"></i></a></li>
                                                    <li><a className="social-icon bg-secondary" href="#javascripts"><i className="fa fa-wechat"></i></a></li>
                                                    <li><a className="social-icon bg-warning" href="#javascripts"><i className="fa fa-share-alt"></i></a></li>
                                                </ul>
                                            </div> */}
                                            <div className="info market-tabs p-0">
                                                <Nav tabs className="border-tab tabs-scoial">
                                                    <NavItem className="nav" id="myTab" role="tablist">
                                                        <NavLink style={{ cursor: 'pointer' }} tag="a" className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
                                                            {"Atividade"}
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem className="nav" id="myTab" role="tablist">
                                                        <NavLink style={{ cursor: 'pointer' }} tag="a" className={activeTab === '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
                                                            {"Delegados IM"}
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <div className="user-designation"></div>
                                                        <div className="title">{data.name}</div>
                                                        <div className="desc mt-2">{data.ANF}</div>
                                                    </NavItem>
                                                    {/* <NavItem className="nav" id="myTab" role="tablist">
                                                        <NavLink style={{ cursor: 'pointer' }} tag="a" className={activeTab === '3' ? 'active' : ''} onClick={() => setActiveTab('3')}>
                                                            {"MF Music"}
                                                        </NavLink>
                                                    </NavItem> */}
                                                    <NavItem className="nav" id="myTab" role="tablist">
                                                        <NavLink style={{ cursor: 'pointer' }} tag="a" className={activeTab === '3' ? 'active' : ''} onClick={() => setActiveTab('3')}>
                                                            {"To Do List"}
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem className="nav" id="myTab" role="tablist">
                                                        <NavLink style={{ cursor: 'pointer' }} tag="a" className={activeTab === '4' ? 'active' : ''} onClick={() => setActiveTab('4')}>
                                                            {"Outros"}
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                            </div>
                                        </Card>
                                    </Col>
                                </Row>
                                <TabContent activeTab={activeTab} className="tab-content">
                                    <TabPane tabId="1">
                                        <Activity data={data} />
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <TeamTab data={data} laboratories={laboratories} />
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <ToDoTab data={data} />
                                    </TabPane>
                                    {/* <TabPane tabId="3">
                                        <MusicTab data={data} />
                                    </TabPane> */}
                                    <TabPane tabId="4">
                                        <OthersTab data={data} />
                                    </TabPane>
                                </TabContent>
                            </div>
                        </Container>
                    </Fragment>
                ) : (
                    <Row style={{ minHeight: 500 }}>
                        <Col sm="12">
                            <div className="loader-box loader-abs">
                                <div className="loader-7" />
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
        </Fragment>
    );
}

export default PharmacyProfile;