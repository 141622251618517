import axios from "axios";
import { useMutation, useQueryClient } from "react-query";

export const postCall = (route, values) => {
    return axios.post(`${route}`, values, values.fileRequest ? {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    } : null).then((res) => res.data);
};

export default function usePost(route, rqModel, values) {
    const queryClient = useQueryClient();

    return useMutation([route, rqModel, values], () => postCall(route, values), {
        onSuccess: (newModel) => {
            if (newModel && newModel._id) {
                queryClient.setQueryData([`${route}/${newModel._id}`, newModel._id, rqModel], newModel)
            }

            const oldModels = queryClient.getQueryData(rqModel);

            if (oldModels && newModel) {
                queryClient.cancelQueries(rqModel);

                queryClient.setQueryData(rqModel, old => {
                    if (old.results) {
                        const newModels = old.results.map(d => {
                            if (d._id !== newModel._id) {
                                return newModel
                            }
                            return d
                        });
                        old.results = newModels;
                        return old;
                    } else {
                        return old.map(d => {
                            if (d._id !== newModel._id) {
                                return newModel
                            }
                            return d
                        });
                    }
                });

                return { oldModels }
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries(rqModel);
        },
    });
}
