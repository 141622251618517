import React, { Fragment, useState } from 'react';
import { Col, Card, CardHeader, CardBody, Button, Media, Collapse } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import three from "../../../../assets/images/user/3.png";
import errorImg from '../../../../assets/images/search-not-found.png';;

const LeftBar = ({ data }) => {
    const history = useHistory();

    // const [isProfile, setisProfile] = useState(true);

    const [isMutual, setisMutual] = useState(true);

    // const [isActivity, setisActivity] = useState(true);

    return data && (
        <Fragment>
            {/* <Col xl="12">
                <Card>
                    <CardHeader>
                        <h5 className="mb-0">
                            <Button color="link pl-0" onClick={() => setisProfile(!isProfile)} data-toggle="collapse" data-target="#collapseicon5" aria-expanded={isProfile} aria-controls="collapseicon5">
                                {MyProfile}
                            </Button>
                        </h5>
                    </CardHeader>
                    <Collapse isOpen={isProfile}>
                        <CardBody className="socialprofile filter-cards-view">
                            <Media><Media className="img-50 img-fluid m-r-20 rounded-circle" src={one} alt="" />
                                <Media body>
                                    <h6 className="font-primary f-w-600">{MyPage}</h6><span className="d-block"><span><i className="fa fa-comments-o"> </i><span className="px-2">{Messages}<span className="badge badge-pill badge-light">{"9"}</span></span></span></span><span className="d-block"><span><i className="fa fa-bell-o"></i><span className="px-2">{Notification}<span className="badge badge-pill badge-light">{"9"}</span></span></span></span>
                                </Media>
                            </Media>
                            <div className="social-btngroup d-flex">
                                <Button color="primary text-center" type="button">{Likes}</Button>
                                <Button color="light text-center" type="button">{View}</Button>
                            </div>
                            <div className="likes-profile text-center">
                                <h5><span><i className="fa fa-heart font-danger"></i> {"884"}</span></h5>
                            </div>
                            <div className="text-center">{"35 New Likes This Week"}</div>
                            <div className="customers text-center social-group">
                                <ul>
                                    <li className="d-inline-block">
                                        <Media className="img-40 rounded-circle" src={three} alt="ThirdImg" id="UncontrolledTooltipExample" />
                                        <UncontrolledTooltip placement="top" target="UncontrolledTooltipExample">
                                            {JohnyWaston}
                                        </UncontrolledTooltip>
                                    </li>
                                    <li className="d-inline-block">
                                        <Media className="img-40 rounded-circle" src={five} alt="FifthImg" id="UncontrolledTooltipExample1" />
                                        <UncontrolledTooltip placement="top" target="UncontrolledTooltipExample1">
                                            {AndewJon}
                                        </UncontrolledTooltip>
                                    </li>
                                    <li className="d-inline-block">
                                        <Media className="img-40 rounded-circle" src={one} alt="FirstImg" id="UncontrolledTooltipExample2" />
                                        <UncontrolledTooltip placement="top" target="UncontrolledTooltipExample2">
                                            {ComerenDiaz}
                                        </UncontrolledTooltip>
                                    </li>
                                    <li className="d-inline-block">
                                        <Media className="img-40 rounded-circle" src={two} alt="secondImg" id="UncontrolledTooltipExample3" />
                                        <UncontrolledTooltip placement="top" target="UncontrolledTooltipExample3">
                                            {BuckyBarnes}
                                        </UncontrolledTooltip>
                                    </li>
                                    <li className="d-inline-block">
                                        <Media className="img-40 rounded-circle" src={eight} alt="eightImg" id="UncontrolledTooltipExample4" />
                                        <UncontrolledTooltip placement="top" target="UncontrolledTooltipExample4">
                                            {JasonBorne}
                                        </UncontrolledTooltip>
                                    </li>
                                    <li className="d-inline-block">
                                        <Media className="img-40 rounded-circle" src={eleven} alt="elevenImg" id="UncontrolledTooltipExample5" />
                                        <UncontrolledTooltip placement="top" target="UncontrolledTooltipExample5">
                                            {ComerenDiaz}
                                        </UncontrolledTooltip>
                                    </li>
                                </ul>
                            </div>
                        </CardBody>
                    </Collapse>
                </Card>
            </Col> */}
            {/* <Col xl="12">
                <Card><Media className="img-fluid" alt="" src={timeline3} /></Card>
            </Col> */}
            <Col xl="12">
                <Card>
                    <CardHeader>
                        <h5 className="mb-0">
                            <Button color="link pl-0" onClick={() => setisMutual(!isMutual)} data-toggle="collapse" data-target="#collapseicon6" aria-expanded={isMutual} aria-controls="collapseicon6">
                                {"maisFarmácia"}
                            </Button>
                        </h5>
                    </CardHeader>
                    <Collapse isOpen={isMutual}>
                        <CardBody className="social-status filter-cards-view">
                            {/* <Form>
                                <FormGroup className="m-0">
                                    <Input className="form-control-social" type="search" placeholder="Procurar..." />
                                </FormGroup>
                            </Form> */}
                            {data.coaches.length > 0 ? (
                                data.coaches.map((user) => (
                                    <Media onClick={() => history.push(`${process.env.PUBLIC_URL}/chat?chat=${user._id}`)} className={"chat-user"}>
                                        <img className="img-50 rounded-circle m-r-15" src={user.profilePhoto && user.profilePhoto !== "" ? user.profilePhoto : three} alt={user.username} />
                                        {/* <div className="social-status social-online"></div> */}
                                        <Media body>
                                            <span className="f-w-600 d-block">{user.name.first} {user.name.last}</span><span className="d-block">{user.email} <br /> {user.phoneNumber}</span>
                                        </Media>
                                    </Media>
                                ))
                            ) : (
                                <Media className="img-fluid m-auto" src={errorImg} alt="no-results" />
                            )}
                        </CardBody>
                    </Collapse>
                </Card>
            </Col>
        </Fragment>
    );
};

export default LeftBar;