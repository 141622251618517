import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Col, Row, Card, CardHeader, CardBody } from 'reactstrap';
import ScrollArea from 'react-scrollbar';
import { Info } from 'react-feather';
import moment from 'moment';
import 'moment/locale/pt';

// import { useGetAll } from '../../../../hooks/';

import errorImg from '../../../../assets/images/search-not-found.png';

const OthersTab = ({ data }) => {
    // const campaignsQuery = useGetAll('/api/v1/news/bytype?type=campanhas&scope=dashboard', 'news_bytype');

    return (
        <Fragment>
            <Row>
                <Col sm="6">
                    <Card>
                        <CardHeader className="reduce-padding">
                            <h5>{"Montra"}</h5>
                        </CardHeader>
                        <CardBody>
                            <img src="/storage/images/showcase.jpg" class="img-fluid mx-auto d-block" alt="showcase" style={{ width: 250 }} />
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="6">
                    <Card>
                        <CardHeader className="reduce-padding">
                            <h5>{"Expositor"}</h5>
                        </CardHeader>
                        <CardBody>
                            <img src="/storage/images/exhibitor.jpg" class="img-fluid mx-auto d-block" alt="exhibitor" style={{ width: 250 }} />
                            {/* <div className="activity-log">
                                <div className="my-activity">
                                    {!campaignsQuery.isLoading ? (
                                        campaignsQuery.data.docs.map((campaign) => (
                                            <p><NavLink to={`/noticias/${campaign._id}`}><Info width={"12"} height={"12"} className="m-r-5" /> {campaign.title}</NavLink> - {moment(campaign.date).format('LL')}</p>
                                        ))
                                    ) : (
                                        <div className="loader-box loader-abs">
                                            <div className="loader-7" />
                                        </div>
                                    )}
                                </div>
                            </div> */}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col sm="6">
                    <Card>
                        <CardHeader className="reduce-padding">
                            <h5>{"Encomendas Push por Responder"}</h5>
                        </CardHeader>
                        <CardBody style={{ minHeight: 250, maxHeight: 250 }}>
                            <ScrollArea
                                style={{ minHeight: 250, maxHeight: 250 }}
                                horizontal={false}
                                vertical={true}
                            >
                                <div className="activity-log">
                                    <div className="my-activity">
                                        {data.inqueritos.length > 0 ? (
                                            data.inqueritos.map((push) => (
                                                <p><NavLink to={`${process.env.PUBLIC_URL}/push/encomenda/${push._id}`}><Info width={"12"} height={"12"} className="m-r-5" /> {push.name}</NavLink> - Termina a {moment(push.end_date).format("DD/MM/YYYY")}</p>
                                            ))
                                        ) : (
                                            <div className="search-not-found text-center">
                                                <div>
                                                    <img className="img-fluid second-search" src={errorImg} alt="no-results" />
                                                    <p>{"Não foram encontrados resultados"}</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </ScrollArea>
                        </CardBody>
                        <div class="card-footer text-center tx-13">
                            <NavLink to={`${process.env.PUBLIC_URL}/push/encomendas`}>Ver todos as Encomendas</NavLink>
                        </div>
                    </Card>
                </Col>
                <Col sm="6">
                    <Card>
                        <CardHeader className="reduce-padding">
                            <h5>{"Publicações Redes Socias Agendadas"}</h5>
                        </CardHeader>
                        <CardBody style={{ minHeight: 250, maxHeight: 250 }}>
                            <ScrollArea
                                style={{ minHeight: 250, maxHeight: 250 }}
                                horizontal={false}
                                vertical={true}
                            >
                                <div className="activity-log">
                                    <div className="my-activity">
                                        {data.facebook_posts.length > 0 ? (
                                            data.facebook_posts.map((post) => (
                                                <p><Info width={"12"} height={"12"} className="m-r-5" /> {post.post_data.name} - A publicar em {moment(post.post_data.scheduled_date).format("DD/MM/YYYY hh:mm")}</p>
                                            ))
                                        ) : (
                                            <div className="search-not-found text-center">
                                                <div>
                                                    <img className="img-fluid second-search" src={errorImg} alt="no-results" />
                                                    <p>{"Não foram encontrados resultados"}</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </ScrollArea>
                        </CardBody>
                        <div class="card-footer text-center tx-13">
                            <NavLink to={`/marketing/facebook`}>Ver todos os Agendamentos</NavLink>
                        </div>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};

export default OthersTab;