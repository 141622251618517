import { actionTypes } from "./";

const initial_state = {
    socket: null
}

export default (state = initial_state, action) => {
    switch (action.type) {
        case actionTypes.INIT_SOCKET:
            return { ...state, socket: action.payload };
        default:
            return state;
    }
}