import React, { Fragment, useState, useEffect } from 'react';
import { FileText, LogIn, User, Minimize, Bell, MessageSquare, ShoppingCart, X, Music } from 'react-feather';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import SweetAlert from 'sweetalert2';
import axios from "axios";

import { setTranslations, setDefaultLanguage, setLanguageCookie, translate } from 'react-switch-lang';

import Bookmark from "../../layout/bookmark";

import man from '../../assets/images/dashboard/profile.jpg';
import NoResults from '../../components/ui-kits/noResults';

import { LogOut } from '../../constant';

import en from '../../assets/i18n/en.json';
import es from '../../assets/i18n/es.json';
import pt from '../../assets/i18n/pt.json';
import fr from '../../assets/i18n/fr.json';
import du from '../../assets/i18n/du.json';
import cn from '../../assets/i18n/cn.json';
import ae from '../../assets/i18n/ae.json';

import { FormGroup, Button, Input, Label } from 'reactstrap';

import { setUser, setMyPharma, markNotificationAsRead } from "../../redux/actionTypes";

import RadioModal from '../../components/radio/modal';

import { useGetAll } from '../../hooks/';

setTranslations({ en, es, pt, fr, du, cn, ae });
setDefaultLanguage('pt');
setLanguageCookie();

const Rightbar = () => {
    const anf_code = useSelector(content => content.User.user.anf_code);
    const notificationsQuery = useGetAll(`/api/v1/notifications`, 'notifications');

    const location = useLocation();

    const dispatch = useDispatch();

    const user = useSelector(content => content.User.user);
    const _mainRole = useSelector(content => content.User._mainRole);

    const notifications = useSelector(content => content.User.notifications);

    const myPharma = useSelector(content => content.User.myPharma);

    const history = useHistory();

    const [moonlight, setMoonlight] = useState(false);
    const [notificationDropDown, setNotificationDropDown] = useState(false);

    const [cartDropdown, setCartDropDown] = useState(false);

    const [chatDropDown, setChatDropDown] = useState(false);

    const [storeCredit, setStoreCredit] = useState(false);

    const [modalOpen, setModalOpen] = useState(false);

    const toggleModal = () => {
        setModalOpen((state) => !state);
    }

    useEffect(() => {
        if (localStorage.getItem("layout_version") === "dark-only") {
            setMoonlight(true)
        }
    }, []);

    const removeFromCart = (product, qty) => {
        axios.post(`/api/v1/pharma/${myPharma.ANF}/add_to_cart`, { productId: typeof product.item === 'object' ? product.item._id : product.item, quantity: qty })
            .then((result) => {
                const newPharma = { ...myPharma };
                newPharma.cart = result.data.cart;
                dispatch(setMyPharma(newPharma));
            })
            .catch((error) => {

            });
    }

    const Logout = () => {
        axios
            .post(`/api/v1/accounts/logout`)
            .then(() => {
                let resetUser = {
                    loaded: false,
                    authenticated: false,
                    kpiInfo: [],
                    user: null,
                }
                dispatch(setUser(resetUser));
                history.push(`${process.env.PUBLIC_URL}/login`)
                localStorage.removeItem('bookmark')
            })
            .catch((error) => {
                console.error(error)
            });
    }

    const UserMenuRedirect = (redirect) => {
        history.push(redirect)
    }

    //full screen function
    function goFull() {
        if ((document.fullScreenElement && document.fullScreenElement !== null) ||
            (!document.mozFullScreen && !document.webkitIsFullScreen)) {
            if (document.documentElement.requestFullScreen) {
                document.documentElement.requestFullScreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullScreen) {
                document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    const MoonlightToggle = (light) => {
        if (light) {
            setMoonlight(!light)
            document.body.className = "light"
            localStorage.setItem('layout_version', 'light');
        } else {
            setMoonlight(!light)
            document.body.className = "dark-only"
            localStorage.setItem('layout_version', 'dark-only');
        }
    }

    const placeOrder = () => {
        let totalLabs = [];

        myPharma.cart.forEach(model => {
            const foundLab = totalLabs.find((lab) => lab.labId === model.item.lab._id);
            if (foundLab) {
                foundLab.total += model.item.price * model.quantity;
                foundLab.totalQuantity += model.quantity;
            } else {
                totalLabs.push({
                    labId: model.item.lab._id,
                    total: model.item.price * model.quantity,
                    totalQuantity: model.quantity,
                    minimumAmount: model.item.lab.minimumAmount,
                    minimumQuantity: (model.item.lab.minimumQuantity) ? model.item.lab.minimumQuantity : 0,
                    shippingAmount: model.item.lab.shippingAmount,
                    name: model.item.lab.name,
                    alwaysAppliesShippingFee: model.item.lab.alwaysAppliesShippingFee
                });
            }
        });

        let message = [];

        totalLabs.forEach(aLab => {
            if (!aLab.alwaysAppliesShippingFee && aLab.total < aLab.minimumAmount && (aLab.shippingAmount !== undefined && aLab.shippingAmount !== null && aLab.shippingAmount > 0)) {
                message.push(`Existe um mínimo de ${aLab.minimumAmount} € na encomenda de artigos ${aLab.name}. Uma taxa de envio de ${aLab.shippingAmount} € será adicionada`);
            }
            else if (!aLab.alwaysAppliesShippingFee && aLab.total < aLab.minimumAmount && (aLab.shippingAmount === undefined || aLab.shippingAmount === null || aLab.shippingAmount === 0)) {
                message.push(`Existe um mínimo de ${aLab.minimumAmount} € na encomenda de artigos ${aLab.name}. Este fornecedor não será processado.`);
            } else if( aLab.alwaysAppliesShippingFee ) {
                message.push(`${aLab.name}: Uma taxa de envio de ${aLab.shippingAmount} € será adicionada`);
            }

            if (!aLab.alwaysAppliesShippingFee && aLab.totalQuantity < aLab.minimumQuantity && (aLab.shippingAmount !== undefined && aLab.shippingAmount !== null && aLab.shippingAmount > 0)) {
                message.push(`Existe um mínimo de ${aLab.minimumQuantity} unidades na encomenda de artigos ${aLab.name}. Uma taxa de envio de ${aLab.shippingAmount} € será adicionada`);
            }
            else if (!aLab.alwaysAppliesShippingFee && aLab.totalQuantity < aLab.minimumQuantity && (aLab.shippingAmount === undefined || aLab.shippingAmount === null || aLab.shippingAmount === 0)) {
                message.push(`Existe um mínimo de ${aLab.minimumQuantity} unidades na encomenda de artigos ${aLab.name}. Este fornecedor não será processado.`);
            } else if(aLab.alwaysAppliesShippingFee) {
                message.push(`${aLab.name}: Uma taxa de envio de ${aLab.shippingAmount} € será adicionada `);
            }
        });

        const submitOrder = (result) => {
            SweetAlert.fire({
                title: 'Confirmação?',
                text: "Campo de Observações",
                input: 'textarea',

                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Cancelar',
            }).then((result) => {
                if (result.dismiss) {
                    return;
                }

                axios.post(`/api/v1/pharma/${myPharma.ANF}/place_order`, { storeCredit: storeCredit, obs: result.value })
                    .then((result) => {
                        if (result.status && result.status === 304) {
                            SweetAlert.fire(
                                'Encomenda não permitida!',
                                'A encomenda não foi efectuada.',
                                'warning'
                            );
                        } else {
                            SweetAlert.fire(
                                'Sucesso!',
                                'Encomenda Efectuada.',
                                'success'
                            )
                            axios
                                .get(`/api/v1/pharma/${myPharma.ANF}`)
                                .then((pharma) => {
                                    dispatch(setMyPharma(pharma.data));
                                })
                                .catch((error) => {
                                    console.error(error)
                                    const newPharma = { ...myPharma };
                                    newPharma.cart = [];
                                    dispatch(setMyPharma(newPharma));
                                });
                        }
                    })
                    .catch((error) => {
                        if (error.status && error.status === 100) {
                            SweetAlert.fire(
                                'Ocorreu um erro!',
                                'Stock Insuficiente.',
                                'error'
                            );
                        } else if (error.status && error.status === 101) {
                            SweetAlert.fire(
                                'Ocorreu um erro!',
                                'Valor da Encomenda não atinge o mínimo estabelecido para este fornecedor.',
                                'error'
                            );
                        } else {
                            SweetAlert.fire(
                                'Ocorreu um erro!',
                                'Encomenda Não Efectuada.',
                                'error'
                            );
                        }
                    });
            });
        }

        if (message.length > 0) {
            SweetAlert.fire({
                title: 'Atenção!',
                text: message[0],

                showCancelButton: true,
                confirmButtonText: 'Continuar',
                cancelButtonText: 'Cancelar',
            }).then(function (result) {
                if (result.dismiss) {
                    return;
                } else if (result.value) {
                    submitOrder();
                }
            })
        } else {
            submitOrder();
        }
    }

    const readNotif = (notif) => {
        dispatch(markNotificationAsRead(notif.message));
        history.push(`${process.env.PUBLIC_URL}/chat?chat=${notif.postedByUser._id}`)
    }

    return (
        <Fragment>
            <div className="nav-right col-8 pull-right right-header p-0">
                <ul className="nav-menus">
                    {/* {user && user.role === "FarmaciaRole" && myPharma && (
                        <li>
                            <div className={`mode ${modalOpen ? "active" : ""}`} onClick={() => toggleModal()}><Music color={`${modalOpen ? "#7366FF" : "#000"}`} /></div>
                        </li>
                    )} */}
                    <li>
                        <div className={`mode ${location.pathname === `${process.env.PUBLIC_URL}/calendario` ? "active" : ""}`} onClick={() => history.push(`${process.env.PUBLIC_URL}/calendario`)}><i className="fa fa-calendar" style={{ color: '#1d7733'}}/></div>
                    </li>
                    <li className="onhover-dropdown">
                        <div className={`notification-box ${user && user.notifications && user.notifications.length > 0 ? "anim" : ""}`} onClick={() => setNotificationDropDown(!notificationDropDown)}><Bell /><span className="badge badge-pill badge-secondary">{user && user.notifications && user.notifications.length}</span></div>
                        <ul className={`notification-dropdown onhover-show-div ${notificationDropDown ? "active" : ""}`}>
                            <li>
                                <Bell />
                                <h6 className="f-18 mb-0">{"Notificações"}</h6>
                            </li>
                            {user && user.notifications && user.notifications.map((notif) => (
                                <li key={notif} >
                                    <p><i className="fa fa-circle-o mr-3 font-primary"> </i>{"Teste"} <span className="pull-right">{"10 min."}</span></p>
                                </li>
                            ))}
                            {/* <li>
                                <button className="btn btn-primary" >{CheckAllNotification}</button>
                            </li> */}
                        </ul>
                    </li>
                    {/* <Bookmark /> */}
                    <li>
                        <div className="mode" onClick={() => MoonlightToggle(moonlight)}><i className={`fa ${moonlight ? 'fa-lightbulb-o' : 'fa-moon-o'}`}></i></div>
                    </li>
                    {user && user.role === "FarmaciaRole" && myPharma && (
                        <li className="cart-nav onhover-dropdown">
                            <div className="cart-box" onClick={() => setCartDropDown(!cartDropdown)}><ShoppingCart /><span className="badge badge-pill badge-primary">{myPharma.cart.length}</span></div>
                            <ul className={`cart-dropdown onhover-show-div ${cartDropdown ? "active" : ""}`}>
                                <li>
                                    <h6 className="mb-0 f-20">{"Carrinho"}</h6><ShoppingCart />
                                </li>
                                {myPharma.cart.map((item, index) => {
                                    if (item.item.groupedItems?.length) {
                                        item.item.price = item.item.groupedItems.reduce((accumulator, object) => {
                                            return accumulator + object.price;
                                          }, 0);
                                    }
                                    return (
                                        <li className="mt-0" key={index}>
                                            <div className="media"><img className="img-fluid rounded-circle mr-3 img-60" src={typeof item.item === 'object' ? item.item.photo : require("../../assets//images/ecommerce/01.jpg")} alt="" />
                                                <div className="media-body"><span>{typeof item.item === 'object' ? item.item.name : item.item}</span>
                                                    <p>{item.quantity} x {item.item.price.toFixed(2)} €</p>
                                                    <h6 className="text-right text-muted">{typeof item.item === 'object' ? (item.item.price * item.quantity).toFixed(2) : item.whenAdded.price.toFixed(2)} €</h6>
                                                </div>
                                                {/* eslint-disable-next-line */}
                                                <div className="close-circle"><a onClick={() => removeFromCart(item, 0)}><X /></a></div>
                                            </div>
                                        </li>
                                    )
                                })}
                                {myPharma.cart.length > 0 ? (
                                    <>
                                        <li>
                                            <FormGroup>
                                                <div className="form-check">
                                                    <div className="checkbox p-0">
                                                        <Input className="form-check-input" id="invalidCheck" type="checkbox" value={storeCredit} onChange={(e) => setStoreCredit(!storeCredit)} />
                                                        <Label className="form-check-label" htmlFor="invalidCheck">{"Usar Crédito"} - {myPharma.storeCredit.toFixed(2)} €</Label>
                                                    </div>
                                                </div>
                                            </FormGroup>
                                        </li>
                                        <li>
                                            <div className="total">
                                                <h6 className="mb-2 mt-0 text-muted">{"Total"} : <span className="f-right f-20">{storeCredit ? getCartTotalsWithCredit(myPharma.cart, myPharma.storeCredit).toFixed(2) + "€" : getCartTotals(myPharma.cart).toFixed(2) + "€"} </span></h6>
                                            </div>
                                        </li>
                                        <li>
                                            <Button color="primary" className="btn btn-block view-cart" onClick={placeOrder}>{"Encomendar"}</Button>
                                        </li>
                                    </>
                                ) : (
                                    <NoResults text={"Carrinho está vazio"} />
                                )}
                            </ul>
                        </li>
                    )}
                    {/* <li className="onhover-dropdown" onClick={() => setChatDropDown(!chatDropDown)}>
                        <div className="notification-box" onClick={() => setChatDropDown(!chatDropDown)}><MessageSquare /><span className="badge badge-pill badge-secondary">{notifications.slice(0, 5).length}</span></div>
                        <ul className={`chat-dropdown onhover-show-div ${chatDropDown ? "active" : ""}`}>
                            <li>
                                <MessageSquare />
                                <h6 className="f-18 mb-0">{"Mensagens"}</h6>
                            </li>
                            {notifications.length > 0 ? notifications.slice(0, 5).map((notif) => {
                                return (
                                    <li onClick={() => readNotif(notif)}>
                                        <div className="media"><img className="img-fluid rounded-circle mr-3" src={notif.postedByUser.profilePhoto} alt="" />
                                            <div className="status-circle online"></div>
                                            <div className="media-body"><span>{notif.postedByUser.name.first} {notif.postedByUser.name.last}</span>
                                                <p>{notif.message.messageText}</p>
                                            </div>
                                            <p className="f-12 font-success">{"agora mesmo"}</p>
                                        </div>
                                    </li>
                                )
                            }) : (
                                <NoResults text={"Sem novas mensagens"} />
                            )}
                            <li className="text-center"> <button className="btn btn-primary" onClick={() => history.push(`${process.env.PUBLIC_URL}/chat`)}>{"Ir para o Chat"}</button></li>
                        </ul>
                    </li> */}
                    {/* <li>
                        <div className={`mode ${location.pathname === '/chat' ? "active" : ""}`} onClick={() => history.push(`/chat`)}><MessageSquare color={`${location.pathname === '/chat' ? "#7366FF" : "#000"}`} /></div>
                    </li> */}
                    <li className="maximize"><a className="text-dark" href="#javascript" onClick={goFull}><Minimize /></a></li>
                    <li className="profile-nav onhover-dropdown p-0">
                        <div className="media profile-media">
                            <img className="b-r-10" src={user ? user.profilePhoto : man} alt="profile-pic" />
                            {/* <img className="b-r-10" src={user ? man : man} alt="man" /> */}
                            <div className="media-body"><span>{user && `${user.name.first} ${user.name.last}`}</span>
                                <p className="mb-0 font-roboto">{user && _mainRole} <i className="middle fa fa-angle-down"></i></p>
                            </div>
                        </div>
                        <ul className="profile-dropdown onhover-show-div">
                            <li onClick={() => UserMenuRedirect(`${process.env.PUBLIC_URL}/minha-conta`)}><User /><span>Minha Conta</span></li>
                            {/* <li onClick={() => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/email-app`)}><Mail /><span>Mensagens</span></li> */}
                            {user && user.role !== 'AdminRole' && (
                                <li onClick={() => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/todo-app/todo`)}><FileText /><span>ToDo List</span></li>
                            )}
                            <li onClick={user && Logout}><LogIn /><span>{LogOut}</span></li>
                        </ul>
                    </li>
                </ul>
            </div>
            {notificationsQuery.data && notificationsQuery.data.playlists && notificationsQuery.data.radio && (
                <RadioModal notificationsQuery={notificationsQuery} data={notificationsQuery.data} ANF={anf_code} modalOpen={modalOpen} toggleModal={toggleModal} />
            )}
        </Fragment>

    );
}

export default translate(Rightbar);

const getCartTotals = (cart) => {
    let sumCart = 0;
    cart.forEach((item) => {
        sumCart += item.quantity * item.item.price
    });
    return sumCart;
}

const getCartTotalsWithCredit = (cart, credit) => {
    let sumCart = 0;
    cart.forEach((item) => {
        sumCart += item.quantity * item.item.price
    });
    if (credit > sumCart) {
        sumCart = 0
    } else {
        sumCart = sumCart - credit
    }
    return sumCart;
}