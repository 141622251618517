export const apiUrl = "/api/v1/fb_pages";
export const rqModel = "fb_pages";
export const pathname = `${process.env.PUBLIC_URL}/marketing/facebook`;

export const columns = [
    {
        name: "Nome",
        selector: "name",
        sortable: true,
        center: true,
    },
    {
        name: "Data Publicação",
        selector: "date",
        sortable: true,
        center: true,
    },
    {
        name: "Opções",
        selector: "action",
        sortable: false,
        center: true,
    },
];
export const columns_template = [
    {
        name: "Nome",
        selector: "name",
        sortable: false,
        center: true,
    },
    {
        name: "Opções",
        selector: "action",
        sortable: false,
        center: true,
    },
]
export const pageColumns = [
    {
        name: "Farmácia",
        selector: "pharm_associated",
        sortable: true,
        center: true,
    },
    {
        name: "Página",
        selector: "name",
        sortable: true,
        center: true,
    },
    {
        name: "Opções",
        selector: "action",
        sortable: false,
        center: true,
    },
];

export const schema = [
    {
        colSize: "12",
        label: "Nome",
        name: "name",
        id: "name",
        type: "text",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "12",
        label: "Data do Post",
        name: "schedule_date",
        id: "schedule_date",
        type: "datepicker",
    },
    {
        colSize: "12",
        label: "Horas",
        name: "schedule_time",
        id: "schedule_time",
        type: "timepicker",
    },
    {
        name: "hr",
        id: "hr",
        type: "component",
        className: "mb-4"
    },
    {
        hidden: false,
        title: "Facebook",
        name: "sectionTitle",
        id: "sectionTitle",
        type: "sectionTitle",
    },
    {
        colSize: "12",
        label: "Descrição",
        name: "post_data.message",
        id: "post_data.message",
        type: "textarea",
    },
    {
        colSize: "12",
        label: "Link",
        name: "post_data.link",
        id: "post_data.link",
        type: "text",
    },
    {
        name: "hr",
        id: "hr",
        type: "component",
        className: "mb-4"
    },
    {
        hidden: false,
        title: "Instagram",
        name: "sectionTitle",
        id: "sectionTitle",
        type: "sectionTitle",
    },
    {
        colSize: "12",
        label: "Descrição",
        name: "instagram_data.caption",
        id: "instagram_data.caption",
        type: "textarea"
    },
    {
        name: "hr",
        id: "hr",
        type: "component",
        className: "mb-4"
    },
    {
        colSize: "12",
        label: "Ficheiro de Imagem",
        name: "media",
        id: "media",
        type: "file",
        content: "image",
        multi: false,
        route: "/api/v1/fb_pages",
        // imageEditInstantEdit: true,
        // imageResizeTargetWidth: 1200,
    },
    {
        name: "hr",
        id: "hr",
        type: "component",
        className: "mb-4"
    },
    {
        colSize: "6",
        label: "Encomendas Push",
        name: "push_type",
        id: "push_type",
        type: "select",
        multi: false,
        options: null,
        optionLabel: 'name',
        optionValue: '_id',
        backendPopulated: true,
        api: {
            route: "/api/v1/inqueritos?state=closed&quicklist=true",
        },
    },
    {
        colSize: "6",
        label: "Target Fidelização",
        name: "loyalty_type",
        id: "loyalty_type",
        type: "select",
        multi: true,
        optionLabel: 'label',
        optionValue: 'value',
        options: [
            {
                value: "all",
                label: "Todos",
            },
            {
                value: "null",
                label: "Outro",
            },
            {
                value: "sauda",
                label: "Cartão Saúda",
            },
            {
                value: "PrestadorRole",
                label: "Prestador",
            },
        ]
    },
    {
        colSize: "6",
        label: "Serviços",
        name: "services_type",
        id: "services_type",
        type: "select",
        multi: false,
        optionLabel: 'label',
        optionValue: 'value',
        options: [
            {
                value: "dietaea",
                label: "Dieta EasySlim",
            },
            {
                value: "nutric",
                label: "Nutrição Clínica",
            },
            {
                value: "podol",
                label: "Podologia",
            },
            {
                value: "osteo",
                label: "Osteopatia",
            },
            {
                value: "enferm",
                label: "Enfermagem",
            },
            {
                value: "consulfarm",
                label: "Consulta Farmacêutica",
            },
            {
                value: "consulhomeo",
                label: "Consulta de Homeopatia",
            },
            {
                value: "nutriviv",
                label: "Nutrição ViV",
            },
            {
                value: "fisio",
                label: "Fisioterapia",
            },
            {
                value: "dispensa",
                label: "Dispensa de Medicação Individualizada",
            },
            {
                value: "checksaude",
                label: "Check Saúde",
            },
            {
                value: "admin_vac",
                label: "Administração de Vacinas e Injectáveis",
            },
            {
                value: "cessacao_tab",
                label: "Consulta de Cessação Tabágica",
            },
            {
                value: "manipul",
                label: "Preparação de Manipulados",
            },
            {
                value: "travely",
                label: "Aconselhamento do Viajante",
            },
            {
                value: "sveti",
                label: "SVETI",
            },
        ]
    },
    {
        colSize: "6",
        label: "Fornecedor Associado",
        name: "fornecedorId",
        id: "fornecedorId",
        type: "select",
        multi: false,
        options: null,
        optionLabel: 'name',
        optionValue: 'fornecedor_id',
        api: {
            route: "/api/v1/laboratories",
            redux: 'Laboratories',
        },
    }
];

export const schema_template = [
    {
        colSize: "12",
        label: "Nome",
        name: "name",
        id: "name",
        type: "text",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        name: "hr",
        id: "hr",
        type: "component",
        className: "mb-4"
    },
    {
        hidden: false,
        title: "Facebook",
        name: "sectionTitle",
        id: "sectionTitle",
        type: "sectionTitle",
    },
    {
        colSize: "12",
        label: "Descrição",
        name: "post_data.message",
        id: "post_data.message",
        type: "textarea",
    },
    {
        colSize: "12",
        label: "Link",
        name: "post_data.link",
        id: "post_data.link",
        type: "text",
    },
    {
        name: "hr",
        id: "hr",
        type: "component",
        className: "mb-4"
    },
    {
        hidden: false,
        title: "Instagram",
        name: "sectionTitle",
        id: "sectionTitle",
        type: "sectionTitle",
    },
    {
        colSize: "12",
        label: "Descrição",
        name: "instagram_data.caption",
        id: "instagram_data.caption",
        type: "textarea",
    },
    {
        name: "hr",
        id: "hr",
        type: "component",
        className: "mb-4"
    },
    {
        colSize: "12",
        label: "Escolher imagem",
        name: "media",
        id: "media",
        type: "file",
        content: "image",
        multi: false,
        route: "/api/v1/templates",
        // imageEditInstantEdit: true,
        // imageResizeTargetWidth: 1200,
    },
];

