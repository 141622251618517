import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import ScrollArea from 'react-scrollbar';
import { Clock } from 'react-feather';
import { NewsUpdate, Notification } from '../../constant';
import moment from 'moment';
import 'moment/locale/pt';

import { useGetOne, useGetAll } from '../../hooks/';

import ChatWithAdmin from './chatWithAdmin/';

import errorImg from '../../assets/images/search-not-found.png';

const LabDashboard = (props) => {
    const anf_code = useSelector(content => content.User.user.anf_code);

    const pharmacyQuery = useGetOne(`/api/v1/pharma/${anf_code}?dashboard=true`, anf_code && anf_code, 'pharmacy');

    const newsQuery = useGetAll(`/api/v2/news?page=1&limit=3`, 'dashboard_news');
    const notificationsQuery = useGetAll(`/api/v1/notifications`, 'notifications');

    const [daytimes, setDayTimes] = useState();
    const today = new Date();
    const curHr = today.getHours();
    const curMi = today.getMinutes();
    const [meridiem, setMeridiem] = useState("AM");
    // eslint-disable-next-line
    const [date, setDate] = useState({ date: new Date() });
    // eslint-disable-next-line
    const [startDate, setStartDate] = useState(new Date());

    useEffect(() => {
        if (curHr < 12) {
            setDayTimes('Bom dia')
        } else if (curHr < 18) {
            setDayTimes('Boa tarde')
        } else {
            setDayTimes('Boa noite')
        }

        if (curHr >= 12) {
            setMeridiem('PM')
        } else {
            setMeridiem('AM')
        }
    }, []) // eslint-disable-line



    return (
        <Fragment>
            <Container fluid={true}>
                {/* <Radio data={notificationsQuery.data} ANF={anf_code} /> */}
                <Row className="second-chart-list third-news-update">
                    
                 
                
                    <Col xl="12 xl-100" className="chart_data_left box-col-12 mb-4">
                        <iframe title="canva" loading="lazy" style={{ position: 'relative', width: '100%', height: 450, top: 0, left: 0, border: 'none', padding: 0, margin: 0 }}
                            src="https://www.canva.com/design/DAFW0RiwsEg/view?embed" width="960" height="450" allowfullscreen="allowfullscreen" allow="fullscreen">
                        </iframe>
                    </Col>
                    

                   

                 
                </Row>
            </Container>
        </Fragment>
    );
}

export default LabDashboard;