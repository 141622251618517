import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Card, CardHeader, CardBody, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import SweetAlert from 'sweetalert2'
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/pt';

import Breadcrumb from '../../../../layout/breadcrumb';

import { useGetAll } from '../../../../hooks/';

import CustomForm from "../../../form";
import { apiUrl, rqModel, columns, schema } from "./settings";

const AdminRenting = () => {
    const pharmacies = useSelector(content => content.Pharmacies.results);
    const loaded = useSelector(content => content.Pharmacies.loaded);
    const rentingQuery = useGetAll(apiUrl, rqModel);

    const [currentRent, setRent] = useState(null);
    const [viewType, setViewType] = useState(null);

    const toggleTable = () => {
        setViewType(null);
        setRent(null);
    }

    const received = (id) => {
        SweetAlert.fire({
            title: 'Atenção!',
            text: 'Confirma que pretende ?',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then((result) => {
                if (result.value) {
                    axios.patch(`/api/v1/renting/${id}`, {})
                        .then((result) => {
                            toast.success("Sucesso", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        })
                        .catch((error) => {
                            toast.error("Erro", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        });
                }
            })
    }

    return (
        <Fragment>
            <Breadcrumb parent="Loja" title="Renting" />
            <Container fluid={true}>
                {!rentingQuery.isLoading && loaded ? (
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader>
                                    <span>{"Descrição"}</span>
                                </CardHeader>
                                <CardBody>
                                    <div className="todo">
                                        {viewType === "form" ? (
                                            <CustomForm data={currentRent} toggleTable={toggleTable} schema={schema} rqModel={rqModel} route={apiUrl} />
                                        ) : currentRent && viewType === "profile" ? (
                                            <CustomForm data={currentRent} toggleTable={toggleTable} schema={schema} rqModel={rqModel} route={apiUrl} />
                                        ) : (
                                            <div className="table-responsive product-table">
                                                <DataTable
                                                    defaultSortField="createdOn"
                                                    defaultSortAsc={false}
                                                    noHeader
                                                    columns={columns}
                                                    data={rentingQuery.data.docs.map((rent) => ({
                                                        createdOn: `${moment(rent.createdOn).format("YYYY/MM/D")}`,
                                                        pharma: `${pharmacies.find((pharma) => pharma.ANF === rent.ANF).name}`,
                                                        period: `${moment(rent.s).format("DD/MM/YYYY")} - ${moment(rent.e).format("DD/MM/YYYY")}`,
                                                        product: `${rent.rentingProductId.name} (${rent.rentingProductId.bulk})`,
                                                        action: <div>
                                                            <Button color="primary" size="xs" className="ml-2" onClick={() => received(rent._id)}>Recebido</Button>
                                                        </div>
                                                    }))}
                                                    highlightOnHover={true}
                                                    pointerOnHover={true}
                                                    pagination={true}
                                                    paginationPerPage={25}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    <Row style={{ minHeight: 500 }}>
                        <Col sm="12">
                            <div className="loader-box loader-abs">
                                <div className="loader-7" />
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
        </Fragment>
    )
}

export default AdminRenting
