import React, { Fragment, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Card, CardHeader, CardBody, Button, Form, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import DataTable from 'react-data-table-component';
import axios from 'axios';

import Breadcrumb from '../../../layout/breadcrumb';
import NoResults from '../../ui-kits/noResults';

import PaginationDT from '../../pagination';

import Filepond from "../../filepond/";
import { getWithFilters } from "../../../api/";
import { apiUrl, rqModel, columns } from "./settings";

const Products = () => {
    const history = useHistory();

    const role = useSelector(content => content.User.user.role);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [keyValue, setKeyValue] = useState(null);
    const [sortValue, setSortValue] = useState(null);

    const [tempSearchKeyword, setTempSearchKeyword] = useState('');
    const [searchKeyword, setSearchKeyword] = useState('');

    const [files, setFiles] = useState([]); //* FILEPOND
    const [modal, setModal] = useState(false);
    const [fileLoading, setFileLoading] = useState(false);
    const [fileError, setFileError] = useState(null);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [updateError, setUpdateError] = useState(null);

    const getProducts = (rqModel, page, limit, searchKeyword, keyValue, sortValue) => getWithFilters({ apiUrl: apiUrl, pagination: { page: page, limit: limit }, filters: { search: searchKeyword, sortBy: keyValue, sort: sortValue } });
    const {
        isLoading,
        data,
        error,
    } = useQuery([rqModel, page, limit, searchKeyword, keyValue, sortValue], () => getProducts(rqModel, page, limit, searchKeyword, keyValue, sortValue), { keepPreviousData: true, staleTime: 300000 });

    if (error && error.response) {
        history.push(`${process.env.PUBLIC_URL}/pages/errors/error${error.response.status}`);
    } else if (error) {
        history.push(`${process.env.PUBLIC_URL}/pages/errors/error500`);
    }

    const handleSearchKeyword = () => {
        if (tempSearchKeyword.length > 3) {
            setPage(1)
            setSearchKeyword(tempSearchKeyword)
        } else if (tempSearchKeyword.length <= 3) {
            setPage(1)
            setSearchKeyword('')
        }
    }

    const download = () => {
        axios.get(`/api/v2/codes/generate_file`)
            .then((result) => {
                toast.success("Ficheiro Gerado", {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
            .catch((error) => {
                toast.error("Ocorreu um erro ao gerar o ficheiro", {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    const toggleModal = () => {
        setModal(true);
    }
    const closeModal = () => {
        setFiles([]); //* FILEPOND
        setModal(false);
    }
    const setCustomFiles = (files) => {
        setFiles(files) //* FILEPOND
    }

    const uploadCatalogo = () => {
        setFileLoading(true);

        let submitValues = new FormData();
        submitValues.append('file[name]', files.name);
        submitValues.append('file', files);
        axios.post(`/api/v2/codes/mass_code_update`, submitValues, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((result) => {
                if (result.status === 200) {
                    toast.success(`Uploaded feito com sucesso!`, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setFileLoading(false);
                    closeModal();
                } else {
                    setFileLoading(false);
                    setFileError("Ocorreu um erro, tente mais tarde.");
                    setTimeout(() => {
                        setFileError(null);
                    }, 3000);
                }
            })
            .catch((error) => {
                setFileLoading(false);
                setFileError("Ocorreu um erro, tente mais tarde.");
                setTimeout(() => {
                    setFileError(null);
                }, 3000);
            });
    }

    const update = () => {
        setUpdateLoading(true);

        axios.post(`/api/v2/codes/update_catalogue`)
            .then((result) => {
                if (result.status === 200) {
                    toast.success(`Pedido enviado com sucesso!!`, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setUpdateLoading(false);
                } else {
                    setUpdateLoading(false);
                    setUpdateError("Ocorreu um erro, tente mais tarde.");
                    setTimeout(() => {
                        setFileError(null);
                    }, 3000);
                }
            })
            .catch((error) => {
                setUpdateLoading(false);
                setUpdateError("Ocorreu um erro, tente mais tarde.");
                setTimeout(() => {
                    setUpdateError(null);
                }, 3000);
            });
    }

    return (
        <Fragment>
            <Breadcrumb parent="Administração" title="Produtos" />
            <Container fluid={true}>
                {!isLoading ? (
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader>
                                    <span>{"Gestão de Produtos de Loja maisfarmácia"}</span>
                                </CardHeader>
                                <CardBody>
                                    <div className="feature-products">
                                        <Row>
                                            <Col md="6">
                                                <Form onSubmit={(ev) => ev.preventDefault()}>
                                                    <FormGroup className="m-0">
                                                        <Input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Procurar (mais de 3 caracteres)"
                                                            defaultValue={searchKeyword}
                                                            value={tempSearchKeyword}
                                                            onChange={(e) => {
                                                                setTempSearchKeyword(e.target.value);
                                                                if (e.target.value.length === 0) handleSearchKeyword();
                                                            }}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') handleSearchKeyword();
                                                            }}
                                                        />
                                                        <Button color="primary" onClick={handleSearchKeyword}><i className="fa fa-search" /></Button>
                                                    </FormGroup>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="todo">
                                        <div className="todo-list-wrapper">
                                            <div className="todo-list-container">
                                                <div className="mark-all-tasks">
                                                    <div className="mark-all-tasks-container">
                                                        {role === "AdminRole" && (
                                                            <>
                                                                <Button className="ml-2" color="primary" onClick={toggleModal}>{"Upload de Catálogo"}</Button>
                                                                <Button className="ml-2" color="primary" onClick={update}>{updateLoading ? (
                                                                    <i className="fa fa-spin fa-spinner" />
                                                                ) : updateError ? (
                                                                    updateError
                                                                ) : (
                                                                    "Update Catálogo"
                                                                )}</Button>
                                                                <Button className="ml-2" color="primary" onClick={download}>{"Download Master"}</Button>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive product-table">
                                            <DataTable
                                                noHeader
                                                columns={columns}
                                                data={data && data.results.map((product) => ({
                                                    CNP: <NavLink to={`${process.env.PUBLIC_URL}/produto/${product.CNP}`}>{product.CNP}</NavLink>,
                                                    NOME: `${product.NOME}`,
                                                    LABORATORIO: `${product.LABORATORIO}`,
                                                    CATEGORIA_MF: `${product.CATEGORIA_MF ? product.CATEGORIA_MF : "-"}`,
                                                    SEGMENTO_MF: `${product.SEGMENTO_MF ? product.SEGMENTO_MF : "-"}`,
                                                }))}
                                                highlightOnHover={true}
                                                pointerOnHover={true}
                                                pagination={true}
                                                noDataComponent={<NoResults />}
                                                paginationComponent={() => {
                                                    return (
                                                        <PaginationDT totalPage={data.totalPages} currentPage={data.page} limit={data.limit} onChangePage={setPage} />
                                                    )
                                                }}
                                                sortServer={true}
                                                onSort={(column, value) => {
                                                    setKeyValue(column.selector)
                                                    setSortValue(value)
                                                    setPage(1)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    <Row style={{ minHeight: 500 }}>
                        <Col sm="12">
                            <div className="loader-box loader-abs">
                                <div className="loader-7" />
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
            <Modal isOpen={modal} toggle={() => closeModal}>
                <ModalHeader toggle={() => closeModal}>
                    {"Upload Catálogo"}
                </ModalHeader>
                <ModalBody>
                    <Filepond
                        name={"mass_upload"}
                        label={"Carregar Ficheiro"}
                        multi={false}
                        files={files}
                        setFiles={setCustomFiles}
                        acceptedFileTypes={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv']}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={uploadCatalogo}>
                        {fileLoading ? (
                            <i className="fa fa-spin fa-spinner" />
                        ) : fileError ? (
                            fileError
                        ) : (
                            "Upload"
                        )}
                    </Button>
                    <Button color="secondary" onClick={() => closeModal()}>
                        {"Cancelar"}
                    </Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}

export default Products