import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Card, CardHeader, CardBody, Table, Button } from 'reactstrap';
import moment from 'moment';
import 'moment/locale/pt';

import ProfileTitle from '../../../layout/profileTitle';

import ProductsTable from './admin/productsTable';

import { useGetOne } from '../../../hooks';
import { getState, getValorProposto } from './admin/utils';

const OrderProfile = (props) => {
    const history = useHistory();
    const { data, isLoading, status } = useGetOne(`/api/v2/inqueritos/${props.match && props.match.params && props.match.params.id}`, props.match && props.match.params && props.match.params.id, 'orders');
console.log('2', data)
    if (status === "error") history.push(`${process.env.PUBLIC_URL}/pages/errors/error404`);

    const pharmacies = useSelector(content => content.Pharmacies.results);
    const loaded = useSelector(content => content.Pharmacies.loaded);

    const [adjustedData, setAdjustedData] = useState(false);
    const [obsAnaliseState, setObsAnalise] = useState(null);
    const [totals, setTotals] = useState(null);

    // const [totalAnswers, setTotalAnswers] = useState(null);

    const [totalFinalBulk, setTotalFinalBulk] = useState(0);
    const [totalBulkInit, setTotalBulkInit] = useState(0);

    //* Products Table
    const [isOpen, setOpen] = useState(false)
    const [currentSegmentName, setCurrentSegmentName] = useState("");
    const [currentProducts, setCurrentProducts] = useState([]);

    useEffect(() => {
        if (!isLoading && loaded && !adjustedData) {
            let prodSize = 0;
            let lowerSegmentId = null;
            let lowerSegmentValue = 0;

            data.segments.forEach((seg, index) => {
                if (seg.products.length > prodSize) {
                    prodSize = seg.products.length;
                }
            });

            data.segments.forEach((seg, index) => {
                let nowTotal = 0;
                seg.products.forEach(prod => {
                    nowTotal += prod.pvf * prod.quantity * (100 - parseInt(prod.discount)) / 100
                });
                if (index === 0) lowerSegmentValue = nowTotal;
                if (nowTotal <= lowerSegmentValue) {
                    lowerSegmentValue = nowTotal;
                    lowerSegmentId = seg._id
                }
            });

            let totalAccept = 0;
            let obsAnalise = {
                excesso: 0,
                rotacao: 0,
                "seg-high": 0,
                another: 0
            }

            data.response.forEach((lePharmInRespose, index) => {
                if (lePharmInRespose.rejected) {
                    obsAnalise[lePharmInRespose.obs] += 1;
                } else {
                    totalAccept++;
                }

                data.segments.forEach((seg, index) => {
                    let foundFlag = false;
                    seg.pharm.forEach((lePharm) => {
                        if (lePharm === lePharmInRespose.pharm_id) {
                            foundFlag = true
                            if (lePharmInRespose.rejected) {
                                lePharmInRespose.segment = data.segments[0].name
                                lePharmInRespose.creditnote = "";
                                lePharmInRespose.rappel = "";
                                lePharmInRespose.chosenSegment = lowerSegmentId;
                            } else {
                                const foundSegment = data.segments.find((seg) => seg._id === lePharmInRespose.chosenSegment);
                                if (!foundSegment) {
                                    lePharmInRespose.segment = data.segments.find((seg) => seg._id === lowerSegmentId) ? data.segments.find((seg) => seg._id === lowerSegmentId).name : "Não Encontrado";
                                    lePharmInRespose.creditnote = "";
                                    lePharmInRespose.rappel = "";
                                    lePharmInRespose.chosenSegment = lowerSegmentId;
                                } else {
                                    const foundSegment2 = data.segments.find((seg) => seg._id === lePharmInRespose.chosenSegment);

                                    lePharmInRespose.segment = foundSegment2 ? foundSegment2.name : "Não Encontrado";
                                    lePharmInRespose.creditnote = foundSegment2 ? foundSegment2.creditnote : "Não Encontrado";
                                    lePharmInRespose.rappel = foundSegment2 ? foundSegment2.rappel : "Não Encontrado";
                                }
                            }
                            // Find the name of this pharmacy in the temporary stored pharmacy list
                            pharmacies.forEach((pharma) => {
                                if (pharma.ANF === lePharmInRespose.pharm_id) {
                                    lePharmInRespose.name = pharma.name;
                                }
                            });
                        }
                    });
                    if (!foundFlag) console.log('Not Found in Segment', lePharmInRespose)
                });


                let myProductsPreMutate = [];

                lePharmInRespose.products.forEach(myResponseProducts => {
                    const mySegmentProducts = data.segments.find((seg) => seg._id === lePharmInRespose.chosenSegment) ? data.segments.find((seg) => seg._id === lePharmInRespose.chosenSegment).products : null
                    let myProd = null;
                    if (mySegmentProducts) {
                        myProd = mySegmentProducts.find((product) => product.cnp === myResponseProducts.cnp);
                    }
                    if (myProd) {
                        myProd['quantity2'] = myResponseProducts.quantity;
                        myProductsPreMutate.push(myProd);
                    } else {
                        console.log('Aviso, Produtos inválidos na resposta da farmacia : ', lePharmInRespose.name)
                        console.log(myResponseProducts)
                    }
                });
                lePharmInRespose.myProductsPreMutate = myProductsPreMutate;
            });

            setObsAnalise(obsAnalise);
            // setTotalAnswers(obsAnalise['excesso'] + obsAnalise['rotacao'] + obsAnalise['seg-high'] + obsAnalise['another']);

            let myTotals = {
                inqTotal: 0,
                percentageTotal: 0,
                percentageAccept: (totalAccept / data.response.length) * 100
            }

            data.segments.forEach(mySegment => {
                myTotals.inqTotal += mySegment.pharm.length
            });

            myTotals.percentageTotal = (data.response.length / myTotals.inqTotal) * 100;

            setTotals(myTotals);

            let myTotal = 0;
            let myTotalInit = 0;

            data.response.forEach(response => {
                const foundSegment = data.segments.find((seg) => seg.name = response.segment);
                if (foundSegment) {
                    response.myProductsPreMutate.forEach((prod) => {
                        let myProcessDiscount = (100 - parseInt(prod.discount)) / 100;
                        myTotal += (prod.pvf * prod.quantity2 * myProcessDiscount)
                    });

                    foundSegment.products.forEach((prod) => {
                        let myProcessDiscount = (100 - parseInt(prod.discount)) / 100;
                        myTotalInit += (prod.pvf * prod.quantity * myProcessDiscount)
                    });
                }
            });

            setTotalBulkInit(myTotalInit)
            setTotalFinalBulk(myTotal)

            setAdjustedData(true);
        }
    }, [isLoading, loaded, adjustedData]) // eslint-disable-line

    const seeProducts = (products, name) => {
        setCurrentProducts(products)
        setCurrentSegmentName(name)
        setOpen(true);
    };

    return (
        <Fragment>
            <Container fluid={true} className="pt-2">
                {!isLoading && loaded && adjustedData ? (
                    <>
                        <ProfileTitle title={data.name} route={`${process.env.PUBLIC_URL}/push/encomendas`} />
                        <Row>
                            {/* <Col xl="4 xl-50" className="box-col-12">
                                <Card style={{ minHeight: 500 }}>
                                    <CardHeader className="card-no-border">
                                        <h5>{getState(data.state) && "Estado"}</h5>
                                        <p>{getState(data.state)}</p>
                                        <h5>{getState(data.type) && "Tipo"}</h5>
                                        <p>{getState(data.type)}</p>
                                        <h5>{moment(data.end_date).format('LL') && "Data de Fecho"}</h5>
                                        <p>{moment(data.end_date).format('LL')}</p>
                                    </CardHeader>
                                </Card>
                            </Col> */}
                            <Col xl="4 xl-50" className="box-col-12">
                                <Card style={{ minHeight: 500 }}>
                                    <CardHeader>
                                        <h5>{"Resultados"}</h5>
                                    </CardHeader>
                                    <CardBody className="new-update pt-4">
                                        <Row>
                                            <Col xl="12" className="mb-4">
                                                <div className="media align-items-center">
                                                    <div className="media-body right-chart-content">
                                                        <h4>{totalFinalBulk.toFixed(2)} €</h4>
                                                        <span>{"Total Final"}</span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xl="12" className="mb-4">
                                                <div className="media align-items-center">
                                                    <div className="media-body right-chart-content">
                                                        <h4>{(totalFinalBulk).toFixed(2)} €</h4>
                                                        <span>{"Total Inicial"}</span>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl="4 xl-50" className="box-col-12">
                                <Card style={{ minHeight: 500 }}>
                                    <CardHeader>
                                        <h5>{"Resposta"}</h5>
                                    </CardHeader>
                                    <CardBody className="new-update pt-4">
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th className="text-center">{"CNP"}</th>
                                                    <th className="text-center">{"Nome"}</th>
                                                    <th className="text-center">{"Quantidade"}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.segments.map((seg) => {
                                                    return (
                                                        seg.products.map((product, index) => (
                                                            <tr key={index}>
                                                                <th className="text-center">{product.cnp}</th>
                                                                <td className="text-center">{product.name}</td>
                                                                <td className="text-center">{product.quantity}</td>
                                                            </tr>
                                                        ))
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl="4 xl-50" className="box-col-12">
                                <Card style={{ minHeight: 500 }}>
                                    <CardHeader>
                                        <h5>{"Resultados"}</h5>
                                    </CardHeader>
                                    <CardBody className="new-update pt-4">
                                        <Row>
                                            <Col xl="12" className="mb-4">
                                                <div className="media align-items-center">
                                                    <div className="media-body right-chart-content">
                                                        <h4>{totalFinalBulk.toFixed(2)} €</h4>
                                                        <span>{"Total Final"}</span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xl="12" className="mb-4">
                                                <div className="media align-items-center">
                                                    <div className="media-body right-chart-content">
                                                        <h4>{(totalFinalBulk).toFixed(2)} €</h4>
                                                        <span>{"Total Inicial"}</span>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <div className="best-seller-table responsive-tbl">
                                            <div className="item">
                                                <div className="table product-list">
                                                    <Table borderless>
                                                        <thead>
                                                            <tr>
                                                                <th className="f-22">{"Segmentos"}</th>
                                                                <th className="text-center">{"Valor minimo"}</th>
                                                                <th className="text-center">{"Unidades Mínimas"}</th>
                                                                <th className="text-center">{"Valor Proposto"}</th>
                                                                <th className="text-center">{"Opções"}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data.segments.map((segment, index) => (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <div className="d-inline-block align-middle">
                                                                            <div className="d-inline-block">
                                                                                <span>{segment.name}</span>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-center">{segment.minimumOrder.toFixed(2)} €</td>
                                                                    {segment.minimumQuantity > 0 ? (
                                                                        <td className="text-center">{segment.minimumQuantity} un.</td>
                                                                    ) : (
                                                                        <td className="text-center">Sem Mínimo</td>
                                                                    )}
                                                                    <td className="text-center"><span className="label">{getValorProposto(segment).toFixed(2)} €</span></td>
                                                                    <td className="text-center"><i className="fa fa-check-circle"></i><Button color="primary" size="xs" className="ml-2" onClick={() => seeProducts(segment.products, segment.name)}>Ver Produtos</Button></td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <ProductsTable
                            isOpen={isOpen}
                            toggle={setOpen}
                            products={currentProducts}
                            segment={currentSegmentName}
                        />
                    </>
                ) : (
                    <Row className="mt-4" style={{ minHeight: 500 }}>
                        <Col sm="12">
                            <div className="loader-box loader-abs">
                                <div className="loader-7" />
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
        </Fragment >
    );
}

export default OrderProfile;