import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Table } from 'reactstrap';

const ProductsTable = ({ isOpen, toggle, products, segment }) => {
    const getTotalRow = (row) => {
        let totalRow = 0

        let myProcessDiscount = (100 - parseFloat(row.discount)) / 100;
        if (row.quantity) {
            totalRow = (row.quantity * parseFloat(row.pvf) * myProcessDiscount);
        } else {
            totalRow = (1 * parseFloat(row.pvf) * myProcessDiscount);
        }
        
        return totalRow;
    }

    return (
        <Modal isOpen={isOpen} toggle={() => toggle(!isOpen)} size="lg" style={{ maxWidth: 1185 }}>
            <ModalHeader toggle={() => toggle(!isOpen)}>
                {segment}
            </ModalHeader>
            <ModalBody>
                <Table>
                    <thead>
                        <tr>
                            <th className="text-center">{"CNP"}</th>
                            <th className="text-center">{"Nome"}</th>
                            <th className="text-center">{"PVF"}</th>
                            <th className="text-center">{"Desconto"}</th>
                            <th className="text-center">{"Quantidade"}</th>
                            <th className="text-center">{"Mín"}</th>
                            <th className="text-center">{"Múlt"}</th>
                            <th className="text-center">{"Valor Final"}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {products.map((product, index) => (
                            <tr key={index}>
                                <th className="text-center">{product.cnp}</th>
                                <td className="text-center">{product.name}</td>
                                <td className="text-center">{product.pvf}€</td>
                                <td className="text-center">{product.discount} %</td>
                                <td className="text-center">{product.quantity}</td>
                                <td className="text-center">{product.minimumOrder}</td>
                                <td className="text-center">{product.stepFactor}</td>
                                <td className="text-center">{getTotalRow(product).toFixed(2)} €</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={() => toggle(!isOpen)}>
                    {"Fechar"}
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default ProductsTable;