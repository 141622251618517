export const apiUrl = "/api/v1/laboratories";
export const rqModel = "laboratories";
export const pathname = `${process.env.PUBLIC_URL}/administracao/fornecedores`;

export const columns = [
  {
    name: "Nome",
    selector: "name",
    sortable: true,
    center: true,
  },
  {
    name: "Fornecedor ID",
    selector: "fornecedor_id",
    sortable: true,
    center: true,
  },
  {
    name: "Situação",
    selector: "partner_state",
    sortable: false,
    center: true,
  },
  {
    name: "Opções",
    selector: "action",
    sortable: false,
    center: true,
  },
];

export const schema = [
  {
    colSize: "6",
    label: "Nome",
    name: "name",
    id: "name",
    type: "text",
    required: true,
    requiredMessage: "Este campo é obrigatório",
  },
  {
    colSize: "6",
    label: "ID do Fornecedor",
    name: "fornecedor_id",
    id: "fornecedor_id",
    type: "text",
    required: true,
    requiredMessage: "Este campo é obrigatório",
  },
  {
    colSize: "6",
    label: "Situação",
    name: "partner_state",
    id: "partner_state",
    type: "select",
    required: true,
    requiredMessage: "Este campo é obrigatório",
    multi: false,
    optionLabel: "label",
    optionValue: "value",
    options: [
      {
        value: "parceiro",
        label: "Parceiro",
      },
      {
        value: "nao_parceiro",
        label: "Não Parceiro",
      },
      {
        value: "avaliacao",
        label: "Em avaliação",
      },
    ],
  },
  {
    colSize: "6",
    label: "Segmentação",
    name: "profile",
    id: "profile",
    type: "select",
    required: true,
    requiredMessage: "Este campo é obrigatório",
    multi: false,
    optionLabel: "label",
    optionValue: "value",
    options: [
      {
        value: "Premium",
        label: "Premium",
      },
      {
        value: "Gold",
        label: "Gold",
      },
      {
        value: "Silver",
        label: "Silver",
      },
      {
        value: "Standard",
        label: "Standard",
      },
    ],
  },
];
