import React, { Fragment } from 'react';
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Card } from 'reactstrap';
import moment from 'moment';
import 'moment/locale/pt';

import ProfileTitle from '../../../../layout/profileTitle';

import { useGetOne } from '../../../../hooks/';

const NewsProfileProfile = (props) => {
    const history = useHistory();
    window.scrollTo(0, 0);
    const user = useSelector((content) => content.User.user);

    const newsQuery = useGetOne(`/api/v1/news/${props.params && props.params.id ? props.params.id : props && props.match && props.match.params.id ? props.match.params.id : null}`, props.params && props.params.id ? props.params.id : props && props.match && props.match.params.id ? props.match.params.id : null, 'news');

    if (newsQuery.status === "error") history.push(`${process.env.PUBLIC_URL}/pages/errors/error404`);

    return (
        <Fragment>
            <Container fluid={true}>
                <Row className="justify-content-center">
                    {newsQuery.data ? (
                        <Col sm="12" className="mt-4">
                            <Card style={{ padding: 25 }}>
                                <div className="profile-img-style">
                                    <Row>
                                        <Col sm="8">
                                            <div className="media">
                                                <div className="media-body align-self-center">
                                                    <ProfileTitle title={newsQuery.data.title} route={user.role === "AdminRole" ? `${process.env.PUBLIC_URL}/centro/noticias` : `${process.env.PUBLIC_URL}/centro/newsletters`} />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm="4" className="align-self-center">
                                            <div className="float-sm-right"><small>{moment(newsQuery.data.date).format("LL")}</small></div>
                                        </Col>
                                    </Row>
                                    <hr/>
                                    <p className="mt-0">{newsQuery.data.teaser}</p>
                                    <div className="fr-view" dangerouslySetInnerHTML={{ __html: newsQuery.data.body }} />
                                </div>
                            </Card>
                        </Col>
                    ) : (
                        <Row style={{ minHeight: 500 }}>
                            <Col sm="12">
                                <div className="loader-box loader-abs">
                                    <div className="loader-7" />
                                </div>
                            </Col>
                        </Row>
                    )}
                </Row>
            </Container>
        </Fragment>
    );
}

export default NewsProfileProfile;