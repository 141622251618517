import React, { Fragment } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'

const PaginationDT = (props) => {
    const { totalPage = 0, currentPage = 1, numberLimit = 5, lastIsActive = true, firstIsActive = true } = props;

    const onChangePage = (e) => {
        props.onChangePage(e)
    }

    let startPoint = 1;
    let endPoint = numberLimit;

    if (numberLimit > totalPage) {
        startPoint = 1;
        endPoint = totalPage;
    } else if (currentPage <= parseInt(numberLimit / 2, 10)) {
        startPoint = 1;
        endPoint = numberLimit;
    } else if (currentPage + parseInt(numberLimit / 2, 10) <= totalPage) {
        startPoint = currentPage - parseInt(numberLimit / 2, 10);
        endPoint = currentPage + parseInt(numberLimit / 2, 10);
    } else {
        startPoint = totalPage - (numberLimit - 1);
        endPoint = totalPage;
    }
    startPoint = startPoint === 0 ? 1 : startPoint;
    const points = [];
    for (var i = startPoint; i <= endPoint; i++) {
        points.push(i);
    }

    let firstPageButtonClassName = currentPage <= 1 ? "disabled" : "";
    let lastPageButtonClassName = currentPage >= totalPage ? "disabled" : "";
    let prevPageButtonClassName = currentPage <= 1 ? "disabled" : "";
    let nextPageButtonClassName = currentPage >= totalPage ? "disabled" : "";

    return totalPage > 1 ? (
        <Fragment>
            <Pagination aria-label="Page navigation example" className={`m-t-30 m-b-30 pagination pagination-primary ${props.centered ? "justify-content-center" : "justify-content-end"}`}>
                {firstIsActive && (
                    <PaginationItem className={`${firstPageButtonClassName}`}><PaginationLink onClick={() => onChangePage(1)}><i className="fa fa-angle-double-left"></i></PaginationLink></PaginationItem>
                )}
                <PaginationItem className={`${prevPageButtonClassName}`}>
                    <PaginationLink onClick={() => onChangePage(currentPage - 1)}>
                        <i className="fa fa-angle-left"></i>
                    </PaginationLink>
                </PaginationItem>
                {points.map((i) => {
                    return (
                        <PaginationItem key={i} className={`${currentPage === i && "active"}`}>
                            <PaginationLink onClick={() => onChangePage(i)}>
                                {i}
                            </PaginationLink>
                        </PaginationItem>
                    );
                })}
                <PaginationItem className={`${nextPageButtonClassName}`}>
                    <PaginationLink onClick={() => onChangePage(currentPage + 1)}>
                        <i className="fa fa-angle-right"></i>
                    </PaginationLink>
                </PaginationItem>
                {lastIsActive && (
                    <PaginationItem className={`${lastPageButtonClassName}`}><PaginationLink onClick={() => onChangePage(totalPage)}><i className="fa fa-angle-double-right"></i></PaginationLink></PaginationItem>
                )}
            </Pagination>
        </Fragment>
    ) : (
            <Fragment />
        );
}

export default PaginationDT;