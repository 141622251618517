import React, { useState, Fragment } from 'react';
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Button, Media, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt";
import moment from 'moment';
import 'moment/locale/pt';
import SweetAlert from 'sweetalert2';
import axios from 'axios';

import Breadcrumb from '../../../layout/breadcrumb';

import PaginationDT from '../../pagination';

import { getWithFilters } from "../../../api/";
import CustomForm from "../../form";
import { apiUrl, rqModel, pathname, schema } from "./settings";

import errorImg from '../../../assets/images/search-not-found.png';
import notFound from '../../../assets/images/not-found.webp';

registerLocale("pt", pt);

const PharmaRenting = () => {
    const history = useHistory();

    const user = useSelector((content) => content.User.user);

    const [currentRent, setRent] = useState(null);
    const [viewType, setViewType] = useState(null);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const getRentingProducts = (page, limit, startDate, endDate) => getWithFilters({ apiUrl: apiUrl, pagination: { page: page, limit: limit }, filters: { startDate: startDate, endDate: endDate } });

    const {
        isLoading,
        data,
        error
    } = useQuery([rqModel, page, limit, startDate, endDate], () => getRentingProducts(page, limit, startDate, endDate), { keepPreviousData: true, staleTime: 300000 });

    if (error && error.response) {
        history.push(`${process.env.PUBLIC_URL}/pages/errors/error${error.response.status}`);
    } else if (error) {
        history.push(`${process.env.PUBLIC_URL}/pages/errors/error500`);
    }

    const [open, setOpen] = useState(false);
    const [singleProduct, setSingleProduct] = useState(null);

    const [buyStartDate, setBuyStartDate] = useState(null);
    const [buyEndDate, setBuyEndDate] = useState(null);

    const toggleTable = () => {
        setViewType(null);
        setRent(null);
    }

    const createView = () => {
        setViewType("form");
        setRent(null);
        window.history.pushState(null, null, `${pathname}?view=create`);
    }

    const editView = (rent) => {
        setViewType("form");
        setRent(rent);
        window.history.pushState(null, null, `${pathname}?view=edit`);
    }

    const createSuccess = (data) => {
        toast.success(`Renting ${data.name} criado!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const editSuccess = (data) => {
        toast.success(`Renting ${data.name} editado!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const onOpenModal = (product) => {
        setOpen(true);

        const enumerateDaysBetweenDates = function(startDate, endDate) {
            var dates = [];

            while(startDate.format('DD/MM/YYYY') !== endDate.format('DD/MM/YYYY')) {
              dates.push(startDate.unix() * 1000);
              startDate = startDate.add(1, 'days');
            }
            dates.push(endDate.unix() * 1000);
            return dates;
        };

        var dates = [];
        product.slots.forEach((el) => {
            let lesDates = enumerateDaysBetweenDates(moment(el.s), moment(el.e));
            dates.push(...lesDates)
        })
       
        
        // function DisableDates(date) {
        //     var string = jQuery.datepicker.formatDate('dd/mm/yy', date);
        //     return [dates.indexOf(string) == -1];
        // }

        product.avoidDates = dates;
        setSingleProduct(product);

        // Reset Dates
        setBuyStartDate(null);
        setBuyEndDate(null);
    };

    const onCloseModal = () => {
        setOpen(false);
        setSingleProduct(null);

        // Reset Dates
        setBuyStartDate(null);
        setBuyEndDate(null);
    };

    const alugar = (product) => {
        SweetAlert.fire({
            title: 'Atenção!',
            text: 'Confirmação de aluguer de equipamento. Pretende usar o crédito disponível ?',
            type: 'warning',
            confirmButtonText: 'Sim',
            input: 'checkbox',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
        }).then(result => {
            if (result.dismiss) {
                return;
            }

            let submitValues = {
                s: moment(buyStartDate).format(),
                e: moment(buyEndDate).format(),
                useCredit: (result.value === 1)
            }


            axios.post(`/api/v1/renting_products/${product._id}/rent`, submitValues)
                .then((result) => {
                    toast.success(<><span>{"Adicionado"}</span><br /><span>{"Aluguer agendado"}</span></>, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                })
                .catch((error) => {
                    toast.error("Ocorreu um erro!", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });
        });
    }

    return (
        <Fragment>
            <Breadcrumb parent="Loja maisFarmácia" title="Renting" />
            <Container fluid={true} className="product-wrapper">
                {viewType === "form" ? (
                    <Card>
                        <CardBody>
                            <CustomForm data={currentRent} toggleTable={toggleTable} schema={schema} rqModel={rqModel} route={apiUrl} createSuccess={createSuccess} editSuccess={editSuccess} pathname={pathname} />
                        </CardBody>
                    </Card>
                ) : currentRent && viewType === "profile" ? (
                    <Card>
                        <CardBody>
                            <CustomForm data={currentRent} toggleTable={toggleTable} schema={schema} rqModel={rqModel} route={apiUrl} createSuccess={createSuccess} editSuccess={editSuccess} pathname={pathname} />
                        </CardBody>
                    </Card>
                ) : (
                    <div className="product-grid">
                        {/* <div className="feature-products">
                            <h4>Filtros</h4>
                            <Row className="mt-4">
                                <Col xl="4">
                                    <DatePicker
                                        style={{ height: 50 }}
                                        locale="pt"
                                        dateFormat="dd/MM/yyyy"
                                        className="form-control digits search-datepicker"
                                        selected={startDate ? moment(startDate)._d : moment()._d}
                                        onChange={(date) => {
                                            setStartDate(date)
                                        }}
                                    />
                                </Col>
                                <Col xl="4">
                                    <DatePicker
                                        style={{ height: 50 }}
                                        locale="pt"
                                        dateFormat="dd/MM/yyyy"
                                        className="form-control digits search-datepicker"
                                        selected={endDate ? moment(endDate)._d : endDate}
                                        onChange={(date) => {
                                            setEndDate(date)
                                        }}
                                    />
                                </Col>
                                <Col xl="4">
                                    <Button color="primary" style={{ height: 50 }} onClick={() => setSearch(!search)}>{"Procurar"}</Button>
                                </Col>
                            </Row>
                        </div> */}
                        {user.role === "AdminRole" && (
                            <div className="feature-products">
                                <Row className="justify-content-end mt-4">
                                    <Col xl="1" className="align-items-end">
                                        <Button color="primary" onClick={createView} style={{ float: "right" }}>{"Criar"}</Button>
                                    </Col>
                                </Row>
                            </div>
                        )}
                        <hr />
                        <div className="product-wrapper-grid">
                            {!isLoading && data.docs.length <= 0 && !error ?
                                <div className="search-not-found text-center">
                                    <div>
                                        <img className="img-fluid second-search" src={errorImg} alt="no-docs" />
                                        <p>{"Não foram encontrados resultados"}</p>
                                    </div>
                                </div>
                                :
                                <Row className="gridRow">
                                    {!isLoading ? data.docs.map((item, i) =>
                                        <div className={'col-xl-3 col-sm-6 xl-4 col-grid-box'} key={i}>
                                            <Card>
                                                <div className="product-box">
                                                    <div className="product-img">
                                                        <img className="img-fluid" style={{width: '100%' }} src={item.productId.photo ? item.productId.photo : notFound} alt={item.name} />
                                                        <div className="product-hover">
                                                            <ul>
                                                                {user.role === "AdminRole" && (
                                                                    <li>
                                                                        <Button color="default" data-toggle="modal"
                                                                            onClick={() => editView(item)}>
                                                                            <i className="icon-pencil"></i>
                                                                        </Button>
                                                                    </li>
                                                                )}
                                                                <li>
                                                                    <Button color="default" data-toggle="modal"
                                                                        onClick={() => onOpenModal(item)}>
                                                                        <i className="icon-eye"></i>
                                                                    </Button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="product-details">
                                                        <h4 onClick={() => onOpenModal(item)} className="font-primary" >{item.name} {`(${item.bulk} Unidade${item.bulk > 1 ? "s" : ""})`}</h4>
                                                        <div className="product-price">
                                                            {(item.productId.price * item.bulk).toFixed(2)} €/dia
                                                        </div>
                                                        <p>De: {moment(item.availabilityRange.start).format('DD/MM/YYYY')} - A: {moment(item.availabilityRange.end).format('DD/MM/YYYY')}</p>
                                                        <p>{item.description}</p>
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    ) : isLoading && (
                                        <div className="loader-box loader-abs">
                                            <div className="loader-7" />
                                        </div>
                                    )}

                                    {singleProduct && (
                                        <Modal className="modal-lg modal-dialog-centered product-modal" isOpen={open}>
                                            <ModalHeader toggle={onCloseModal}>
                                                {singleProduct.name}
                                            </ModalHeader>
                                            <ModalBody>
                                                <div className="product-box row">
                                                    <Col lg="6" className="product-img">
                                                        <Media className="img-fluid" src={singleProduct.photo ? singleProduct.photo : notFound} alt={singleProduct.name} />
                                                    </Col>
                                                    <Col lg="6" className="product-details text-left">
                                                        <div className="product-price">
                                                            {(singleProduct.productId.price * singleProduct.bulk).toFixed(2)} €/dia
                                                        </div>
                                                        <hr />
                                                        <div className="product-price">
                                                            <p>De: {moment(singleProduct.availabilityRange.start).format('DD/MM/YYYY')} <br /> A: {moment(singleProduct.availabilityRange.end).format('DD/MM/YYYY')}</p>
                                                        </div>
                                                        <hr />
                                                        <div className="product-view">
                                                            <h6 className="f-w-600">{"Detalhes"}</h6>
                                                            {/* <p className="mb-0">{singleProduct.description && singleProduct.description}</p> */}
                                                            <div dangerouslySetInnerHTML={{ __html: singleProduct.description }} />
                                                        </div>
                                                        <hr />
                                                        <Row>
                                                            <Col xl="6">
                                                                <DatePicker
                                                                    // style={{ height: 50 }}
                                                                    locale="pt"
                                                                    dateFormat="dd/MM/yyyy"
                                                                    className="form-control digits"
                                                                    selected={buyStartDate ? moment(buyStartDate)._d : buyStartDate}
                                                                    onChange={(date) => {
                                                                        setBuyStartDate(moment(date))
                                                                    }}
                                                                    excludeDates={singleProduct.avoidDates}
                                                                    minDate={singleProduct.availabilityRange ? moment(singleProduct.availabilityRange.start)._d : false}
                                                                    maxDate={singleProduct.availabilityRange ? moment(singleProduct.availabilityRange.end)._d : false}
                                                                    popperPlacement="bottom-end"
                                                                    popperProps={{
                                                                        positionFixed: true // use this to make the popper position: fixed
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col xl="6">
                                                                <DatePicker
                                                                    // style={{ height: 50 }}
                                                                    locale="pt"
                                                                    dateFormat="dd/MM/yyyy"
                                                                    className="form-control digits"
                                                                    selected={buyEndDate ? moment(buyEndDate)._d : buyEndDate}
                                                                    onChange={(date) => {
                                                                        setBuyEndDate(moment(date))
                                                                    }}
                                                                    excludeDates={singleProduct.avoidDates}
                                                                    minDate={singleProduct.availabilityRange ? moment(singleProduct.availabilityRange.start)._d : false}
                                                                    maxDate={singleProduct.availabilityRange ? moment(singleProduct.availabilityRange.end)._d : false}
                                                                    popperPlacement="bottom-end"
                                                                    popperProps={{
                                                                        positionFixed: true // use this to make the popper position: fixed
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <div className="product-qnty mt-4">
                                                            <div className="addcart-btn">
                                                                <Button color="primary" className="mr-2 mt-2" onClick={() => alugar(singleProduct)}>Alugar {`(${singleProduct.bulk} Unidade${singleProduct.bulk > 1 ? "s" : ""})`}</Button>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </div>
                                            </ModalBody>
                                        </Modal>
                                    )}
                                </Row>
                            }
                        </div>
                    </div>
                )}

                {!isLoading && data.docs.length > 0 && !error && (
                    <div className="mb-4">
                        <PaginationDT
                            centered
                            totalPage={data.totalPages}
                            currentPage={data.page}
                            limit={data.limit}
                            onChangePage={setPage}
                        />
                    </div>
                )}
            </Container>
        </Fragment>
    );
}

export default PharmaRenting;