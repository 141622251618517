import React, { Fragment, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Container, Row, Col, Card, CardHeader, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Form, FormGroup, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import DataTable from 'react-data-table-component';
import SweetAlert from 'sweetalert2';
import axios from 'axios';

import Breadcrumb from '../../../layout/breadcrumb';
import NoResults from '../../ui-kits/noResults';

import PaginationDT from '../../pagination';

import { useDelete } from '../../../hooks/';

import CustomForm from "../../form";
import Filepond from "../../filepond/";
import { getWithFilters } from "../../../api/";
import { apiUrl, rqModel, pathname, columns, schema } from "./settings";

const Suppliers = () => {
    const history = useHistory();

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [keyValue, setKeyValue] = useState('name');
    const [sortValue, setSortValue] = useState('asc');

    const [tempSearchKeyword, setTempSearchKeyword] = useState('');
    const [searchKeyword, setSearchKeyword] = useState('');

    const [currentLab, setLab] = useState(null);
    const [viewType, setViewType] = useState(null);
    const [supplierId, setSupplierId] = useState(null);

    const [files, setFiles] = useState([]); //* FILEPOND

    const [modal, setModal] = useState(false);
    const [currentLabId, setLabId] = useState(null);
    const [fileLoading, setFileLoading] = useState(false);
    const [fileError, setFileError] = useState(null);

    const deleteData = useDelete(apiUrl, rqModel, supplierId)

    const getLaboratories = (rqModel, page, limit, searchKeyword, keyValue, sortValue) => getWithFilters({ apiUrl: '/api/v1/laboratories/table', pagination: { page: page, limit: limit }, filters: { search: searchKeyword, sortBy: keyValue, sort: sortValue } });
    const {
        isLoading,
        data,
        error,
    } = useQuery([rqModel, page, limit, searchKeyword, keyValue, sortValue], () => getLaboratories(rqModel, page, limit, searchKeyword, keyValue, sortValue), { keepPreviousData: true, staleTime: 300000 });

    if (error && error.response) {
        history.push(`${process.env.PUBLIC_URL}/pages/errors/error${error.response.status}`);
    } else if (error) {
        history.push(`${process.env.PUBLIC_URL}/pages/errors/error500`);
    }

    const handleSearchKeyword = () => {
        if (tempSearchKeyword.length > 3) {
            setPage(1)
            setSearchKeyword(tempSearchKeyword)
        } else if (tempSearchKeyword.length <= 3) {
            setPage(1)
            setSearchKeyword('')
        }
    }

    const toggleTable = () => {
        setViewType(null);
        setLab(null);
    }

    const createView = () => {
        setViewType("form");
        setLab(null);
        window.history.pushState(null, null, `${pathname}?view=create`);
    }

    const editView = (lab) => {
        setViewType("form");
        setLab(lab);
        window.history.pushState(null, null, `${pathname}?view=edit`);
    }

    const createSuccess = (data) => {
        toast.success(`Fornecedor criado!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const editSuccess = (data) => {
        toast.success(`Fornecedor editado!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const toggleModal = (_id) => {
        setLabId(_id);
        setModal(true);
    }
    const closeModal = () => {
        setFiles([]); //* FILEPOND
        setModal(false);
    }
    const setCustomFiles = (files) => {
        setFiles(files) //* FILEPOND
    }

    const delegados = () => {
        setFileLoading(true);

        let submitValues = new FormData();
        submitValues.append('file[name]', files.name);
        submitValues.append('file', files);
        axios.post(`/api/v1/laboratories/${currentLabId}/import_representatives`, submitValues, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((result) => {
                if (result.status === 201) {
                    setFileLoading(false);
                    closeModal();
                } else {
                    setFileLoading(false);
                    setFileError("Ocorreu um erro, tente mais tarde.");
                    setTimeout(() => {
                        setFileError(null);
                    }, 3000);
                }
            })
            .catch((error) => {
                setFileLoading(false);
                setFileError("Ocorreu um erro, tente mais tarde.");
                setTimeout(() => {
                    setFileError(null);
                }, 3000);
            });
    }

    const deleteRequest = async (_id) => {
        await setSupplierId(_id);
        SweetAlert.fire({
            title: 'Atenção!',
            text: 'Confirma que pretende apagar o Fornecedor ?',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then(async (result) => {
                if (result.value) {
                    await deleteData.mutate();
                    if (deleteData.isIdle) {
                        toast.success("Fornecedor apagado!", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                }
            });
    }

    return (
        <Fragment>
            <Breadcrumb parent="Administração" title="Fornecedores" />
            <Container fluid={true}>
                {!isLoading ? (
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader>
                                    <span>{"Gestão de Fornecedores"}</span>
                                </CardHeader>
                                <CardBody>
                                    {viewType !== "form" && (
                                        <div className="feature-products">
                                            <Row>
                                                <Col md="6">
                                                    <Form onSubmit={(ev) => ev.preventDefault()}>
                                                        <FormGroup className="m-0">
                                                            <Input
                                                                className="form-control"
                                                                type="text"
                                                                placeholder="Procurar (mais de 3 caracteres)"
                                                                defaultValue={searchKeyword}
                                                                value={tempSearchKeyword}
                                                                onChange={(e) => {
                                                                    setTempSearchKeyword(e.target.value);
                                                                    if (e.target.value.length === 0) handleSearchKeyword();
                                                                }}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter') handleSearchKeyword();
                                                                }}
                                                            />
                                                            <Button color="primary" onClick={handleSearchKeyword}><i className="fa fa-search" /></Button>
                                                        </FormGroup>
                                                    </Form>
                                                </Col>
                                            </Row>
                                        </div>
                                    )}
                                    <div className="todo">
                                        <div className="todo-list-wrapper">
                                            <div className="todo-list-container">
                                                <div className="mark-all-tasks">
                                                    <div className="mark-all-tasks-container">
                                                        {viewType !== "form" && <Button color="primary" onClick={createView}>{"Criar"}</Button>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {viewType === "form" ? (
                                            <CustomForm data={currentLab} toggleTable={toggleTable} schema={schema} rqModel={"suppliers"} route={apiUrl} createSuccess={createSuccess} editSuccess={editSuccess} pathname={pathname} />
                                        ) : currentLab && viewType === "profile" ? (
                                            <CustomForm data={currentLab} toggleTable={toggleTable} schema={schema} rqModel={"suppliers"} route={apiUrl} createSuccess={createSuccess} editSuccess={editSuccess} pathname={pathname} />
                                        ) : (
                                            <div className="table-responsive product-table">
                                                <DataTable
                                                    noHeader
                                                    columns={columns}
                                                    defaultSortField="name"
                                                    defaultSortAsc={true}
                                                    data={data && data.results.map((lab) => ({
                                                        name: <NavLink to={`/administracao/fornecedor/${lab.fornecedor_id}`}>{lab.name}</NavLink>,
                                                        // name: lab.name,
                                                        fornecedor_id: `${lab.fornecedor_id}`,
                                                        partner_state: lab.partner_state === 'parceiro' ? (
                                                            <Badge color={"success"}>Parceiro</Badge>
                                                        ) : (
                                                            <Badge color={"light"}>Não Parceiro</Badge>
                                                        ),
                                                        action: <div>
                                                            <Button color="primary" size="xs" className="ml-2" onClick={() => deleteRequest(lab._id)}><i className="fa fa-trash"></i></Button>
                                                            <Button color="primary" size="xs" className="ml-2" onClick={() => editView(lab)}><i className="fa fa-pencil"></i></Button>
                                                            <Button color="primary" size="xs" className="ml-2" onClick={() => toggleModal(lab._id)}>Delegados</Button>
                                                        </div>
                                                    }))}
                                                    highlightOnHover={true}
                                                    pointerOnHover={true}
                                                    paginationPerPage={25}
                                                    pagination={true}
                                                    noDataComponent={<NoResults />}
                                                    paginationComponent={() => {
                                                        return (
                                                            <PaginationDT totalPage={data.totalPages} currentPage={data.page} limit={data.limit} onChangePage={setPage} />
                                                        )
                                                    }}
                                                    sortServer={true}
                                                    onSort={(column, value) => {
                                                        setKeyValue(column.selector)
                                                        setSortValue(value)
                                                        setPage(1)
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    <Row style={{ minHeight: 500 }}>
                        <Col sm="12">
                            <div className="loader-box loader-abs">
                                <div className="loader-7" />
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
            <Modal isOpen={modal} toggle={() => closeModal}>
                <ModalHeader toggle={() => closeModal}>
                    {"Upload de Ficheiro Delegados"}
                </ModalHeader>
                <ModalBody>
                    <Filepond
                        name={"file-representatives"}
                        label={"Carregar Ficheiro"}
                        multi={false}
                        files={files}
                        setFiles={setCustomFiles}
                        acceptedFileTypes={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv']}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={delegados}>
                        {fileLoading ? (
                            <i className="fa fa-spin fa-spinner" />
                        ) : fileError ? (
                            fileError
                        ) : (
                            "Selecionar"
                        )}
                    </Button>
                    <Button color="secondary" onClick={() => closeModal()}>
                        {"Fechar"}
                    </Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}

export default Suppliers
