import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux';
import InputRange from "react-input-range";
import { Container, Row, Col, ListGroup, ListGroupItem, Media, Label, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import SweetAlert from 'sweetalert2';
import Lightbox from "react-image-lightbox";
import Select from 'react-select';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/pt';

const Verification = ({ data, changeView, pathname }) => {
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [scoreRequestLoading, setScoreRequestLoading] = useState(false);
    const [scoreRequestMessage, setScoreRequestMessage] = useState(null);

    const pharmacies = useSelector(content => content.Pharmacies.results);

    const [selectedDate, setSelectedDate] = useState(null);
    const [dates, setDates] = useState([]);

    const [selectedPharma, selectPharma] = useState(null);
    const [availablePharmacies, setAvailablePharmacies] = useState([]);

    const [submissions, setSubmissions] = useState([]);
    const [score, setScore] = useState(0);

    const initilindex = { index: 0, isOpen: false };
    const [photoIndex, setPhotoIndex] = useState(initilindex);

    const onMovePrev = () => {
        const prev = (photoIndex.index + submissions.length - 1) % submissions.length
        setPhotoIndex({ ...photoIndex, index: prev })
    }

    const onMoveNext = () => {
        const next = (photoIndex.index + 1) % submissions.length
        setPhotoIndex({ ...photoIndex, index: next })
    }

    useEffect(() => {
        if (loading) {
            data.pharmaSubmission.reverse().forEach((sub) => {
                if (dates.indexOf(sub.dateRef) === -1) {
                    setDates([...dates, sub.dateRef])
                }
            });

            fillPharmacies();

            setLoading(false);
        }
    }, []) // eslint-disable-line

    const fillPharmacies = () => {
        data.pharmaSubmission.forEach(sub => {
            if (sub.dateRef === selectedDate) {
                const pharmacy = pharmacies.find((pharma) => {
                    if (pharma.ANF === sub.ANF) {
                        return pharma
                    } else {
                        return false
                    }
                });
                if (pharmacy) {
                    const found = availablePharmacies.find((pharma) => pharma.name === pharmacy.name);
                    if (!found) {
                        setAvailablePharmacies([...availablePharmacies, { ANF: sub.ANF, name: pharmacy.name }])
                    }
                }
            }
        });
    }

    const showSubmissions = () => {
        const thisPharmaSubmissions = data.pharmaSubmission.filter((subm) => subm.ANF === selectedPharma.ANF && subm.dateRef === selectedDate);
        setSubmissions(thisPharmaSubmissions);
    }

    const scoreUploads = () => {
        setScoreRequestLoading(true);

        let submitValues = {
            kpi: "categoryManagement",
            displayName: "Gestão de Categorias (GC)",
            type: (score === 0) ? "penalty" : "bonus",
            id: selectedPharma.ANF,
            scoreFactor: (score === 0) ? 100 : score,
            categoria_id: "a3",
            categoria_nome: "visibilidade"
        };

        SweetAlert.fire({
            title: 'Atenção!',
            text: 'Confirma que pretende pontuar?',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then((result) => {
                if (result.value) {
                    axios.post(`/api/v1/kpis`, submitValues)
                        .then((result) => {
                            setScoreRequestLoading(false);
                            toast.success("A Farmácia foi pontuado com sucesso", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            setTimeout(() => {
                                setScoreRequestMessage("Sucesso");
                            }, 3000);
                        })
                        .catch((error) => {
                            setScoreRequestLoading(false);
                            toast.error("Ocorreu um erro na pontuação", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            setTimeout(() => {
                                setScoreRequestMessage("Erro");
                            }, 3000);
                        });
                }
            })
    }

    history.listen((location => {
        if (history.action === "POP" && location.pathname === pathname) {
            changeView("", null)
        }
    }));

    return (
        <Fragment>
            <Container fluid={true}>
                {!loading ? (
                    <Row>
                        <Col sm="12">
                            <p>Na seguinte tabela são apresentadas as listas de fotografias tiradas. A pontuação atribuída deve ser sempre de 0 a 100, sendo que 100 atribui o valor máximo da pontuação e 0 o mínimo. Pontuar com 0 atribui a pontuação de "penalti" considerada para este KPI.</p>
                        </Col>
                        <Col sm="6" className="mt-4">
                            <h5 className="mb-4">{data.name + '   -   ' + data.type}</h5>
                            <Label>Periodo</Label>
                            <Select
                                placeholder="Selecionar ..."
                                className="basic-single mb-4"
                                id={"dates"}
                                classNamePrefix="select"
                                defaultValue={dates && dates[0]}
                                value={selectedDate && selectedDate}
                                isClearable
                                isSearchable
                                name={"dates"}
                                options={dates}
                                onChange={(fieldValue) => {
                                    setSelectedDate(fieldValue)
                                    fillPharmacies();
                                }}
                                getOptionLabel={(opt) => `${opt.split('_')[1]} Quinzena de ${moment(opt.split('_')[0], 'YYYYMM').format(' MMM YYYY')}`}
                                getOptionValue={(opt) => opt}
                            />
                            <Label>Farmácia</Label>
                            <Select
                                placeholder="Selecionar ..."
                                className="basic-single mb-4"
                                id={"pharmacy"}
                                classNamePrefix="select"
                                defaultValue={availablePharmacies && availablePharmacies[0]}
                                value={selectedPharma}
                                isClearable
                                isSearchable
                                name={"pharmacy"}
                                options={availablePharmacies}
                                onChange={(fieldValue) => {
                                    selectPharma(fieldValue)
                                }}
                                getOptionLabel={(opt) => opt.name}
                                getOptionValue={(opt) => opt.ANF}
                            />
                            <div className="mt-4">
                                <Button color="primary" type="submit" className="mr-1" onClick={showSubmissions} disabled={availablePharmacies.length === 0}>
                                    {availablePharmacies.length === 0 ? ("Não há farmácias com fotografias neste mês") : ("Ver")}
                                </Button>
                                <Button color="secondary" onClick={() => changeView("", null)}>{"Cancelar"}</Button>
                            </div>
                        </Col>
                        {submissions.length > 0 && (
                            <Col sm="6" className="mt-4">
                                <h5 className="mb-4">{selectedDate.split('_')[1] + "º"} Quinzena de {moment(selectedDate.split('_')[0], 'YYYYMM').format('MMMM YYYY')}</h5>
                                <ListGroup className="mb-4">
                                    {submissions.map((subm, index) => (
                                        <ListGroupItem className="list-group-item-action" key={index}>
                                            <Media
                                                body
                                                src={subm.photo}
                                                alt={index}
                                                onClick={() =>
                                                    setPhotoIndex({ ...photoIndex, index: index, isOpen: true })
                                                }
                                            />
                                            {pharmacies.find((pharma) => pharma.ANF === selectPharma.ANF) ? pharmacies.find((pharma) => pharma.ANF === selectPharma.ANF).name : "Farmácia inexistente"}
                                        </ListGroupItem>
                                    ))}
                                </ListGroup>
                                <div className="mt-4 pl-2 pr-2">
                                    <InputRange
                                        maxValue={100}
                                        minValue={0}
                                        value={score}
                                        onChange={(value) =>
                                            setScore(value)
                                        }
                                    />
                                </div>
                                <div className="mt-4">
                                    <Button color="primary" className="mr-1" disabled={scoreRequestLoading || scoreRequestMessage} onClick={scoreUploads}>
                                        {scoreRequestLoading ? <i className="fa fa-spinner fa-spin"></i> : scoreRequestMessage ? scoreRequestMessage : "Pontuar"}
                                    </Button>
                                </div>
                            </Col>
                        )}
                    </Row>
                ) : (
                    <Row style={{ minHeight: 425 }}>
                        <Col sm="12">
                            <div className="loader-box loader-abs">
                                <div className="loader-7" />
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
            {photoIndex.isOpen && submissions.length > 0 && (
                <Lightbox
                    mainSrc={submissions[photoIndex.index].photo}
                    nextSrc={submissions[(photoIndex.index + 1) % submissions.length]}
                    prevSrc={submissions[(photoIndex.index + submissions.length - 1) % submissions.length]}
                    imageTitle={photoIndex.index + 1 + "/" + submissions.length}
                    onCloseRequest={() => setPhotoIndex({ ...photoIndex, isOpen: false })}
                    onMovePrevRequest={onMovePrev}
                    onMoveNextRequest={onMoveNext}
                />
            )}
        </Fragment>
    );
};

export default Verification;