import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Table, Row, Col, Label, Input } from 'reactstrap';
import moment from 'moment';
import 'moment/locale/pt';
import axios from 'axios';
import Select from 'react-select';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import { getWithFilters } from "../../../api/";
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';


const ProductsTable = ({ isOpen, toggle, order, role, setCurrentOrder, refetch }) => {
    const user = useSelector(content => content.User.user);

    const [cancelReason, setCancelReason] = useState("");
    const [newProd, setNewProd] = useState(null);
    const [newProdQt, setNewProdQt] = useState(0);

    const page = 1;
    const limit = 1000
    const searchKeyword = '';
    const laboratory = null;
    const category = null;

    const getProducts = (page, limit, searchKeyword, laboratory) => getWithFilters({ apiUrl: '/api/v2/store_products', pagination: { page, limit }, filters: { search: searchKeyword, laboratory: laboratory, category: null } });

     const {
        isLoading,
        data,
        error
    } = useQuery([page, limit, searchKeyword, laboratory, category], () => getProducts(page, limit, searchKeyword, laboratory, category), { keepPreviousData: true, staleTime: 300000 });

    if(data) {
        data.results.forEach(el => {
            if(el.groupedItems?.length) {
                el.groupedPrice = el.groupedItems.reduce((accumulator, object) => {
                    return accumulator + object.price;
                  }, 0);
            }
        })
    }

    const addNewProduct = () => {
        console.log(newProd, newProdQt)
        axios.post(`/api/v1/orders/${order._id}/add_item`, {
            quantity: newProdQt,
            itemId: newProd
        })
        .then((result) => {
            const itemsCopy = [...order.items, result.data.newItem];
            setCurrentOrder({ ...order, items: itemsCopy });

            toast.success("Alteração Efectuada", {
                position: toast.POSITION.TOP_RIGHT
            });
        })
    }
    const editQuantityOrderRow = (item) => {
        axios.patch(`/api/v1/orders/${order._id}/items/${item._id}`, {
            quantity: 1,
        })
            .then((result) => {


                // const newItems = order.items.filter((i) => i._id !== item._id);
                // const itemsCopy = [...newItems];

                setCurrentOrder({ ...order, items: order.items.push(result.newItem) });
                // refetch();

                // toast.success("Anulação Efectuada", {
                //     position: toast.POSITION.TOP_RIGHT
                // });
            })
            .catch((error) => {
                toast.error("Erro não identificado", {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
        
            
    }

    const removeOrderRow = (item) => {
        SweetAlert.fire({
            title: 'Confirmação ?',
            text: 'Deseja anular esta Linha ?',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then((result) => {
                if (result.value) {
                    axios.delete(`/api/v1/orders/${order._id}/items/${item._id}`)
                        .then((result) => {
                            // ! TEMP
                            window.location.reload();

                            // const newItems = order.items.filter((i) => i._id !== item._id);
                            // const itemsCopy = [...newItems];

                            // setCurrentOrder({ ...order, items: itemsCopy });
                            // refetch();

                            // toast.success("Anulação Efectuada", {
                            //     position: toast.POSITION.TOP_RIGHT
                            // });
                        })
                        .catch((error) => {
                            toast.error("Erro não identificado", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        });
                }
            })
    }
    const applyCredits = () => {
        SweetAlert.fire({
            title: 'Confirmação ?',
            text: "Deseja aplicar os créditos, se disponíveis, da farmácia?",
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then((result) => {
                if (result.value) {
                    axios.post(`/api/v1/orders/${order._id}/apply_credit`)
                        .then((result) => {
                            toggle(false);
                            refetch();
                            if(result.data.credits === 0) {
                                toast.warn(`Não existem créditos a abater na farmácia`, {
                                    position: toast.POSITION.TOP_RIGHT
                                });
                            } else {
                                toast.success(`Foram aplicados ${result.data.credits.toFixed(2)} € da farmácia ${result.data.phName}, na encomenda ${result.data.orderNo}`, {
                                    position: toast.POSITION.TOP_RIGHT
                                });
                            }
                            
                        })
                        .catch((error) => {
                            toast.error("Erro não identificado", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        });
                }
            })
    }
    const cancelThisOrder = () => {
        SweetAlert.fire({
            title: 'Confirmação ?',
            text: "Deseja anular esta encomenda?",
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then((result) => {
                if (result.value) {
                    axios.put(`/api/v1/orders/${order._id}/set_cancelled`, { status: 'cancelled', cancelReason: cancelReason })
                        .then((result) => {
                            toggle(false);
                            refetch();
                            toast.success("Anulação Efectuada", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        })
                        .catch((error) => {
                            toast.error("Erro não identificado", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        });
                }
            })
    }

    return (
        <Modal isOpen={isOpen} toggleModal={() => toggle(!isOpen)} size="lg" style={{ maxWidth: 1185 }}>
            <ModalHeader toggleModal={() => toggle(!isOpen)} style={{ borderBottom: 0 }}>
                {`Encomenda Numero #${order.orderNumber} - ${moment(order.createdOn).format('LLL')}`}
                <br />
                {order.pharmName}
                {order.obs && (
                    <>
                        <p><strong>Observações</strong></p>
                        {order.obs}
                    </>
                )}
            </ModalHeader>
            <ModalBody>
                <Table>
                    <thead>
                        <tr>
                            <th className="text-center">{"Nome"}</th>
                            <th className="text-center">{"Código"}</th>
                            <th className="text-center">{"Fornecedor"}</th>
                            <th className="text-center">{"Quantidade"}</th>
                            <th className="text-center">{"Preço"}</th>
                            <th className="text-center">{"Total"}</th>
                            { user && user.role === "AdminRole" && (<th className="text-center">{"Opt"}</th>)}
                        </tr>
                    </thead>
                    <tfoot>
                        <tr>
                            <th className="text-center">Total</th>
                            <th className="text-center"></th>
                            <th className="text-center"></th>
                            <th className="text-center"></th>
                            <th className="text-center"></th>
                            <th className="text-center">{(getSum(order.items) - order.usedCredit).toFixed(2)} €</th>
                            { user && user.role === "AdminRole" && (<th className="text-center"></th>)}
                        </tr>
                    </tfoot>
                    <tbody>
                        {order.items.map((thisItem, index) => {
                            return thisItem && (
                                <tr key={index}>
                                    <th className="text-center">{thisItem.name}</th>
                                    <th className="text-center">{thisItem.productId?.code}</th>
                                    <td className="text-center">{thisItem.seller ? thisItem.seller.name : 'Fornecedor em Falta'}</td>
                                    <td className="text-center">{thisItem.quantity}</td>
                                    {/* <td className="text-center"><Input className="form-control" type="number" min="1" step="1" id="123" name="rowinput" defaultValue={thisItem.quantity}/></td> */}
                                    <td className="text-center">{thisItem.price.toFixed(2)} €</td>
                                    <td className="text-center">{(thisItem.price * thisItem.quantity).toFixed(2)} €</td>
                                    <td className="text-center">
                                        {/* {<Button color="primary" size="xs" className="ml-2" onClick={() => editQuantityOrderRow(thisItem)}><i className="fa fa-pencil"></i></Button>} */}
                                        {order.items.length > 1 && user && user.role === "AdminRole" && <Button color="primary" size="xs" className="ml-2" onClick={() => removeOrderRow(thisItem)}><i className="fa fa-trash"></i></Button>}
                                    </td>
                                </tr>
                            )
                        })}
                        <th className="text-center">Crédito Usado</th>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <th className="text-center">{order.usedCredit.toFixed(2)} €</th>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                    </tbody>
                </Table>
                <Row className="mt-4">
                    {order.usedCredit === 0 && user && user.role === "AdminRole" ? (
                        <>
                        <Col sm={"12"}>
                            <Button color="primary" size="md" className="ml-2" style={{ marginTop: 0, marginBottom: 20, height: 38 }} onClick={() => applyCredits()}>Abater Créditos em Loja</Button>
                        </Col>
                    </> )  : null
                    }
                    {role === 'AdminRole' && order.status !== 'cancelled' ? (
                        <>
                            <Col sm={"8"}>
                                <Label>Motivo da Anulação</Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="cancel-reason"
                                    onChange={((ev) => setCancelReason(ev.target.value))}
                                />
                            </Col>
                            <Col sm={"4"}>
                                <Button color="primary" size="md" className="ml-2" style={{ marginTop: 29, height: 38 }} onClick={() => cancelThisOrder()}>Anular Encomenda</Button>
                            </Col>
                        </>
                    ) : order.status === 'cancelled' ? (
                        <Col sm={"8"}>
                            <h4 className="f-w-600">Estado: Anulado</h4>
                            <p>{order.cancelReason && order.cancelReason}</p>
                        </Col>
                    ) : order.internalStatus === 'inSupplier' && (
                        <Col sm={"8"}>
                            <h5 className="f-w-600">Em processamento pelos fornecedores</h5>
                            <p>{order.inSupplier}</p>
                        </Col>
                    )}

                    { !isLoading && user && user.role === "AdminRole" && (
                        <Col sm={"12"} >
                            <hr/>
                            <Row className="mt-4" style={{marginTop: 20}}>
                        <Col sm={"6"} >
                            <Label>Adicionar Artigo</Label>
                            <Select
                                placeholder="Selecionar ..."
                                className="basic-single"
                                id=""
                                classNamePrefix="select"
                                // defaultValue={getDefaultValue(field)}
                                isMulti={false}
                                isDisabled={false}
                                isClearable
                                isSearchable
                                name=""
                                options={data.results.map(el => el)}
                                onChange={(values, input) => {
                                    setNewProd(values._id);
                                }}
                                getOptionLabel={(opt) => opt.name}
                                getOptionValue={(opt) => opt._id}
                            />
                        </Col>
                        <Col sm={"2"}>
                        <Label>Quantidade</Label>
                            <Input
                                type="number"
                                className="form-control"
                                name="cancel-reason"
                                onChange={event => {
                                    setNewProdQt(event.target.value);
                                }}
                                defaultValue={newProdQt}
                            />
                        </Col>
                        <Col sm={"4"}>
                            <Button color="primary" size="md" className="ml-2" style={{ marginTop: 29, height: 38 }} onClick={() => addNewProduct()}>Adicionar</Button>
                        </Col>
                    </Row>
                    </Col>)}
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={() => toggle(!isOpen)}>
                    {"Fechar"}
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default ProductsTable;

const getSum = (items) => {
    let thisSum = 0;

    items.forEach((thisItem) => {
        thisSum += (thisItem.price * thisItem.quantity)
    });

    return thisSum
}