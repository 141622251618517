import React, { Fragment, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom'
import { Container, Row, Col, Card, CardHeader, CardBody, Button, Badge, Form, FormGroup, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import SweetAlert from 'sweetalert2';
import axios from 'axios';

import Breadcrumb from '../../../layout/breadcrumb';
import NoResults from '../../ui-kits/noResults';

import PaginationDT from '../../pagination';

import { useDelete } from '../../../hooks/';

import getFinalRole from '../../../data/getFinalRole';

import CustomForm from "../../form";
import { getWithFilters } from "../../../api/";
import { apiUrl, rqModel, pathname, columns, schema } from "./settings";
import { schema as adminSchema } from "./admin_addable_schema";
import { schema as pharmaSchema } from "./pharma_addable_schema";
import { schema as fornecedorSchema } from "./fornecedor_addable_schema";

const Users = () => {
    const history = useHistory();

    const [currentSchema, setCurrentSchema] = useState(schema);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [keyValue, setKeyValue] = useState('username');
    const [sortValue, setSortValue] = useState('asc');

    const [tempSearchKeyword, setTempSearchKeyword] = useState('');
    const [searchKeyword, setSearchKeyword] = useState('');

    const [currentUser, setUser] = useState(null);
    const [viewType, setViewType] = useState(null);
    const [userId, setUserId] = useState(null);

    const role = useSelector(content => content.User.user.role);
    const deleteData = useDelete(apiUrl, rqModel, userId);

    if (role === 'AdminRole' && columns.length === 4) {
        columns.push({
            name: "Opções",
            selector: "action",
            sortable: false,
            center: true,
            grow: 2,
        })
    };

    const getUsers = (page, limit, searchKeyword, keyValue, sortValue) => getWithFilters({ apiUrl: '/api/v1/accounts/table', pagination: { page: page, limit: limit }, filters: { search: searchKeyword, sortBy: keyValue, sort: sortValue } });
    const {
        isLoading,
        data,
        error,
    } = useQuery([rqModel, page, limit, searchKeyword, keyValue, sortValue], () => getUsers(page, limit, searchKeyword, keyValue, sortValue), { keepPreviousData: true, staleTime: 300000 });

    if (error && error.response) {
        history.push(`${process.env.PUBLIC_URL}/pages/errors/error${error.response.status}`);
    } else if (error) {
        history.push(`${process.env.PUBLIC_URL}/pages/errors/error500`);
    }

    const handleSearchKeyword = () => {
        if (tempSearchKeyword.length > 3) {
            setPage(1)
            setSearchKeyword(tempSearchKeyword)
        } else if (tempSearchKeyword.length <= 3) {
            setPage(1)
            setSearchKeyword('')
        }
    }

    const toggleTable = () => {
        setViewType(null);
        setUser(null);
    }

    const createView = (type) => {
        const i = 7; // Merge in the i position of main schema
        if (type === 'admin') {
            schema.find(el => el.id === 'role').defaultValue = 'AdminRole'
            setCurrentSchema(schema.slice(0, i).concat(adminSchema, schema.slice(i)));
        }

        if (type === 'pharma') {
            schema.find(el => el.id === 'role').defaultValue = 'FarmaciaRole'
            setCurrentSchema(schema.slice(0, i).concat(pharmaSchema, schema.slice(i)));
        }

        if (type === 'fornecedor') {
            schema.find(el => el.id === 'role').defaultValue = 'FornecedorRole'
            setCurrentSchema(schema.slice(0, i).concat(fornecedorSchema, schema.slice(i)));
        }


        setViewType("form");
        setUser(null);
       
        window.history.pushState(null, null, `${pathname}?view=create`);
    }

    const editView = (user) => {
        setViewType("form");
        // Sets the permissions to a default of true
        if(user.role === 'FarmaciaRole' && !user.permissions ) {
            user.permissions = {
                has_analytics_btn : true,
                pharmas_data : true,
                reads_inqueritos : true
            }
        }
        setUser(user);
        window.history.pushState(null, null, `${pathname}?view=edit`);
    }

    const createSuccess = (data) => {
        toast.success(`Utilizador ${data.name.first} ${data.name.last} criado!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const editSuccess = (data) => {
        toast.success(`Utilizador ${data.name.first} ${data.name.last} editado!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const deleteRequest = async (_id) => {
        await setUserId(_id);
        SweetAlert.fire({
            title: 'Atenção!',
            text: 'Confirma que pretende apagar o Utilizador?',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then(async (result) => {
                if (result.value) {
                    await deleteData.mutate();
                    if (deleteData.isIdle) {
                        toast.success("User apagado!", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                }
            })
    }

    const activateQlik = (id) => {
        SweetAlert.fire({
            title: 'Atenção!',
            text: 'Confirma que pretende renovar a tabela Qlik?',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then((result) => {
                if (result.value) {
                    axios.get(`/api/v1/laboratories/${id}/refresh-sql`)
                        .then((result) => {
                            toast.success("Tabela Renovada", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        })
                        .catch((error) => {
                            toast.error("Cancelado", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        });
                }
            })
    }

    const impersonate = (user) => {
        SweetAlert.fire({
            title: 'Atenção!',
            text: `Confirma que pretende activar modo impersonate para ${user.name.first} ${user.name.last} ?`,
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then((result) => {
                if (result.value) {
                    axios.post(`/api/v1/accounts/${user._id}/impersonate-qlik`, {
                        impersonator: user.username
                    })
                        .then((result) => {
                            toast.success("Terá fazer logout e novo login para restabelecer sessão como administrador", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        })
                        .catch((error) => {
                            toast.error("Cancelado", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        });
                }
            })
    }

    return (
        <Fragment>
            <Breadcrumb parent="Administração" title="Utilizadores" />
            <Container fluid={true}>
                {!isLoading ? (
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader>
                                    <span>{"Gestão de Utilizador"}</span>
                                </CardHeader>
                                <CardBody>
                                    {viewType !== "form" && (
                                        <div className="feature-products">
                                            <Row>
                                                <Col md="6">
                                                    <Form onSubmit={(ev) => ev.preventDefault()}>
                                                        <FormGroup className="m-0">
                                                            <Input
                                                                className="form-control"
                                                                type="text"
                                                                placeholder="Procurar (mais de 3 caracteres)"
                                                                defaultValue={searchKeyword}
                                                                value={tempSearchKeyword}
                                                                onChange={(e) => {
                                                                    setTempSearchKeyword(e.target.value);
                                                                    if (e.target.value.length === 0) handleSearchKeyword();
                                                                }}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter') handleSearchKeyword();
                                                                }}
                                                            />
                                                            <Button color="primary" onClick={handleSearchKeyword}><i className="fa fa-search" /></Button>
                                                            <i className="fa fa-search"></i>
                                                        </FormGroup>
                                                    </Form>
                                                </Col>
                                            </Row>
                                        </div>
                                    )}
                                    <div className="todo">
                                        <div className="todo-list-wrapper">
                                            <div className="todo-list-container">
                                                <div className="mark-all-tasks">
                                                    <div className="mark-all-tasks-container">
                                                        {viewType !== "form" && <Button style={{marginRight: 10 }} color="primary" onClick={() => createView('admin')}>{"Criar Administrador"}</Button>}
                                                        {viewType !== "form" && <Button style={{marginRight: 10 }}color="primary" onClick={() => createView('pharma')}>{"Criar Farmácia"}</Button>}
                                                        {viewType !== "form" && <Button color="primary" onClick={() => createView('fornecedor')}>{"Criar Fornecedor"}</Button>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {viewType === "form" ? (
                                            <CustomForm data={currentUser} toggleTable={toggleTable} schema={currentSchema} rqModel={rqModel} route={apiUrl} createSuccess={createSuccess} editSuccess={editSuccess} pathname={pathname} />
                                        ) : currentUser && viewType === "profile" ? (
                                            <CustomForm data={currentUser} toggleTable={toggleTable} schema={schema} rqModel={rqModel} route={apiUrl} createSuccess={createSuccess} editSuccess={editSuccess} pathname={pathname} />
                                        ) : (
                                            <div className="table-responsive product-table">
                                                <DataTable
                                                    noHeader
                                                    columns={columns}
                                                    defaultSortField="username"
                                                    defaultSortAsc={true}
                                                    data={ data && data.results.map((user) => {
                                                        let returningData =  {
                                                            username: <><NavLink to={`${process.env.PUBLIC_URL}/utilizador/${user._id}`}>{user.username}</NavLink> {user.profile === "representative" && <Badge color={"primary"}>{"DIM"}</Badge>}</>,
                                                            role: <Badge color={getFinalRole(user) ? getFinalRole(user).color : "primary"}>{getFinalRole(user) ? getFinalRole(user)._mainRole : "Não Definido"}</Badge>,
                                                            name: `${user.name.first} ${user.name.last}`,
                                                            email: `${user.email}`,
                                                           
                                                        }
                                                        if (role === 'AdminRole') {
                                                            returningData = {
                                                                ...returningData,
                                                                action: <div>
                                                                    <Button color="primary" size="xs" className="ml-2" onClick={() => deleteRequest(user._id)}><i className="fa fa-trash"></i></Button>
                                                                    {/* <Button color="primary" size="xs" className="ml-2" onClick={() => history.push(`${process.env.PUBLIC_URL}/utilizador/${user._id}`)}><i className="fa fa-eye"></i></Button> */}
                                                                    <Button color="primary" size="xs" className="ml-2" onClick={() => editView(user)}><i className="fa fa-pencil"></i></Button>
                                                                    <Button color="primary" size="xs" className="ml-2" onClick={() => activateQlik(user._id)}>Ativar Qlik</Button>
                                                                    <Button color="primary" size="xs" className="ml-2" onClick={() => impersonate(user)}>Impersonate</Button>
                                                                </div>
                                                            }
                                                        }
                                                        return returningData;
                                                    })}
                                                    highlightOnHover={true}
                                                    pointerOnHover={true}
                                                    pagination={true}
                                                    paginationPerPage={25}
                                                    noDataComponent={<NoResults />}
                                                    paginationComponent={() => {
                                                        return (
                                                            <PaginationDT totalPage={data.totalPages} currentPage={data.page} limit={data.limit} onChangePage={setPage} />
                                                        )
                                                    }}
                                                    sortServer={true}
                                                    onSort={(column, value) => {
                                                        setKeyValue(column.selector)
                                                        setSortValue(value)
                                                        setPage(1)
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    <Row style={{ minHeight: 500 }}>
                        <Col sm="12">
                            <div className="loader-box loader-abs">
                                <div className="loader-7" />
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
        </Fragment>
    )
}

export default Users