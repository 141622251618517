import React, { Fragment, useState } from 'react';
import { Container, Row, Col, Form, Label, Button } from 'reactstrap'
import { useForm } from 'react-hook-form'
import axios from 'axios';

import Filepond from "../../filepond/index"

import { Submit, Cancel } from "../../../constant";

const Upload = ({ toggleTable }) => {
    const { handleSubmit } = useForm({});

    const [loading, setLoading] = useState(false);
    const [requestMessage, setRequestMessage] = useState(null);

    const [showcaseFile, setShowcaseFile] = useState([]);
    const [exhibitorFile, setExhibitorFile] = useState([]);

    const onSubmit = async () => {
        let showcaseRequest;
        let exhibitorRequest;

        setLoading(true);

        //* SHOWCASE
        let showcaseValues = new FormData();
        showcaseValues.append('photo', showcaseFile);

        showcaseRequest = await axios.post(`/api/v1/upload/showcase`, showcaseValues, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((result) => {
                return result
            })
            .catch((error) => {
                return error
            });


        //* EXHIBITOR
        let exhibitorValues = new FormData();
        exhibitorValues.append('photo', exhibitorFile);

        exhibitorRequest = await axios.post(`/api/v1/upload/exhibitor`, exhibitorValues, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((result) => {
                return result
            })
            .catch((error) => {
                return error
            });


        //!TEMP - ERROR HANDLING
        if (showcaseRequest && exhibitorRequest) {
            setLoading(false);
            if (showcaseRequest.status && exhibitorRequest.status) {
                setRequestMessage("Nova montra e expositor guardados!")
                setTimeout(() => {
                    setRequestMessage(null);
                    toggleTable();
                }, 1500);
            } else {
                setRequestMessage("Ocorreu um erro!")

                setTimeout(() => {
                    setRequestMessage(null);
                }, 1500);
            }
        }
        //!TEMP - ERROR HANDLING
    }

    const setCustomFiles = (files, type) => {
        if (type === "showcase") {
            setShowcaseFile(files) //* FILEPOND
        } else {
            setExhibitorFile(files) //* FILEPOND
        }
    }

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Form className="theme-form needs-validation" onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-row">
                                <Col md={`12 mb-3`}>
                                    <h6>Montra Atual</h6>
                                </Col>
                                <Col md={`12 mb-3`}>
                                    <img src="/storage/images/showcase.jpg" className="wd-100p wd-sm-200 rounded mg-sm-r-20 mg-b-20 mg-sm-b-0 img-fluid" alt="montra-atual" />
                                </Col>
                                <Col md={`12 mb-3`}>
                                    <Label className="col-form-label pt-0">{"Nova Montra"}</Label>
                                    <Filepond
                                        name={"montra"}
                                        label={"Carregar Imagem"}
                                        files={showcaseFile}
                                        setFiles={(files) => setCustomFiles(files, "showcase")}
                                        content={`image`}
                                    />
                                </Col>
                                <Col md={`12 mb-3`} >
                                    <hr />
                                </Col>
                                <Col md={`12 mb-3`}>
                                    <h6>Expositor Atual</h6>
                                </Col>
                                <Col md={`12 mb-3`}>
                                    <img src="/storage/images/exhibitor.jpg" className="wd-100p wd-sm-200 rounded mg-sm-r-20 mg-b-20 mg-sm-b-0 img-fluid" alt="expositor-atual" />
                                </Col>
                                <Col md={`12 mb-3`}>
                                    <Label className="col-form-label pt-0">{"Novo Expositor"}</Label>
                                    <Filepond
                                        name={"expositor"}
                                        label={"Carregar Imagem"}
                                        files={exhibitorFile}
                                        setFiles={(files) => setCustomFiles(files, "exhibitor")}
                                        content={`image`}
                                    />
                                </Col>
                            </div>
                            <div className="button-wrapper mt-4">
                                <Button
                                    color="primary" type="submit" className="mr-1"
                                    disabled={loading || requestMessage}
                                >
                                    {loading ? (
                                        <i className="fa fa-spin fa-spinner" />
                                    ) : requestMessage ? (
                                        requestMessage
                                    ) : (
                                        Submit
                                    )}
                                </Button>
                                <Button color="secondary" onClick={toggleTable}>{Cancel}</Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </Fragment >
    );
};

export default Upload;