import React, { Fragment } from 'react';
import { Container } from 'reactstrap';

import Breadcrumb from '../../../layout/breadcrumb';

import SalesByPharma from './byPharma';
import SalesBySupplier from './bySupplier';

import { apiUrlPharma, rqModelPharma, columnsByPharma, apiUrlSupplier, rqModelSupplier, columnsBySupplier } from "./settings";

const Statistics = () => {
    return (
        <Fragment>
            <Breadcrumb parent="Loja" title="Estatisticas" />
            <Container fluid={true}>
                <SalesByPharma apiUrl={apiUrlPharma} rqModel={rqModelPharma} columns={columnsByPharma} />
                <SalesBySupplier apiUrl={apiUrlSupplier} rqModel={rqModelSupplier} columns={columnsBySupplier} />
            </Container>
        </Fragment>
    )
}

export default Statistics
