import React, { useState, Fragment } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import SweetAlert from 'sweetalert2';

import { ToDo } from '../../../../constant';

import { useGetOne, usePost, usePatch, useDelete } from '../../../../hooks/';

const TodoTab = ({ data }) => {
    const todosQuery = useGetOne(`/api/v1/pharma/${data.ANF}/todo`, data.ANF, 'to_do');

    const [submitValues, setSubmitValues] = useState({});
    const createData = usePost(`/api/v1/pharma/${data.ANF}/todo`, 'to_do', submitValues);

    const [todoId, setToDoId] = useState(null);
    const deleteData = useDelete(`/api/v1/pharma/${data.ANF}/todo`, 'to_do', todoId)

    const [patchSubmitValues, setPatchSubmitValues] = useState({});
    const patchData = usePatch(`/api/v1/pharma/${data.ANF}/todo`, 'to_do', patchSubmitValues);

    const [addTask, setAddTask] = useState('');
    const [task, setTask] = useState('');

    const addNewTask = async () => {
        if (task === '') {
            document.querySelector('.ng-valid').classList.remove('border-danger')
            document.querySelector('.ng-valid').classList.add('border-danger')
        } else {
            let todo = { title: task.task };
            await setSubmitValues(todo);
            await createData.mutateAsync();
            setAddTask('');
            setTask('');
            todosQuery.refetch();

            document.getElementById('newtask').value = '';
            document.querySelector('.ng-valid').classList.add('taskmag-hide')
            document.querySelector('.ng-valid').classList.remove('taskmag-show')
            document.querySelector('.add-task-btn').classList.remove('hide')
        }
    }

    const handleRemoveTodo = async (todoId) => {
        await setToDoId(todoId);
        SweetAlert.fire({
            title: 'Atenção!',
            text: 'Confirma que pretende apagar a Tarefa ?',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then(async (result) => {
                if (result.value) {
                    await deleteData.mutateAsync();
                    todosQuery.data = todosQuery.data.filter((todo) => todo._id !== todoId);
                    if (deleteData.isIdle) {
                        toast.success("Tarefa Apagada !");
                    }
                }
            });
    }

    const handleMarkedTodo = async (itemId, completed) => {
        await setToDoId(itemId);
        if (completed) {
            await setPatchSubmitValues({ _id: itemId, completed: true });
            await patchData.mutateAsync();
            if (patchData.isIdle) {
                toast.success("Tarefa Completada !");
                patchData.reset();
                todosQuery.refetch();
            }
        } else if (!completed) {
            await setPatchSubmitValues({ _id: itemId, completed: false });
            await patchData.mutateAsync();
            if (patchData.isIdle) {
                toast.success("Tarefa Incompleta !");
                patchData.reset();
                todosQuery.refetch();
            }
        }
    }

    const openTaskWrapper = () => {
        setAddTask(' visible')
        document.querySelector('.add-task-btn').classList.add('hide')
    }

    const closeTaskWrapper = () => {
        setAddTask('')
        document.querySelector('.add-task-btn').classList.remove('hide')
    }

    const onTaskChanged = (e) => {
        setTask({
            task: e.currentTarget.value
        });
    }

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col xl="12">
                        <Card>
                            <CardHeader className="reduce-padding">
                                <h5>{ToDo}</h5>
                            </CardHeader>
                            <CardBody>
                                <div className="todo">
                                    <div className="todo-list-wrapper">
                                        <div className="todo-list-container">
                                            {/* <div className="mark-all-tasks">
                                                <div className="mark-all-tasks-container">
                                                    <span className="mark-all-btn" id="mark-all-finished" role="button">
                                                        <span className="btn-label">{"Mark all as finished"}</span>
                                                        <span className="action-box completed" onClick={markAllStatus}>
                                                            {markAll &&
                                                                <i className="icon"><i className="icon-check"></i></i>}
                                                        </span>
                                                    </span>
                                                    <span className="mark-all-btn move-down" id="mark-all-incomplete" role="button">
                                                        <span className="btn-label">{"Mark all as Incomplete"}</span>
                                                        <span className="action-box"><i className="icon"><i className="icon-check"></i></i>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div> */}
                                            <div className="todo-list-body">
                                                <ul id="todo-list">
                                                    {todosQuery.data && todosQuery.data && todosQuery.data.length > 0 ?
                                                        todosQuery.data.map((todo, index) =>
                                                            <li className={`task ${todo.completed ? "completed" : ""}`} key={index} >
                                                                <div className="task-container">
                                                                    <h4 className="task-label">{todo.title}</h4>
                                                                    <span className="task-action-btn">
                                                                        <span className="action-box large delete-btn" title="Delete Task" onClick={() => handleRemoveTodo(todo._id)} >
                                                                            <i className="icon"><i className="icon-trash"></i></i></span>
                                                                        <span className="action-box large complete-btn" title="Mark Complete" onClick={() => handleMarkedTodo(todo._id, !todo.completed)} >
                                                                            <i className="icon"><i className="icon-check"></i></i>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </li>
                                                        ) : ''}
                                                </ul>
                                            </div>
                                            <div className="todo-list-footer">
                                                <div className="add-task-btn-wrapper"><span className="add-task-btn">
                                                    <Button color="primary" onClick={openTaskWrapper}>
                                                        <i className="icon-plus"></i> {"Adicionar nova tarefa"}</Button></span>
                                                </div>
                                                <div className={"new-task-wrapper" + addTask}>
                                                    <textarea
                                                        className={"ng-untouched ng-pristine ng-valid"}
                                                        id="newtask"
                                                        placeholder="Descreva a tarefa..."
                                                        defaultValue={task}
                                                        onChange={onTaskChanged}
                                                    >
                                                    </textarea>
                                                    <Button color="danger" className="cancel-btn" id="close-task-panel" onClick={closeTaskWrapper}>{"Cancelar"}</Button>
                                                    <Button color="success" className="ml-3 add-new-task-btn" id="add-task" onClick={addNewTask}>{"Adicionar Tarefa"}</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="notification-popup hide">
                                        <p><span className="task"></span><span className="notification-text"></span></p>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default TodoTab;