import { Key, Folder, Package, Award, ArrowDownCircle, Airplay } from 'react-feather'
export const MENUITEMS = [
    {
        menuTitle: null,
        menuContent: null,
        Items: [
            {
                title: 'Administração', icon: Key, type: 'sub', active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/administracao/farmacias`, title: 'Farmácias', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/administracao/utilizadores`, title: 'Contas de Utilizador', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/administracao/fornecedores`, title: 'Fornecedores', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/administracao/produtos`, title: 'Produtos', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/administracao/premiacao`, title: 'Premiação', type: 'link' },
                ]
            },
            {
                title: 'Push', icon: ArrowDownCircle, type: 'sub', active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/push/encomendas`, title: 'Encomendas', type: 'link' }
                ]
            },
            // { path: `${process.env.PUBLIC_URL}/visibilidade`, icon: Target, title: 'Visibilidade', type: 'link' },
            // { path: `${process.env.PUBLIC_URL}/alinhamento`, icon: AlignCenter, title: 'Alinhamento', type: 'link' },
        ]
    },
    {
        menuTitle: "Gestão Diária",
        menuContent: " ",
        Items: [
            {
                title: 'Comunicação', icon: Folder, type: 'sub', active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/centro/newsletters`, title: 'Newsletters', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/centro/noticias`, title: 'Notícias', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/centro/revista`, title: 'Revista+', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/centro/parcerias`, title: 'Parcerias Externas', type: 'link' },
                ]
            },
            {
                title: 'Marketing', icon: Award, type: 'sub', active: false, children: [
                    { path: `https://formfacade.com/public/105414721870392670145/all/form/1FAIpQLScuiABunfgy9iaVTJ4WGNBSubR2l7jEq_ZNpMECu3Z93GGJ4A`, type: 'globalLink', title: 'Upload Mupi' },
                    { path: `https://www.dropbox.com/sh/lzgoet4n3wgsu1r/AADxlGUdwzECFXClhGb2Y4F2a?dl=0`, type: 'globalLink', title: 'Conteúdos Digitais' },
                    { path: `https://www.dropbox.com/sh/j3b1x233e14wid9/AAAN8LyysHG6-6W9XMKR1iuPa?dl=0`, type: 'globalLink', title: 'Gestão de Categorias' },
                ]
            },
            {
                title: 'Formações', icon: Airplay, type: 'sub', active: false, children: [
                    { path: `https://forms.gle/cCwbWC3nAjmhgSGb7`, title: 'Inscrições be.smart', type: 'globalLink'},
                    { path: `https://formacao.maisfarmacia.org/`, title: 'Plataforma be.smart', type: 'globalLink' },
                   
                ]
            },
            
            {
                title: 'Loja', icon: Package, type: 'sub', active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/loja/produtos-de-loja`, title: 'Produtos de Loja', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/loja/equipamentos-de-renting`, title: 'Equipamentos de Renting', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/loja/renting`, title: 'Renting', type: 'link' },
                    // { path: `${process.env.PUBLIC_URL}/loja/subscricoes`, title: 'Subscrições', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/loja/fornecedores`, title: 'Fornecedores', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/loja/estatisticas`, title: 'Estatísticas', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/loja/encomendas`, title: 'Encomendas', type: 'link' },
                ]
            }
        ]
    }
]