import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { Container, Row, Col, Card, CardHeader, CardBody, Button, Form, FormGroup, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import DataTable from 'react-data-table-component';
import SweetAlert from 'sweetalert2'
import moment from 'moment';
import 'moment/locale/pt';

import Breadcrumb from '../../../layout/breadcrumb';
import NoResults from '../../ui-kits/noResults';

import PaginationDT from '../../pagination';

import { useDelete } from '../../../hooks/';

import CustomForm from "../../form";
import { getWithFilters } from "../../../api/";
import { apiUrl, rqModel, pathname, columns, schema } from "./settings";

const Newsletters = () => {
    const history = useHistory();

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [keyValue, setKeyValue] = useState(null);
    const [sortValue, setSortValue] = useState(null);

    const [tempSearchKeyword, setTempSearchKeyword] = useState('');
    const [searchKeyword, setSearchKeyword] = useState('');

    const [currentNewsletter, setNewsletter] = useState(null);
    const [viewType, setViewType] = useState(null);
    const [newsletterId, setNewsletterId] = useState(null);

    const deleteData = useDelete(apiUrl, rqModel, newsletterId)

    const getNewsletters = (page, limit, searchKeyword, keyValue, sortValue) => getWithFilters({ apiUrl: '/api/v1/newsletters/table', pagination: { page: page, limit: limit }, filters: { search: searchKeyword, sortBy: keyValue, sort: sortValue } });
    const {
        isLoading,
        data,
        error,
    } = useQuery([rqModel, page, limit, searchKeyword, keyValue, sortValue], () => getNewsletters(page, limit, searchKeyword, keyValue, sortValue), { keepPreviousData: true, staleTime: 300000 });

    if (error && error.response) {
        history.push(`${process.env.PUBLIC_URL}/pages/errors/error${error.response.status}`);
    } else if (error) {
        history.push(`${process.env.PUBLIC_URL}/pages/errors/error500`);
    }

    const handleSearchKeyword = () => {
        if (tempSearchKeyword.length > 3) {
            setPage(1)
            setSearchKeyword(tempSearchKeyword)
        } else if (tempSearchKeyword.length <= 3) {
            setPage(1)
            setSearchKeyword('')
        }
    }

    const toggleTable = () => {
        setViewType(null);
        setNewsletter(null);
    }

    const createView = () => {
        setViewType("form");
        setNewsletter(null);
        window.history.pushState(null, null, `${pathname}?view=create`);
    }

    const editView = (newsletter) => {
        setViewType("form");
        setNewsletter(newsletter);
        window.history.pushState(null, null, `${pathname}?view=edit`);
    }

    const createSuccess = (data) => {
        toast.success(`Newsletter ${data.title} criada!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const editSuccess = (data) => {
        toast.success(`Newsletter ${data.title} editada!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const deleteRequest = async (_id) => {
        await setNewsletterId(_id);
        SweetAlert.fire({
            title: 'Atenção!',
            text: 'Confirma que pretende apagar a Newsletter?',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then(async (result) => {
                if (result.value) {
                    await deleteData.mutate();
                    if (deleteData.isIdle) {
                        toast.success("Newsletter apagada!", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                }
            })
    }

    return (
        <Fragment>
            <Breadcrumb parent="Geral" title="Newsletters" />
            <Container fluid={true}>
                {!isLoading ? (
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader>
                                    <span>{"Gestão de Newsletters"}</span>
                                </CardHeader>
                                <CardBody>
                                    {viewType !== "form" && (
                                        <div className="feature-products">
                                            <Row>
                                                <Col md="6">
                                                    <Form onSubmit={(ev) => ev.preventDefault()}>
                                                        <FormGroup className="m-0">
                                                            <Input
                                                                className="form-control"
                                                                type="text"
                                                                placeholder="Procurar (mais de 3 caracteres)"
                                                                defaultValue={tempSearchKeyword}
                                                                value={tempSearchKeyword}
                                                                onChange={(e) => {
                                                                    setTempSearchKeyword(e.target.value);
                                                                    if (e.target.value.length === 0) handleSearchKeyword();
                                                                }}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter') handleSearchKeyword();
                                                                }}
                                                            />
                                                            <Button color="primary" onClick={handleSearchKeyword}><i className="fa fa-search" /></Button>
                                                        </FormGroup>
                                                    </Form>
                                                </Col>
                                            </Row>
                                        </div>
                                    )}
                                    <div className="todo">
                                        <div className="todo-list-wrapper">
                                            <div className="todo-list-container">
                                                <div className="mark-all-tasks">
                                                    <div className="mark-all-tasks-container">
                                                        {viewType !== "form" && <Button color="primary" onClick={createView}>{"Criar"}</Button>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {viewType === "form" ? (
                                            <CustomForm data={currentNewsletter} toggleTable={toggleTable} schema={schema} rqModel={rqModel} route={apiUrl} createSuccess={createSuccess} editSuccess={editSuccess} pathname={pathname} />
                                        ) : currentNewsletter && viewType === "profile" ? (
                                            <CustomForm data={currentNewsletter} toggleTable={toggleTable} schema={schema} rqModel={rqModel} route={apiUrl} createSuccess={createSuccess} editSuccess={editSuccess} pathname={pathname} />
                                        ) : (
                                            <div className="table-responsive product-table">
                                                <DataTable
                                                    defaultSortField="createdOn"
                                                    defaultSortAsc={false}
                                                    noHeader
                                                    columns={columns}
                                                    data={data && data.results.map((newsletter) => ({
                                                        createdOn: `${moment(newsletter.createdOn).calendar()}`,
                                                        title: `${newsletter.title}`,
                                                        action: <div>
                                                            <Button color="primary" size="xs" className="ml-2" onClick={() => editView(newsletter)}><i className="fa fa-pencil"></i></Button>
                                                            <Button color="primary" size="xs" className="ml-2" onClick={() => deleteRequest(newsletter._id)}><i className="fa fa-trash"></i></Button>
                                                        </div>
                                                    }))}
                                                    highlightOnHover={true}
                                                    pointerOnHover={true}
                                                    paginationPerPage={25}
                                                    pagination={true}
                                                    noDataComponent={<NoResults />}
                                                    paginationComponent={() => {
                                                        return (
                                                            <PaginationDT totalPage={data.totalPages} currentPage={data.page} limit={data.limit} onChangePage={setPage} />
                                                        )
                                                    }}
                                                    sortServer={true}
                                                    onSort={(column, value) => {
                                                        setKeyValue(column.selector)
                                                        setSortValue(value)
                                                        setPage(1)
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    <Row style={{ minHeight: 500 }}>
                        <Col sm="12">
                            <div className="loader-box loader-abs">
                                <div className="loader-7" />
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
        </Fragment>
    )
}

export default Newsletters
