export const actionTypes = {
    SET_USER: "SET_USER",
    SET_MY_PHARMA: "SET_MY_PHARMA",
    SET_NEW_NOTIFICATION: "SET_NEW_NOTIFICATION",
    MARK_NOTIFICATION_AS_READ: "MARK_NOTIFICATION_AS_READ",
    REFRESH_TODOS: "REFRESH_TODOS",
};

export const setUser = (payload) => ({
    type: actionTypes.SET_USER,
    payload: payload,
});

export const setMyPharma = (payload) => ({
    type: actionTypes.SET_MY_PHARMA,
    payload: payload,
});

export const setNewNotification = (payload) => ({
    type: actionTypes.SET_NEW_NOTIFICATION,
    payload: payload,
});

export const markNotificationAsRead = (payload) => ({
    type: actionTypes.MARK_NOTIFICATION_AS_READ,
    payload: payload,
});

export const refreshToDos = (payload) => ({
    type: actionTypes.REFRESH_TODOS,
    payload: payload,
});
