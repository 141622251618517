import React, { Fragment, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Container, Row, Col, Card, CardHeader, CardBody, Button, Input, FormGroup, Form, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { saveAs } from 'file-saver';
import moment from 'moment';
import 'moment/locale/pt';
import DataTable from 'react-data-table-component';
import Select from 'react-select';
import SweetAlert from 'sweetalert2';
import axios from 'axios';

import Breadcrumb from '../../../../layout/breadcrumb';
import NoResults from '../../../ui-kits/noResults';

import PaginationDT from '../../../pagination';

import { useGetAll, useDelete } from '../../../../hooks/';

import CustomForm from "../../../form";
import Filepond from "../../../filepond/";
import { getWithFilters } from "../../../../api/";
import { apiUrl, rqModel, pathname, columns, schema } from "./settings";

import * as XLSX from 'xlsx'

const StoreProducts = () => {
    const history = useHistory();

    const laboratories = useGetAll(`/api/v1/store_laboratories?fetch=all`, 'store_laboratories');

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [keyValue, setKeyValue] = useState(null);
    const [sortValue, setSortValue] = useState(null);

    const [tempSearchKeyword, setTempSearchKeyword] = useState('');
    const [searchKeyword, setSearchKeyword] = useState('');

    const [laboratory, setLaboratory] = useState(null);

    const getProducts = (page, limit, searchKeyword, laboratory, keyValue, sortValue) => getWithFilters({ apiUrl: apiUrl, pagination: { page: page, limit: limit }, filters: { search: searchKeyword, laboratory: laboratory, sortBy: keyValue, sort: sortValue } });

    const [currentProduct, setProduct] = useState(null);
    const [viewType, setViewType] = useState(null);
    const [productId, setProductId] = useState(null);

    const [files, setFiles] = useState([]); //* FILEPOND
    const [modal, setModal] = useState(false);
    const [fileLoading, setFileLoading] = useState(false);
    const [fileError, setFileError] = useState(null);

    const {
        isLoading,
        data,
        error,
    } = useQuery([rqModel, page, searchKeyword, laboratory, keyValue, sortValue], () => getProducts(page, limit, searchKeyword, laboratory, keyValue, sortValue), { keepPreviousData: true, staleTime: 300000 });
    const deleteData = useDelete(apiUrl, rqModel, productId, page);

    if (error && error.response) {
        history.push(`${process.env.PUBLIC_URL}/pages/errors/error${error.response.status}`);
    } else if (error) {
        history.push(`${process.env.PUBLIC_URL}/pages/errors/error500`);
    }

    const handleSearchKeyword = (keyword) => {
        if (tempSearchKeyword.length > 3) {
            setPage(1)
            setSearchKeyword(tempSearchKeyword)
        } else if (tempSearchKeyword.length <= 3) {
            setPage(1)
            setSearchKeyword('')
        }
    }

    const toggleTable = () => {
        setViewType(null);
        setProduct(null);
    }

    const createView = () => {
        setViewType("form");
        setProduct(null);
        window.history.pushState(null, null, `${pathname}?view=create`);
    }

    const editView = (user) => {
        setViewType("form");
        setProduct(user);
        window.history.pushState(null, null, `${pathname}?view=edit`);
    }

    const createSuccess = (data) => {
        toast.success(`Produto ${data.name} criado!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const editSuccess = (data) => {
        toast.success(`Produto ${data.name} editado!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const deleteRequest = async (_id) => {
        await setProductId(_id);
        SweetAlert.fire({
            title: 'Atenção!',
            text: 'Confirma que pretende apagar este Produto?',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then(async (result) => {
                if (result.value) {
                    await deleteData.mutate();
                    if (deleteData.isIdle) {
                        toast.success("Produto apagado!", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                }
            })
    }

    const downloadAll = () => {
        axios.get(`/api/v2/store_products/generate_file`)
            .then((result) => {
                toast.success("Ficheiro foi pedido. Irá receber via email", {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
            .catch((error) => {
                toast.error("Ocorreu um erro ao gerar o ficheiro", {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }
    const downloadItems = () => {
        /* Call XLSX */
        let wb = XLSX.utils.book_new();

        let myBookData = [];
        myBookData.push(["Nome", "Laboratorio", "Codigo", "Preco","Preço Custo", "Desconto", "Stock Actual", "Minimo Unidades", "Minimo Valor", "Categoria"]);

        data.results.forEach((myItem, index) => {
            let myArr = [
                myItem.name,
                myItem.lab.name,
                myItem.code,
                myItem.price,
                myItem.priceCost,
                myItem.discount,
                myItem.stock,
                myItem.minimumOrderQuantity,
                myItem.minimumValue,
                myItem.category
            ];
            myBookData.push(myArr)
        });

        let ws = sheet_from_array_of_arrays(myBookData);
        let defaultName = 'Encomenda'
        wb.SheetNames.push(defaultName);
        wb.Sheets[defaultName] = ws;
        let wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });

        function s2ab(s) {
            let buf = new ArrayBuffer(s.length);
            let view = new Uint8Array(buf);
            for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        }

        saveAs(new Blob([s2ab(wbout)], { type: "" }), `extrato_produtos_${moment().format('YYYYMMDD')}.xlsx`);
    }

    const toggleModal = () => {
        setModal(true);
    }
    const closeModal = () => {
        setFiles([]); //* FILEPOND
        setModal(false);
    }
    const setCustomFiles = (files) => {
        setFiles(files) //* FILEPOND
    }

    const uploadXLS = () => {
        setFileLoading(true);

        let submitValues = new FormData();
        submitValues.append('file[name]', files.name);
        submitValues.append('file', files);
        axios.post(`/api/v2/store_products/upload`, submitValues, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((result) => {
                if (result.status === 200) {
                    toast.success(`Uploaded feito com sucesso!`, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setFileLoading(false);
                    closeModal();
                } else {
                    setFileLoading(false);
                    setFileError("Ocorreu um erro, tente mais tarde.");
                    setTimeout(() => {
                        setFileError(null);
                    }, 3000);
                }
            })
            .catch((error) => {
                setFileLoading(false);
                setFileError("Ocorreu um erro, tente mais tarde.");
                setTimeout(() => {
                    setFileError(null);
                }, 3000);
            });
    }

    return (
        <Fragment>
            <Breadcrumb parent="Loja" title="Produtos" />
            <Container fluid={true}>
                {!isLoading ? (
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader>
                                    <span>{"Gestão de Loja maisfarmácia"}</span>
                                </CardHeader>
                                <CardBody>
                                    {viewType !== "form" && (
                                        <div className="feature-products">
                                            <Row style={{marginBottom: 20}}>
                                                <Col md="6">
                                                    <Form onSubmit={(ev) => ev.preventDefault()}>
                                                        <Input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Procurar (mais de 3 caracteres)"
                                                            defaultValue={searchKeyword}
                                                            value={tempSearchKeyword}
                                                            onChange={(e) => {
                                                                setTempSearchKeyword(e.target.value);
                                                                if (e.target.value.length === 0) handleSearchKeyword();
                                                            }}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') handleSearchKeyword();
                                                            }}
                                                        />
                                                            {/* <Button color="primary" onClick={handleSearchKeyword}><i className="fa fa-search" /></Button> */}
                                                    </Form>
                                                </Col>
                                                <Col md="3" className="text-right">
                                                    <Select
                                                        placeholder="Selecionar Fornecedor"
                                                        className="basic-single"
                                                        // id="lab_select"
                                                        classNamePrefix="select"
                                                        defaultValue={null}
                                                        isMulti={false}
                                                        isDisabled={false}
                                                        isClearable
                                                        isSearchable
                                                        name={"lab_select"}
                                                        options={laboratories.data.results.map(el => el).sort((a, b) => a.name.localeCompare(b.name))}
                                                        onChange={(value) => {
                                                            setPage(1);
                                                            if(value) setLaboratory(value._id)
                                                            else setLaboratory(null);
                                                        }}
                                                        getOptionLabel={(opt) => opt.name}
                                                        getOptionValue={(opt) => opt._id}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    )}
                                    <div className="todo">
                                        <div className="todo-list-wrapper">
                                            <div className="todo-list-container">
                                                <div className="mark-all-tasks">
                                                    <div className="mark-all-tasks-container">
                                                        {viewType !== "form" && (
                                                            <>
                                                            <Button className="ml-2" color="primary" onClick={downloadAll}>{"Pedir Extracto"}</Button>
                                                                {/* <Button className="ml-2" color="primary" onClick={downloadItems}>{"Download Resultados"}</Button> */}
                                                                <Button className="ml-2" color="primary" onClick={toggleModal}>{"Upload XLS"}</Button>
                                                                <Button className="ml-2" color="primary" onClick={createView}>{"Novo Produto"}</Button>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {viewType === "form" ? (
                                            <CustomForm data={currentProduct} toggleTable={toggleTable} schema={schema} rqModel={rqModel} route={apiUrl} createSuccess={createSuccess} editSuccess={editSuccess} pathname={pathname} />
                                        ) : currentProduct && viewType === "profile" ? (
                                            <CustomForm data={currentProduct} toggleTable={toggleTable} schema={schema} rqModel={rqModel} route={apiUrl} createSuccess={createSuccess} editSuccess={editSuccess} pathname={pathname} />
                                        ) : (
                                            <div className="table-responsive product-table">
                                                <DataTable
                                                    noHeader
                                                    columns={columns}
                                                    data={data.results.map((product) => ({
                                                        name: <NavLink to={`${process.env.PUBLIC_URL}/produto-de-loja/${product._id}`}>{product.name}</NavLink>,
                                                        code: `${product.lab.code}`,
                                                        score: `${product.score ? product.score : "-"}`,
                                                        price: `${product.price.toFixed(2)} €`,
                                                        stock: `${product.stock}`,
                                                        action: <div>
                                                            {/* <Button color="primary" size="xs" className="ml-2" onClick={() => history.push(`/produto-de-loja/${product._id}`)}><i className="fa fa-eye"></i></Button> */}
                                                            <Button color="primary" size="xs" className="ml-2" onClick={() => editView(product)}><i className="fa fa-pencil"></i></Button>
                                                            <Button color="primary" size="xs" className="ml-2" onClick={() => deleteRequest(product._id)}><i className="fa fa-trash"></i></Button>
                                                        </div>,
                                                        settings: (product.isHidden ? <i className="fa fa-eye-slash"></i> : null)
                                                    }))}
                                                    highlightOnHover={true}
                                                    pointerOnHover={true}
                                                    pagination={true}
                                                    noDataComponent={<NoResults />}
                                                    paginationComponent={() => {
                                                        return (
                                                            <PaginationDT totalPage={data.totalPages} currentPage={data.page} limit={data.limit} onChangePage={setPage} />
                                                        )
                                                    }}
                                                    sortServer={true}
                                                    onSort={(column, value) => {
                                                        setKeyValue(column.selector)
                                                        setSortValue(value)
                                                        setPage(1)
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    <Row style={{ minHeight: 500 }}>
                        <Col sm="12">
                            <div className="loader-box loader-abs">
                                <div className="loader-7" />
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
            <Modal isOpen={modal} toggle={() => closeModal}>
                <ModalHeader toggle={() => closeModal}>
                    {"Upload Master Produtos Loja Online"}
                </ModalHeader>
                <ModalBody>
                    <Filepond
                        name={"file"}
                        label={"Carregar Ficheiro"}
                        multi={false}
                        files={files}
                        setFiles={setCustomFiles}
                        imageResizeTargetWidth={1200}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={uploadXLS}>
                        {fileLoading ? (
                            <i className="fa fa-spin fa-spinner" />
                        ) : fileError ? (
                            fileError
                        ) : (
                            "Upload"
                        )}
                    </Button>
                    <Button color="secondary" onClick={() => closeModal()}>
                        {"Cancelar"}
                    </Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}

export default StoreProducts

const sheet_from_array_of_arrays = (data, opts) => {
    const datenum = (v, date1904) => {
        if (date1904) v += 1462;
        var epoch = Date.parse(v);
        return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
    }

    let ws = {};
    let range = { s: { c: 10000000, r: 10000000 }, e: { c: 0, r: 0 } };
    for (let R = 0; R !== data.length; ++R) {
        for (let C = 0; C !== data[R].length; ++C) {
            if (range.s.r > R) range.s.r = R;
            if (range.s.c > C) range.s.c = C;
            if (range.e.r < R) range.e.r = R;
            if (range.e.c < C) range.e.c = C;
            let cell = { v: data[R][C] };
            if (cell.v == null) continue;
            let cell_ref = XLSX.utils.encode_cell({ c: C, r: R });

            if (typeof cell.v === 'number') cell.t = 'n';
            else if (typeof cell.v === 'boolean') cell.t = 'b';
            else if (cell.v instanceof Date) {
                cell.t = 'n'; cell.z = XLSX.SSF._table[14];
                cell.v = datenum(cell.v);
            }
            else cell.t = 's';

            ws[cell_ref] = cell;
        }
    }
    if (range.s.c < 10000000) ws['!ref'] = XLSX.utils.encode_range(range);
    return ws;
}