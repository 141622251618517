const getFinalRole = ({ role, mf_role, internal_role, profile }) => {
    if (role && role === 'AdminRole') {
        let roles = {
            _mainRole: 'Administrador',
            color: "dark"
        }
        if (mf_role) {
            if (mf_role === 'pcj') {
                roles._finalRole = 'PC Júnior'
                return roles;
            } else if (mf_role === 'pcs') {
                roles._finalRole = 'PC Senior'
                return roles;
            } else if (mf_role === 'adv') {
                roles._finalRole = 'Administrativo'
                return roles;
            } else if (mf_role === 'coo') {
                roles._finalRole = 'Coordenação'
                return roles;
            } else if (mf_role === 'neg') {
                roles._finalRole = 'Negociação'
                return roles;
            } else if (mf_role === 'opr') {
                roles._finalRole = 'Operações'
                return roles;
            } else if (mf_role === 'mkt') {
                roles._finalRole = 'Marketing'
                return roles;
            } else if (mf_role === 'adm') {
                roles._finalRole = 'Administração'
                return roles;
            } else if (mf_role === 'regular') {
                roles._finalRole = ''
                return roles;
            } else {
                roles._finalRole = ''
                return roles;
            }
        } else {
            roles._finalRole = ''
            return roles;
        }
    } else if (role && role === 'FarmaciaRole') {
        let roles = {
            _mainRole: 'Farmácia',
            color: "success"
        }
        if (internal_role) {
            if (internal_role === 'dt') {
                roles._finalRole = 'Director Técnico'
                return roles;
            } else if (internal_role === 'fm') {
                roles._finalRole = 'Farmacêutico'
                return roles;
            } else if (internal_role === 'fmas') {
                roles._finalRole = 'Farmacêutico Adj Subst'
                return roles;
            } else if (internal_role === 'tf') {
                roles._finalRole = 'Técnico de Farmácia'
                return roles;
            } else if (internal_role === 'tfa') {
                roles._finalRole = 'Ajudante Técnico'
                return roles;
            } else if (internal_role === 'aux') {
                roles._finalRole = 'Auxiliar'
                return roles;
            } else if (internal_role === 'ges') {
                roles._finalRole = 'Gestor'
                return roles;
            } else {
                roles._finalRole = 'Não Especificado'
                return roles;
            }
        } else {
            roles._finalRole = ''
            return roles;
        }
    } else if (role && role === 'FornecedorRole') {
        let roles = {
            _mainRole: 'Fornecedor',
            color: "primary"
        }
        if (profile === null) {
            roles._finalRole = 'Fornecedor'
            return roles;
        } else if (profile === 'representative') {
            roles._finalRole = 'Delegado'
            return roles;
        } else {
            roles._finalRole = 'Fornecedor'
            return roles;
        }
    } else if (role && role === 'PrestadorRole') {
        let roles = {
            _mainRole: 'Prestador',
            color: "info"
        }
        roles._finalRole = 'Regular'
        return roles;
    } else if (role && role === 'FacebookTesterRole') {
        let roles = {
            _mainRole: 'Facebook Tester',
            color: 'danger'
        }
        roles._finalRole = 'Regular'
        return roles;
    } else {
        return {
            _mainRole: null,
            _finalRole: null,
            color: null
        }
    }
}

export default getFinalRole;
