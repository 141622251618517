import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Card, CardHeader, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useGetAll } from '../../hooks/';
import axios from 'axios';

import Filepond from "../filepond/";

const VisibilidadePharma = (props) => {
    const history = useHistory();
    const uploads = useGetAll(`/api/v1/category_management`, 'uploads');

    const [files, setFiles] = useState([]); //* FILEPOND

    const [modal, setModal] = useState(false);
    const [uploadId, setUploadId] = useState(null);
    const [fileLoading, setFileLoading] = useState(false);
    const [fileError, setFileError] = useState(null);

    if (uploads.status === "error") history.push(`${process.env.PUBLIC_URL}/pages/errors/error404`);

    const toggleModal = (_id) => {
        setUploadId(_id);
        setModal(true);
    }
    const closeModal = () => {
        setFiles([]); //* FILEPOND
        setModal(false);
    }
    const setCustomFiles = (files) => {
        setFiles(files) //* FILEPOND
    }

    const uploadPhoto = () => {
        setFileLoading(true);

        let submitValues = new FormData();
        submitValues.append('file[name]', files.name);
        submitValues.append('file', files);
        axios.post(`/api/v1/category_management/${uploadId}/p_submission`, submitValues, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((result) => {
                if (result.status === 200) {
                    setFileLoading(false);
                    closeModal();
                } else {
                    setFileLoading(false);
                    setFileError("Ocorreu um erro, tente mais tarde.");
                    setTimeout(() => {
                        setFileError(null);
                    }, 3000);
                }
            })
            .catch((error) => {
                setFileLoading(false);
                setFileError("Ocorreu um erro, tente mais tarde.");
                setTimeout(() => {
                    setFileError(null);
                }, 3000);
            });
    }

    return (
        <Fragment>
            <Container fluid={true}>
                {uploads.data ? (
                    <Row>
                        <Col sm="12" className="mt-4">
                            <Card>
                                <CardHeader>
                                    <h5>{"Uploads"}</h5>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        {uploads.data.results.map((item, i) =>
                                            <Col xl="4" md="6" key={i}>
                                                <div className="prooduct-details-box btn-absolute">
                                                    <div className="media">
                                                        <div className="media-body ml-3">
                                                            <div className="product-name">
                                                                <h6>{item.name}</h6>
                                                            </div>
                                                            <div className="price">
                                                                <span className="text-muted mr-2">{"Rentabilidade Total"}: {item.total_profitability} €</span>
                                                                <span className="text-muted mr-2">{"Rentabilidade Média"}: {item.average_profitability} €</span>
                                                            </div>
                                                            <div className="avaiabilty">
                                                                <div className="text-success">{"A Dinamização do Expositor atribui "}{"100/-25 Pts"}</div>
                                                            </div>
                                                            <Button color="primary" size="xs" onClick={() => toggleModal(item._id)}>Upload</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        )}
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Modal isOpen={modal} toggle={() => closeModal}>
                            <ModalHeader toggle={() => closeModal}>
                                {"Submeter Fotografia"}
                            </ModalHeader>
                            <ModalBody>
                                <Filepond
                                    name={"profilePhoto"}
                                    label={"Carregar Imagem"}
                                    multi={false}
                                    files={files}
                                    setFiles={setCustomFiles}
                                    content={`image`}
                                    imageEditInstantEdit
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={uploadPhoto}>
                                    {fileLoading ? (
                                        <i className="fa fa-spin fa-spinner" />
                                    ) : fileError ? (
                                        fileError
                                    ) : (
                                        "Selecionar"
                                    )}
                                </Button>
                                <Button color="secondary" onClick={() => closeModal()}>
                                    {"Fechar"}
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </Row>
                ) : (
                    <Row style={{ minHeight: 500 }}>
                        <Col sm="12">
                            <div className="loader-box loader-abs">
                                <div className="loader-7" />
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
        </Fragment>
    );
}

export default VisibilidadePharma;