import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane, Input, Table, Button } from 'reactstrap'
import { RefreshCcw, Tool, List } from 'react-feather';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/pt';

import { useDebounce } from '../../hooks/';

import Wizard from './wizard/';

import Breadcrumb from '../../layout/breadcrumb'

import errorImg from '../../assets/images/search-not-found.png';

const getRealPushType = (inqType) => {
    switch (inqType) {
        case 'tacita':
            return 'Push Aceite por Defeito'
        case 'tacitap':
            return 'Push Aceite por Defeito PLUS'
        case 'facultativo':
            return 'Push Opcional';
        case 'obrigatório':
            return 'Obrigatória'
        case 'direct':
            return 'maisDirect'
        default:
            return '';
    }
}

const getMyTotal = (response, segment) => {
    if (response) {
        let myTotal = 0;

        response[0].products.forEach((product) => {
            const foundDiscountProduct = segment.products.find((prod) => prod.cnp === product.cnp)
            const foundDiscount = foundDiscountProduct ? foundDiscountProduct.discount : 0;
            let myProcessDiscount = (100 - foundDiscount) / 100;
            if ((segment.products.find((prod) => prod.cnp === product.cnp))) {
                if ((segment.products.find((prod) => prod.cnp === product.cnp)).pvf) {
                    myTotal += (segment.products.find((prod) => prod.cnp === product.cnp)).pvf * product.quantity * myProcessDiscount;
                } else {
                    myTotal += 0
                }
            } else {
                myTotal += 0
            }
        });
        return myTotal;
    } else {
        let myTotal = 0;
        segment.products.forEach((product) => {
            let myProcessDiscount = (100 - (product.discount ? parseInt(product.discount) : 0)) / 100;
            myTotal += (product.pvf * product.quantity * myProcessDiscount)
        })
        return myTotal;
    }
}

const Orders = () => {
    const history = useHistory();

    const [activeTab, setActiveTab] = useState("ongoing");
    const [currentOrder, setOrder] = useState(null);

    const getOrders = (activeTab) => axios.get('/api/v2/inqueritos?type=something&state=' + activeTab).then((res) => res.data)
    const {
        data,
        isLoading,
        isFetching,
    } = useQuery(['orders', activeTab], () => getOrders(activeTab), { keepPreviousData: true, staleTime: 300000 });

    const userRole = useSelector(content => content.User.user.role);
    const laboratories = useSelector(content => content.Laboratories.results);
    const loaded = useSelector(content => content.Laboratories.loaded);

    const [searchTerm, setSearchTerm] = React.useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    useEffect(() => {
        if (history.location.pathname.split("/")[3] && history.location.pathname.split("/")[3].length > 15 && data && data.results) {
            const urlOrder = data.results.find((order) => order._id === history.location.pathname.split("/")[3]);
            setOrder(urlOrder);
        }
    }, [history, data])

    const toggleTable = () => {
        setActiveTab("ongoing");
        setOrder(null);
    }

    const filterResults = () => {
        if (data && data.results) {
            if (debouncedSearchTerm && debouncedSearchTerm.length > 0) {
                return data.results.filter((el) => (el.name).toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '').includes(debouncedSearchTerm.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '')));
            } else {
                return data.results
            }
        } else {
            return [];
        }
    }

    let results = filterResults();

    return !currentOrder ? (
        <Fragment>
            <Breadcrumb parent="Push" title="Encomendas" />
            <Container fluid={true}>
                <Row>
                    <Col md="12" className="project-list">
                        <Card>
                            <Row>
                                <Col sm="8">
                                    <Nav tabs className="border-tab">
                                        <NavItem><NavLink style={{ cursor: 'pointer' }} className={activeTab === "ongoing" ? "active" : ''} onClick={() => setActiveTab("ongoing")}><RefreshCcw />{"A Decorrer"}</NavLink></NavItem>
                                        <NavItem><NavLink style={{ cursor: 'pointer' }} className={activeTab === "closed" ? "active" : ''} onClick={() => setActiveTab("closed")}><Tool />{"Em Processamento"}</NavLink></NavItem>
                                        <NavItem><NavLink style={{ cursor: 'pointer' }} className={activeTab === "sent" ? "active" : ''} onClick={() => setActiveTab("sent")}><List />{"Histórico"}</NavLink></NavItem>
                                    </Nav>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col md="12" className="project-list">
                        <Card>
                            <Row>
                                <Col sm="8">
                                    <Input
                                        className="form-control"
                                        type="text"
                                        placeholder="Procurar"
                                        value={searchTerm}
                                        onChange={(e) => {
                                            setSearchTerm(e.target.value);
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <TabContent activeTab={"main"}>
                                    <TabPane tabId="main">
                                        {!isLoading && !isFetching && loaded && data.results.length > 0 ? (
                                            <Row>
                                                {results.map((item, i) => {
                                                    return (
                                                        <Col sm="6" className="mt-4" key={i}>
                                                            <div className="project-box">
                                                                {item.response.length >= 1 && (
                                                                    !item.response[0].rejected ? (
                                                                        item.response[0].obs === 'Auto Accepted' ? (
                                                                            <span className={`badge badge-success`}>{"Aceite Tácitamente"}</span>
                                                                        ) : (
                                                                            <span className={`badge badge-success`}>{"Aceite"}</span>
                                                                        )
                                                                    ) : (
                                                                        <span className={`badge badge-warning`}>{"Rejeitado"}</span>
                                                                    )
                                                                )}
                                                                <h6>{getRealPushType(item.inq_type)} :: {item.name}</h6>
                                                                <div className="media">
                                                                    <div className="media-body">
                                                                        <p className="text-primary">{item.fornecedor && laboratories.find((lab) => item.fornecedor === lab.fornecedor_id) ? laboratories.find((lab) => item.fornecedor === lab.fornecedor_id).name : "Sem Fornecedor Associado"}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="table-responsive mb-4">
                                                                    <Table size="xs">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col" style={{ padding: "0.75rem" }}>{"Seg"}</th>
                                                                                <th scope="col" style={{ padding: "0.75rem" }}>{"Valor Min"}</th>
                                                                                <th scope="col" style={{ padding: "0.75rem" }}>{"Unid. Min"}</th>
                                                                                <th scope="col" style={{ padding: "0.75rem" }}>{"Valor"}</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {item.segments.map((seg, index) => {
                                                                                return item.response.length === 1 ? (
                                                                                    <tr key={index}>
                                                                                        <th scope="row" style={{ padding: "0.75rem" }}>{seg.name}</th>
                                                                                        <td style={{ padding: "0.75rem" }}>{seg.minimumOrder.toFixed(2)} €</td>
                                                                                        <td style={{ padding: "0.75rem" }}>{seg.minimumQuantity > 0 ? seg.minimumQuantity + "un." : "-"}</td>
                                                                                        <td style={{ padding: "0.75rem" }}>{getMyTotal(item.response, seg).toFixed(2)} €</td>
                                                                                    </tr>
                                                                                ) : (
                                                                                    <tr key={index}>
                                                                                        <th scope="row" style={{ padding: "0.75rem" }}>{seg.name}</th>
                                                                                        <td style={{ padding: "0.75rem" }}>{seg.minimumOrder.toFixed(2)} €</td>
                                                                                        <td style={{ padding: "0.75rem" }}>{seg.minimumQuantity > 0 ? seg.minimumQuantity + "un." : "-"}</td>
                                                                                        <td style={{ padding: "0.75rem" }}>{getMyTotal(undefined, seg).toFixed(2)} €</td>
                                                                                    </tr>
                                                                                )
                                                                            })}
                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                                <Row className="details mb-4">
                                                                    <Col xs="6"><span>{"Push termina em"} </span></Col>
                                                                    <Col xs="6" className={'text-primary'}>{moment(item.end_date).calendar()}</Col>
                                                                    {item.delivery && (
                                                                        <>
                                                                            <Col xs="6"> <span>{"Envio esperado em"}</span></Col>
                                                                            <Col xs="6" className={'text-primary'}>{moment(item.delivery.end).format('DD/MM/YYYY')}</Col>
                                                                        </>
                                                                    )}
                                                                </Row>
                                                                <p>{item.description}</p>
                                                                <div className="project-status mt-4">
                                                                    <div className="media mb-0">
                                                                        {item.response.length === 0 && item.state === 'ongoing' ? (
                                                                            userRole && userRole !== 'FornecedorRole' ? (
                                                                                <>
                                                                                    <Button color="primary" onClick={() => setOrder(item)}>Responder</Button>
                                                                                    <Button className="ml-4" color="info" onClick={() => history.push(`${process.env.PUBLIC_URL}/push/encomenda/${item._id}`)}>Ver Detalhes</Button>
                                                                                </>
                                                                            ) : (
                                                                                <Button color="primary" onClick={() => history.push(`${process.env.PUBLIC_URL}/push/encomenda/${item._id}`)}>Ver Detalhes</Button>
                                                                            )
                                                                        ) : (
                                                                            <Button color="primary" onClick={() => history.push(`${process.env.PUBLIC_URL}/push/encomenda/${item._id}`)}>Ver Detalhes</Button>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )
                                                }
                                                )}
                                            </Row>
                                        ) : !isLoading && !isFetching && data.results.length <= 0 ? (
                                            <div className="search-not-found text-center">
                                                <div>
                                                    <img className="img-fluid second-search" src={errorImg} alt="no-results-img" />
                                                    <p>{"Não foram encontrados resultados"}</p>
                                                </div>
                                            </div>
                                        ) : (
                                            <Row style={{ minHeight: 450 }}>
                                                <div className="loader-box loader-abs">
                                                    <div className="loader-7" />
                                                </div>
                                            </Row>
                                        )}
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    ) : (
        <Wizard currentOrder={currentOrder} toggleTable={toggleTable} history={history} />
    );
}

export default Orders;