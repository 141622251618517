import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Card, CardHeader, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { toast } from 'react-toastify';
import moment from 'moment';
import 'moment/locale/pt';

import { useGetAll, useDelete } from '../../hooks/';

import CustomForm from "../form";
import { apiUrl, rqModel, schema } from "./settings";

const localizer = momentLocalizer(moment);

const eventStyleGetter = (event) => {
    let backgroundColor;
    if (event.type === "Campanhas ao Utente") {
        backgroundColor = "#0068FA"
    } else if (event.type === "Dias comemorativos") {
        backgroundColor = "#0DB759"
    } else if (event.type === "Formações") {
        backgroundColor = "#F10075"
    } else if (event.type === "Montras + Expositor") {
        backgroundColor = "#00CCCC"
    } else if (event.type === "Publicações Redes Sociais") {
        backgroundColor = "#5A47FB"
    } else if (event.type === "Outros") {
        backgroundColor = "#FD7D13"
    }
    let style = {
        backgroundColor: backgroundColor,
    };
    return {
        style: style
    };
};

const MFCalendar = () => {
    const user = useSelector(content => content.User.user);

    const events = useGetAll(apiUrl, rqModel);

    const [currentEvent, setEvent] = useState(null);
    const [viewType, setViewType] = useState(null);
    const [eventId, setEventId] = useState(null);

    const deleteData = useDelete(apiUrl, rqModel, eventId)

    const toggleTable = () => {
        setViewType(null);
        setEvent(null);
    }

    const createView = () => {
        setViewType("form");
        setEvent(null);
    }

    const createSuccess = (data) => {
        toast.success(`Evento ${data.title} criada!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const editSuccess = (data) => {
        toast.success(`Evento ${data.title} editada!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const eventClick = async (event) => {
        await setEventId(event._id);
        Swal.fire({
            title: `${event.title} - ${event.type}`,
            html:
                `<div className="table-responsive">
                    <table className="table">
                        <tbody>
                            <tr>
                                <td>Inicio</td>
                                <td><strong>`+ moment(event.start).format("LL") + `</strong></td>
                            </tr>
                            <tr>
                                <td>Fim</td>
                                <td><strong>`+ moment(event.end).format("LL") + `</strong></td>
                            </tr>
                            <tr>
                                <p>${event.description}</p>
                            </tr>
                        </tbody>
                    </table>
                </div>`,
            showCloseButton: user && user.role === "FarmaciaRole" ? true : false,
            showConfirmButton: user && user.role === "FarmaciaRole" ? false : true,
            showCancelButton: user && user.role === "FarmaciaRole" ? false : true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Remover Evento",
            cancelButtonText: "Cancelar"
        }).then(async (result) => {
            if (result.value) {
                await deleteData.mutate();
                if (deleteData.isIdle) {
                    toast.success("Evento apagado!", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            }
        });
    };

    return (
        <Fragment>
            <Container fluid={true}>
                {!events.isLoading ? (
                    <Row>
                        <Col sm="12" className="mt-4">
                            <Card>
                                <CardHeader>
                                    <h5>Calendário</h5>
                                    <div className="todo">
                                        <div className="todo-list-wrapper">
                                            <div className="todo-list-container">
                                                <div className="mark-all-tasks">
                                                    {user && user.role === "AdminRole" && (
                                                        <div className="mark-all-tasks-container">
                                                            {viewType !== "form" && <Button color="primary" onClick={createView}>{"Criar"}</Button>}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody>

                                    {viewType === "form" ? (
                                        <CustomForm data={currentEvent} toggleTable={toggleTable} schema={schema} rqModel={rqModel} route={apiUrl} createSuccess={createSuccess} editSuccess={editSuccess} />
                                    ) : currentEvent && viewType === "profile" ? (
                                        <CustomForm data={currentEvent} toggleTable={toggleTable} schema={schema} rqModel={rqModel} route={apiUrl} createSuccess={createSuccess} editSuccess={editSuccess} />
                                    ) : (
                                        <Calendar
                                            showAllEvents={true}
                                            localizer={localizer}
                                            scrollToTime={new Date(1970, 1, 1, 6)}
                                            defaultDate={new Date()}
                                            onSelectEvent={eventClick}
                                            views={['month', 'agenda', 'day']}
                                            events={events.data && events.data.results}
                                            eventOverlap
                                            dragRevertDuration={500}
                                            dragScroll
                                            droppable={false}
                                            showMultiDayTimes
                                            step={60}

                                            startAccessor="start"
                                            endAccessor="end"
                                            eventPropGetter={(eventStyleGetter)}
                                         
                                            messages={{
                                                next: "Seguinte",
                                                previous: "Anterior",
                                                today: "Hoje",
                                                month: "Mês",
                                                work_week: "Semana de Trabalho",
                                                week: "Semana",
                                                day: "Dia",
                                                date: "Data",
                                                time: "Tempo",
                                                event: "Evento",
                                                allDay: "Dia inteiro",
                                                noEventsInRange: "Não existem eventos este mês",
                                                yesterday: 'Ontem',
                                                tomorrow: 'Amanhã',
                                                agenda: 'Agenda',
                                                showMore: function showMore(total) {
                                                    return "+" + total + " mais";
                                                }
                                            }}
                                        />
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    <Row style={{ minHeight: 500 }}>
                        <Col sm="12">
                            <div className="loader-box loader-abs">
                                <div className="loader-7" />
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
        </Fragment>
    );

};

export default MFCalendar;