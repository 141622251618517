export const getState = (state) => {
  switch (state) {
    case "temporary":
      return "Temporário";
    case "ongoing":
      return "Activo";
    case "closed":
      return "Fechado";
    case "onmf":
      return "Processamento MF";
    case "onlab":
      return "Processamento Laboratório";
    case "sent":
      return "Enviado";
    case "archived":
      return "Arquivado";
    default:
      return null;
  }
};

export const getButton = (state) => {
  switch (state) {
    case "temporary":
      return "Iniciar";
    case "ongoing":
      return "Encerrar";
    case "closed":
      return "Finalizar Processo";
    case "onlab":
      return "Enviar";
    case "sent":
      return "Arquivar";
    default:
      return null;
  }
};

export const getValorProposto = (segment) => {
  let myTotal = 0;

  segment.products.forEach((myProduct) => {
    var myProcessDiscount = (100 - parseInt(myProduct.discount)) / 100;
    myTotal += myProduct.pvf * myProduct.quantity * myProcessDiscount;
  });

  return myTotal;
};

export const doughnutOption = {
  maintainAspectRatio: false,
  options: {
    layout: {
      padding: {
        bottom: 50,
      },
    },
  },
  legend: {
    display: true,
    position: "bottom",
  },
  plugins: {
    datalabels: {
      display: true,
      color: "white",
    },
  },
};

export const columns = [
  {
    name: "",
    selector: "icon",
    sortable: false,
    center: true,
  },
  {
    name: "Farmácia",
    selector: "name",
    sortable: true,
    center: true,
  },
  {
    name: "Segmento",
    selector: "segment",
    sortable: true,
    center: true,
  },
  {
    name: "Dist",
    selector: "distribution",
    sortable: true,
    center: true,
  },
  {
    name: "Eficiencia",
    selector: "segments",
    sortable: false,
    center: true,
  },
  {
    name: "Valor",
    selector: "myTotal",
    sortable: false,
    center: true,
  },
  {
    name: "Status",
    selector: "products_issues",
    sortable: false,
    center: true,
  },
  {
    name: "Opções",
    selector: "action",
    sortable: false,
    center: true,
  },
];

export const getTotalPercent = (response, segments) => {
  let myTotal = 0;

  response.myProductsPreMutate &&
    response.myProductsPreMutate.forEach((product) => {
      let myProcessDiscount = (100 - parseInt(product.discount)) / 100;
      myTotal += product.pvf * product.quantity2 * myProcessDiscount;
    });

  let myTotalInit = 0;

  const theSegmentFoundO = segments.find(
    (seg) => seg.name === response.segment
  );
  if (theSegmentFoundO) {
    theSegmentFoundO.products.forEach((myProduct) => {
      let myProcessDiscount = (100 - parseInt(myProduct.discount)) / 100;
      myTotalInit += myProduct.pvf * myProduct.quantity * myProcessDiscount;
    });
    let totalPercent = (
      ((myTotal - myTotalInit) / myTotalInit + 1) *
      100
    ).toFixed(2);

    return {
      totalPercent: totalPercent,
      myTotal: myTotal,
    };
  } else if (myTotal) {
    return {
      totalPercent: null,
      myTotal: myTotal,
    };
  } else {
    return {
      totalPercent: null,
      myTotal: null,
    };
  }
};

export const getPatchOrderType = (state) => {
  let type = {
    text1: "",
    text2: "",
    newState: "",
  };

  if (state === "temporary") {
    type.text1 = "Inquérito Iniciado!";
    type.text2 =
      "O inquérito foi iniciado e irá calcular os sellouts potenciais. Ficará disponível para as farmácias em 24 horas.";
    type.newState = "ongoing";
  } else if (state === "ongoing") {
    type.text1 = "Inquérito Fechado!";
    type.text2 =
      "O inquérito foi fechado e ficou indisponível para as farmácias responderem.";
    type.newState = "closed";
  } else if (state === "closed") {
    type.text1 = "Encomenda Efectuada!";
    type.text2 = "A encomenda foi efectuada e seguiu para Laboratório.";
    type.newState = "onlab";
  } else if (state === "onlab") {
    type.text1 = "Encomenda Enviada!";
    type.text2 = "A encomenda foi enviada e seguiu para as Farmácias.";
    type.newState = "sent";
  } else if (state === "sent") {
    type.text1 = "Inquérito Encerrado!";
    type.text2 = "O inquérito foi dado como encerrado.";
    type.newState = "archived";
  }

  return type;
};
