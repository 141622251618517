import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Table, UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';
import 'moment/locale/pt';

const ProductsTable = ({ isOpen, toggle, credits, currentPharmacy }) => {
const translationCredits = {
  'YearObjectiveCredit_2023': {
    name: 'Prémio 2023',
    tooltip: 'Adicionado em massa por prémio final ano 2023'
  },
  'subtractedDueToOrder': {
    name: 'Compra Loja',
    tooltip: 'Subtraído na encomenda na loja:'
  },
  'AutomaticPushAdded': {
    name: 'Adicionado Prémio Push',
    tooltip: 'Adicionado automáticamente como prémio da push:'
  },
  'addedDueToOrderItemRemoval': {
    name: 'Ajuste em Encomenda',
    tooltip: 'Devolvido por cancelamento ou alteração da encomenda: '
  }
}


    return (
        <Modal isOpen={isOpen} toggleModal={() => toggle(!isOpen)} size="lg" style={{ maxWidth: 1185 }}>
            <ModalHeader toggleModal={() => toggle(!isOpen)} style={{ borderBottom: 0 }}>
                {`Historico de uso de crédito farmácia ${currentPharmacy?.name}`}
                <br />
  
            </ModalHeader>
            <ModalBody>
                <Table>
                    <thead>
                        <tr>
                            <th className="text-center">{"Data"}</th>
                            <th className="text-center">{"Valor"}</th>
                            <th className="text-center">{"Tipo"}</th>
                        </tr>
                    </thead>
                    
                    <tbody>
                      {credits.data.map((thisItem, index) => {
                        return thisItem && (
                            <tr key={index}>
                                <th className="text-center">{moment(thisItem.createdOn).format('YYYY/MM/DD')}</th>
                                <th className="text-center">{thisItem.amount.toFixed(2)} €</th>
                                <td className="text-center" id={'unc' + thisItem._id}>{translationCredits[thisItem.reasonCode] ? translationCredits[thisItem.reasonCode].name : thisItem.reasonCode}
                                  <UncontrolledTooltip placement="top" target={'unc' + thisItem._id}>
                                  {translationCredits[thisItem.reasonCode] ? translationCredits[thisItem.reasonCode].tooltip : 'Sem informação'}
                                  </UncontrolledTooltip>
                                  </td> 
                            </tr>
                        )
                      })}
                    </tbody>
                </Table>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={() => toggle(!isOpen)}>
                    {"Fechar"}
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default ProductsTable;
