import React, { Fragment } from 'react';
import { Container } from 'reactstrap';
import Jitsi from 'react-jitsi'

const roomName = 'my-super-teste-123'
const userFullName = 'Teste'

const Loader = () => {
    return (
        <div className="loader-box loader-abs">
            <div className="loader-7" />
        </div>
    )
}

const Video = () => {
    return (
        (true) ?
            <Fragment>
                <Container fluid={true}>
                    <Jitsi
                        loadingComponent={Loader}
                        roomName={roomName}
                        displayName={userFullName}
                        onAPILoad={JitsiMeetAPI => console.log('Good Morning everyone!')}
                    />
                </Container>
            </Fragment>
            :
            <div className="loader-box loader-abs">
                <div className="loader-7" />
            </div>
    );
}

export default Video;