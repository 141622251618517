import { actionTypes } from "./";

const INIT_STATE = {
    loaded: false,
    results: null,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case actionTypes.SET_LABORATORIES:
            return { ...state, ...action.payload, results: action.payload.results, loaded: true };
        default:
            return { ...state };
    }
};
