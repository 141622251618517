import io from "socket.io-client";
import axios from "axios";

export const actionTypes = {
    INIT_SOCKET: "INIT_SOCKET",
};

// let ENDPOINT;
// if (process.env.NODE_ENV === 'development') {
//     ENDPOINT = process.env.TYPE === 'vpn' ? "http://10.0.8.2:9012" : process.env.TYPE === 'espace' ? "http://192.168.2.2:9012" : process.env.TYPE === 'local' ? "http://localhost:9012" : "http://localhost:9012";
// } else {
//     ENDPOINT = "https://be.maisfarmacia.org";
// }

const ENDPOINT = "http://localhost:9012"


export const initSocket = (data) => {
    // const socket = io(ENDPOINT, { path: "/piradio" });

    // socket.emit("identity", data);

    // axios.get(`/api/v1/chat_room/`)
    //     .then((result) => {
    //         if(result.data.rooms) {
    //             result.data.rooms.forEach((room) => {
    //             socket.emit("subscribe", room._id, room.userIds[0]);
    //             });
    //         }
    //     })
    //     .catch((error) => {
    //         console.error(error);
    //     })
    
    return {
        type: actionTypes.INIT_SOCKET,
        payload: null
    }
}
