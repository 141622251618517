import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Card, CardHeader, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { Facebook } from 'react-feather';
import Select from 'react-select';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import SweetAlert from 'sweetalert2';
import moment from 'moment';
import 'moment/locale/pt';
import axios from "axios";

import Breadcrumb from '../../../layout/breadcrumb';
import NoResults from '../../ui-kits/noResults';

import { useGetAll, useDelete } from '../../../hooks/';

import Form from "../../form";
import { apiUrl, rqModel, pathname, columns, columns_template, pageColumns, schema_template, schema } from "./settings";

const FacebookAdmin = () => {
    // const history = useHistory();

    const facebookQuery = useGetAll(apiUrl, rqModel);
    const templateQuery = useGetAll("/api/v1/templates", 'social_templates');

    const pharmacies = useSelector(content => content.Pharmacies.results);
    const loaded = useSelector(content => content.Pharmacies.loaded);

    const [currentPost, setPost] = useState(null);
    const [template, setTemplate] = useState(null);
    const [viewType, setViewType] = useState(null);
    const [postId, setPostId] = useState(null);
    const [templateId, setTemplateId] = useState(null);
    const [pageId, setPageId] = useState(null);

    const [viewType2, setViewType2] = useState(null);
    const [currentPost2, setPost2] = useState(null);

    const [modal, setModal] = useState(false);
    const [facebookSdkReady, setFacebookSdkReady] = useState(false);

    const [loginSection, setLoginSection] = useState(false);
    const [logoutSection, setLogoutSection] = useState(false);

    const [pageResults, setPageResults] = useState(false);
    const [selectedPharma, selectPharma] = useState(null);
    const [selectedPage, selectPage] = useState(null);
    const [pages, setPages] = useState([]);
    const [accessToken, setAccessToken] = useState(null);
    const [instagramId, setInstagramId] = useState(null);

    const deleteData = useDelete('/api/v1/fb_pages/post', rqModel, postId)
    const deleteTemplateData = useDelete('/api/v1/templates', 'social_templates', templateId)
    const deletePageData = useDelete('/api/v1/fb_pages', rqModel, pageId)

    const [messageLogoutBtn, setMessageLogoutBtn] = useState(null);
    const [loadingLogoutBtn, setLoadingLogoutBtn] = useState(false);

    const [messageAuthBtn, setMessageAuthBtn] = useState(null);
    const [loadingAuthBtn, setLoadingAuthBtn] = useState(false);

    const formRef = useRef(); // access form functions

    useEffect(() => {
        // LOADS FACEBOOK CODE
        if (!facebookSdkReady) {
            window.fbAsyncInit = function () {
                window.FB.init({
                    appId: '570502896633177', // real 930816183683115 // old 527354624784713 // test 570502896633177
                    autoLogAppEvents: true,
                    xfbml: true,
                    version: 'v10.0'
                });
            };
            (function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) { return; }
                js = d.createElement(s); js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));

            setFacebookSdkReady(true);
        }
    }, []); // eslint-disable-line

    const toggleTable = () => {
        setViewType(null);
        setPost(null);
        setViewType2(null);
        setPost2(null);
    }

    const createView = () => {
        setViewType("form");
        setPost(null);
        window.history.pushState(null, null, `${pathname}?view=create`);
    }

    const editView = (page) => {
        setViewType("form");
        setPost(page);
        window.history.pushState(null, null, `${pathname}?view=edit`);
    }

    const createView2 = () => {
        setViewType2("form");
        setPost2(null);
        window.history.pushState(null, null, `${pathname}?view=create`);
    }

    const editView2 = (template) => {
        setViewType2("form");
        setPost2(template);
        window.history.pushState(null, null, `${pathname}?view=edit`);
    }

    const createSuccess = (data) => {
        toast.success(`Post ${data.name} criado!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const editSuccess = (data) => {
        toast.success(`Post ${data.name} editado!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const createSuccess2 = (data) => {
        toast.success(`Template ${data.name} criado!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const editSuccess2 = (data) => {
        toast.success(`Template ${data.name} editado!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const toggleModal = () => {
        setModal(true);
        allowAccess();
    }
    const closeModal = () => {
        setModal(false);
    }

    const deleteRequest = async (_id) => {
        await setPostId(_id);
        SweetAlert.fire({
            title: 'Atenção!',
            text: 'Confirma que pretende apagar o Post?',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then(async (result) => {
                if (result.value) {
                    await deleteData.mutate();
                    if (deleteData.isIdle) {
                        toast.success("Post apagado!", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                }
            })
    }

    const deleteTemplateRequest = async (_id) => {
        await setTemplateId(_id);
        SweetAlert.fire({
            title: 'Atenção!',
            text: 'Confirma que pretende apagar o Template?',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then(async (result) => {
                if (result.value) {
                    await deleteTemplateData.mutate();
                    if (deleteTemplateData.isIdle) {
                        toast.success("Template apagado!", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                }
            })
    }

    const deletePageRequest = async (_id) => {
        await setPageId(_id);
        SweetAlert.fire({
            title: 'Atenção!',
            text: 'Confirma que pretende apagar a Página?',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then(async (result) => {
                if (result.value) {
                    await deletePageData.mutate();
                    if (deletePageData.isIdle) {
                        toast.success("Página apagada!", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                }
            })
    }

    const getAccountInfo = () => {
        window.FB.api('/me/accounts?fields=name,id,access_token,instagram_business_account{id,username,profile_picture_url}', (response) => {
            console.log(response, 'get account info')
            if (response) {
                setPageResults(true);
                setLogoutSection(true);
                setLoginSection(false);

                setPages(response.data);
                setAccessToken(response.data[0].access_token);
                setInstagramId(response.data[0].instagram_business_account.id);
            } else {
                setPageResults(false);
                setLogoutSection(false);
                setLoginSection(true);
            }
        });
    }

    const logoutFacebook = () => {
        setLoadingLogoutBtn(true);
        window.FB.api('/me/permissions', 'DELETE', (response) => {
            if (response) {
                window.FB.logout((response) => {
                    setLoadingLogoutBtn(false);
                    setMessageLogoutBtn('Sucesso')
                    window.location.reload();
                });
            } else {
                setLoadingLogoutBtn(false);
                setMessageLogoutBtn('Erro tente mais tarde');
                console.log('Error revoking app');
            }
        });
    }

    const loginFacebook = () => {
        window.FB.login((response) => {
            console.log(response, 'login')
            getAccountInfo();
        }, {
            scope: "email,pages_read_engagement,pages_manage_posts,pages_read_user_content,instagram_basic,instagram_content_publish,pages_show_list",
            return_scopes: true
        });
    }

    const allowAccess = () => {
        window.FB.getLoginStatus((response) => {
            if (response.status === 'connected') {
                setLogoutSection(true);
                setLoginSection(false);
                getAccountInfo();
            }
            else {
                setLoginSection(true);
                setLogoutSection(false);
            }
        });
    }

    const authPage = () => {
        setLoadingAuthBtn(true);
        const newFbAuth = {
            page: pages[0],
            instagram_business_account: instagramId,
            username_associated: sessionStorage.getItem('username'),
            pharm_associated: {
                id: selectedPharma._id,
                name: selectedPharma.name,
                loyalty_type: selectedPharma.loyalty && selectedPharma.loyalty.loyalty_type ? selectedPharma.loyalty.loyalty_type : ""
            }
        };
        axios.post(`/api/v1/fb_pages/`, newFbAuth)
            .then((result) => {
                console.log(result);
                setLoadingAuthBtn(false);
                setMessageAuthBtn('Sucesso');
                window.location.reload();
            })
            .catch((error) => {
                setLoadingAuthBtn(false);
                setMessageAuthBtn('Erro')
                console.error(error);
            });
    }

    return (
        <Fragment>
            <Breadcrumb parent="Marketing" title="Social" />
            <Container fluid={true}>
                {!templateQuery.isLoading && !facebookQuery.isLoading && facebookSdkReady && loaded ? (
                    <>
                        <Row>
                            <Col sm="12">
                                <Card>
                                    <CardHeader>
                                        <span>{"Posts Social"}</span>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="todo">
                                            <div className="todo-list-wrapper">
                                                <div className="todo-list-container">
                                                    <div className="mark-all-tasks">
                                                        <div className="mark-all-tasks-container">
                                                            {viewType !== "form" && (
                                                                <>
                                                                    <Button color="primary" className="ml-2" onClick={createView}>{"Criar Post"}</Button>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {viewType === "form" ? (
                                                <Form
                                                    ref={formRef}
                                                    data={currentPost}
                                                    toggleTable={toggleTable}
                                                    schema={schema}
                                                    rqModel={rqModel}
                                                    route={'/api/v1/fb_pages/post'}
                                                    createSuccess={createSuccess}
                                                    editSuccess={editSuccess}
                                                    pathname={pathname}
                                                    selectTemplate={
                                                        <Col sm="12" className='mb-4'>
                                                            <Label className="col-form-label pt-0">{"Escolher Template"}</Label>
                                                            <Select
                                                                placeholder="Selecionar ..."
                                                                className="basic-single"
                                                                id={"template"}
                                                                classNamePrefix="select"
                                                                defaultValue={null}
                                                                value={template}
                                                                isClearable
                                                                isSearchable
                                                                name={"template"}
                                                                options={templateQuery.data.docs}
                                                                getOptionLabel={(opt) => opt['name']}
                                                                getOptionValue={(opt) => opt['_id']}
                                                                onChange={(values, input) => {
                                                                    setTemplate(values)
                                                                    formRef.current.setFormValues(values);
                                                                }}
                                                            />
                                                        </Col>
                                                    }
                                                />
                                            ) : currentPost && viewType === "profile" ? (
                                                <Form data={currentPost} toggleTable={toggleTable} schema={schema} rqModel={rqModel} route={'/api/v1/fb_pages/post'} createSuccess={createSuccess} editSuccess={editSuccess} pathname={pathname} />
                                            ) : (
                                                <div className="table-responsive product-table">
                                                    <DataTable
                                                        noHeader
                                                        columns={columns}
                                                        data={facebookQuery.data.results.listPosts.map((post) => ({
                                                            name: `${post.name}`,
                                                            date: `${post.scheduled_date ? moment(post.scheduled_date).calendar() : ""}`,
                                                            action: <div>
                                                                <Button color="primary" size="xs" className="ml-2" onClick={() => deleteRequest(post._id)}><i className="fa fa-trash"></i></Button>
                                                                <Button color="primary" size="xs" className="ml-2" onClick={() => editView(post)}><i className="fa fa-pencil"></i></Button>
                                                            </div>
                                                        }))}
                                                        noDataComponent={<NoResults />}
                                                        highlightOnHover={true}
                                                        pointerOnHover={true}
                                                        pagination={true}
                                                        paginationPerPage={25}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col sm="12">
                                <Card>
                                    <CardHeader>
                                        <span>{"Templates"}</span>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="todo">
                                            <div className="todo-list-wrapper">
                                                <div className="todo-list-container">
                                                    <div className="mark-all-tasks">
                                                        <div className="mark-all-tasks-container">
                                                            {viewType !== "form" && (
                                                                <>
                                                                    <Button color="primary" className="ml-2" onClick={createView2}>{"Criar Template"}</Button>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {viewType2 === "form" ? (
                                                <Form data={currentPost2} toggleTable={toggleTable} schema={schema_template} rqModel={'social_templates'} route={'/api/v1/templates'} createSuccess={createSuccess2} editSuccess={editSuccess2} pathname={pathname} />
                                            ) : currentPost2 && viewType2 === "profile" ? (
                                                <Form data={currentPost2} toggleTable={toggleTable} schema={schema_template} rqModel={'social_templates'} route={'/api/v1/templates'} createSuccess={createSuccess2} editSuccess={editSuccess2} pathname={pathname} />
                                            ) : (
                                                <div className="table-responsive product-table">
                                                    <DataTable
                                                        noHeader
                                                        columns={columns_template}
                                                        data={templateQuery.data.docs.map((post) => ({
                                                            name: `${post.name}`,
                                                            action: <div>
                                                                <Button color="primary" size="xs" className="ml-2" onClick={() => deleteTemplateRequest(post._id)}><i className="fa fa-trash"></i></Button>
                                                                <Button color="primary" size="xs" className="ml-2" onClick={() => editView2(post)}><i className="fa fa-pencil"></i></Button>
                                                            </div>
                                                        }))}
                                                        noDataComponent={<NoResults />}
                                                        highlightOnHover={true}
                                                        pointerOnHover={true}
                                                        pagination={true}
                                                        paginationPerPage={25}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row> */}
                        <Row>
                            <Col sm="12">
                                <Card>
                                    <CardHeader>
                                        <span>{"Páginas Autorizadas"}</span>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="todo">
                                            <div className="todo-list-wrapper">
                                                <div className="todo-list-container">
                                                    <div className="mark-all-tasks">
                                                        <div className="mark-all-tasks-container">
                                                            {viewType !== "form" && (
                                                                <>
                                                                    <Button color="primary" onClick={toggleModal}>{"Gerir conta facebook"}</Button>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-responsive product-table">
                                                <DataTable
                                                    noHeader
                                                    columns={pageColumns}
                                                    data={facebookQuery.data.results.listPages.map((page) => ({
                                                        pharm_associated: `${page.pharm_associated.name}`,
                                                        name: `${page.page.name}`,
                                                        action: <div>
                                                            <Button color="primary" size="xs" className="ml-2" onClick={() => deletePageRequest(page._id)}><i className="fa fa-trash"></i></Button>
                                                        </div>
                                                    }))}
                                                    noDataComponent={<NoResults />}
                                                    highlightOnHover={true}
                                                    pointerOnHover={true}
                                                    pagination={true}
                                                    paginationPerPage={25}
                                                />
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </>
                ) : (
                    <Row style={{ minHeight: 500 }}>
                        <Col sm="12">
                            <div className="loader-box loader-abs">
                                <div className="loader-7" />
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
            <Modal isOpen={modal} toggle={() => closeModal}>
                <ModalHeader toggle={() => closeModal}>
                    {"Gerir conta facebook"}
                </ModalHeader>
                <ModalBody>
                    {loginSection && (
                        <Row className="mb-4">
                            <Col sm="12">
                                <button onClick={loginFacebook} class="btn" style={{ backgroundColor: "#3b5998", color: "#fff", border: "none" }}>
                                    <Facebook style={{ position: "relative", top: 2, width: 22, height: 22, marginRight: 10 }} />
                                    <span style={{ position: "relative", bottom: 3 }}>Login com Facebook</span>
                                </button>
                            </Col>
                        </Row>

                    )}

                    {logoutSection && (
                        <Row className="mb-4">
                            <Col sm="12">
                                <button onClick={logoutFacebook} class="btn" style={{ backgroundColor: "#3b5998", color: "#fff", border: "none" }} disabled={loadingLogoutBtn || messageLogoutBtn}>
                                    {loadingLogoutBtn ? <i className="fa fa-spinner fa-spin" /> : messageLogoutBtn ? messageLogoutBtn : <><Facebook style={{ position: "relative", top: 2, width: 22, height: 22, marginRight: 10 }} /><span style={{ position: "relative", bottom: 3 }}>Logout</span></>}
                                </button>
                            </Col>
                        </Row>
                    )}

                    {pageResults && (
                        <Row className="mb-4">
                            <Col sm="12">
                                <Label className="col-form-label pt-0">{"Farmácia"}</Label>
                                <Select
                                    placeholder="Selecionar ..."
                                    className="basic-single"
                                    id={"pharma"}
                                    classNamePrefix="select"
                                    defaultValue={null}
                                    value={selectedPharma}
                                    isClearable
                                    isSearchable
                                    name={"pharma"}
                                    options={pharmacies}
                                    getOptionLabel={(opt) => opt['name']}
                                    getOptionValue={(opt) => opt['ANF']}
                                    onChange={(values, input) => {
                                        selectPharma(values)
                                    }}
                                />
                            </Col>
                            {/* <Col className="mt-4" sm="12">
                                <Label className="col-form-label pt-0">{"Páginas Disponíveis"}</Label>
                                <Select
                                    placeholder="Selecione a página a dar acesso ..."
                                    className="basic-single"
                                    id={"pages"}
                                    classNamePrefix="select"
                                    defaultValue={null}
                                    value={selectedPage}
                                    isClearable
                                    isSearchable
                                    name={"pages"}
                                    options={pages}
                                    getOptionLabel={(opt) => opt['name']}
                                    getOptionValue={(opt) => opt['id']}
                                    onChange={(values, input) => {
                                        selectPage(values)
                                    }}
                                />
                            </Col> */}
                            <Col className="mt-4" sm="12">
                                <button onClick={authPage} class="btn" style={{ backgroundColor: "#3b5998", color: "#fff", border: "none" }} disabled={loadingLogoutBtn || messageLogoutBtn || loadingAuthBtn || messageAuthBtn}>
                                    {loadingAuthBtn ? <i className="fa fa-spinner fa-spin" /> : messageAuthBtn ? messageAuthBtn : <><Facebook style={{ position: "relative", top: 2, width: 22, height: 22, marginRight: 10 }} /><span style={{ position: "relative", bottom: 3 }}>Autorizar</span></>}
                                </button>
                            </Col>
                        </Row>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => closeModal()}>
                        {"Cancelar"}
                    </Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}

export default FacebookAdmin