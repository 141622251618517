import React from "react";

export const apiUrl = "/api/v2/store_products";
export const rqModel = "store_products";
export const pathname = `${process.env.PUBLIC_URL}/loja/produtos-de-loja`;

export const columns = [
  {
    name: "Nome",
    selector: "name",
    sortable: true,
    center: false,
    width:'500px',
    wrap: true
  },
  {
    name: "Código Laboratório",
    selector: "code",
    sortable: true,
    center: true,
    width:'auto'
  },
  {
    name: "Pontos",
    selector: "score",
    sortable: true,
    center: true,
  },
  {
    name: "Preço",
    selector: "price",
    sortable: true,
    center: true,
  },
  {
    name: "Stock",
    selector: "stock",
    sortable: true,
    center: true,
  },
  {
    name: "Opções",
    selector: "action",
    sortable: false,
    center: true,
  },
  {
    name: "Settings",
    selector: "settings",
    sortable: false,
    center: false,
  },
];

export const schema = [
  {
    colSize: "6",
    label: "Nome",
    name: "name",
    id: "name",
    type: "text",
    maxLength: 60,
    required: true,
    requiredMessage: "Este campo é obrigatório",
  },
  {
    colSize: "6",
    label: "Código",
    name: "code",
    id: "code",
    type: "text",
    required: true,
    requiredMessage: "Este campo é obrigatório",
  },
  {
    colSize: "12",
    label: "Imagem",
    name: "photo",
    id: "photo",
    type: "file",
    content: "image",
    multi: false,
    route: "/api/v2/store_products",
    imageEditInstantEdit: true,
    imageResizeTargetWidth: 900,
    cropAspectRatio: "1:1",
    cropAspectRatioOptions: [
      {
        label: "Square",
        value: "1:1",
      },
    ],
  },
 
  {
    colSize: "3",
    label: "Preço",
    name: "price",
    id: "price",
    type: "number",
    required: true,
    requiredMessage: "Este campo é obrigatório",
    step: 0.01,
  },
  {
    colSize: "3",
    label: "Preço de Custo",
    name: "priceCost",
    id: "priceCost",
    type: "number",
    required: false,
    step: 0.01,
  },
  {
    colSize: "3",
    label: "Desconto Comercial (%)",
    name: "discount",
    id: "discount",
    type: "number",
    required: true,
    requiredMessage: "Este campo é obrigatório",
  },
  {
    colSize: "3",
    label: "Stock inicial",
    name: "stock",
    id: "stock",
    type: "number",
    required: true,
    requiredMessage: "Este campo é obrigatório",
  },
  {
    colSize: "4",
    label: "Quantidade Mínima",
    name: "minimumOrderQuantity",
    id: "minimumOrderQuantity",
    type: "number",
    required: true,
    requiredMessage: "Este campo é obrigatório",
  },
  {
    colSize: "4",
    label: "Pontuação",
    star: true,
    name: "score",
    id: "score",
    type: "number",
    required: true,
    requiredMessage: "Este campo é obrigatório",
  },
  {
    colSize: "2",
    label: "Produto Estrela ?",
    extraLabel: <i className="fa fa-star" style={{ color: "#EEBB00" }} />,
    name: "starred",
    id: "starred",
    type: "checkbox",
  },
  {
    colSize: "2",
    label: "Esconder?",
    extraLabel: <i className="fa fa-star" style={{ color: "#EEBB00" }} />,
    name: "isHidden",
    id: "isHidden",
    type: "checkbox",
  },
  {
    colSize: "4",
    label: "Categoria",
    name: "category",
    id: "category",
    type: "text",
    required: true,
    requiredMessage: "Este campo é obrigatório",
  },
  {
    colSize: "4",
    label: "Fornecedor",
    name: "lab",
    id: "lab",
    type: "select",
    required: true,
    requiredMessage: "Este campo é obrigatório",
    multi: false,
    options: null,
    optionLabel: "name",
    optionValue: "_id",
    backendPopulated: true,
    api: {
      route: "/api/v1/store_laboratories?fetch=all",
    },
  },
  {
    colSize: "4",
    label: "Pack",
    name: "groupedItems",
    id: "groupedItems",
    type: "select",
    required: false,
    requiredMessage: "Este campo é obrigatório",
    multi: true,
    options: null,
    optionLabel: "name",
    optionValue: "_id",
    backendPopulated: true,
    api: {
      route: "/api/v2/store_products",
    },
  },
  {
    colSize: "12",
    label: "Descrição",
    name: "description",
    id: "description",
    type: "froala",
    imageUploadParams: "products_editor",
    maxCharacters: 60,
  },
];
