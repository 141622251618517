import React, { Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Row, Col, Card, CardHeader, CardBody, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import SweetAlert from 'sweetalert2'
import moment from 'moment';
import 'moment/locale/pt';

import Breadcrumb from '../../layout/breadcrumb';

import { useGetAll, usePatch, useDelete } from '../../hooks/';

import CustomForm from "../form";
import { apiUrl, rqModel, pathname, columns, schema } from "./settings";

const getState = (state) => {
    if (state === 'temporary') {
        return "Temporário"
    } else if (state === 'ongoing') {
        return "Activo"
    } else if (state === 'awaiting') {
        return "A Processar ..."
    } else if (state === 'closed') {
        return "Processamento MF"
    } else if (state === 'onmf') {
        return "Processamento MF"
    } else if (state === 'onlab') {
        return "Processamento Laboratório"
    } else if (state === 'sent') {
        return "Enviado"
    } else if (state === 'archived') {
        return "Arquivado"
    }
}

// const getResponses = (responses) => {
//     let myTotalAck = 0;
//     if (responses) {
//         console.log(responses)
//         responses.forEach((leResponse) => {
//             if (leResponse.ack == true) myTotalAck += 1;
//         });
//     }
//     return myTotalAck;
// }

const Orders = () => {
    const ordersQuery = useGetAll(apiUrl, rqModel);

    const [currentOrder, setOrder] = useState(null);
    const [viewType, setViewType] = useState(null);
    const [orderId, setOrderId] = useState(null);

    const [submitValues, setSubmitValues] = useState({});

    const deleteData = useDelete(apiUrl, rqModel, orderId)
    const patchData = usePatch(apiUrl, rqModel, submitValues);

    const toggleTable = () => {
        setViewType(null);
        setOrder(null);
    }

    const createView = () => {
        setViewType("form");
        setOrder(null);
        window.history.pushState(null, null, `${pathname}?view=create`);
    }

    const editView = (user) => {
        setViewType("form");
        setOrder(user);
        window.history.pushState(null, null, `${pathname}?view=edit`);
    }

    const createSuccess = (data) => {
        toast.success(`Encomenda ${data.name} criada!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const editSuccess = (data) => {
        toast.success(`Encomenda ${data.name} editada!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const deleteRequest = async (_id) => {
        await setOrderId(_id);
        SweetAlert.fire({
            title: 'Atenção!',
            text: 'Confirma que pretende apagar a Encomenda Push?',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then(async (result) => {
                if (result.value) {
                    await deleteData.mutate();
                    if (deleteData.isIdle) {
                        toast.success("Encomenda apagada!", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                }
            })
    }

    const patchOrder = async (_id, state) => {
        const type = getPatchOrderType(state)
        const values = { _id: _id, state: type.newState };

        await setSubmitValues(values);
        await patchData.mutate();

        if (patchData.status !== "loading" && patchData.status === "idle") {
            toast.success(<><span>{type.text1}</span><br /><span>{type.text2}</span></>, {
                position: toast.POSITION.TOP_RIGHT
            });

            setTimeout(() => {
                patchData.reset();
            }, 1250);
        } else {
            toast.error("Ocorreu um erro!", {
                position: toast.POSITION.TOP_RIGHT
            });

            setTimeout(() => {
                patchData.reset();
            }, 1250);
        }


    }

    return (
        <Fragment>
            <Breadcrumb parent="Push" title="Desafio Flash" />
            <Container fluid={true}>
                {!ordersQuery.isLoading ? (
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader>
                                    <span>{"Descrição"}</span>
                                </CardHeader>
                                <CardBody>
                                    <div className="todo">
                                        <div className="todo-list-wrapper">
                                            <div className="todo-list-container">
                                                <div className="mark-all-tasks">
                                                    <div className="mark-all-tasks-container">
                                                        {viewType !== "form" && <Button color="primary" onClick={createView}>{"Criar"}</Button>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {viewType === "form" ? (
                                            <CustomForm data={currentOrder} toggleTable={toggleTable} schema={schema} rqModel={rqModel} route={apiUrl} createSuccess={createSuccess} editSuccess={editSuccess} pathname={pathname} />
                                        ) : currentOrder && viewType === "profile" ? (
                                            <CustomForm data={currentOrder} toggleTable={toggleTable} schema={schema} rqModel={rqModel} route={apiUrl} createSuccess={createSuccess} editSuccess={editSuccess} pathname={pathname} />
                                        ) : (
                                            <div className="table-responsive product-table">
                                                <DataTable
                                                    defaultSortField="createdOn"
                                                    defaultSortAsc={false}
                                                    noHeader
                                                    columns={columns}
                                                    data={ordersQuery.data.results.map((order) => ({
                                                        createdOn: `${moment(order.createdOn).format('YYYY/MM/DD')}`,
                                                        name: <NavLink to={`${process.env.PUBLIC_URL}/push/encomenda/${order._id}`}>{order.name}</NavLink>,
                                                        state: `${getState(order.state)}`,
                                                        // responses: `${getResponses(order.response).toString()}`,
                                                        responses: `${order.response.length}`,
                                                        received: 0,
                                                        action: <div>
                                                            <Button color="primary" size="xs" className="ml-2" id="tooltip-info" onClick={() => patchOrder(order._id, order.state)}>
                                                                {patchData.isLoading ? (
                                                                    <i className="fa fa-spinner fa-spin" />
                                                                ) : (
                                                                    order.state === "temporary" ? (
                                                                        "Iniciar"
                                                                    ) : order.state === "ongoing" ? (
                                                                        "Encerrar"
                                                                    ) : order.state === "closed" ? (
                                                                        "Fim de Processo"
                                                                    ) : order.state === "onlab" ? (
                                                                        "Enviado"
                                                                    ) : order.state === "sent" && (
                                                                        "Arquivar"
                                                                    )
                                                                )}
                                                            </Button>
                                                            {/* <Tooltip
                                                                placement="top"
                                                                isOpen={basictooltip}
                                                                target="tooltip-info"
                                                                toggle={toggle}>
                                                                {"Fim de Processo Mais Farmácia"}
                                                            </Tooltip> */}
                                                            <Button color="primary" size="xs" className="ml-2" onClick={() => editView(order)}><i className="fa fa-pencil"></i></Button>
                                                            {/* <Button color="primary" size="xs" className="ml-2" onClick={() => history.push(`/push/encomenda/${order._id}`)}><i className="fa fa-eye"></i></Button> */}
                                                            <Button color="primary" size="xs" className="ml-2" onClick={() => deleteRequest(order._id)}><i className="fa fa-trash"></i></Button>
                                                        </div>
                                                    }))}
                                                    highlightOnHover={true}
                                                    pointerOnHover={true}
                                                    pagination={true}
                                                    paginationPerPage={25}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    <Row style={{ minHeight: 500 }}>
                        <Col sm="12">
                            <div className="loader-box loader-abs">
                                <div className="loader-7" />
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
        </Fragment>
    )
}

export default Orders

const getPatchOrderType = (state) => {
    let type = {
        text1: "",
        text2: "",
        newState: ""
    };

    if (state === "temporary") {
        type.text1 = "Inquérito Iniciado!"
        type.text2 = "O inquérito foi iniciado e irá calcular os sellouts potenciais. Ficará disponível para as farmácias em 24 horas."
        type.newState = "ongoing"
    } else if (state === "ongoing") {
        type.text1 = "Inquérito Fechado!"
        type.text2 = "O inquérito foi fechado e ficou indisponível para as farmácias responderem."
        type.newState = "closed"
    } else if (state === "closed") {
        type.text1 = "Encomenda Efectuada!"
        type.text2 = "A encomenda foi efectuada e seguiu para Laboratório."
        type.newState = "onlab"
    } else if (state === "onlab") {
        type.text1 = "Encomenda Enviada!"
        type.text2 = "A encomenda foi enviada e seguiu para as Farmácias."
        type.newState = "sent"
    } else if (state === "sent") {
        type.text1 = "Inquérito Encerrado!"
        type.text2 = "O inquérito foi dado como encerrado."
        type.newState = "archived"
    }

    return type;
}
