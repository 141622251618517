import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Table } from 'reactstrap';

const ProductsTable = ({ leProducts, isOpen, toggle, products, segment }) => {
    return (
        <Modal isOpen={isOpen} toggle={() => toggle(!isOpen)} size="lg" style={{ maxWidth: 1185 }}>
            <ModalHeader toggle={() => toggle(!isOpen)}>
                {segment}
            </ModalHeader>
            <ModalBody>
                <Table>
                    <thead>
                        <tr>
                            <th className="text-center">Nome</th>
                            <th className="text-center">CNP</th>
                            <th className="text-center">Preço</th>
                            <th className="text-center">Quantidade</th>
                            <th className="text-center">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            products.map((product, index) => {
                                const currentProduct = leProducts.find((prod) => prod.cnp === product.cnp);
                                let myProcessDiscount = (100 - parseFloat(currentProduct.discount)) / 100;
                                return (
                                    <tr key={index}>
                                        <th className="text-center">{currentProduct.name}</th>
                                        <td className="text-center">{currentProduct.cnp}</td>
                                        <td className="text-center">{currentProduct.pvf.toFixed(2)} €</td>
                                        <td className="text-center">{product.quantity}</td>
                                        <td className="text-center">{(currentProduct.pvf * product.quantity * myProcessDiscount).toFixed(2)} €</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={() => toggle(!isOpen)}>
                    {"Fechar"}
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default ProductsTable;