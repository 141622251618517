import React, { useRef, useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageEdit from "filepond-plugin-image-edit";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginImageResize from "filepond-plugin-image-resize";

import FilePondPluginImageSizeMetadata from "../../data/filepond-plugin-image-size-metadata";
import "filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import * as Doka from "../../assets/dokka/lib/doka.esm.min";
import "../../assets/dokka/lib/doka.min.css";

import "filepond/dist/filepond.min.css";

import SweetAlert from 'sweetalert2';
import * as XLSX from 'xlsx';

registerPlugin(
  FilePondPluginImageEdit,
  FilePondPluginImagePreview,
  FilePondPluginImageTransform,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,
  FilePondPluginImageValidateSize,
  FilePondPluginImageSizeMetadata,
  FilePondPluginImageResize
);

const recomendedCropOptions = [
  {
    label: "Free",
    value: null,
  },
  {
    label: "Square",
    value: "1:1",
  },
  {
    label: "Portrait",
    value: 1.5,
  },
  {
    label: "Landscape",
    value: 0.75,
  }
];

Doka.setOptions({
  labelStatusAwaitingImage: 'Aguardar a Imagem…',
  labelStatusLoadImageError: 'Erro ao carregar a Imagem…',
  labelStatusLoadingImage: 'A carregar a Imagem',
  labelStatusProcessingImage: 'A processar a Imagem…',
  labelButtonUtilCrop: 'Crop',
  labelButtonUtilFilter: 'Filtro',
  labelButtonUtilColor: 'Cor',
  labelButtonUtilResize: 'Redimensionar',
  labelButtonUtilMarkup: 'Markup',
  labelButtonCropAspectRatio: 'Aspecto',
  labelButtonCropFlipHorizontal: 'Espelhar Horizontalmente',
  labelButtonCropFlipVertical: 'Espelhar Verticalmente'
});

const Filepond = (props) => {
  const pond = useRef(null);
  const [fileLoaded, setFileLoaded] = useState(false);

  return (
    <FilePond
      ref={pond}
      labelIdle={props.label}
      allowMultiple={props.multi}
      instantUpload={false}

      // files={props.files}
      // allowImageResize={false}
      // imageResizeMode={"contain"}
      // imageResizeUpscale={false}

      acceptedFileTypes={props.acceptedFileTypes ? props.acceptedFileTypes : null}
      name={props.name}
      imageEditInstantEdit={props.imageEditInstantEdit}
      imageCropAspectRatio={props.imageCropAspectRatio ? props.imageCropAspectRatio : 1}
      imageEditEditor={props.content === "image" ? Doka.create({
        onconfirm: (output, item) => {
          pond.current.prepareFile(item);
        },
        imageResizeTargetWidth: props.imageResizeTargetWidth,
        cropAspectRatio: props.cropAspectRatio ? props.cropAspectRatio : 1,
        cropAspectRatioOptions: props.cropAspectRatioOptions ? props.cropAspectRatioOptions : recomendedCropOptions,
      }) : null}
      onremovefile={(error, file) => {
        if (props.setOutput) props.setOutput(null);
        if (props.setFiles) props.setFiles(null);
      }}
      onpreparefile={(fileItem, output) => {
        if (props.validate === 'push' && fileItem && !fileLoaded) {
          let mySegments = [];

          let selFile = pond.current.getFile().file;
          let reader = new FileReader();

          if (selFile && !fileLoaded) {
            reader.readAsArrayBuffer(selFile);
            reader.onload = (e) => {
              let arraybuffer = e.target.result;
              /* convert data to binary string */
              let data = new Uint8Array(arraybuffer);
              let arr = [];
              for (let i = 0; i !== data.length; ++i) arr[i] = String.fromCharCode(data[i]);
              let bstr = arr.join("");

              /* Call XLSX */
              // let workbook = XLSX.utils.book_new();
              let workbook = XLSX.read(bstr, { type: "binary" });
              let worksheet_PH = workbook.Sheets["INPUT_PH"];
              let worksheet_SEG = workbook.Sheets["INPUT_SEG"];
              let alphaRef = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T']

              // console.log(workbook)
              // console.log(worksheet_PH)
              // console.log(worksheet_SEG)

              let phObjMix = [];

              if (typeof worksheet_PH == 'undefined' || typeof worksheet_SEG == 'undefined') {
                SweetAlert.fire({
                  title: 'Erro no Ficheiro!',
                  text: `Não foi encontrado um ficheiro bem formatado`,
                })
                return;
              }

              // PARSE SEGMENTS
              let range_SEG = XLSX.utils.decode_range(worksheet_SEG['!ref']);
              let range_PH = XLSX.utils.decode_range(worksheet_PH['!ref']);

              for (let i = 2; i <= range_SEG.e.r + 1; i++) {
                let thisSegment = {
                  products: [],
                  pharm: []
                }

                for (let j = 0; j <= range_SEG.e.c; j++) {
                  let desired_cell = worksheet_SEG[alphaRef[j] + i];
                  if (desired_cell !== undefined) {
                    if (j === 0) thisSegment.name = desired_cell.v;
                    if (j === 1) thisSegment.minimumOrder = desired_cell.v
                    if (j === 2) thisSegment.minimumQuantity = desired_cell.v
                    if (j === 3) thisSegment.merch = desired_cell.v
                    if (j === 4) thisSegment.obs = desired_cell.v
                    if (j === 5) thisSegment.copaymentLab = desired_cell.v
                    if (j === 6) thisSegment.copaymentPh = desired_cell.v
                    if (j === 7) thisSegment.discountInDirect = desired_cell.v
                  }
                }

                let permaRange = XLSX.utils.decode_range(workbook.Sheets['SEG']['!ref']);

                if (!permaRange) console.log('Could not find Sheet for segment SEG');

                let currentWork = workbook.Sheets['SEG'];

                for (let ii = 2; ii <= permaRange.e.r + 1; ii++) {

                  let thisProduct = {};
                  let nameInFirstCol = ''
                  for (let jj = 0; jj <= permaRange.e.c; jj++) {
                    let desired_cell = currentWork[alphaRef[jj] + ii];

                    if (desired_cell !== undefined) {
                      if (jj === 0) nameInFirstCol = desired_cell.v;
                      if (jj === 1) thisProduct.cnp = desired_cell.v;
                      if (jj === 2) thisProduct.name = desired_cell.v;
                      if (jj === 3) thisProduct.pvf = desired_cell.v;
                      if (jj === 4) thisProduct.discount = desired_cell.v;
                      if (jj === 5) thisProduct.iva = desired_cell.v;
                      if (jj === 6) thisProduct.quantity = desired_cell.v;
                      if (jj === 7) thisProduct.minimumOrder = desired_cell.v;
                      if (jj === 8) thisProduct.stepFactor = desired_cell.v;

                      if (jj === 9) {
                        //! Quantidade Objectivo Fixo
                        thisProduct.qtObjFixed = desired_cell.v;
                      }
                      if (jj === 10) thisProduct.qtObjVariable = desired_cell.v;
                      if (jj === 11) thisProduct.qtObjVariableCat = desired_cell.v;
                      if (jj === 12) thisProduct.phAdditionalDiscount = desired_cell.v;
                      if (jj === 13) thisProduct.flashDiscount = desired_cell.v;
                      if (jj === 14) thisProduct.phConsumerDiscount = desired_cell.v;
                      if (jj === 15) thisProduct.hmMonths = desired_cell.v;
                    }
                  }
                  if (typeof thisProduct.cnp !== 'undefined' && thisProduct.cnp !== '' && thisSegment.name === nameInFirstCol) thisSegment.products.push(thisProduct);
                }

                if (thisSegment.products.length >= 1) {
                  mySegments.push(thisSegment)
                }
              }

              let qtObjFixed = [];
              let goodPharmas = 0;
              let badPharmas = 0;

              for (let iii = 2; iii <= range_PH.e.r + 1; iii++) {
                if (worksheet_PH[alphaRef[0] + iii]) {
                  let ANF = worksheet_PH[alphaRef[0] + iii].v;
                  let segments = worksheet_PH[alphaRef[2] + iii].v.split(',');
                  let objMix = (worksheet_PH[alphaRef[3] + iii]) ? worksheet_PH[alphaRef[3] + iii].v : 0;

                  let objFixed = (worksheet_PH[alphaRef[4] + iii]) ? worksheet_PH[alphaRef[4] + iii].v : 0;

                  phObjMix.push({
                    pharma: ANF,
                    amount: objMix
                  });
                  qtObjFixed.push({
                    pharma: ANF,
                    amount: objFixed
                  });

                  segments.map((elSeg) => elSeg.trim());
                 
                  for (const segment of segments) {
                    const foundSegment = mySegments.find((seg) => seg.name === segment);
                    
                    if (foundSegment) {
                      foundSegment.pharm.push(ANF);
                      goodPharmas += 1;
                    } else {
                      console.log(`Input Pharma in segment not found ${ANF}`);
                      badPharmas += 1;
                    }
                  };
                }
              }

              // DO SOMETHING TO SHOW THE USER THE STUFF
              setFileLoaded(true);
              SweetAlert.fire({
                title: 'Ficheiro OK!',
                text: `Foram encontrados ${mySegments.length} segmentos com ${goodPharmas} entradas no total.`,
              });
              props.setFiles(output, mySegments, phObjMix, qtObjFixed);

              return;
            }
            reader.onerror = (e) => {
              console.log(e);
            };
          } else {
            SweetAlert.fire({
              title: 'Erro no Ficheiro!',
              text: `Não foi encontrado um ficheiro bem formatado`,
            });
            return;
          }
        } else if(props.validate === 'manual_score' && fileItem && !fileLoaded) {
          console.log(props)
          props.setFiles(output);
        } else {
          //* Edit Account Photo
          if (props.setOutput) props.setOutput(output);

          //* Form
          if (props.setFiles) props.setFiles(output);
        }
      }}
      onerror={(err) => {
        console.error(err);
      }}
    />
  )
}

export default Filepond;