import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Form, Input, Label, Button, TabContent, TabPane } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from 'axios';

const parse_query_string = (query) => {
    var vars = query.split("&");
    var query_string = {};
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        // If first entry with this name
        if (typeof query_string[pair[0]] === "undefined") {
            query_string[pair[0]] = decodeURIComponent(pair[1]);
            // If second entry with this name
        } else if (typeof query_string[pair[0]] === "string") {
            var arr = [query_string[pair[0]], decodeURIComponent(pair[1])];
            query_string[pair[0]] = arr;
            // If third or later entry with this name
        } else {
            query_string[pair[0]].push(decodeURIComponent(pair[1]));
        }
    }
    return query_string;
}

const ChangePassword = (props) => {
    const history = useHistory();

    const { register, handleSubmit, errors } = useForm();

    const [loading, setLoading] = useState(false);
    const [requestMessage, setRequestMessage] = useState(null);

    const qs = parse_query_string(history.location.search.substring(1));

    const changePwd = (values) => {
        setLoading(true);
        
        const formOptions = {
            rel_usr: qs.rel_usr,
            req_link: qs.req_link_param,
            password: values.password
        }

        return axios.post('/api/v1/accounts/sendresetpass', formOptions)
            .then(user => {
                setLoading(false);
                setRequestMessage("Password alterado");
                setTimeout(() => {
                    setRequestMessage(null);
                }, 2000);
                setTimeout(() => {
                    window.location.href = `${process.env.PUBLIC_URL}/login`;
                }, 3000);
            })
            .catch(error => {
                setLoading(false);
                setRequestMessage("Ocorreu um erro");
                setTimeout(() => {
                    setRequestMessage(null);
                }, 3000);
            });
    }

    if (!qs.rel_usr && qs.req_link_param) {
        history.push('/pages/errors/error500');
    }

    return (
        <Container fluid={true} className="p-0">
            <Row>
                <Col xs="12">
                    <div className="login-card">
                        <div>
                            <div>
                                <a className="logo" href="https://www.maisfarmacia.org/" target="_blank" rel="noopener noreferrer" style={{ width: 200, marginLeft: 'auto', marginRight: 'auto' }}>
                                    <img className="img-fluid for-light" src={require("../assets/images/logo/logo.png")} alt="logotipo" />
                                    <img className="img-fluid for-dark" src={require("../assets/images/logo/logo.png")} alt="logotipo" />
                                </a>
                            </div>
                            <div className="login-main login-tab">
                                <TabContent activeTab={"jwt"} className="content-login">
                                    <TabPane className="fade show" tabId={"jwt"}>
                                        <Form className="theme-form needs-validation" noValidate="" onSubmit={handleSubmit(changePwd)}>
                                            <h2>Alterar Password</h2>
                                            <p>{"Bem-vindo à Plataforma maisFarmácia Business Intelligence."} <br /> {"Grupo maisFarmácia."}</p>
                                            <div className="form-row">
                                                <Label className="col-form-label">{"Nova Password"}</Label>
                                                <Input className="form-control" type={"password"} name="password" innerRef={register({ required: true })} />
                                                <span className="form-error">{errors.password && 'Este campo é obrigatório'}</span>
                                            </div>
                                            <div className="form-row mb-0 mt-4">
                                                <Button color="primary" className="btn-block" type="submit" disabled={loading || requestMessage}>
                                                    {loading ? (<i className="fa fa-spin fa-spinner" />) : requestMessage ? (requestMessage) : ("Alterar Password")}
                                                </Button>
                                            </div>
                                            <div className="form-row mb-0 mt-4">
                                                <NavLink to={"/login"}>{"Login ?"}</NavLink>
                                            </div>
                                        </Form>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default withRouter(ChangePassword);