import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { Container, Row, Col, Card, CardHeader, CardBody, Button } from 'reactstrap'
import { toast } from 'react-toastify';
import SweetAlert from 'sweetalert2';

import { useGetAll, useDelete } from '../../../hooks'

import CustomForm from "../../form";
import Verification from "./verification";
import { apiUrl, rqModel, pathname, schema } from "./settings";

const VisibilidadeAdmin = () => {
    const history = useHistory();
    const uploads = useGetAll(apiUrl, rqModel);

    const [currentUpload, setUpload] = useState(null);
    const [viewType, setViewType] = useState(null);
    const [uploadId, setUploadId] = useState(null);

    const deleteData = useDelete(apiUrl, rqModel, uploadId)

    if (uploads.status === "error") history.push(`${process.env.PUBLIC_URL}/pages/errors/error404`);

    const toggleTable = () => {
        setViewType(null);
        setUpload(null);
    }

    const changeView = (view, model) => {
        setViewType(view);
        setUpload(model);
        if (view === "verification") window.history.pushState(null, null, `${pathname}?view=verification`);
    }

    const deleteRequest = async (_id) => {
        await setUploadId(_id);
        SweetAlert.fire({
            title: 'Atenção!',
            text: 'Confirma que pretende apagar o Upload?',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then(async (result) => {
                if (result.value) {
                    await deleteData.mutate();
                    if (deleteData.isIdle) {
                        toast.success("Upload apagado!", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                }
            })
    }

    return (
        <Fragment>
            <Container fluid={true}>
                {uploads.data ? (
                    <Row>
                        <Col sm="12" className="mt-4">
                            <Card>
                                <CardHeader>
                                    <h5>{viewType === "verification" ? "Validação de Fotografias" : "Uploads"}</h5>
                                </CardHeader>
                                <CardBody>
                                    <div className="todo">
                                        <div className="todo-list-wrapper">
                                            <div className="todo-list-container">
                                                <div className="mark-all-tasks">
                                                    <div className="mark-all-tasks-container">
                                                        {viewType !== "form" && viewType !== "verification" && (
                                                            <>
                                                                <Button color="primary" className="ml-2" onClick={() => changeView("form", null)}>{"Criar"}</Button>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {viewType === "form" ? (
                                            <CustomForm data={currentUpload} toggleTable={toggleTable} schema={schema} rqModel={rqModel} route={apiUrl} pathname={pathname} />
                                        ) : viewType === "verification" ? (
                                            <Verification data={currentUpload} changeView={changeView} pathname={pathname} />
                                        ) : (
                                            <Row>
                                                {uploads.data.results.map((item, i) =>
                                                    <Col xl="4" md="6" key={i}>
                                                        <div className="prooduct-details-box">
                                                            <div className="media">
                                                                <div className="media-body ml-3">
                                                                    <div className="product-name">
                                                                        <h6>{item.name}</h6>
                                                                    </div>
                                                                    <div className="price">
                                                                        <span className="text-muted mr-2">{"Rentabilidade Total"}: {item.total_profitability} €</span>
                                                                        <span className="text-muted mr-2">{"Rentabilidade Média"}: {item.average_profitability} €</span>
                                                                    </div>
                                                                    <div className="avaiabilty">
                                                                        <div className="text-success">{"A Dinamização do Expositor atribui "}{"100/-25 Pts"}</div>
                                                                    </div>
                                                                    <div className="avaiabilty mt-4">
                                                                        <Button color="primary" size="xs" onClick={() => changeView("verification", item)}>Ver</Button>
                                                                        <Button color="primary" className="ml-2" size="xs" onClick={() => changeView("form", item)}>Editar</Button>
                                                                        <Button color="secondary" className="ml-2" size="xs" onClick={() => deleteRequest(item._id)}>Apagar</Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                )}
                                            </Row>
                                        )}
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    <Row style={{ minHeight: 500 }}>
                        <Col sm="12">
                            <div className="loader-box loader-abs">
                                <div className="loader-7" />
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
        </Fragment>
    );
}

export default VisibilidadeAdmin;