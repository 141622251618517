export const apiUrl = "/api/v1/events";
export const rqModel = "events";

export const schema = [
    {
        colSize: "6",
        label: "Título",
        name: "title",
        id: "title",
        type: "text",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        type: "component",
        colSize: "6",
        name: "col",
        id: "col",
    },
    {
        colSize: "4",
        label: "Início do evento",
        name: "start",
        id: "start",
        type: "datepicker",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "4",
        label: "Término do evento",
        name: "end",
        id: "end",
        type: "datepicker",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        type: "component",
        colSize: "4",
        name: "col",
        id: "col",
    },
    {
        colSize: "6",
        label: "Categoria",
        name: "type",
        id: "type",
        type: "select",
        required: true,
        requiredMessage: "Este campo é obrigatório",
        multi: false,
        optionLabel: "label",
        optionValue: "value",
        options: [
            {
                value: "Campanhas ao Utente",
                label: "Campanhas ao Utente",
            },
            {
                value: "Dias comemorativos",
                label: "Dias comemorativos",
            },
            {
                value: "Formações",
                label: "Formações",
            },
            {
                value: "Montras + Expositor",
                label: "Montras + Expositor",
            },
            {
                value: "Publicações Redes Sociais",
                label: "Publicações Redes Sociais",
            },
            {
                value: "Promoção ao utente",
                label: "Promoção ao utente",
            },
            {
                value: "Montra",
                label: "Montra",
            },
            {
                value: "Formações e webinares",
                label: "Formações e webinares",
            },
            {
                value: "Publicações Redes Sociais",
                label: "Publicações Redes Sociais",
            },
            {
                value: "Revista+",
                label: "Revista+",
            },
            {
                value: "Outros",
                label: "Outros",
            },
        ],
    },
    {
        colSize: "6",
        label: "Farmácia",
        name: "anf_code",
        id: "anf_code",
        type: "select",
        multi: true,
        options: null,
        optionLabel: 'name',
        optionValue: 'ANF',
        api: {
            route: "/api/v1/pharma",
            redux: 'Pharmacies',
        },
    },
    {
        name: "hr",
        id: "hr",
        type: "component",
        className: "mb-4"
    },
    {
        colSize: "12",
        label: "Descrição (opcional)",
        name: "description",
        id: "description",
        type: "textarea",
    },
];
