import React from 'react';
import errorImg from '../../assets/images/search-not-found.png';

const NoResults = (props) => {
    return (
        <div className="search-not-found text-center">
            <div>
                <img className="img-fluid second-search" src={errorImg} alt="no-results" />
                <p>{props.text ? props.text : "Não foram encontrados resultados"}</p>
            </div>
        </div>
    );
}

export default NoResults;