import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom'
import { Container, Row, Col, Card, CardHeader, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { Facebook } from 'react-feather';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import SweetAlert from 'sweetalert2'
import moment from 'moment';
import 'moment/locale/pt';
import Select from 'react-select';
import axios from "axios";

import Breadcrumb from '../../../layout/breadcrumb';
import NoResults from '../../ui-kits/noResults';

import { useGetAll, useDelete } from '../../../hooks/';

import Form from "../../form";
import { apiUrl, rqModel, columns, schema } from "./settings";

const FacebookPharma = () => {
    const facebookQuery = useGetAll(apiUrl, rqModel);

    const user = useSelector((content) => content.User.user);
    const pharmacies = useSelector(content => content.Pharmacies.results);
    const loaded = useSelector(content => content.Pharmacies.loaded);

    const [currentPost, setPost] = useState(null);
    const [viewType, setViewType] = useState(null);
    const [userId, setUserId] = useState(null);

    const deleteData = useDelete('/api/v1/fb_pages/post', rqModel, userId);

    const [modal, setModal] = useState(false);
    const [facebookSdkReady, setFacebookSdkReady] = useState(false);

    const [loginSection, setLoginSection] = useState(false);
    const [logoutSection, setLogoutSection] = useState(false);

    const [pageResults, setPageResults] = useState(false);
    const [selectedPharma, selectPharma] = useState(null);
    const [selectedPage, selectPage] = useState(null);
    const [pages, setPages] = useState([]);
    const [accessToken, setAccessToken] = useState(null);
    const [instagramId, setInstagramId] = useState(null);

    useEffect(() => {
        // LOADS FACEBOOK CODE
        if (!facebookSdkReady) {
            window.fbAsyncInit = function () {
                window.FB.init({
                    appId: '570502896633177', // real 930816183683115 // odl 527354624784713 // test 570502896633177
                    autoLogAppEvents: true,
                    xfbml: true,
                    version: 'v10.0'
                });
            };
            (function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) { return; }
                js = d.createElement(s); js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));

            setFacebookSdkReady(true);
        }
    }, []); // eslint-disable-line

    const toggleTable = () => {
        setViewType(null);
        setPost(null);
    }

    const editView = (post) => {
        setViewType("form");
        setPost(post);
    }

    const createSuccess = (data) => {
        toast.success(`Post ${data.name} criado!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const editSuccess = (data) => {
        toast.success(`Post ${data.name} editado!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const deleteRequest = async (_id) => {
        await setUserId(_id);
        SweetAlert.fire({
            title: 'Atenção!',
            text: 'Confirma que pretende apagar o Post?',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then(async (result) => {
                if (result.value) {
                    await deleteData.mutate();
                    if (deleteData.isIdle) {
                        toast.success("Post apagado!", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                }
            })
    }

    const toggleModal = () => {
        setModal(true);
        allowAccess();
    }
    const closeModal = () => {
        setModal(false);
    }

    const getAccountInfo = () => {
        window.FB.api('/me/accounts?fields=name,id,access_token,instagram_business_account{id,username,profile_picture_url}', (response) => {
            console.log(response, 'get account info')
            if (response) {
                setPageResults(true);
                setLogoutSection(true);
                setLoginSection(false);

                selectPharma(pharmacies.find((ph) => ph.ANF === user.anf_code));

                setPages(response.data);
                setAccessToken(response.data[0].access_token);
                setInstagramId(response.data[0].instagram_business_account.id);
            } else {
                // selectPharma(pharmacies.find((ph) => ph.ANF === user.anf_code));

                setPageResults(false);
                setLogoutSection(false);
                setLoginSection(true);
            }
        });
    }

    const logoutFacebook = () => {
        window.FB.api('/me/permissions', 'DELETE', (response) => {
            if (response) {
                window.FB.logout((response) => {
                    window.location.reload();
                });
            } else {
                console.log('Error revoking app');
            }
        });
    }

    const loginFacebook = () => {
        window.FB.login((response) => {
            console.log(response, 'login')
            getAccountInfo();
        }, {
            scope: "email,pages_read_engagement,pages_manage_posts,pages_read_user_content,instagram_basic,instagram_content_publish,pages_show_list",
            return_scopes: true
        });
    }

    const allowAccess = () => {
        window.FB.getLoginStatus((response) => {
            if (response.status === 'connected') {
                setLogoutSection(true);
                setLoginSection(false);
                getAccountInfo();
            }
            else {
                setLoginSection(true);
                setLogoutSection(false);
            }
        });
    }

    const authPage = () => {
        const newFbAuth = {
            page: selectedPage,
            instagram_business_account: instagramId,
            username_associated: sessionStorage.getItem('username'),
            pharm_associated: {
                id: selectedPharma._id,
                name: selectedPharma.name,
                loyalty_type: selectedPharma.loyalty && selectedPharma.loyalty.loyalty_type ? selectedPharma.loyalty.loyalty_type : ""
            }
        };
        axios.post(`api/v1/fb_pages/`, newFbAuth)
            .then((result) => {
                console.log(result);
                window.location.reload();
            })
            .catch((error) => {
                console.error(error);
            });
    }


    return (
        <Fragment>
            <Breadcrumb parent="Marketing" title="Facebook" />
            <Container fluid={true}>
                {!facebookQuery.isLoading && facebookSdkReady && loaded ? (
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader>
                                    <span>{"Post Facebook"}</span>
                                </CardHeader>
                                <CardBody>
                                    <div className="todo">
                                        <div className="todo-list-wrapper">
                                            <div className="todo-list-container">
                                                <div className="mark-all-tasks">
                                                    <div className="mark-all-tasks-container">
                                                        {viewType !== "form" && (
                                                            <>
                                                                {/* <Button color="primary" onClick={toggleModal}>{"Gerir conta facebook"}</Button> */}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {viewType === "form" ? (
                                            <Form data={currentPost} toggleTable={toggleTable} schema={schema} rqModel={rqModel} route={apiUrl} createSuccess={createSuccess} editSuccess={editSuccess} />
                                        ) : currentPost && viewType === "profile" ? (
                                            <Form data={currentPost} toggleTable={toggleTable} schema={schema} rqModel={rqModel} route={apiUrl} createSuccess={createSuccess} editSuccess={editSuccess} />
                                        ) : (
                                            <div className="table-responsive product-table">
                                                <DataTable
                                                    noHeader
                                                    columns={columns}
                                                    data={facebookQuery.data.results.listPosts.map((post) => ({
                                                        name: <NavLink to={`/facebook/${post._id}`}>{post.post_data.name}</NavLink>,
                                                        date: `${post.post_data.scheduled_date ? moment(post.post_data.scheduled_date).calendar() : ""}`,
                                                        isAdHoc: `${post.isAdHoc ? "Sim" : "Não"}`,
                                                        action: <div>
                                                            <Button color="primary" size="xs" className="ml-2" onClick={() => deleteRequest(post._id)}><i className="fa fa-trash"></i></Button>
                                                            <Button color="primary" size="xs" className="ml-2" onClick={() => editView(post)}><i className="fa fa-pencil"></i></Button>
                                                        </div>
                                                    }))}
                                                    highlightOnHover={true}
                                                    pointerOnHover={true}
                                                    pagination={true}
                                                    paginationPerPage={25}
                                                    noDataComponent={<NoResults />}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    <Row style={{ minHeight: 500 }}>
                        <Col sm="12">
                            <div className="loader-box loader-abs">
                                <div className="loader-7" />
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
            <Modal isOpen={modal} toggle={() => closeModal}>
                <ModalHeader toggle={() => closeModal}>
                    {"Gerir conta facebook"}
                </ModalHeader>
                <ModalBody>
                    {loginSection && (
                        <Row className="mb-4">
                            <Col sm="12">
                                <button onClick={loginFacebook} class="btn" style={{ backgroundColor: "#3b5998", color: "#fff", border: "none" }}>
                                    <Facebook style={{ position: "relative", top: 2, width: 22, height: 22, marginRight: 10 }} />
                                    <span style={{ position: "relative", bottom: 3 }}>Login com Facebook</span>
                                </button>
                            </Col>
                        </Row>

                    )}

                    {logoutSection && (
                        <Row className="mb-4">
                            <Col sm="12">
                                <button onClick={logoutFacebook} class="btn" style={{ backgroundColor: "#3b5998", color: "#fff", border: "none" }}>
                                    <Facebook style={{ position: "relative", top: 2, width: 22, height: 22, marginRight: 10 }} />
                                    <span style={{ position: "relative", bottom: 3 }}>Logout</span>
                                </button>
                            </Col>
                        </Row>
                    )}

                    {pageResults && (
                        <Row className="mb-4">
                            <Col sm="12">
                                <Label className="col-form-label pt-0">{"Farmácia"}</Label>
                                <Select
                                    placeholder="Selecionar ..."
                                    className="basic-single"
                                    id={"pharma"}
                                    classNamePrefix="select"
                                    defaultValue={null}
                                    value={selectedPharma}
                                    isClearable
                                    isSearchable
                                    name={"pharma"}
                                    options={pharmacies.filter((ph) => ph.ANF === user.anf_code)}
                                    getOptionLabel={(opt) => opt['name']}
                                    getOptionValue={(opt) => opt['ANF']}
                                    isDisabled
                                    onChange={(values, input) => {
                                        selectPharma(values)
                                    }}
                                />
                            </Col>
                            <Col className="mt-4" sm="12">
                                <Label className="col-form-label pt-0">{"Páginas Disponíveis"}</Label>
                                <Select
                                    placeholder="Selecione a página a dar acesso ..."
                                    className="basic-single"
                                    id={"pages"}
                                    classNamePrefix="select"
                                    defaultValue={null}
                                    value={selectedPage}
                                    isClearable
                                    isSearchable
                                    name={"pages"}
                                    options={pages}
                                    getOptionLabel={(opt) => opt['name']}
                                    getOptionValue={(opt) => opt['id']}
                                    onChange={(values, input) => {
                                        selectPage(values)
                                    }}
                                />
                            </Col>
                            <Col className="mt-4" sm="12">
                                <button onClick={authPage} class="btn" style={{ backgroundColor: "#3b5998", color: "#fff", border: "none" }}>
                                    <Facebook style={{ position: "relative", top: 2, width: 22, height: 22, marginRight: 10 }} />
                                    <span style={{ position: "relative", bottom: 3 }}>Autorizar</span>
                                </button>
                            </Col>
                        </Row>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => closeModal()}>
                        {"Cancelar"}
                    </Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}

export default FacebookPharma

