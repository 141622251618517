import React, { Fragment, useState } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import StepZilla from "react-stepzilla";

import Segment from "./steps/segment";
import Product from "./steps/product";
import Summary from "./steps/summary";

import ProfileTitle from '../../../layout/profileTitle/'

const FormWizard = ({ currentOrder, toggleTable }) => {
    const history = useHistory();

    const [currentSegment, setCurrentSegment] = useState(currentOrder.segments.length === 1 ? currentOrder.segments[0] : null);

    const steps = [
        { name: 'Escolha de Segmento', component: <Segment currentOrder={currentOrder} currentSegment={currentSegment} setCurrentSegment={setCurrentSegment} toggleTable={toggleTable} /> },
        { name: 'Gerir Produtos', component: <Product currentOrder={currentOrder} currentSegment={currentSegment} setCurrentSegment={setCurrentSegment} /> },
        { name: 'Resumo da Encomenda', component: <Summary currentOrder={currentOrder} currentSegment={currentSegment} setCurrentSegment={setCurrentSegment} toggleTable={toggleTable} /> },
    ]

    const leToggle = () => {
        history.push(`${process.env.PUBLIC_URL}/push/encomendas`);
        toggleTable();
    }

    return (
        <Fragment>
            <ProfileTitle title="Responder Encomenda" goBack={leToggle} />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <h5>Ficha de Encomenda - {currentOrder.name}</h5>
                            </CardHeader>
                            <CardBody>
                                <StepZilla
                                    steps={steps}
                                    showSteps={true}
                                    showNavigation={true}
                                    stepsNavigation={false}
                                    preventEnterSubmission={true}
                                    prevBtnOnLastStep={true}
                                    dontValidate={true}
                                    nextButtonText={"Seguinte"}
                                    backButtonText={"Atrás"}
                                    nextTextOnFinalActionStep={"Seguinte"}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>


        </Fragment>
    );
};

export default FormWizard;