import React, { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form'
import { Row, Col, Form, Table, Input, Card, CardHeader, CardBody, Button } from 'reactstrap'
import { toast } from 'react-toastify';

const Product = ({ currentSegment, setCurrentSegment, jumpToStep }) => {
    const { handleSubmit } = useForm();

    const onSubmit = data => {
        currentSegment.products.forEach((product) => {
            if (parseInt(product.quantity) < product.minimumOrder) {
                toastNotif({
                    title: `Produto "${product.name}" Inválido`,
                    text: `Quantidade mínima por CNP não satisfeita. Mínimo ${product.minimumOrder}`,
                });
                product.valid = false;
            } else if (parseInt(product.quantity) % product.stepFactor !== 0) {
                toastNotif({
                    title: `Produto "${product.name}" Inválido`,
                    text: `Múltiplos de unidades não satisfeita. Múltiplo de ${product.stepFactor}`,
                });
                product.valid = false;
            } else {
                product.valid = true;
            }
        });

        if (currentSegment.products.filter((product) => !product.valid).length === 0) {
            jumpToStep(2);
        }
    };

    const toastNotif = ({ title, text }) => {
        toast.error(<><span>{title}</span><br /><span>{text}</span></>, {
            position: toast.POSITION.BOTTOM_CENTER
        });
    };

    let button = document.getElementById("next-button");
    if (button) button.style.display = 'none';

    return (
        <Fragment>
            <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>

                <Row className="mt-4">
                    <Col sm="12">
                        <Table className="mb-4">
                            <thead>
                                <tr>
                                    <th scope="col">CNP</th>
                                    <th scope="col">Nome</th>
                                    <th scope="col">PVF</th>
                                    <th scope="col">Desconto</th>
                                    <th scope="col">Quantidade</th>
                                    <th scope="col">Mín</th>
                                    <th scope="col">Múlt</th>
                                    <th scope="col">Valor Final</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentSegment.products.map((product, index) => {
                                    let myProcessDiscount = (100 - parseInt(product.discount)) / 100;
                                    let totalRow = 0

                                    if (product.quantity) {
                                        totalRow = (product.quantity * parseFloat(product.pvf) * myProcessDiscount);
                                    } else {
                                        totalRow = (1 * parseFloat(product.pvf) * myProcessDiscount);
                                    }
                                    
                                    return (
                                        <tr key={index}>
                                            <th scope="row">{product.cnp}</th>
                                            <td>{product.name}</td>
                                            <td>{product.pvf} €</td>
                                            <td>{product.discount} %</td>
                                            <td>
                                                <Input
                                                    type="number"
                                                    className="form-control"
                                                    name="product_z_quantity"
                                                    step={product.stepFactor}
                                                    value={product.quantity}
                                                    onChange={(ev) => {
                                                        const productEdited = currentSegment.products.find((leProduct) => product.cnp === leProduct.cnp)
                                                        productEdited.quantity = ev.target.value
                                                        const newSegment = { ...currentSegment }
                                                        setCurrentSegment(newSegment);
                                                    }}
                                                />
                                            </td>
                                            <td>{product.minimumOrder}</td>
                                            <td>{product.stepFactor}</td>
                                            <td>{totalRow}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        <Card>
                            <CardHeader>
                                <h5>Rentabilidade2</h5>
                            </CardHeader>
                            <CardBody></CardBody>
                        </Card>
                    </Col>
                </Row>
                <Button color="primary" className="pull-right" onClick={onSubmit}>{"Seguinte"}</Button>
            </Form>
        </Fragment>
    );
};

export default Product;