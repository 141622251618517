export const apiUrl = "/api/v1/newsletters";
export const rqModel = "newsletters";
export const pathname = `${process.env.PUBLIC_URL}/centro/newsletters`;

export const columns = [
    {
        name: "Data",
        selector: "createdOn",
        sortable: true,
        center: true,
    },
    {
        name: "Título",
        selector: "title",
        sortable: true,
        center: true,
    },
    {
        name: "Opções",
        selector: "action",
        sortable: false,
        center: true,
    },
];

export const schema = [
    {
        colSize: "6",
        label: "Título",
        name: "title",
        id: "name",
        type: "text",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "6",
        label: "Tag",
        name: "type",
        id: "name",
        type: "text",
    },
    {
        colSize: "6",
        label: "Dica da Semana",
        name: "dicas",
        id: "dicas",
        type: "select",
        multi: true,
        options: null,
        optionLabel: "title",
        optionValue: "_id",
        backendPopulated: true,
        api: {
            route: "/api/v2/news/bytype?type=dicas",
            editRoute: "/api/v2/news/bytype?type=dicas"
        },
    },
    {
        colSize: "6",
        label: "Quadro Operacional",
        name: "quadroOperacional",
        id: "quadroOperacional",
        type: "select",
        multi: true,
        options: null,
        optionLabel: "title",
        optionValue: "_id",
        backendPopulated: true,
        api: {
            route: "/api/v2/news/bytype?type=quadroOperacional",
            editRoute: "/api/v2/news/bytype?type=quadroOperacional"
        },
    },
    {
        colSize: "6",
        label: "Campanhas",
        name: "campanhas",
        id: "campanhas",
        type: "select",
        multi: true,
        options: null,
        optionLabel: "title",
        optionValue: "_id",
        backendPopulated: true,
        api: {
            route: "/api/v2/news/bytype?type=campanhas",
            editRoute: "/api/v2/news/bytype?type=campanhas"

        },
    },
    {
        colSize: "6",
        label: "Negociações",
        name: "negociacoes",
        id: "negociacoes",
        type: "select",
        multi: true,
        options: null,
        optionLabel: "title",
        optionValue: "_id",
        backendPopulated: true,
        api: {
            route: "/api/v2/news/bytype?type=negociacoes",
            editRoute: "/api/v2/news/bytype?type=negociacoes"
        },
    },
    {
        colSize: "6",
        label: "Formações e Serviços",
        name: "formacoes",
        id: "formacoes",
        type: "select",
        multi: true,
        options: null,
        optionLabel: "title",
        optionValue: "_id",
        backendPopulated: true,
        api: {
            route: "/api/v2/news/bytype?type=formacoes",
            editRoute: "/api/v2/news/bytype?type=formacoes"
        },
    },
    {
        colSize: "6",
        label: "Outras Informações",
        name: "outras",
        id: "outras",
        type: "select",
        multi: true,
        options: null,
        optionLabel: "title",
        optionValue: "_id",
        backendPopulated: true,
        api: {
            route: "/api/v2/news/bytype?type=outras",
            editRoute: "/api/v2/news/bytype?type=outras"
        },
    },
];
