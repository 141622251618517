import configDB from '../../data/customizer/config'
const primary = localStorage.getItem('default_color') || configDB.data.color.primary_color;
const secondary = localStorage.getItem('secondary_color') || configDB.data.color.secondary_color;

export const lineChartData = {
    labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Maio', 'Junh', 'Julh', 'Agost', 'Set', 'Out', 'Nov', 'Dez'],
    datasets: [
        {
            data: [10, 59, 80, 81, 56, 55, 40, 10, 10, 10, 10, 10],
            borderColor: primary,
            backgroundColor: "rgba(145, 46, 252, 0.4)",
            borderWidth: 2,
        },
        {
            data: [80, 132, 33, 12, 89, 99, 12, 0, 83, 50, 32, 12],
            borderColor: secondary,
            backgroundColor: "rgba(247, 49, 100, 0.4)",
            borderWidth: 2,
        }
    ],
    plugins: {
        datalabels: {
            display: false,
            color: 'white'
        }
    }
}

export const lineChartOptions = {
    maintainAspectRatio: true,
    legend: {
        display: false,
    },
    scales: {
        xAxes: [{
            stacked: false,
        }],
        yAxes: [{
            stacked: false,
        }]
    },
    plugins: {
        datalabels: {
            display: false,
        }
    }
}