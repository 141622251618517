import React, { Fragment, useState } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import SweetAlert from 'sweetalert2';

import DataTableExtensions from 'react-data-table-component-extensions';
import "react-data-table-component-extensions/dist/index.css";

import Breadcrumb from '../../../layout/breadcrumb';

import { useGetAll, useDelete } from '../../../hooks/';

import CustomForm from "../../form";
import { apiUrl, rqModel, pathname, columns, schema } from "./settings";

const Suppliers = () => {
    const suppliersQuery = useGetAll(apiUrl, rqModel);

    const [currentSupplier, setSupplier] = useState(null);
    const [viewType, setViewType] = useState(null);
    const [supplierId, setSupplierId] = useState(null);

    const deleteData = useDelete(apiUrl, rqModel, supplierId)

    const toggleTable = () => {
        setViewType(null);
        setSupplier(null);
    }

    const createView = () => {
        setViewType("form");
        setSupplier(null);
        window.history.pushState(null, null, `${pathname}?view=create`);
    }

    const editView = (supplier) => {
        setViewType("form");
        setSupplier(supplier);
        window.history.pushState(null, null, `${pathname}?view=edit`);
    }

    const createSuccess = (data) => {
        toast.success(`Fornecedor de Loja ${data.name} criado!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const editSuccess = (data) => {
        toast.success(`Fornecedor de Loja ${data.name} editado!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const deleteRequest = async (_id) => {
        await setSupplierId(_id);
        SweetAlert.fire({
            title: 'Atenção!',
            text: 'Confirma que pretende apagar o Fornecedor?',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then(async (result) => {
                if (result.value) {
                    await deleteData.mutate();
                    if (deleteData.isIdle) {
                        toast.success("Fornecedor apagado!", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                }
            })
    }

    return (
        <Fragment>
            <Breadcrumb parent="Loja" title="Fornecedores" />
            <Container fluid={true}>
                {!suppliersQuery.isLoading ? (
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader>
                                    <span>{"Descrição"}</span>
                                </CardHeader>
                                <CardBody>
                                    <div className="todo">
                                        <div className="todo-list-wrapper">
                                            <div className="todo-list-container">
                                                <div className="mark-all-tasks">
                                                    <div className="mark-all-tasks-container">
                                                        {viewType !== "form" && <Button color="primary" onClick={createView}>{"Criar"}</Button>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {viewType === "form" ? (
                                            <CustomForm data={currentSupplier} toggleTable={toggleTable} schema={schema} rqModel={rqModel} route={apiUrl} pathname={pathname} createSuccess={createSuccess} editSuccess={editSuccess} />
                                        ) : currentSupplier && viewType === "profile" ? (
                                            <CustomForm data={currentSupplier} toggleTable={toggleTable} schema={schema} rqModel={rqModel} route={apiUrl} pathname={pathname} createSuccess={createSuccess} editSuccess={editSuccess} />
                                        ) : (
                                            <div className="table-responsive product-table">
                                                <DataTableExtensions
                                                    defaultSortField="name"
                                                    defaultSortAsc={true}
                                                    columns={columns}
                                                    filterPlaceholder={"Procurar..."}
                                                    filterHidden={false}
                                                    export={false}
                                                    print={false}
                                                    data={suppliersQuery.data.results.map((supplier) => ({
                                                        name: `${supplier.name}`,
                                                        code: `${supplier.code}`,
                                                        action: <div>
                                                            <Button color="primary" size="xs" className="ml-2" onClick={() => deleteRequest(supplier._id)}><i className="fa fa-trash"></i></Button>
                                                            <Button color="primary" size="xs" className="ml-2" onClick={() => editView(supplier)}><i className="fa fa-pencil"></i></Button>
                                                        </div>
                                                    }))}
                                                >
                                                    <DataTable
                                                        defaultSortField="name"
                                                        defaultSortAsc={true}
                                                        noHeader
                                                        columns={columns}
                                                        data={suppliersQuery.data.results.map((supplier) => ({
                                                            name: `${supplier.name}`,
                                                            code: `${supplier.code}`,
                                                            action: <div>
                                                                <Button color="primary" size="xs" className="ml-2" onClick={() => deleteRequest(supplier._id)}><i className="fa fa-trash"></i></Button>
                                                                <Button color="primary" size="xs" className="ml-2" onClick={() => editView(supplier)}><i className="fa fa-pencil"></i></Button>
                                                            </div>
                                                        }))}
                                                        highlightOnHover={true}
                                                        pointerOnHover={true}
                                                        pagination={true}
                                                        paginationPerPage={25}
                                                    />
                                                </DataTableExtensions>
                                            </div>
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    <Row style={{ minHeight: 500 }}>
                        <Col sm="12">
                            <div className="loader-box loader-abs">
                                <div className="loader-7" />
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
        </Fragment>
    )
}

export default Suppliers
