export const apiUrl = "/api/v1/commercials";
export const rqModel = "commercials";
export const pathname = `${process.env.PUBLIC_URL}/marketing/music`;

export const columns = [
    {
        name: "Nome",
        selector: "name",
        sortable: true,
        center: true,
    },
    {
        name: "Url",
        selector: "url",
        sortable: true,
        center: true,
    },
    {
        name: "nº de Farmácias",
        selector: "ANFs",
        sortable: true,
        center: true,
    },
    {
        name: "Opções",
        selector: "action",
        sortable: false,
        center: true,
    },
];

export const schema = [
    {
        colSize: "6",
        label: "Título",
        name: "name",
        id: "name",
        type: "text",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "6",
        label: "Intervalo (Valor em minutos)",
        name: "interval",
        id: "interval",
        type: "number",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "6",
        label: "Farmácias",
        name: "ANFs",
        id: "ANFs",
        type: "select",
        required: true,
        requiredMessage: "Este campo é obrigatório",
        multi: true,
        options: null,
        optionLabel: 'name',
        optionValue: 'ANF',
        api: {
            route: "/api/v1/pharma",
            redux: 'Pharmacies',
        },
    },
    {
        colSize: "6",
        label: "Encomendas Push",
        name: "fPush",
        id: "fPush",
        type: "select",
        required: true,
        requiredMessage: "Este campo é obrigatório",
        multi: false,
        options: null,
        optionLabel: 'name',
        optionValue: '_id',
        backendPopulated: true,
        api: {
            route: "/api/v2/inqueritos",
        },
    },
    {
        colSize: "6",
        label: "Serviços",
        name: "fService",
        id: "fService",
        type: "select",
        required: true,
        requiredMessage: "Este campo é obrigatório",
        multi: false,
        optionLabel: 'label',
        optionValue: 'value',
        options: [
            {
                value: "dietaea",
                label: "Dieta EasySlim",
            },
            {
                value: "nutric",
                label: "Nutrição Clínica",
            },
            {
                value: "podol",
                label: "Podologia",
            },
            {
                value: "osteo",
                label: "Osteopatia",
            },
            {
                value: "enferm",
                label: "Enfermagem",
            },
            {
                value: "consulfarm",
                label: "Consulta Farmacêutica",
            },
            {
                value: "consulhomeo",
                label: "Consulta de Homeopatia",
            },
            {
                value: "nutriviv",
                label: "Nutrição ViV",
            },
            {
                value: "fisio",
                label: "Fisioterapia",
            },
            {
                value: "dispensa",
                label: "Dispensa de Medicação Individualizada",
            },
            {
                value: "checksaude",
                label: "Check Saúde",
            },
            {
                value: "admin_vac",
                label: "Administração de Vacinas e Injectáveis",
            },
            {
                value: "cessacao_tab",
                label: "Consulta de Cessação Tabágica",
            },
            {
                value: "manipul",
                label: "Preparação de Manipulados",
            },
            {
                value: "travely",
                label: "Aconselhamento do Viajante",
            },
            {
                value: "sveti",
                label: "SVETI",
            },
        ]
    },
    {
        colSize: "12",
        label: "Ficheiro Audio",
        name: "files",
        id: "files",
        type: "file",
        content: "file",
        multi: false,
        route: "/api/v1/commercials",
    },
];
